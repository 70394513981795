import React, {useEffect, useState,forwardRef, useImperativeHandle} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {Header, IconView, PageLoading} from "../Components/PageComponents";
import {useLocation} from "react-router-dom";
import {
    SegmentedControl,
    Stack,
    Paper,
    Group,
    Divider,
    Text,
    Button,
    Grid,
    Badge,
    Modal,
    TextInput, Image
} from "@mantine/core";
import {BoldText, SmallText} from "../Components/TextComponents";
import {IconArrowMoveDown, IconArrowMoveUp, IconFile, IconPlus, IconTrash} from "@tabler/icons";
import FormComponents from "./FormComponents";
import {Colors} from "../../Constants/Colors";
import {
    CheckboxGroup, DatePickerView, DropdownList,
    Footer,
    Heading, ImageFlow,
    RadioButtonsGroup,
    TextAreaView,
    TextInputView
} from "../WhatsappFlow/FlowComponents";
import {ActionShowAlert} from "../../store/actions/alert";

const numbers = ["ZER0","One","Two","Three","Four","Five","Six","Seven","Eight","Nine"]
const FormUpdate = () =>{
    const {state} = useLocation();
    const {flowId,flowName} = state;
    const {sessionId} = useSelector(state => state.active);
    const [jsonDetails,setJsonDetails] = useState();
    const [errors,setErrors] = useState();
    const [leadsHeaders,setLeadsHeaders] = useState([]);
    const [valid,setValid] = useState();
    const [screens,setScreens] = useState([]);
    const [selected,setSelected] = useState();
    const [selectedScreen,setSelectedScreen] = useState();
    const[isLoading,setIsLoading] = useState(false);
    const[isUploading,setIsUploading] = useState(false);
    const[previewLoad,setPreviewLoad] = useState(false);
    const[render,setRender] = useState(1);
    const[isAdd,setIsAdd] = useState();
    const[isAddScreen,setIsAddScreen] = useState();
    const [isEdit,setIsEdit] = useState(false);
    const dispatch = useDispatch();

    const FormDetails = async () =>{
        setIsLoading(true);
        const data = await RequestGet(`${CloudApi.whatsappFlows}/json/${sessionId}/${flowId}`);
        setJsonDetails(data);
        const screens = data?.screens?.map(a =>{
            const isFooter = a?.layout?.children?.[0]?.children?.find(a => a?.type === "Footer");
            const comp = a?.layout?.children?.[0]?.children || [];
            if(!isFooter){
                comp.push({type: "Footer",label: "Complete","on-click-action": {name: "complete"}});
            }
            return {id: a?.id, title: a?.title, components: comp,terminal: a?.terminal}
        })
        setScreens(screens || []);
        setSelected(screens?.[0]?.id);
        const headers = screens?.find(a => a?.terminal === true)?.components?.find(a => a?.type === "Footer")?.["on-click-action"]?.payload || {};
        setLeadsHeaders(Object.keys(headers)?.filter(a => a !== "flowId") || []);
        setIsLoading(false);
    }
    useEffect(() =>{
        FormDetails();
    },[]);
    useEffect(() =>{
       if(selected) setSelectedScreen(screens?.find(a => a?.id === selected));
    },[selected]);

    const updateScreen = ({id,title}) =>{
        const isExists = screens?.find(a => a?.id === id);
        let s = screens;
        if(isExists){
            s = screens?.map(a => a?.id === id ? {...a, title}: a)
        }else{
            s.push({id,title,components: [{type: "Footer",label: "Complete","on-click-action": {name: "complete"}}]});
        }
        if(selectedScreen?.id === id){
            setSelectedScreen(s?.find(a => a?.id === id));
        }
        setScreens(s);
        setRender(render+1);
        setIsAddScreen();
        setIsEdit(true);
    }
    const handleDelete = () =>{
        const s = screens?.filter(a => a?.id !== selected);
        setScreens(s || []);
        setSelected(s?.[0]?.id);
        setRender(render+1);
        setIsEdit(true);
    }
    const onUpdateComponent = ({data}) =>{
        const s = screens?.find(a => a?.id === selected);
        let comp = s?.components || [];
        if(data?.type === "Footer" && comp?.map(a => a?.type).includes("Footer") && ((comp?.[isAdd?.index]?.type !== "Footer" && isAdd?.type !== "Add") || isAdd?.type === "Add") ){
            dispatch(ActionShowAlert({message: "Per Screen one Footer only Allowed",color: "red"}));
        }else if(isAdd?.type === "Add"){
            comp.splice(-1, 0, data);
        }else{
            comp = comp?.map((a,i) => i === isAdd?.index ? data : a)
        }
        s.components = comp;
        setSelectedScreen(s);
        setScreens(screens?.map(a => a?.id === selected ? s : a));
        setIsAdd()
        setIsEdit(true);
        setRender(render+1);
    }
    const DeleteComponent = (index) =>{
        const s = selectedScreen;
        let comp = s?.components?.filter((a,i )=> i !== index);
        const formatted =  {...s,components: comp};
        setSelectedScreen(formatted);
        setScreens(screens?.map(a => a?.id === selected ? formatted : a));
        setRender(render+1);
        setIsEdit(true);
    }
    function moveElement(arr, fromIndex, toIndex) {
        if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) {
            return arr; // No movement needed if indices are out of bounds
        }
        const newArr = [...arr];
        const [element] = newArr.splice(fromIndex, 1); // Remove the element from the fromIndex
        newArr.splice(toIndex, 0, element); // Insert the element at the toIndex
        return newArr;
    }

    const MoveUpComponent = (index) =>{
        const s = selectedScreen;
        let comp = moveElement(s?.components, index,index -1);
        const formatted =  {...s,components: comp};
        setSelectedScreen(formatted);
        setScreens(screens?.map(a => a?.id === selected ? formatted : a));
        setRender(render+1);
        setIsEdit(true);
    }
    const MoveDownComponent = (index) =>{
        const s = selectedScreen;
        let comp = moveElement(s?.components, index,index + 1);
        const formatted =  {...s,components: comp};
        setSelectedScreen(formatted);
        setScreens(screens?.map(a => a?.id === selected ? formatted : a));
        setRender(render+1);
        setIsEdit(true);
    }

    const renderComponent = (a,i) =>{
        switch (a?.type){
            case "Text":
            case "TextHeading":
            case "TextSubheading":
            case "TextBody":
            case "TextCaption":
                return <Text>{a?.text}</Text>
            case "TextInput":
            case "TextArea":
            case "CheckboxGroup":
            case "RadioButtonsGroup":
            case "Dropdown":
            case "DatePicker":
                return <>
                    <BoldText text={a?.label} size={"sm"} mb={5} />
                    {a?.["data-source"]?.length > 0 && <Group my={10} sx={{gap: 3}}>{a?.["data-source"]?.map(b => <Badge>{b?.title}</Badge>)}</Group>}
                    <Text size={"xs"} mt={10}>Lead Header: <b>{a?.name}</b></Text>
                </>
            case "Image":
                return <Image src={`data:image/jpg;base64,${a?.src}`} width={100} height={100} alt={"Image"} />
            case "Footer":
                return <Group position={"apart"}>
                    <BoldText text={a?.label} size={"sm"} mx={10} />
                    <Text size={"xs"} color={"dimmed"}>
                        <b>{a?.["on-click-action"]?.name}</b>
                        {a?.["on-click-action"]?.next?.name &&<b>( {a?.["on-click-action"]?.next?.name} )</b>}
                    </Text>
                </Group>
        }
    }

    const checkIsValid = () =>{
        let validList = [];
        let s =  screens?.map((a,i) => {
            const payload = a?.components?.map(a => a?.name)?.filter(a => !!a);
            const footer = a?.components?.find(a => a?.type === "Footer");
            return {data: a,payload, terminal: footer?.["on-click-action"]?.name === "complete"};
        });
        const terminal = s?.filter(a => a?.terminal === true)?.length;
        if(terminal > 1 || terminal === 0){
            return {isValid: false, message: terminal > 1 ? `More than One Footer With Complete Type`: `No Footer With Complete`}
        }else{
            let payload = {flowId,payload:"${data}"};
            s?.map(a =>{
                const screenId = a?.data?.id;
                a?.payload?.map(b => {
                    payload[b] = a?.terminal === true ? "${form."+ b +"}" :"${screen."+screenId+".form."+b+"}";
                });
            });
            s = s?.map(a =>{
                if(a?.terminal === true){
                    const comp = a?.data?.components?.map(b => {
                        if(b?.type === "Footer") return {...b,"on-click-action": {...b?.["on-click-action"],payload}}
                        return b;
                    });
                    return  {...a?.data,components: comp}
                }else{
                    return  a?.data;
                }
            })
            setLeadsHeaders(Object.keys(payload)?.filter(a => a !== "flowId") || []);
            return {isValid: !validList?.includes(false), screens:s}
        }
    }
    const uploadJson = async () =>{
        const {isValid,screens,message} = checkIsValid();
        if(isValid){
            setIsUploading(true);
            const {response} =  await RequestPost(`${CloudApi.whatsappFlows}/update-json/${sessionId}/${flowId}`, {screens});
            setErrors(response?.validation_errors);
            if(response?.success === true){
                dispatch(ActionShowAlert({message: `Updated ${response?.validation_errors?.length > 0 ? `with ${response?.validation_errors?.length} Errors`: "Successfully"}`}));
                setIsUploading(false);
                FormDetails();
            }else{
                setIsUploading(false);
                dispatch(ActionShowAlert({message: "Something Went Wrong"}));
            }

        }else{
            setErrors([{message}])
        }
    }
    const FlowPreview = async () =>{
        setPreviewLoad(true);
        const data = await RequestGet(`${CloudApi.whatsappFlows}/preview/${sessionId}/${flowId}`);
        window.open(data?.url);
        setPreviewLoad(false);
    }

    return <>
        <Header
            link={{name: "Whatsapp Forms", link: `forms`}}
            title={flowName}
            subTitle={"Edit Your Whatsapp Form Here"}
            buttons={[{type: "add", title:"Add Screen"},{type: "view", title:"Preview"},{type: "refresh", title:"Refresh"}]}
            buttonProps={[
                {onClick: () => setIsAddScreen({type: "Add"})},
                {onClick: FlowPreview, loading: previewLoad},
                {onClick: FormDetails, loading: isLoading}
            ]}
        />
        <PageLoading isLoading={isLoading}>
            <Group position={"apart"}>
                <SegmentedControl size={"xs"} color={"violet"} data={screens?.map(a => ({label: a?.id,value: a?.id}))} value={selected} onChange={setSelected} />
                <Button leftIcon={<IconFile size={14} />} compact loading={isUploading} onClick={uploadJson}>Save Form Details</Button>
            </Group>
            {errors?.length > 0 && <Group position={"apart"} mb={5}>
                <Stack sx={{backgroundColor: "lightpink", gap: 1}}>
                    {errors?.map((a, i) => <SmallText text={` ${i + 1}. ${a?.message}`}/>)}
                </Stack>
            </Group>}
            {leadsHeaders?.length > 0 && <Paper shadow={"md"} withBorder p={3}>
                <Group>
                    <BoldText text={"Lead Headers : "} />
                    {leadsHeaders?.map(a => <SmallText text={a} sx={{border: "1px solid black" , borderRadius: 10, padding: 3}} />)}
                </Group>
            </Paper>}
            <Stack sx={{gap: 3}}>
                <Paper shadow={"md"} withBorder p={5}>
                    <Group position={"apart"} >
                            <Group>
                                <Text size={"xs"} weight={"bold"} variant={"gradient"}>{selectedScreen?.id}</Text>
                            </Group>
                            <Group>
                                <Group>
                                    <BoldText text={"Title: "} />
                                    <Text size={"xs"}>{selectedScreen?.title}</Text>
                                </Group>
                                <IconView iconType={"edit"} onClick={() => setIsAddScreen({type: "Edit",details: {id: selectedScreen?.id,title: selectedScreen?.title}})} />
                            </Group>
                        <Group>
                            <Button compact leftIcon={<IconPlus />} onClick={() => setIsAdd({type: "Add"})}> Add</Button>
                            {screens?.length > 1 && <Button compact leftIcon={<IconTrash/>} variant={"filled"} color={"red"}
                                     onClick={handleDelete}> Delete Screen</Button>}
                        </Group>
                    </Group>
                </Paper>
                <Grid>
                    {selectedScreen?.components?.map((a,i) =>{
                        return <Grid.Col span={6}>
                            <Paper shadow={"md"} withBorder p={5}>
                                <Group position={"apart"} px={5}>
                                    <Group>
                                        <Text weight={"bold"} color={Colors.Primary}>{i+1}. {a?.type}</Text>
                                        {a?.required && <Badge size={"xs"}>required</Badge>}
                                    </Group>
                                    <Group>
                                        <IconView iconType={"edit"} onClick={() => setIsAdd({type: "Edit", details: a,index: i})}  />
                                        {a?.type !== "Footer" && <>
                                            {i !== 0 && <IconView icon={<IconArrowMoveUp />}  onClick={() => MoveUpComponent(i)} label={"Move Up"} />}
                                            {i !== selectedScreen?.components?.length - 2  && <IconView icon={<IconArrowMoveDown />}   onClick={() => MoveDownComponent(i)} label={"Move Down"} />}
                                            <IconView iconType={"delete"} onClick={() => DeleteComponent(i)}/>
                                        </>}
                                    </Group>
                                </Group>
                            <Divider my={3} />
                            {renderComponent(a,i)}
                        </Paper>
                        </Grid.Col>
                    })}
                </Grid>
                {isAdd && <FormComponents editType={isAdd?.type} details={isAdd?.details} onClose={setIsAdd} onSave={onUpdateComponent} screens={screens} screenId={selectedScreen?.id} />}
                {isAddScreen && <AddScreen screens={screens} type={isAddScreen?.type} details={isAddScreen?.details} onClose={() => setIsAddScreen()} updateScreen={updateScreen}  />}
            </Stack>
        </PageLoading>
    </>
}
export default FormUpdate;

const AddScreen = ({onClose,type,details,screens,updateScreen}) =>{
    const [id,setId] = useState(details?.id  || `SCREEN_${(screens?.length + 1).toString().split("")?.map(a => numbers?.[a]).join("_").toUpperCase()}`);
    const [title,setTitle] = useState(details?.title || "");
    const [isError,setIsError] = useState(false);

    const onSubmit = () =>{
        if(id?.trim() !== "" && title?.trim() !== "" && containsOnlyAlphabetsAndUnderscore(id) === true){
            if(type === "Add" && screens?.map(a => a?.id)?.includes(id)){
                setIsError(true);
            }else{
                setIsError(false);
                updateScreen({id,title});
            }
        }else{
            setIsError(true);
        }
    }
    function containsOnlyAlphabetsAndUnderscore(str) {
        const regex = /^[A-Za-z_]+$/;
        return regex.test(str);
    }

    return <Modal opened={true} onClose={onClose} title={<Text weight={"bold"} mx={10}>{type} Screen</Text>}>
        <Stack sx={{gap: 3}}>
            {type === "Add" ? <TextInput size={"xs"} label={"Screen Id"} value={id} onChange={(e) => setId(e.target.value)} error={isError && id?.trim() === ""? "Screen Id is Required": containsOnlyAlphabetsAndUnderscore(id) === false ? "Id should contain Alphabets and _": screens?.map(a => a?.id)?.includes(id)? "Screen Id Already Exists" :""} />: <Text>{id}</Text>}
            <TextInput size={"xs"} label={"Screen Title"} value={title} onChange={(e) => setTitle(e.target.value)} />
            <Group position={"center"} mt={10}>
                <Button onClick={onSubmit}>{type} Screen</Button>
            </Group>
        </Stack>
    </Modal>
}

import React, {Component, useEffect, useState} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {Group, Button, Loader, Grid} from "@mantine/core";
import {AdminApi, RequestGet} from "../Layouts/RequestManager";
import {convertUnixToFormat} from "../Constants/Functions";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const CpuChart = () =>{
    const [isLoading,setIsLoading] = useState(true);
    const [details,setDetails] = useState();
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'WBA - Cpu and DB usage',
            },
        },
    };
    useEffect(()=>{
        getDetails();
    },[])

    const getDetails = async () =>{
        setIsLoading(true);
        const result = await RequestGet(AdminApi.cpuStats);
        const res = result?.data?.reverse();
        const labels = res?.map(a => convertUnixToFormat(a?.time,'LT'))
        const cpu = res?.map(a => a?.cpu)
        const memory = res?.map(a => a?.storage)
        const ram = res?.map(a => a?.ram);
        const data = {
            labels,
            datasets: [
                {
                    label: 'Cpu',
                    data: cpu,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: 'RAM',
                    data: ram,
                    borderColor: 'rgb(255, 200, 99)',
                    backgroundColor: 'rgba(255, 99, 100, 0.5)',
                },
                {
                    label: 'DB',
                    data: memory,
                    borderColor: 'rgb(255, 99, 12)',
                    backgroundColor: 'rgba(255, 99, 12, 0.5)',
                },

            ],
        };
        setDetails(data);
        setIsLoading(false);
    }



    return <>
        {isLoading ? <Loader /> : <Line options={options} data={details} />}
    </>;
}

export default CpuChart;

import React,{useState,useEffect} from 'react';
import {Anchor, Grid, Group, Pagination, Paper, Stack, Text, TextInput} from "@mantine/core";
import {IconSquare, IconSquareCheck} from "@tabler/icons";
import _ from "underscore";
import {SmallText} from "../Components/TextComponents";

const NumberSelectView = ({data,selected,updateValues,maxLimit,showValue,error}) =>{
    const [page,setPage] = useState(1);
    const [totalPage,setTotalPage] = useState(Math.ceil(data?.length / 20));
    const [search,setSearch] = useState("");
    const [filterData,setFilterData] = useState(data);

    useEffect(() =>{
        setTotalPage(Math.ceil(data?.length / 20));
        // setPage(1)
        setFilterData(data);
    },[data]);
    useEffect(() =>{
        const results = data?.filter(a => a?.label?.toLowerCase()?.includes(search?.toLowerCase()) || (showValue && a?.value?.toLowerCase()?.includes(search?.toLowerCase())));
        setFilterData(results);
        setTotalPage(Math.ceil(results?.length / 20));
        setPage(1);
    },[search]);

    const onSelect = (value) =>{
        let values = selected?.includes(value) ? selected.filter(a => a !== value) : [...selected,value];
        values= values?.slice(0,maxLimit);
        updateValues(values);
    }
    const DeselectAll = () => updateValues([]);
    const SelectAll = () => {
        const out = _.unique(_.pluck(data, 'value'));
        updateValues(maxLimit ? out?.slice(0,maxLimit): out);
    }

    return <>
        {data?.length > 0 ?  <Grid.Col span={12}>
            <Group position={'apart'}>
                <Group>
                    <TextInput size={'xs'} fullwidth placeholder={'search'} variant={'filled'} value={search} onChange={(e) => setSearch(e.target.value)}/>
                    <Group sx={{cursor: "pointer"}} onClick={selected?.length > 0 ? DeselectAll: SelectAll}>
                        {(selected.length === data.length || selected.length === maxLimit) ? <IconSquareCheck /> : <IconSquare />}
                        <Text>{selected?.length > 0 ? `Deselect all` : `Select All`}</Text>
                    </Group>
                    <Text weight={'bold'} color={error && selected?.length <= 0 ? 'red': 'black'}>Selected: {selected?.length}/{data?.length}</Text>
                    {maxLimit && <Text weight={'bold'} color={'orange'}>MaxLimit: {maxLimit}</Text>}
                </Group>
                <Group>
                    <Pagination size={'sm'} sx={{cursor: "pointer"}} total={totalPage} page={page} onChange={setPage}/>
                </Group>
            </Group>
        </Grid.Col>: <Grid.Col span={12}>
            <SmallText>No Data Found</SmallText>
        </Grid.Col>}
        {filterData?.slice((page - 1) * 20,page *20)?.map(a => {
            return <Grid.Col span={3} sx={{gap: 2}}>
                <Paper shadow={'md'} withBorder onClick={() => onSelect(a?.value)} >
                    <Group>
                        {selected?.includes(a?.value) ? <IconSquareCheck /> : <IconSquare color={selected?.length >= maxLimit && !selected?.includes(a?.value)? 'lightgray': 'black'} />}
                        <Stack sx={{gap: 1}}>
                            <Text size={'sm'} color={selected?.length >= maxLimit && !selected?.includes(a?.value)? 'lightgray': 'black'}>{a?.label}</Text>
                            {showValue && <Text size={'sm'} color={'dimmed'}>{a?.value?.replace('@s.whatsapp.net', '')}</Text>}
                        </Stack>
                    </Group>
                </Paper>
            </Grid.Col>
        })}
    </>
}
export default NumberSelectView;

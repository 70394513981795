import React, {useEffect, useRef, useState} from "react";
import {Button, Grid, Group, Paper, Text} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import SequenceBuilder from "./SequenceBuilder";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {Header, PageLoading} from "../Components/PageComponents";
import {ActionShowAlert} from "../../store/actions/alert";
const nodeList = [
    // {label: "Send Message", value: "sendMessage"},
    {label: "Message", value: "message"},
    {label: "Button", value: "button"},
    {label: "List", value: "list"},
    // {label: "Template", value: "template"},
]
const PageHeading = {
    link: {name: "Sequence", link: `sequence`},
    title: "Edit",
    subTitle: "Edit Your Sequence and Save to Update",
    buttons:[{type:"refresh", title: "Refresh"}]
}

const SequenceEdit = () =>{
    const {sessionId} = useSelector(state => state.active);
    const {id} = useParams();
    const flowRef = useRef();
    const dispatch = useDispatch();

    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();

    useEffect(() =>{
        sequenceDetails();
    },[])

    const sequenceDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.sequence}/details/${sessionId}`, {id});
        setDetails(data?.details || []);
        setIsLoading(false);
    }

    const handleSave = async () =>{
        const details = flowRef?.current?.onSave();
        const data = await RequestPost(`${CloudApi.sequence}/update/${sessionId}`, {id,details});
        dispatch(ActionShowAlert({message: data?.message}));
    }

    return <>
        <Header {...PageHeading}  buttonProps={[{onClick: sequenceDetails}]} />
        <PageLoading isLoading={isLoading}>
            <Grid>
                <Grid.Col span={12}>
                    <Paper shadow={"md"} withBorder p={5}>
                        <Group position={"apart"}>
                            <Text>{details?.title}</Text>
                            <Button variant={"outline"} compact onClick={handleSave}>Save</Button>
                        </Group>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={2} sx={{backgroundColor: "#ccc"}}>
                    {nodeList?.map(a =>{
                        return  <Paper shadow={"md"} withBorder mx={5} onClick={() => flowRef?.current?.addNode(`${a?.value}`)}>
                            <Text mx={5}>{a?.label}</Text>
                        </Paper>
                    })}
                </Grid.Col>
                <Grid.Col span={10}>
                   <SequenceBuilder ref={flowRef} details={details?.details || {}} />
                </Grid.Col>
            </Grid>
        </PageLoading>
    </>
}
export default SequenceEdit;

import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {ActionShowAlert} from "../../store/actions/alert";
import {Button, Grid, Group, Modal, MultiSelect, Text} from "@mantine/core";
import {FilledTextInput} from "../Sequence/CustomButton";

const FormCreate = ({onClose,sessionId,isClone}) =>{

    const [name,setName] = useState("");
    const [categories,setCategories] = useState(["SIGN_UP"]);
    const [error,setError] = useState("");
    const [isLoading,setIsLoading] = useState("");
    const dispatch=useDispatch();

    const CreateForm = async () =>{
        setIsLoading(true);
        setError();
        let body = {name,categories};
        if(isClone?.id) body={...body,clone_flow_id: isClone?.id }
        const data = await RequestPost(`${CloudApi.whatsappFlows}/create/${sessionId}`,body);
        setIsLoading(false);
        if(data?.status === 1){
            onClose(true);
            dispatch(ActionShowAlert({message: data?.message}));
        }else{
            setError(data?.message);
        }
    }

    return    <Modal size={"50%"} opened={true} title={<b>{isClone?.id ? "Clone ": "Create"} Form</b>} onClose={() => onClose()}>
        <Grid>
            {isClone?.id && <Grid.Col span={12}>
                <Text>Cloned Flow From <b>{isClone?.name}</b></Text>
            </Grid.Col>}
            <Grid.Col span={6}>
                <FilledTextInput label={"Flow Name"} value={name} onChange={setName}  />
            </Grid.Col>
            <Grid.Col span={12}>
                <Text color={"red"} size={"xs"}>{error}</Text>
            </Grid.Col>
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={CreateForm} compact variant={"gradient"} loading={isLoading} disabled={name?.trim() === "" || categories?.length === 0}>{isClone?.id ? "Clone" :"Create"} Form</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>

}
export default FormCreate;

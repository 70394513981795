import {R365Api, RequestGet, RequestPost} from "../Layouts/RequestManager";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {IconWithText, PageLoading, TableView} from "../Pages/Components/PageComponents";
import {Divider, Grid, Group, Paper, Text, Button} from "@mantine/core";
import {IconCheck, IconChecks, IconCircleX, IconInfoCircle, IconUsers} from "@tabler/icons";
import React from "react";
import {PlanStatus} from "../Constants/Strings";
import UserMessageStats from "../Pages/Dashboard/UserMessageStats";
import {BoldText, SmallText} from "../Pages/Components/TextComponents";
import ProductSelect from "./Misc/ProductSelect";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../store/actions/alert";

const R365Dashboard = () =>{
    const {r365} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [msgList,setMsgList] = useState([]);
    const [details,setDetails] = useState({});
    const [productId,setProductId] = useState(r365?.productId?.[0]);
    const dispatch = useDispatch();

    useEffect(() =>{
        getDetails();
    },[productId])


    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${R365Api.stats}/${productId}/msgStats`,{});
        setMsgList(data?.msgList || []);
        setDetails(data?.details || []);
        setIsLoading(false);
    }
    const ResendMessages = (tz) =>{
        dispatch(ActionShowPopup({
            title: "Resend Failure/ Missed Messages",
            content: "Are you sure want to Resend Messages",
            successTitle: "Resend",
            cancelTitle:"Cancel",
            onSuccess: async () =>{
                const data = await RequestGet(`${R365Api.SendMessages}/${tz}/${productId}`);
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert({message: data?.message}));
            }
        }))
    }

    return <PageLoading isLoading={isLoading}>
        <ProductSelect productId={productId} setProductId={setProductId} />
        <Grid mt={10}>
            <Grid.Col span={6}>
                <Paper shadow={"md"} withBorder>
                    <Text weight={"bold"} align={"Center"}>Today Messages</Text>
                    <Divider />
                    <TableView headers={["Time","Scheduled","Stats", ""]}>
                        {msgList?.map(a =>{
                            return <tr>
                                <td>{a?.time}</td>
                                <td>{a?.total}</td>
                                <td>
                                    <Group position={"apart"}>
                                        <IconWithText text={a?.notSent || 0} icon={<IconCircleX size={14} color={"red"}/>} label={"NotSent"}  />
                                        <IconWithText text={a?.sent || 0} icon={<IconCheck size={14} />} label={"Sent"} />
                                        <IconWithText text={a?.delivered || 0} icon={<IconChecks size={14} color={"lightgrey"} />} label={"Delivered"} />
                                        <IconWithText text={a?.read || 0} icon={<IconChecks size={14} color={"green"} />} label={"Read"} />
                                        <IconWithText text={a?.failed || 0} icon={<IconInfoCircle size={14} color={"red"} />} label={"Failed"} />
                                    </Group>
                                </td>
                                <td>
                                    <Button compact onClick={() => ResendMessages(a?.tz)}>Resend</Button>
                                </td>
                            </tr>
                        })}
                    </TableView>
                </Paper>
            </Grid.Col>
            <Grid.Col span={6}>

                <Paper shadow={"md"} withBorder my={10} p={10}>
                    <BoldText text={`User Status`} mb={10} />
                    <Group>
                        {details?.plans?.map(a => <Group position={"apart"} sx={{width: 150, border: "1px solid", borderColor:"lightgray", borderRadius: 5}} px={5}>
                            <BoldText text={PlanStatus?.[a?._id]} />
                            <SmallText text={a?.count} />
                        </Group>)}
                    </Group>
                </Paper>
                <Paper shadow={"md"} withBorder my={10} p={10}>
                    <BoldText text={`Timezone Status`} mb={10} />
                    <Group>
                    {details?.tz?.map(a => <Group position={"apart"} sx={{width: 150, border: "1px solid", borderColor:"lightgray", borderRadius: 5}} px={5}>
                        <BoldText text={a?._id} />
                        <SmallText text={a?.count} />
                    </Group>)}
                    </Group>
                </Paper>
                <Paper shadow={"md"} withBorder my={10} p={10}>
                    <BoldText text={`Language Status`} mb={10} />
                    <Group>
                        {details?.lang?.map(a => <Group position={"apart"} sx={{width: 150, border: "1px solid", borderColor:"lightgray", borderRadius: 5}} px={5}>
                            <BoldText text={a?._id} />
                            <SmallText text={a?.count} />
                        </Group>)}
                    </Group>
                </Paper>
                <UserMessageStats />
            </Grid.Col>
        </Grid>
    </PageLoading>
}
export default R365Dashboard;
import React, {forwardRef, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ActionShowAlert} from "../../../store/actions/alert";
import {Button, CopyButton, Divider, Grid, Group, Loader, Select, Text} from "@mantine/core";
import ResponseView from "./ResponseView";
import {socket} from "../../Alerts/Socket";
import {CheckBoxView, FlowWebhookButton} from "../../Components/PageComponents";
import IntegrationDrawer from "../IntegrationDrawer";
import {CloudApi, RequestPost} from "../../../Layouts/RequestManager";
import {IconBluetoothConnected, IconPlugConnected, IconPlugOff} from "@tabler/icons";

const Instamojo = ({props,value}) =>{
    const app ='instamojo';
    const [event,setEvent] = useState("newPay");
    const [connId,setConnId] = useState(value?.connId || undefined);
    const [openConn,setOpenConn] = useState(false);
    const [withUser,setWithUser] = useState(value?.withUser ? value?.withUser : true);
    const [waitingWH,setWaitingWh] = useState(false);
    const [sampleResponse,setSampleResponse] = useState(value?.webhookRes || undefined)
    const dispatch = useDispatch();
    const {userId,sessionId} = useSelector(state => state.active);
    const [error,setError] = useState("")
    const [loading,setLoading] = useState(false);

    const onEventChange = (event) =>{
        setEvent(event);
        setConnId(undefined);
        setWaitingWh(false);
    }
    const updateConnection = (conn) =>{
        setConnId(conn);
        setOpenConn(false);
        setWaitingWh(false);
    }

    const handleSaveFlow = async () =>{
        setLoading(true);
        const flow = {app,event,connId,withUser};
        const data = await RequestPost(`${CloudApi.bsmFlows}/saveDetails/${sessionId}`,{flow,step: props?.step, id: props?.id});
        dispatch(ActionShowAlert({message: data?.message}));
        triggerWebhookListener();
    }
    const triggerWebhookListener = async () =>{
        setWaitingWh(true);
        await RequestPost(`${CloudApi.bsmFlows}/WebhookListen/${sessionId}`,{step: props?.step, id: props?.id});
        socket.on(`Flow${props?.id}_STEP${props?.step}`, handleWebhook);
    }
    const handleWebhook = (data) =>{
        dispatch(ActionShowAlert({message: "Webhook Captured", color: "green"}));
        setSampleResponse(data);
        setWaitingWh(false);
    }

    const SelectItem = forwardRef(({ image, label, description, ...others }, ref) => (
        <div ref={ref} {...others}>
            <Group noWrap>
                <div>
                    <Text size="sm">{label}</Text>
                    <Text size="xs" color={"dimmed"}>
                        {description}
                    </Text>
                </div>
            </Group>
        </div>
    ));
    const events = [{label: "New Payment Received",description: "When New payment Received from Instamojo", value: "newPay"}];
    return <>
        {openConn && <IntegrationDrawer app={app} connection={connId} updateConnection={updateConnection} onClose={() => setOpenConn(false)}/>}
        {app && <Grid.Col span={3}>
            <Select label={"Choose Event"}
                    itemComponent={SelectItem}
                    data={events} value={event} onChange={onEventChange} />
        </Grid.Col>}
        {app && event && !connId && <Grid.Col span={3}>
            <Button  mt={25}  onClick={() => setOpenConn(true)} variant={'outline'} leftIcon={<IconPlugConnected />}>Connect With Instamojo</Button>
        </Grid.Col>}
        {connId && <>
            <Grid.Col span={3}>
                <Button mt={25} color={'green'} onClick={() => setOpenConn(true)} leftIcon={<IconPlugConnected />}>Connected</Button>
            </Grid.Col>
            <Grid.Col span={12}>
                <Divider />
            </Grid.Col>
            <Grid.Col span={12}>
                <Group position={"apart"}>
                    <Group>
                        <Group mt={20}><CheckBoxView text={"Get User Details With Response"} onClick={setWithUser} isSelected={withUser} /></Group>
                        <FlowWebhookButton id={props?.id} />
                    </Group>
                    {!waitingWH ? <Button loading={loading} onClick={handleSaveFlow} variant={'gradient'}>Save & Check Response</Button>:
                        <Button loading={true}>Waiting For Webhook</Button>}
                </Group>
            </Grid.Col>
         </>}
        {error && <Grid.Col span={12}>
            <Text color={"red"}>{error}</Text>
        </Grid.Col>}
        {sampleResponse && <ResponseView response={sampleResponse} onRefresh={triggerWebhookListener} />}
    </>
}

export default Instamojo;

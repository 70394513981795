import {Button, Group, Image, Stack, Text} from "@mantine/core";
import {IconArrowForward, IconDownload, IconList, IconLogout} from "@tabler/icons";
import {IconView} from "../Components/PageComponents";
import TemplatePreview from "../Templates/PreviewTemplate";
import React from "react";

const MessageView = ({message}) =>{
    const {type,text,image,file,audio,video,interactive} = message;
    const renderInteractive = () => {
        const {header, body, action, footer,type,button_reply,list_reply} = interactive;
        return <Stack sx={{gap: 3}}>
            {header?.text && <Text weight={"bold"} size={"xs"}>{header?.text}</Text>}
            {body?.text && <Text  size={"xs"}> {body?.text}</Text>}
            {footer?.text && <Text  size={"xs"} color={"dimmed"}>{footer?.text}</Text>}
            {type ==="button" && action?.buttons?.length > 0 && <Group m={5}>
                {action?.buttons?.map(a =>{
                    return <Button compact variant={"outline"}>{a?.reply?.title}</Button>
                })}
            </Group>}
            {type ==="list" &&  <Button m={5} compact variant={"outline"} leftIcon={<IconList />}>{action?.button}</Button>}
            {type ==="flow" &&  <Button m={5} compact variant={"outline"}>{action?.parameters?.flow_cta}</Button>}
            {action?.name ==="cta_url" && action?.parameters && <Button m={5} compact variant={"outline"} leftIcon={<IconLogout />}>{action?.parameters?.display_text}</Button>}
            {type ==="button_reply" && <>
                <Text weight={"bold"} size={"xs"} mb={5}>{type?.toUpperCase()}</Text>
                <Text  size={"xs"}>{button_reply?.title}</Text>
            </>}
            {type ==="list_reply" && <>
                <Text weight={"bold"} size={"xs"} mb={5}>{type?.toUpperCase()}</Text>
                <Text size={"xs"}>{list_reply?.title}</Text>
                <Text size={"xs"} color={"dimmed"}>{list_reply?.description}</Text>
            </>}
            {type ==="nfm_reply" && <>
                <Text weight={"bold"} size={"xs"} mb={5}>Flow Response Received</Text>
            </>}
        </Stack>
    }
    switch (type){
        case 'text':
            return <>{text?.body?.toString()?.split("\n")?.map(a => <Text  size={"xs"}>{a}</Text>)}</>
        case 'image':
            return <>
                <Image src={image?.link} height={"50"}  />
                {image?.caption?.split("\n")?.map(a => <Text  size={"xs"}>{a}</Text>)}
            </>
        case 'audio':
        case 'video':
        case 'document':
            return <Group position={"apart"} sx={{border: 1}}>
                <Text weight={"bold"} size={"xs"}>{message?.[type]?.filename || `${type.toUpperCase()}`}</Text>
                <IconView icon={<IconDownload />}  />
            </Group>
        case 'template':
            return message?.message ? <TemplatePreview message={message?.message} variables={message?.variables} />: <Group position={"apart"} sx={{border: 1}}>
                <Text size={"xs"} color={"dimmed"} weight={"bold"}>Template:</Text> <Text size={"xs"}>{message?.template?.name}</Text>
            </Group>
        case "button":
            return  <>
                <Group position={"apart"}>
                    <Text size={"xs"} mb={5}>Button Click</Text>
                    <Text weight={"bold"} size={"xs"} mb={5}>{message?.button?.text}</Text>
                </Group>
                <Text  size={"xs"}><b>payload:</b> {message?.button?.payload?.toString()}</Text>
            </>
        case 'interactive':
            return  <>
                {renderInteractive()}
            </>
        default:
            return  <Text size={"xs"}>Unknown Type - {type}</Text>
    }
}
export default MessageView;

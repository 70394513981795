import React, {Component, useState} from 'react';
import {
    Accordion, Button,
    Grid,
    Group,
    Select,
    Text,
} from "@mantine/core";
import {Colors} from "../../Constants/Colors";
import {FlowAppDetails} from "../../Constants/Strings";
import {IconView} from "../Components/PageComponents";
import ChatbotLeads from "./FlowApps/ChatbotLeads";
import BSMWebhook from "./FlowApps/BSMWebhook";
import BSMProgram from "./FlowApps/BSMProgram";
import Instamojo from "./FlowApps/Instamojo";
import RazorPay from "./FlowApps/RazorPay";
import WBASendMessage from "./FlowApps/WBASendMessage";
import BSMBlockList from "./FlowApps/BSMBlockList";
import TriggerSequence from "./FlowApps/triggerSequence";
import ConvertKit from "./FlowApps/Convertkit";
import FormLeads from "./FlowApps/FormLeads";
import TriggerWebhook from "./FlowApps/triggerWebhook";
// import FBLeads from "./FlowApps/FBLeads";

const FlowStep = ({details,apps,step,deleteStep,...props}) =>{
    const [app,setApp] = useState(details?.app);
    const [value,setValue] = useState(details);
    const renderAppBased = (app) => {
        const customProps = { value , props: {step,...props} };
        switch(app){
            case 'ck':
                return <ConvertKit {...customProps} />;
            case 'chatbotLeads':
                return <ChatbotLeads {...customProps} />;
            case 'formLeads':
                return <FormLeads {...customProps} />;
            case 'webhook':
                return <BSMWebhook {...customProps} />;
            case 'pro':
                return <BSMProgram {...customProps} />;
            case 'instamojo':
                return <Instamojo {...customProps} />;
            case 'razorpay':
                return <RazorPay  {...customProps}/>
            case 'sendMessage':
                return <WBASendMessage {...customProps} />;
            case "triggerSequence":
                return  <TriggerSequence {...customProps} />
            case "triggerWebhook":
                return  <TriggerWebhook {...customProps} />
            // case "blockList":
            //     return <BSMBlockList {...customProps} />
            // case 'fbLeads':
            //     return <FBLeads {...customProps} />;
        }
    }

    return <Accordion.Item value={step?.toString()} mx={20} sx={{borderStyle: "solid", borderWidth: 1, borderColor: Colors.Primary}}>
        {/*{videoDemo && <BSMYoutube title={"Tutorial"} videoId={videoDemo} onClose={() => this.setState({videoDemo: undefined})}/>}*/}
        <Accordion.Control>
            <Group position={'apart'}>
                <>
                    {!app && <Text  weight={"bold"} variant={"gradient"}>{step + 1}. Choose {step === 0 ? "Trigger" : "Next Step"} </Text>}
                    {app && <Group sx={{gap: 3}}>
                        <Text weight={"bold"} variant={"gradient"}>{step + 1}. {FlowAppDetails?.[app]?.label}</Text>
                        {/*{tutor && <Button mx={20} color={"orange"} leftIcon={<IconPlayerPlay size={12} />} compact size={"xs"} variant={"outline"} onClick={() => this.setState({videoDemo: tutor})}>View Tutorial</Button>}*/}
                    </Group>}
                </>
                {step !== 0 && <IconView iconType={"delete"} onClick={() => deleteStep(value?.id)} />}
            </Group>
        </Accordion.Control>
        <Accordion.Panel>
            <Grid>
                <Grid.Col span={6} onClick = {() =>{
                    setApp();
                    setValue({id: details?.id})
                }}>
                    <Select label={"Choose App"} data={apps} value={app}  onChange={setApp} />
                </Grid.Col>
                {app && renderAppBased(app)}
            </Grid>
        </Accordion.Panel>
    </Accordion.Item>
}


export default FlowStep;



import {useEffect, useState} from "react";
import {
    Drawer,
    FileButton,
    Group,
    List,
    Button,
    Text,
    Grid,
    Paper,
    Modal,
    Pagination,
    Image,
    Stack
} from "@mantine/core";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {IconView, PageLoading} from "../Components/PageComponents";
import {IconUpload} from "@tabler/icons";
import {BoldText, ErrorText} from "../Components/TextComponents";
import React from "react";
import {ActionShowAlert} from "../../store/actions/alert";
import AlertPopup from "../Alerts/AlertPopup";

const Attachments = ({onClose,details}) =>{
    const {sessionId,...active} = useSelector(state =>state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [files,setFiles] = useState([]);
    const [onDelete,setOnDelete] = useState();
    const [page,setPage] = useState(1);


    const dispatch = useDispatch();

    useEffect(() =>{
        getFiles();
    },[]);

    const getFiles = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.Attachments}/files/${sessionId}`, {folderId: details?.id});
        setFiles(data?.files);
        setIsLoading(false);
    }
    const deleteCurrentFile = async ( fileId)=>{
        const data = await RequestPost(`${CloudApi.Attachments}/deleteFile/${sessionId}`, {folderId: details?.id, fileId});
        dispatch(ActionShowAlert({message: data?.message, color: data?.status === 1? "green": "red"}));
        setOnDelete();
        getFiles();
    }
    const DeleteFile = (fileId) =>{
        setOnDelete({
            title: "Delete File",
            content: "It will Delete File. You can't use it here after?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: () => deleteCurrentFile(fileId)
        })
    }
    return <Drawer onClose={onClose} opened={true} position={"right"}  size={"80%"} title={<BoldText text={details?.title} mx={10} size={"md"} />}>
        <PageLoading isLoading={isLoading}>
            <UploadFiles onRefresh={getFiles} sessionId={sessionId} folderId={details?.id} />
            {onDelete && <AlertPopup opened={true} {...onDelete} onClose={() => setOnDelete()} />}
            <Grid mt={20} mx={10}>
                <Grid.Col span={12}>
                    <Group position={"apart"}>
                        <BoldText size={"md"} text={`Total Results:  ${files?.length}`} />
                        <Pagination size={"xs"} total={Math.ceil(files?.length/16)} page={page} onChange={setPage} />
                    </Group>
                </Grid.Col>
                {files?.slice((page-1) * 16, page * 16)?.map(a => {
                    return <Grid.Col span={3}>
                        <Paper shadow={"md"} p={5} withBorder>
                            {a?.mediaLink && <Image src={a?.mediaLink} width={"auto"} height={100} fit={"contain"} />}
                            <Group position={"apart"}>
                                <BoldText text={a?.name} />
                                <Group onClick={(e) => {e.stopPropagation()}}>
                                    <IconView iconType={"delete"} label={"Delete"} onClick={() => DeleteFile(a?._id)} />
                                </Group>
                            </Group>
                        </Paper>
                    </Grid.Col>
                })}
            </Grid>
        </PageLoading>
    </Drawer>
}
const UploadFiles = ({onRefresh,sessionId,folderId}) =>{
    const {r365}= useSelector(state => state.active);
    const [uploadFiles,setUploadFiles] = useState([]);
    const [uploaded,setUploaded] = useState(0);
    const [isUploading,setIsUploading] = useState(false);
    const [success,setSuccess] = useState(0);
    const [failure,setFailure] = useState(0);
    const [render,setRender] = useState(0);
    const [errors,setErrors] = useState([]);

    const deleteUpload = (index) =>{
        let list  = uploadFiles?.filter((a,i) => i!==index);
        setUploadFiles(list);
    }
    const UploadFile = async () =>{
        setIsUploading(true);
        let up = 0;
        let success = 0;
        let failure = 0;
        for(let i =0; i< uploadFiles?.length; i++){
            const file = uploadFiles?.[i];
            const reader = new FileReader();
            reader.onload = async function (event) {
                const base64Data = event.target.result;
                const f =  {name: file?.name, size: file?.size, file: base64Data, type: file?.type};
                const data = await RequestPost(`${CloudApi.Attachments}/uploadFile/${sessionId}`, {folderId: folderId, file: f});
                   if(data?.status === 0){
                       errors.push(data?.message)
                   }
                data?.id || data?.mediaLink ? success++ : failure++;
                up++;
                setUploaded(success);
                setFailure(failure);
                setSuccess(success);
                if(up === uploadFiles?.length -1 ){
                    setIsUploading(false);
                    setUploadFiles([]);
                    setSuccess(0);
                    setFailure(0)
                    onRefresh();
                }
            };
            reader.readAsDataURL(file);
        }
    }


    return <>
        {isUploading && <Stack sx={{gap: 3}}>
                <Group sx={{backgroundColor: "#ccc"}}>
                 <BoldText  text={`${uploaded} / ${uploadFiles?.length} - success: ${success}, Failed: ${failure}`} />
                </Group>
                {errors?.map(a => <ErrorText text={a} />)}
            </Stack>}
        {uploadFiles?.length === 0 && <Group position="right">
            <FileButton onChange={(files) => setUploadFiles(files?.slice(0,r365?.productId?.length > 0 ? 30 :5))} accept="*" multiple>
                {(props) => <Button  {...props} mx={10} leftIcon={<IconUpload />}>Upload Files</Button>}
            </FileButton>
        </Group>}
        {uploadFiles?.length > 0 && !isUploading && <Grid mx={10} sx={{border: "1px solid"}}>
            <Grid.Col span={12}>
                <BoldText text={`Upload Files (Max: ${r365?.productId?.length > 0 ? 30 :5})`} size={"lg"} />
            </Grid.Col>
            {uploadFiles.map((file, index) => (
                <Grid.Col span={4}>
                    <Paper shadow={"md"} p={5} withBorder>
                        <Group position={"apart"}>
                            {file.name}
                            <IconView iconType={"delete"} onClick={() => deleteUpload(index)}/>
                        </Group>
                    </Paper>
                </Grid.Col>
            ))}
            <Grid.Col span={12}>
                <Group position={"apart"} mx={10}>
                    <Button sx={{width: "40%", backgroundColor: "grey"}} onClick={() => setUploadFiles([])}>Cancel</Button>
                    <Button sx={{width: "40%"}} onClick={UploadFile} loading={isUploading}>Upload</Button>
                </Group>
            </Grid.Col>
        </Grid>}
    </>
}

export default Attachments;

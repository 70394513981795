import {
    Checkbox,
    Drawer,
    Grid,
    Group,
    Select,
    Stack,
    Text,
    TextInput,
    Button,
    SegmentedControl,
    NumberInput, FileButton, Image, ScrollArea
} from "@mantine/core";
import React, {useState} from "react";
import {SmallText} from "../Components/TextComponents";
import {IconView} from "../Components/PageComponents";
import {IconInfoCircle, IconPlus} from "@tabler/icons";
const ListOfComponents = ["TextHeading","TextSubheading","TextBody","TextCaption","TextInput","TextArea","CheckboxGroup" ,"RadioButtonsGroup","Footer" ,"Dropdown","DatePicker","Image"] //,"OptIn" ,"EmbeddedLink"
const Input = ({label,limit,value,required,onChange,...props}) => <Stack sx={{gap: 1}}>
    <Group position={"apart"}>
        <Group sx={{gap: 3}}>
            <SmallText text={label} weight={"bold"} />
            {required && <SmallText text={"*"}  weight={"bold"} color={"red"} />}
        </Group>
        {limit && <SmallText text={`${value?.length} / ${limit}`}/>}
    </Group>
    <TextInput size={"xs"} variant={"filled"} onChange={e => onChange(limit ? e.target.value?.slice(0,limit): e.target.value)} {...props} value={value}  />
</Stack>
const NameLabel = () =>{
    return <Group sx={{gap: 3}}>
        <Text>Lead Header</Text>
        <IconView icon={<IconInfoCircle size={14} />} label={"Value Holder to store the User response of this input field"} />
    </Group>
}
const limits ={
    TextHeading: {
        text: 80
    },
    TextSubheading: {
        text: 80
    },
    TextBody: {
        text: 4096
    },
    TextCaption: {
        text: 4096
    },
    "TextInput":{
        helperText: 80,
        label: 20
    },
    "TextArea":{
        helperText: 80,
        label: 20
    },
    "CheckboxGroup":{
        label: 30,
        description: 300,
        optionTitle: 30,
        optionDesc: 300,
        optionMeta: 20
    },
    "RadioButtonsGroup":{
        label: 30,
        description: 300,
        optionTitle: 30,
        optionDesc: 300,
        optionMeta: 20
    },
    "Footer":{},
    "Dropdown":{
        label: 30,
        optionTitle: 30,
        optionDesc: 300,
        optionMeta: 20
    },
    "DatePicker":{
        label: 20,
    },
    "Image":{}
}
const PlaceHolders ={
    TextHeading: {
        text: "Name"
    },
    TextSubheading: {
        text: "Description of your Heading"
    },
    TextBody: {
        text: "Paragraph of detailed Text"
    },
    TextCaption: {
        text: "Paragraph of detailed Text"
    },
    "TextInput":{
        label :{"text": "Enter Your Name",
            "number": "Enter Your Number",
            "email": "Enter your Email"},
        name :{"text": "name",
            "number": "number",
            "email": "email"},


    },
    "TextArea":{
        label: "Get Paragraph of response from your users",
        name: "response",
    },
    "CheckboxGroup":{
        label: "Choose Language",
        name: "language",
        options: ["Tamil","English","Hindi"]
    },
    "RadioButtonsGroup":{
        label: "Choose Gender",
        name: "gender",
        options: ["Male","Female","Not Say"]
    },
    "Dropdown":{
        label: "Choose Meal type",
        name: "meal",
        options: ["BreakFast","Lunch","Dinner"]
    },

    "DatePicker":{
        label: "Choose Date",
        name: "date",
    },
    "Footer":{
        label: "Complete"
    },
    "Image":{}
}

const types = ["Heading","Input","Select","Misc"];
const subTypes = {
    "Heading":["TextHeading","TextSubheading","TextBody","TextCaption"],
    "Input":["TextInput","TextArea"],
    "Select":["CheckboxGroup" ,"RadioButtonsGroup","Dropdown"],
    "Misc": ["DatePicker","Image","Footer"]
};
const FormComponents = ({onClose,details,editType, onSave,screens,screenId}) =>{
    const [isError,setIsError] = useState(false);
    const [render,setRender] = useState(1);
    const getGroup = () =>{
       return  Object.values(subTypes)?.map((a,i)  => a?.includes(details?.type) ? types?.[i] :undefined)?.filter(a => !!a)?.[0];
    }
    const [typeGroup,setTypeGroup] = useState(getGroup() || "Heading");
    const [type,setType] = useState(details?.type || "TextHeading");

    const [text,setText] = useState(details?.text || "");
    const [label,setLabel] = useState(details?.label || "");
    const [name,setName] = useState(details?.name ||"");
    const [required,setRequired] = useState(details?.required || false);
    const [inputType,setInputType] = useState(details?.["input-type"] || "text");
    const [minChar,setMin] = useState(details?.["min-chars"]);
    const [maxChar,setMax] = useState(details?.["max-chars"] || 80);
    const [helperText,setHelperText] = useState(details?.["helper-text"] || "");
    const [description,setDescription] = useState(details?.["description"] || "");
    const [options,setOptions] = useState(details?.["data-source"] || [{}]);

    const [file,setFile] = useState();
    const [src,setSource] = useState(details?.["src"]);
    const [width,setWidth] = useState(300);
    const [height,setHeight] = useState(300);
    const [scale,setScale] = useState("contain");
    const [aspect,setAspect] = useState(1);
    const [altText,setAltText] = useState("");
    const [fileError,setFileError] = useState("");

    const [clickAction,setClickAction] = useState(details?.["on-click-action"]?.name || "");
    const [page,setPage] = useState(details?.["on-click-action"]?.next?.name || "");

    const checkValid = () => {
        switch (type) {
            case "TextHeading":
            case "TextSubheading":
            case "TextBody":
            case "TextCaption": {
                const isValid = text?.trim() !== "";
                return {isValid, data: {type, text}};
            }
            case "TextInput": {
                const isValid = label?.trim() !== "" && name?.trim() !== "";
                const data = {type,label,name,required,"input-type": inputType,"min-chars": minChar,"max-chars":maxChar,"helper-text": helperText};
                return {isValid,data}
            }
            case "TextArea": {
                const isValid = label?.trim() !== "" && name?.trim() !== "";
                const data = {type,label,name,required,"max-length":maxChar,"helper-text": helperText};
                return {isValid, data};
            }
            case "CheckboxGroup": {
                const isValid = label?.trim() !== "" && name?.trim() !== "" && options?.length > 0 && options?.filter(a => !a?.id?.trim() || !a?.title?.trim())?.length === 0;
                const data = {type,label,name,description,required,"min-selected-items": minChar,"max-selected-items": maxChar,"data-source": options}
                return {isValid, data};
            }
            case "RadioButtonsGroup": {
                const isValid = label?.trim() !== "" && name?.trim() !== "" && options?.length > 0 && options?.filter(a => !a?.id?.trim() || !a?.title?.trim())?.length === 0;;
                const data = {type,label,name,description,required,"data-source": options}
                return {isValid, data};
            }
            case "Dropdown": {
                const isValid = label?.trim() !== "" && name?.trim() !== "" && options?.length > 0 && options?.filter(a => !a?.id?.trim() || !a?.title?.trim())?.length === 0;
                const data = {type, label, name, required, "data-source": options};
                return {isValid, data};
            }
            case "DatePicker": {
                const isValid = label?.trim() !== "" && name?.trim() !== "";
                const data = {type, label, name, required,"helper-text": helperText}
                return {isValid, data }
            }
            case "Image": {
                const isValid = !!src;
                const data = {
                    type,
                    src,
                    width,
                    height,
                    "scale-type": scale,
                    "aspect-ratio": aspect,
                    "alt-text": altText
                };
                return {isValid, data};
            }
            case "Footer":{
                let isValid = label?.trim() !== "" && clickAction && (clickAction !== "navigate" || (clickAction === "navigate" && page?.trim() !== ""));
                let action = {};
                if(clickAction === "navigate"){
                    action= {
                        "on-click-action": {
                            "name": clickAction,
                            next: {type: "screen", name: page },
                            payload:  {},
                        },
                    }
                }
                else{
                    action = {"on-click-action": {"name": clickAction}}
                }
                const data = {type,label, ...action};
                return {isValid,data}
            }
            default:
                return {};
        }
    }
    const onSubmit = () =>{
        const {isValid,data} = checkValid();
        if(!isValid){
            setIsError(true)
        }else{
            setIsError(false);
            onSave({data});
        }
    }
    const optionChange = (index,key,value) =>{
        const l = options;
        l[index] = {...l?.[index],[key]: value}
        setOptions(l);
        setRender(render+1);
    }
    const deleteOption = (index)=>{
        setOptions(options?.filter((a,i)=> i !== index));
    }
    const renderData = () =>{
        let list = [];
        if(screens?.length > 1){
            if(screens?.[screens?.length - 1]?.id === screenId){
                list.push("complete")
            }else{
                list.push("navigate","complete")
            }

        }else{
            list.push("complete");
        }
        return list?.map(a => ({label: a,value:a}));

    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(/,(.+)/.exec(reader?.result)[1]);
        reader.onerror = reject;
    });
    const onChangeFile = async (file) =>{
        if(file?.size > 300000){
            setFileError("Image Max.size is 300 KB")
        }else{
            setFileError();
            setFile(file);
            const srcFile= await toBase64(file);
            setSource(srcFile);
        }

    }
    const onChangeGroup = (group) =>{
        setTypeGroup(group);
        setType(subTypes?.[group]?.[0])
    }


    return <Drawer opened={true} lockScroll={false} onClose={onClose} position={"right"} size={"50%"} title={<Text size={"md"} weight={"bold"} mx={10}>{editType} Component</Text>}>
        <ScrollArea type={"auto"} sx={{height: "90%"}}>
            <Stack sx={{gap: 10}} mx={10} mb={50}>
                <SegmentedControl size={"xs"} color={"violet"} data={types?.map(a => ({label: a,value: a}))} value={typeGroup} onChange={onChangeGroup} />
                <Select data={subTypes?.[typeGroup]?.map(a =>({label: a,value: a})) || []} label={"Select Type"} value={type} onChange={setType} />

                {["TextHeading","TextSubheading","TextBody","TextCaption"].includes(type) &&<Input label={"Message to Display"} required placeHolder={PlaceHolders?.[type]?.text} value={text} onChange={setText} limit={limits?.[type]?.text} error={isError && text?.trim() === "" ? "text is Required":""} />}
                {["TextInput","TextArea","CheckboxGroup","RadioButtonsGroup","Dropdown","DatePicker","Footer"].includes(type) && <Input label={"Label"} placeHolder={type === "TextInput" ? PlaceHolders?.[type]?.label?.[inputType]:PlaceHolders?.[type]?.label} required value={label} onChange={setLabel} limit={limits?.[type]?.label} error={isError && label?.trim() === "" ? "Label is Required":""} />}
                {["TextInput","TextArea","CheckboxGroup","RadioButtonsGroup","Dropdown","DatePicker"].includes(type) && <Input label={<NameLabel />} placeHolder={type === "TextInput" ? PlaceHolders?.[type]?.name?.[inputType]:PlaceHolders?.[type]?.name}  required value={name} onChange={setName} error={isError && name?.trim() === "" ? "Name is Required":""} />}
                {["CheckboxGroup","RadioButtonsGroup"].includes(type) && <Input label={"Description"} value={description} onChange={setDescription} limit={limits?.[type]?.description}  />}
                {["TextInput","TextArea","CheckboxGroup","RadioButtonsGroup","Dropdown","DatePicker"].includes(type) && <Group my={10}>
                    <Checkbox size={"xs"} label={"Required"}  checked={required} onChange={value => setRequired(value.target.checked)} />
                </Group>}
                {["TextInput","TextArea","DatePicker"].includes(type) &&  <Input label={"Helper text"} value={helperText} onChange={setHelperText} limit={limits?.[type]?.helperText} />}
                {["TextInput"].includes(type) &&  <Group>
                    <Select size={"xs"} label={"Input Type"} data={['text','number','email', 'password', 'passcode', 'phone']?.map(a => ({label: a,value: a}))} value={inputType} onChange={setInputType} />
                    <NumberInput size={"xs"} label={"Min Chars"}  value={minChar} onChange={setMin} />
                    <NumberInput size={"xs"} label={"Max Chars"}  value={maxChar} onChange={setMax} />
                </Group>}
                {["TextArea"].includes(type) && <NumberInput size={"xs"} label={"Max Chars"}  value={maxChar} onChange={setMax} />}
                {["CheckboxGroup"].includes(type) &&  <Group>
                    <NumberInput size={"xs"} label={"Min Selected"}  value={minChar} onChange={setMin} />
                    <NumberInput size={"xs"} label={"Max Selected"}  value={maxChar} onChange={setMax} />
                </Group>}
                {["CheckboxGroup","RadioButtonsGroup","Dropdown"].includes(type) && <>
                    <Group position={"right"}>
                        <Button sx={{width: 150}}  compact size={"xs"} leftIcon={<IconPlus size={14} />} onClick={() => setOptions([...options, {}])}> Add Options</Button>
                    </Group>
                    {options?.map((a,i) =>{
                        return <Grid mx={10} sx={{width: "100%"}}>
                            <Grid.Col span={2}>
                                <Input required label={"Id"} placeHolder={PlaceHolders?.[type]?.options?.[i % 3]} value={a?.id || ""} onChange={(value) => optionChange(i,"id",value)}  error={isError && !a?.id?.trim() ? "Id is Required":""} />
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Input required label={"Title"} placeHolder={PlaceHolders?.[type]?.options?.[i % 3]} value={a?.title || ""} onChange={(value) => optionChange(i,"title",value)}  error={isError && !a?.title?.trim() ? "Title is Required":""} limit={limits?.[type]?.optionTitle} />
                            </Grid.Col>
                            <Grid.Col span={3}>
                                <Input label={"description"} value={a?.description || ""} onChange={(value) => optionChange(i,"description" ,value)} limit={limits?.[type]?.optionDesc} />
                            </Grid.Col>
                            <Grid.Col span={2}>
                                <Input label={"metadata"} value={a?.metadata || ""} onChange={(value) => optionChange(i,"metadata",value)} limit={limits?.[type]?.optionMeta} />
                            </Grid.Col>
                            <Grid.Col span={1}>
                                {options?.length > 1 && <IconView iconType={"delete"} onClick={() => deleteOption(i)} />}
                            </Grid.Col>
                        </Grid>
                    })}
                </>}
                {["Footer"].includes(type)  && <Group>
                    <Select size={"xs"} label={"onClick"} data={renderData()} required value={clickAction} onChange={setClickAction} error={isError && !clickAction ? "Click Action is Required": ""} />
                    {clickAction === "navigate" &&<Select size={"xs"} label={"Select Page"} data={screens?.filter(a => a?.id !== screenId)?.map(a =>({label:a?.id,value: a?.id}))} required value={page} onChange={setPage} error={isError && page?.trim() === "" ? "Page is Required": ""}   />}
                </Group>}
                {["Image"].includes(type)  &&<>
                    <FileButton onChange={onChangeFile} accept="image/png,image/jpeg">
                        {(props) => <Group  align={"center"} mt={15} mx={10}>
                            <Button size={"xs"} compact {...props}>Choose image (max: 300kb)</Button>
                            {fileError && <SmallText text={fileError} color={"red"} />}
                            {isError && !file && <SmallText text={"File is Required"} color={"red"} />}
                        </Group>}
                    </FileButton>
                    {src && <Image src={`data:image/jpg;base64,${src}`} width={100} height={100} alt={"Image"}/>}
                    <Group mt={10} mx={10}>
                        <Select required size={"xs"} data={["cover","contain"]?.map(a => ({label: a, value: a}))}
                                label={"Scale Type"}
                                value={scale}
                                onChange={setScale}
                        />
                        <NumberInput size={"xs"} label={"Width"}  value={width} onChange={setWidth} />
                        <NumberInput size={"xs"} label={"Height"}  value={height} onChange={setHeight} />
                    </Group>
                    <Group mt={10} mx={10}>
                        <Stack sx={{gap: 1}}><Input label={"Aspect ratio"} value={aspect} onChange={setAspect} /></Stack>
                        <Stack sx={{gap: 1}}><Input label={"Alternate text"} value={altText} onChange={setAltText} /></Stack>
                    </Group>
                </>}
                <Group position={"center"} my={20}>
                    <Button fullWidth onClick={onSubmit}>Save</Button>
                </Group>
            </Stack>
        </ScrollArea>
    </Drawer>
}
export default FormComponents;

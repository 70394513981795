import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useSelector} from "react-redux";
import {Button, Grid, Group, Modal, Select, TextInput} from "@mantine/core";
import {ErrorText, SmallText} from "../Components/TextComponents";
import FilePreview from "../Attachments/FilePreview";
const fileFilter = {
    "image": ["image"],
    "audio": ["audio"],
    "video": ["video"],
    "document": ["image","audio","video","application"]
}
const PickFile = forwardRef(({msgType,details, isLink}, ref)=>{
    const {files} = useSelector(state => state.active);
    const [file, setFile] = useState(details?.file);
    const [opened,setOpened] = useState(false);
    const [folder,setFolder] = useState("");
    const [code,setCode] = useState(details?.file || "");
    const [isError,setIsError] = useState(false)

    useEffect(() => {
        if(msgType !== details?.msgType)  setFile("");
    },[msgType]);
    useEffect(() => {
        setFile(details?.file);
    },[details]);

    const handleImageSave = () =>{
        console.log("calling save")
        setFile(code);
        setFolder("");
        setCode("");
        setOpened(false);
    }
    useImperativeHandle(ref, () => ({
        isValid() {
            const isValid = file?.trim() !== "";
            setIsError(!isValid)
            return {isValid, file,isCode: file?.startsWith("fi_"), details: {folder,file}}
        },
    }));

    const OpenModal = () =>{
        if(details?.file){
            const folder = files?.find(a => a?.files?.filter(b => b?.value === file)?.length > 0)?.value;
            setFolder(folder);
            setCode(file);
        }
        setOpened(true);
    }
    return <>
            <Grid.Col span={12} lg={6} xl={6}>
                <Group position={"apart"}>
                    <TextInput sx={{width: "60%"}} size={"xs"}
                               label={"Enter Attachment Code or Url"} value={file}
                               onChange={(e) => setFile(e?.target?.value)}
                               error={isError && file?.trim() === ""? "Please Provide Link or code of Attachment": ""}  />
                    {!isLink &&<>
                        <SmallText text={"(or)"} mt={15} />
                        <Button compact mt={15} onClick={OpenModal}>Choose From Attachments</Button>
                    </>}
                </Group>
                {isLink && <SmallText text={"Only Direct Links Allowed in this type of Message"} color={"red"} />}
                <Modal opened={opened} size={"50%"} onClose={() => setOpened(false)} title={<b>Choose File</b>}>
                    <Grid>
                        <Grid.Col span={12} lg={3} xl={3}>
                            <Select size={"xs"} nothingFound={"No Attachments.. Please Create"} label={"Choose Folder"}
                                    data={files}
                                    value={folder}
                                    onChange={setFolder}
                            />
                        </Grid.Col>
                        {folder && <Grid.Col lg={3} xl={3} span={12}>
                            <Select size={"xs"} nothingFound={"No Files.. Please Add"}
                                    label={"Choose File"}
                                    data={files.find(a => a.value === folder)?.files?.filter(a => fileFilter?.[msgType?.toLowerCase()]?.includes(a?.type)) || []}
                                    value={code}
                                    onChange={setCode}
                            />
                        </Grid.Col>}
                        <Grid.Col span={12} lg={3} xl={3}>
                            <Button compact mt={25} onClick={handleImageSave}>Save</Button>
                        </Grid.Col>
                </Grid>
                </Modal>
            </Grid.Col>
            <Grid.Col span={6} mt={15}>
                <FilePreview code={file} />
            </Grid.Col>
        </>
});

export default PickFile;
import React, {useEffect, useState} from "react";
import {
    Group,
    Select,
    Button,
    Tabs,
    Text,
    Badge,
    Modal,
    TextInput,
    Pagination,
    SegmentedControl,
    Grid
} from "@mantine/core";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {
    IconCalendar,
    IconCheck, IconChecks,
    IconCopy,
    IconEye,
    IconMessage, IconRefresh,
    IconToggleLeft,
    IconToggleRight,
    IconX
} from "@tabler/icons";
import {Header, IconView, IconWithText, PageLoading, TableView} from "../Components/PageComponents";
import {convertUnixToFormat, currentISTTime, formatLTTime, timeConversionWithOffset} from "../../Constants/Functions";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Colors} from "../../Constants/Colors";
import {Calendar} from "@mantine/dates";
import {BoldText, SmallText} from "../Components/TextComponents";
import ScheduleDetails from "./ScheduleDetails";
const renderSchTime = (options) =>{
    let msg = "";
    switch (options?.type){
        case 1:
            msg = `Daily ${options?.time}`
            break;
        case 2:
            msg = `Weekly  ${options?.time}`
            break;
        case 3:
            msg = `Every Month  ${options?.time}`
            break;
        case 4:
            msg=`${options?.customDate} ${options?.time}`;
            break;
        case 5:
            msg = "Send Now";
            break;
        case 6:
            msg=`${convertUnixToFormat(options?.sendTimes?.[0])} ${options?.sendTimes?.length > 1 ? ` & ${options?.sendTimes?.length - 1} others`: ""}`;
            break;
    }
    return msg;
}
const renderStatus = (status = 0) =>{
    const array = [{color: "green",label: "Active"},{color: "red",label: "Paused"},{color: "orange",label: "Completed"}]
    return <Badge color={array?.[status]?.color}>{array?.[status]?.label}</Badge>
}
const PageHeading = {
    title: "Scheduled Message",
    subTitle: "You can see Scheduled Messages Here",
    buttons: [{type: "add", title: "Create Schedule"},{type: "refresh", title: "Refresh"}]
}
const ScheduleList = () =>{
    const {sessionId,alias} = useSelector(state => state.active)
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [openDetails,setOpenDetails] = useState();

    const onEdit = (id,type, isClone) =>{
        navigation(`/device/${alias}/schedule-message`,{state: {id, type,isClone}});
    }
    const onDelete = (id) =>{
        dispatch(ActionShowPopup({
            title: "Delete Schedule",
            content: "It will Delete All Upcoming Schedules in This Series. Are you Sure want to Delete ?.",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
               const result = await RequestGet(`${CloudApi.deleteSchedule}/${sessionId}/${id}`);
               dispatch(ActionHidePopup());
               dispatch(ActionShowAlert({message: result?.message}));
            }}));
    }
    const onPause = (schId) => {
        dispatch(ActionShowPopup({
            title: "Pause Schedule",
            content: "It Will Pause the Schedule and This Schedule Will Not Send till you Resume. Are you sure want to Pause?",
            successTitle: "Pause",
            cancelTitle: "Close",
            onSuccess: async () => {
                const result = await RequestGet(`${CloudApi.pauseSchedule}/${sessionId}/${schId}`);
                dispatch(ActionShowAlert({message: result?.message}));
                dispatch(ActionHidePopup());
            }
        }))
    }
    const onResume = (schId) =>{
        dispatch(ActionShowPopup({
            title: "Resume Schedule",
            content: "It Will Resume the Schedule and This Schedule Will Be sent Based on Scheduled Time. Are you sure want to Resume?",
            successTitle: "Resume",
            cancelTitle: "Close",
            onSuccess: async () => {
                const result = await RequestGet(`${CloudApi.resumeSchedule}/${sessionId}/${schId}`);
                dispatch(ActionShowAlert({message: result?.message}));
                dispatch(ActionHidePopup());
            }
        }))

    }
    const addSchedule = () =>{
        navigation(`/device/${alias}/schedule-message`)
    }
    const onRefresh = () =>{}

    const defaultProps = {onView: setOpenDetails,onDelete,onEdit,onPause,onResume}
    return <>
        <Header {...PageHeading} buttonProps={[{onClick: addSchedule},{onClick: onRefresh}]} />
        {openDetails && <ScheduleDetails {...openDetails} onClose={() => setOpenDetails()} />}
        <Tabs defaultValue="all"  keepMounted={false}>
        <Tabs.List grow>
            <Tabs.Tab value="all">All Schedules</Tabs.Tab>
            <Tabs.Tab value="date">Date Wise</Tabs.Tab>
            <Tabs.Tab value="upcoming">Upcoming</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="all" pt="xs">
            <AllSchedules {...defaultProps} />
        </Tabs.Panel>
        <Tabs.Panel value="date" pt="xs">
           <DateWiseSchedule {...defaultProps} />
        </Tabs.Panel>
        <Tabs.Panel value="upcoming" pt="xs">
           <UpcomingSchedules {...defaultProps} />
        </Tabs.Panel>
    </Tabs>
    </>
}
export default ScheduleList;


const AllSchedules = ({onView,onEdit,onDelete,onPause,onResume}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading]= useState(false)
    const [schType,setSchType] = useState("recurring");
    const [search,setSearch] = useState("");
    const [page,setPage] = useState(1);
    const [list,setList] = useState([]);
    const [total,setTotal] = useState();

    useEffect(() =>{
        fetchSchedules();
    },[schType,page]);

    const fetchSchedules = async () =>{
        setIsLoading(true);
        const response = await RequestPost(CloudApi.AllSchedules, {schType,sessionId,page,search});
        setList(response?.schedules);
        setTotal(response?.total);
        setIsLoading(false);
    }
    const handleSearch = () =>{
        setPage(1);
        fetchSchedules();
    }


    return <>
        <Grid my={5}>
            <Grid.Col span={3}>
                <SegmentedControl
                    size={"xs"}
                    color={"violet"}
                    data={[{label: "All" ,value: 'all'},{label: "Recurring" ,value: 'recurring'},{label: "Upcoming" ,value: 'upcoming'},{label: "Completed" ,value: 'completed'}]}
                    value={schType}
                    onChange={setSchType} />
            </Grid.Col>
            <Grid.Col span={3}>
                <Group>
                    <TextInput size={"xs"} placeholder={"Search"}  variant={"filled"} value={search} onChange={(e) => setSearch(e.target.value)} />
                    <Button compact size={"xs"} onClick={handleSearch}>Search</Button>
                </Group>
            </Grid.Col>
            <Grid.Col span={3}>
                <BoldText text={`Total Results: ${total}`} mt={10} />
            </Grid.Col>
            <Grid.Col span={3}>
                <Group position={"right"}>
                    {total > 10 && <Pagination position={"center"} size={"xs"} page={page} total={Math.ceil(total / 10)} onChange={setPage}/>}
                    <Button compact size={"xs"} onClick={fetchSchedules} leftIcon={<IconRefresh />}>Refresh</Button>
                </Group>
            </Grid.Col>
        </Grid>
        <TableView headers={["Title","Occurances","Time", "Last Sent","Last Edited","Status","Options"]}>
            <PageLoading isLoading={isLoading}>
                {list?.map(b =>{
                    return <tr>
                        <td><SmallText text={b?.title} lineClamp={1} /></td>
                        <td>{b?.sentList?.length || 0} </td>
                        <td>{renderSchTime(b?.options)}</td>
                        <td>{b?.sentList?.length > 0 ? timeConversionWithOffset({type: "timestampFromNow", timestamp: b?.sentList?.[b?.sentList?.length -1]}) : ""}</td>
                        <td>{b?.edited ? timeConversionWithOffset({type: "timestampFromNow", timestamp: b?.edited}): b?.created ?  timeConversionWithOffset({typ: "timestampFromNow", timestamp: b?.created}): "" }</td>
                        <td>{renderStatus(b?.status)}</td>
                        <td>
                            <Group  mx={5} mb={5}>
                                {[0,1].includes(b?.status) &&<>
                                    {b?.status === 0 ? <IconView onClick={() => onPause(b?.id)} icon={<IconToggleRight compact color={"green"}  />} label={"Pause Schedule"} />: <IconView onClick={() => onResume(b?.id)} icon={ <IconToggleLeft color={"red"}/>} label={"Resume Schedule"} />}
                                </>}
                                <IconView label={"View Schedule Details"} icon={<IconEye />} onClick={() => onView(b?.id)} />
                                <IconView label={"Clone Schedule"} icon={<IconCopy />} onClick={() => onEdit( b?.id,b?.type,true)} />
                                <IconView label={"Edit Schedule"} iconType={"edit"} onClick={() => onEdit( b?.id,b?.type,false)} />
                                <IconView label={"Delete Schedule"} iconType={"delete"}  onClick={() => onDelete(b?.id,b?.type)} />
                            </Group>
                        </td>
                    </tr>
                })}
            </PageLoading>
        </TableView>

    </>
}
const DateWiseSchedule = ({onView,onEdit,onDelete,onPause,onResume}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [show,setShow] = useState();
    const [date,setDate] = useState(new Date());
    const [isLoading,setIsLoading] = useState(false);
    const [list,setList] = useState([]);
    const [page,setPage] = useState(1);
    const [search,setSearch] = useState("");
    const [total,setTotal] = useState(1);

    useEffect(() =>{
        fetchSchedules();
    },[date,page]);

    const fetchSchedules = async () =>{
        setIsLoading(true);
        const response = await RequestPost(CloudApi.DateSchedules, {sessionId,page,date,search});
        setList(response?.schedules);
        setTotal(response?.total);
        setIsLoading(false);
    }
    const handleDate = (date) =>{
        console.log(date)
        setDate(date);
        setPage(1);
        setShow();
    }
    //search pagination, upcoming
    return<>
        <Grid my={5}>
            <Grid.Col span={3}>
                <Group>
                    <TextInput size={"xs"} variant={"filled"} value={search} onChange={(e) => setSearch(e.target.value)} />
                    <Button size={"xs"} compact onClick={fetchSchedules}>Search</Button>
                </Group>
            </Grid.Col>
            <Grid.Col span={3}>
                <Group sx={{cursor: "pointer"}} mr={15}  onClick={setShow} position={"center"}>
                    <Text size={'lg'} align={'center'} weight={'bolder'} color={Colors.Primary}>
                        {timeConversionWithOffset({type: "momentFormat",dateTime: date, format: "ll"})}
                    </Text>
                    <IconCalendar sx={{color: Colors.Primary}}/>
                </Group>
            </Grid.Col>
            <Grid.Col span={3}>
                <BoldText align={"center"} text={`Total Results: ${total}`} mt={10} />
            </Grid.Col>
            <Grid.Col span={3}>
                <Group position={"right"}>
                    {total > 10 && <Pagination position={"center"} size={"xs"} page={page} total={Math.ceil(total / 10)} onChange={setPage}/>}
                    <Button compact size={"xs"} onClick={fetchSchedules} leftIcon={<IconRefresh />}>Refresh</Button>
                </Group>
            </Grid.Col>
        </Grid>

        <Modal onClose={() => setShow(false)} opened={show} title={<Text align={'center'} weight={'bold'}>Pick Date</Text>}>
            <Calendar fullWidth value={date} onChange={handleDate} minDate={currentISTTime(moment().subtract(15,'day'))} />
        </Modal>
        <PageLoading isLoading={isLoading}>
            <TableView headers={["Time","Type","Title","Contact Type","Log Details","Options"]}>
                {list?.map(b =><tr>
                            <td width={"10%"}>{convertUnixToFormat(b?.sendTime,'LT')}</td>
                            <td width={"10%"}>{b?.type|| "schedule"}</td>
                            <td width={"20%"}><Text lineClamp={1}>{b?.title}</Text></td>
                            <td width={"20%"}>{b?.contacts?.count || b?.contacts?.numbers?.length} {b?.contacts?.type}</td>
                            <td width={"20%"}>
                                {b?.sendTime <= moment().unix() ? <Group position={"apart"}>
                                    <Group sx={{gap: 3}}><IconMessage size={18} color={Colors.Primary} /><Text>{b?.logInfo?.msgLength}</Text></Group>
                                    <Group sx={{gap: 3}}><IconCheck  size={18} color={'grey'} /><Text>{b?.logInfo?.sent}</Text></Group>
                                    <Group sx={{gap: 3}}><IconChecks  size={18} color={'grey'} /><Text>{b?.logInfo?.delivered}</Text></Group>
                                    <Group sx={{gap: 3}}><IconChecks  size={18} color={'green'} /><Text>{b?.logInfo?.read}</Text></Group>
                                    <Group sx={{gap: 3}}><IconX size={18} color={'red'} /><Text>{b?.logInfo?.failed}</Text></Group>
                                </Group>:  <Group position={"center"}>
                                    <Badge>Upcoming</Badge>
                                </Group>}
                            </td>
                            <td width={"15%"}>
                                <Group mx={5} mb={5} position={"right"}>
                                    {moment().unix() < b?.sendTime && b?.type !== "Program" && <>
                                        <IconView label={"Edit Schedule"} iconType={"edit"} onClick={() => onEdit(b?.schId,b?.type,false)} />
                                        <IconView label={"Delete Schedule"} iconType={"delete"}  onClick={() => onDelete(b?.schId,b?.type)} />
                                    </>}
                                    <IconView label={"View Schedule Details"} icon={<IconEye />} onClick={() => onView({id: b?._id})} />
                                    <IconView label={"Clone Schedule"} icon={<IconCopy />} onClick={() => onEdit(b?.schId,b?.type,true)} />
                                </Group>
                            </td>
                        </tr>)}
            </TableView>
        </PageLoading>
    </>
}
const UpcomingSchedules  = ({onView,onEdit,onDelete,onPause,onResume}) =>{

    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [search,setSearch] = useState("");
    const [page,setPage] = useState(1);
    const [list,setList] = useState([]);
    const [total,setTotal] = useState();
    const [totalPage,setTotalPage] = useState();

    useEffect(() =>{
        fetchSchedules();
    },[page]);

    const fetchSchedules = async () =>{
        setIsLoading(true);
        const response = await RequestPost(CloudApi.UpcomingSchedules, {sessionId,page,search});
        setList(response?.schedules);
        setTotal(response?.total);
        setTotalPage(response?.totalPage);
        setIsLoading(false);
    }
    const handleSearch = (value) =>{
        setPage(1);
        fetchSchedules();
    }
    const onRefresh = () =>{
        setSearch("");
        setPage(1);
    }

    return <>
        <Grid my={5}>
            <Grid.Col span={4}>
                <Group>
                    <TextInput size={"xs"} placeholder={"Search"}  variant={"filled"} value={search} onChange={(e) => setSearch(e.target.value)} />
                    <Button size={"xs"} compact onClick={handleSearch}>Search</Button>
                </Group>
            </Grid.Col>
            <Grid.Col span={4}>
                <BoldText align={"center"} text={`Total Results: ${total}`} mt={10} />
            </Grid.Col>
            <Grid.Col span={4}>
                <Group position={"right"}>
                    {total > 10 && <Pagination position={"center"} size={"xs"} page={page} total={Math.ceil(total / 10)} onChange={setPage}/>}
                    <Button compact size={"xs"} onClick={fetchSchedules} leftIcon={<IconRefresh />}>Refresh</Button>
                </Group>
            </Grid.Col>
        </Grid>
        <PageLoading isLoading={isLoading}>
            <TableView headers={["Time","Type","Title","Contacts","Options"]}>
               {Object.keys(list).map(a =>{
                    return <>
                        <td colSpan={6} align={'center'} style={{backgroundColor: "lightgrey"}}>{a}</td>
                        {list?.[a]?.map(b =>{
                            return <tr>
                                <td width={"20%"}>{convertUnixToFormat(b?.sendTime,'LT')}</td>
                                <td width={"20%"}>{b?.type || "Schedule"}</td>
                                <td width={"20%"}><Text lineClamp={1}>{b?.title}</Text></td>
                                <td width={"20%"}>{b?.contacts?.count || b?.contacts?.numbers?.length} {b?.contacts?.type}</td>
                                <td width={"20%"}>
                                    <Group  mx={5} mb={5}>
                                        <IconView label={"View Schedule Details"} icon={<IconEye />} onClick={() => onView(b?._id)} />
                                        <IconView label={"Clone Schedule"} icon={<IconCopy />} onClick={() => onEdit(b?.schId,b?.type, b?.scheduleType,true)} />
                                        {moment().unix() < b?.sendTime && <>
                                            <IconView label={"Edit Schedule"} iconType={"edit"} onClick={() => onEdit( b?.schId,b?.type,b?.scheduleType,)} />
                                            <IconView label={"Delete Schedule"} iconType={"delete"}  onClick={() => onDelete(b?.schId,b?.type)} />
                                        </>}
                                    </Group>
                                </td>
                            </tr>
                        })}
                    </>
                })}
            </TableView>
        </PageLoading>
    </>
}

import React, {useEffect, useState} from "react";
import {
    Tabs,
    Button,
    Group,
    Paper,
    Text,
    MultiSelect,
    Grid,
    Divider,
    Modal,
    TextInput,
    Drawer,
    Stack,
    Badge
} from "@mantine/core";
import {Header, IconView, PageLoading, TableView} from "../../Pages/Components/PageComponents";
import {BoldText, ErrorText} from "../../Pages/Components/TextComponents";
import {R365Api, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {useDispatch, useSelector} from "react-redux";
import BookTemplates from "./BookTemplates";
import {IconMenuOrder} from "@tabler/icons";
import ProductSelect from "../Misc/ProductSelect";
import {WeekDays} from "../../Constants/Strings";
import ModalAddMessage from "../Components/ModalAddMessage";
import moment from "moment";
import PreviewScheduleMessage from "../../Pages/Components/PreviewScheduleMessage";
const PageHeading = {
    title: "Books",
    subTitle: "Manage Books.",
    buttons: [{type: "add", title: "Create Books"},{type: "refresh", title: "Refresh"}]
};
const Books = () =>{
    const {userId, r365} = useSelector(state => state.active);
    const [productId,setProductId] = useState(r365?.productId?.[0]);
    const [isLoading,setIsLoading] = useState(r365?.productId?.[0]);
    useEffect(()=>{
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 1000)
    },[productId])

    return <PageLoading isLoading={isLoading}>
        <ProductSelect productId={productId} setProductId={setProductId} />
        <Tabs  keepMounted={false} defaultValue="books">
            <Tabs.List grow>
                <Tabs.Tab value="books">Books</Tabs.Tab>
                <Tabs.Tab value="bookTemplates">Templates</Tabs.Tab>
                <Tabs.Tab value="AddOn">Add on Messages</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="books" pt="xs">
                <BookList productId={productId} />
            </Tabs.Panel>
            <Tabs.Panel value="bookTemplates" pt="xs">
                <BookTemplates productId={productId} />
            </Tabs.Panel>
            <Tabs.Panel value="AddOn" pt="xs">
                <AddonMessages productId={productId} />
            </Tabs.Panel>
        </Tabs>
    </PageLoading>
}

export default Books;


const BookList = ({productId}) => {
    const [isLoading,setIsLoading] = useState();
    const [books,setBooks] = useState([]);
    const [isAdd,setIsAdd] = useState();
    const [isPartsEdit,setIsPartsEdit] = useState();

    useEffect(() => {
        getDetails();
    },[])
    const getDetails = async () =>{
        setIsLoading(true);
        const data= await RequestPost(`${R365Api.books}/list/${productId}`,{});
        setBooks(data?.books || [])
        setIsLoading(false);

    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: () => setIsAdd({}), isLoading},{onClick: getDetails, isLoading}]} />
        {isAdd && <AddBook productId={productId} onClose={() => setIsAdd()} details={isAdd} onRefresh={getDetails}  />}
        {isPartsEdit && <BookTemplateOrder bookId={isPartsEdit} productId={productId} onRefresh={getDetails} onClose={() => setIsPartsEdit()} />}
        <PageLoading isLoading={isLoading}>
            <TableView headers={["Title","Author","Parts"," id","Options"]}>
                {books.map((data) =>  <tr key={data.id}>
                    <td style={{width: '40%'}}><BoldText text={data?.title} /></td>
                    <td style={{width: '20%'}}>{data?.author}</td>
                    <td style={{width: '10%'}}>{data?.ind}</td>
                    <td style={{width: '10%'}}>{data?.id}</td>
                    <td style={{width: '15%'}}>
                        <Group>
                            <IconView iconType={"edit"} label={"Edit"} onClick={() => setIsAdd(data)} />
                            <IconView icon={<IconMenuOrder />} label={"Order Templates"} onClick={() => setIsPartsEdit(data?.id)} />
                        </Group>
                    </td>
                </tr>)}
            </TableView>
        </PageLoading>
    </>
}
const AddBook = ({productId, onClose, onRefresh, details}) =>{
    const [title,setTitle] = useState(details?.title || "");
    const [id,setId] = useState(details?.id ||"");
    const [author,setAuthor] = useState(details?.author ||"");
    const [image,setImage] = useState(details?.image ||"");
    const [isLoading,setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [error,setError] = useState("");

    const handleCreate = async () =>{
        if(title?.trim() !== "" && id?.trim() !== "" && author?.trim() !== ""){
            setIsLoading(true);
            const data= await RequestPost(`${R365Api.books}/create/${productId}`,{id,author,title,image , oldId:details?.id, isEdit: !!details?.id});
            if(data?.status === 1){
                dispatch(ActionShowAlert({message: data?.message}));
                onClose();
                onRefresh();
            }
            else{
                setError(data?.message);
            }
            setIsLoading(false);
        }else{
            setError("Please Fill All Fields");
        }
    }

    return <Modal title={"Add / Edit Book"} onClose={onClose} opened={true} size={"70%"}>
        <Grid>
            <Grid.Col span={6}>
                <TextInput label={"Title"} value={title} onChange={e => setTitle(e.target.value)} />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput label={"Id"} value={id} onChange={e => setId(e.target.value)}  />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput label={"Author"} value={author} onChange={e => setAuthor(e.target.value)}   />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput label={"Image Url"} value={image} onChange={e => setImage(e.target.value)}   />
            </Grid.Col>
            <Grid.Col span={12}>
                {error && <ErrorText text={error} />}
                <Group position={"center"}>
                    <Button onClick={handleCreate} loading={isLoading}>Create / Update Book</Button>
                </Group>
            </Grid.Col>
        </Grid>

    </Modal>
}
const BookTemplateOrder = ({productId, bookId,onClose,onRefresh}) => {
    const [isLoading,setIsLoading] = useState();
    const [list,setList] = useState([]);
    const [selected,setSelected] = useState([]);
    const [selectedList,setSelectedList] = useState([]);
    const [isEdit,setIsEdit] = useState(false);
    const [isUpdating,setIsUpdating] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        getDetails();
    },[])
    const getDetails = async () =>{
        setIsLoading(true);
        const data= await RequestPost(`${R365Api.books}/singleBookTemplates/${productId}`,{bookId});
        console.log("datas", data)
        setList(data?.list || [])
        setSelected(data?.selected || []);
        setSelectedList(data?.selected || []);
        setIsLoading(false);
    }
    const handleSubmit = async () =>{
        setIsUpdating(true);
        const data= await RequestPost(`${R365Api.books}/updateBookParts/${productId}`,{bookId,parts: selectedList});
        dispatch(ActionShowAlert({message: data?.message}));
        setIsUpdating(false);
        onClose();
        onRefresh();
    }

    return <Drawer onClose={onClose} opened={true} size={"50%"} position={"right"}>
        <PageLoading isLoading={isLoading}>
            <Stack sx={{gap: 3}} mx={10}>
                {!isEdit ? <>
                    <Group position={'apart'} onClick={() => setIsEdit(true)} m={10}>
                        <Text weight={'bold'}>Templates Order: ({selected?.length} Selected)</Text>
                        <IconView iconType={"edit"} label={"Edit Order"} />
                    </Group>
                    <Divider />
                    <Grid mt={5}>
                        {selected?.map((a,i) => <Grid.Col span={3}>
                            <Paper shadow={"md"} withBorder p={3}>
                                <Text lineClamp={1}><b>{i+1}. </b>{list?.find(b => b.value === a)?.label}</Text>
                            </Paper>
                        </Grid.Col>)}
                    </Grid>
                </>: <Group m={5} p={5} mb={300}>
                    <MultiSelect
                        label={"Templates Order"}
                        searchable
                        data={list}
                        value={selectedList}
                        onChange={setSelectedList}
                    />
                    {JSON.stringify(selectedList) != JSON.stringify(selected) && <Button loading={isUpdating} mt={20} onClick={handleSubmit}> Update List</Button>}
                </Group>}
            </Stack>
    </PageLoading>
    </Drawer>
}
const BookDefaults = ({productId}) => {
    const [isLoading,setIsLoading] = useState();
    const [books,setBooks] = useState([]);
    const [selected,setSelected] = useState([]);
    const [selectedList,setSelectedList] = useState([]);
    const [isEdit,setIsEdit] = useState(false);
    const [isUpdating,setIsUpdating] = useState(false);
    const dispatch = useDispatch()

    useEffect(() => {
        getDetails();
    },[])
    const getDetails = async () =>{
        setIsLoading(true);
        const data= await RequestPost(`${R365Api.books}/defaults/${productId}`,{});
        setBooks(data?.books || [])
        setSelected(data?.selected || []);
        setSelectedList(data?.selected || []);
        setIsLoading(false);
    }
    const handleSubmit = async () =>{
        setIsUpdating(true);
        const data= await RequestPost(`${R365Api.books}/updateDefaults/${productId}`,{bookIds: selectedList});
        dispatch(ActionShowAlert({message: data?.message}));
        setIsUpdating(false);
    }

    return <PageLoading isLoading={isLoading}>
        <Paper shadow={'md'} withBorder mx={10} pb={50}>
            {!isEdit ? <>
                <Group position={'apart'} onClick={() => setIsEdit(true)} m={10}>
                    <Text weight={'bold'}>Book Order: ({selected?.length} Selected)</Text>
                   <IconView iconType={"edit"} label={"Edit Order"} />
                </Group>
                <Divider />
                <Grid mt={5} mx={5}>
                    {selected?.map((a,i) => <Grid.Col span={3}>
                        <Paper shadow={"md"} withBorder p={3}>
                            <Text lineClamp={1}><b>{i+1}. </b>{books?.find(b => b.value === a)?.label}</Text>
                        </Paper>
                    </Grid.Col>)}
                </Grid>
            </>: <Group m={5} p={5} mb={300}>
                <MultiSelect
                    label={"Books Order"}
                    data={books}
                    value={selectedList}
                    onChange={setSelectedList}
                />
                {JSON.stringify(selectedList) != JSON.stringify(selected) && <Button loading={isUpdating} mt={20} onClick={handleSubmit}> Update List</Button>}
            </Group>}
        </Paper>
    </PageLoading>
}
const MessagesList = ({productId}) => {
    const [isLoading,setIsLoading] = useState();
    const [msgList,setMsgList] = useState([]);
    const [list,setList] = useState([]);
    const dispatch = useDispatch()

    useEffect(() => {
        getDetails();
    },[])
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestGet(`${R365Api.GetMessageStats}/${productId}`);
        setMsgList(data?.msgList);
        setList(data?.list);
        setIsLoading(false);
    }

    return <PageLoading isLoading={isLoading}>
        <Paper shadow={"md"} withBorder>
            <Text weight={"bold"} align={"Center"}>Today Messages</Text>
            <Divider />
            <TableView headers={["Time","Scheduled","Total","Read","Success","Failed"]}>
                {msgList?.map(a =>{
                    return <tr>
                        <td>{a?.time}</td>
                        <td>{a?.count}</td>
                        <td>{a?.stats?.total}</td>
                        <td>{a?.stats?.read}</td>
                        <td>{a?.stats?.success}</td>
                        <td>{a?.stats?.failed}</td>
                    </tr>
                })}
            </TableView>

        </Paper>
    </PageLoading>
}


const AddonMessages = ({productId}) =>{
    const [isAdd,setIsAdd] = useState();
    const [isLoading,setIsLoading] = useState(false);
    const [addons,setAddons] = useState([]);
    const dispatch = useDispatch();

    const DeleteMessage = async  (day,deleteIndex) =>{
        dispatch(ActionShowPopup({
            title: "Delete AddOn Message",
            content: "Are you sure want to Delete AddOn Message ?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () => {
                const data = await RequestPost(`${R365Api.UpdateAddOn}/delete/${productId}`,{day,deleteIndex});
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert({message: data?.message}));
                getDetails();
            }
        }));

    }


    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${R365Api.UpdateAddOn}/list/${productId}`,{});
        setAddons(data?.addOns);
        console.log(data);
        setIsLoading(false);

    }
    useEffect(() =>{
        getDetails();
    },[])

    return <PageLoading isLoading={isLoading}>
            {isAdd && <ModalAddMessage productId={productId} type={"AddOn"} title={"Add On Messages"} {...isAdd} onClose={() => setIsAdd()} onRefresh={getDetails} /> }
            <Stack>
                {WeekDays?.map((a,day)=> {
                    return <Stack sx={{gap: 3}}>
                        <Group px={5} position={"apart"} sx={{backgroundColor: "#ccc"}}>
                            <BoldText text={a} />
                            <IconView iconType={"add"} onClick={() => setIsAdd({day})} />
                        </Group>
                        <Divider />
                        <Grid>
                            {addons?.[day]?.map((b,j) =>{
                                return <Grid.Col span={4}>
                                    <Paper shadow={"md"} withBorder p={5}>
                                        <Group position={"apart"}>
                                            <Badge>{b?.time}</Badge>
                                            <Group>
                                                <IconView iconType={"edit"} onClick={() => setIsAdd({day, details: b?.editOptions, editIndex: j})} />
                                                <IconView iconType={"delete"} onClick={() => DeleteMessage(day,j)} />
                                            </Group>
                                        </Group>
                                        <Divider />
                                        <PreviewScheduleMessage message={b} />
                                    </Paper>
                                </Grid.Col>
                            })}
                        </Grid>
                    </Stack>
                })}
            </Stack>
        </PageLoading>
}
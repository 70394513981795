import React from "react";
import {SegmentedControl} from "@mantine/core";
import {useSelector} from "react-redux";

const ProductSelect = ({productId,setProductId}) =>{
    const {r365} = useSelector(state => state.active);
    return <>
        {r365?.productId?.length > 1 && <SegmentedControl my={5} size={"xs"} color={"violet"} data={r365?.productId?.map(a => ({label:a,value: a}))} value={productId} onChange={setProductId} />}
    </>
}
export default ProductSelect;
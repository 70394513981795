import React, {useEffect, useState} from "react";
import {CheckBoxView, IconView, PageLoading, TableView} from "../Components/PageComponents";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import _ from "underscore";
import moment from "moment";
import {
    IconCheck, IconDownload, IconFilter,
    IconMessage,
    IconRefresh,
    IconSearch,
    IconSortAscending,
    IconSortDescending, IconSquare, IconSquareCheck, IconTrash,
    IconUsers
} from "@tabler/icons";
import {
    Group,
    Badge,
    Grid,
    TextInput,
    Button,
    Indicator,
    Pagination,
    Paper,
    Text,
    ScrollArea,
    Drawer, Stack, Modal, NumberInput, Select, Menu
} from "@mantine/core";
import {Colors} from "../../Constants/Colors";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {useViewportSize} from "@mantine/hooks";
import {ProgramMemberStatus} from "../../Constants/Strings";
import AddBulkMembers from "./AddBulkMembers";

const Members = ({programId}) =>{
    const {sessionId,alias,...list} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [members,setMembers] = useState([]);
    const statusArray = [{message: 'Pending',color: 'blue'}, {message: 'Duplicate',color: 'orange'}, {message:'Verified',color: 'green'}, {message:'Hold',color: 'red'},{message: "Denied", color: 'red'},{message: "Completed", color: 'yellow'}];
    const [page,setPage] = useState(1);
    const [total,setTotal] = useState(1);
    const [totalPage,setTotalPage] = useState(1);
    const [openDetails,setOpenDetails] = useState();
    const [editDetails,setEditDetails] = useState();
    const [filter,setFilter] = useState();
    const [isAdd,setIsAdd] = useState(false);
    const [render,setRender] = useState(1);
    //Delete
    const [isDelete,setIsDelete] = useState(false);
    const [selected,setSelected]= useState([]);



    const dispatch = useDispatch();
    const updateFilter = (filter) =>{
        setFilter(filter);
        setPage(1);
        fetchMembers({...filter,page: 1});
    }

    useEffect(() =>{
        fetchMembers();
    },[page]);

    const fetchMembers = async (params ={}) =>{
        console.log("Calling Fetch")
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "list",...filter,page,...params});
        setMembers(data?.members || [])
        setTotal(data?.total);
        setTotalPage(Math.ceil(data?.total / 10));
        setIsLoading(false);
    }
    const onDelete = (id) =>{
        dispatch(ActionShowPopup({
            title: "Delete Member",
            content: "Are you sure want to delete member ?",
            successTitle:"Delete",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "delete", id});
                dispatch(ActionShowAlert({message:  data?.message}));
                dispatch(ActionHidePopup());

                fetchMembers();
            }
        }))
    }

    const handleFormatMessage = async () => {
        dispatch(ActionShowPopup({title: "Format Messages",
            content:"It will Format Today Messages with New Changes in Messages you Made",
            successTitle: "Format",
            cancelTitle: "Close",
            onSuccess: async () => {
                const data = await RequestGet(`${CloudApi.programMsgFormat}/${sessionId}/${programId}`);
                dispatch(ActionShowAlert({message:  data?.message}));
                dispatch(ActionHidePopup());
            },
        }))

    }
    const handleUserDays = async () =>{
        dispatch(ActionShowPopup({title: "Reset member Days to 0",
            content:"It will Reset all Members Day to zero ?. From Tomorrow They will receive Day 1 Messages",
            successTitle: "Reset",
            cancelTitle: "Close",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "resetDay"});
                dispatch(ActionShowAlert({message:  data?.message}));
                dispatch(ActionHidePopup());
                fetchMembers();
            }
        }))

    }
    const VerifyMembers = async () => {
        dispatch(ActionShowPopup({title: "Verify Pending Members",
            content:"It will Move Pending Members as Verified Members. Are you sure want to Verify ?",
            successTitle: "Verify",
            cancelTitle: "Close",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "verifyMembers"});
                dispatch(ActionShowAlert({message:  data?.message}));
                dispatch(ActionHidePopup());
                fetchMembers();
            }
        }))
    }
    const DownloadMembers = () =>{
        window.open(`${CloudApi.downloadMembers}/${sessionId}/${programId}`);
    }

   const handleDeleteMembers = () =>{
        dispatch(ActionShowPopup({title: "Delete Members",
            content:"It will Delete All Selected Members. Do you want to Delete?",
            successTitle: "Delete",
            cancelTitle: "Close",
            onSuccess: async() => {
                const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "BulkDelete", list: selected});
                dispatch(ActionShowAlert({message:  data?.message}));
                dispatch(ActionHidePopup());
                onCancelDelete();
                fetchMembers();
            }
        }))

    }
   const handleDeleteAll = () =>{
        dispatch(ActionShowPopup({title: "Delete All Members",
            content:"It will Delete All Members In this Program. Do you want to Delete?",
            successTitle: "Delete",
            cancelTitle: "Close",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "deleteAll"});
                dispatch(ActionShowAlert({message:  data?.message}));
                dispatch(ActionHidePopup());
                onCancelDelete();
                fetchMembers();
            }
        }))
    }
   const handleDeleteDuplicates = () =>{
        dispatch(ActionShowPopup({title: "Delete Duplicate Members",
            content:"It will Delete All Duplicate Members In this Program. Do you want to Delete?",
            successTitle: "Delete",
            cancelTitle: "Close",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "deleteDuplicates"});
                dispatch(ActionShowAlert({message:  data?.message}));
                dispatch(ActionHidePopup());
                onCancelDelete();
                fetchMembers();
            }
        }))

    }

    const onCancelDelete = () =>{
        setSelected([]);
        setIsDelete(false);
    }
    const handleSelectThisPage =() =>{
        const result = _.unique([...selected,...(_.pluck(members,'_id'))]);
        setSelected(result);
        setRender(render+1);
    }
    const handleDeSelectThisPage =() =>{
        const ids = _.pluck(members,'_id');
        setSelected(selected.filter(a => !ids.includes(a)))
        setRender(render+1);

    }
    const handleSelect = (id) =>{
        if(selected?.includes(id)){
            setSelected(selected?.filter(a => a != id))
        }else{
            setSelected([...selected,id]);
        }
        setRender(render+1);
    }


    return <>
        <PageLoading isLoading={isLoading}>
            {isAdd && <AddBulkMembers sessionId={sessionId} programId={programId} onClose={() => setIsAdd(false)} onRefresh={() => fetchMembers()} />}
            <Grid position={'apart'} mx={10}>
                <Grid.Col span={2}>
                    {members?.length > 0 && <Group sx={{gap: 3}} my={3} position={"left"}>
                            <IconUsers size={16} color={Colors.Primary}/>
                            <Text size={"xs"}>{((page - 1) * 10) + 1} - {page * 10 > total ? total : page*10} of {total}</Text>
                        </Group>}
                </Grid.Col>
                <Grid.Col span={4}>
                    <FilterMembers filter={filter} updateFilter={updateFilter} programId={programId} />
                </Grid.Col>
                <Grid.Col span={2}>
                    {totalPage > 1 && <Group mr={10} position={'left'}>
                        <Pagination size={'xs'} page={page} total={totalPage} onChange={setPage}/>
                    </Group>}
                </Grid.Col>
                <Grid.Col span={4}>
                    <Group position={'right'} mb={10}>
                        <IconView variant={'gradient'} iconType={'add'} label={"Add Members"} onClick={() => setIsAdd(true)} color={'white'} />
                        <IconView variant={'gradient'} label={'Verify Pending Members'} onClick={VerifyMembers}  icon={<IconCheck size={18}  color={'white'} />} />
                        <IconView variant={'gradient'} label={'Download Members'} onClick={DownloadMembers} icon={<IconDownload size={18}  color={'white'} />} />
                        <IconView variant={'gradient'} label={'Refresh'} onClick={() => fetchMembers()} icon={<IconRefresh size={18}  color={'white'} />}  />
                        <IconView variant={'gradient'} label={'Delete Members'} onClick={() => setIsDelete(true)} icon={<IconTrash size={18}  color={'white'} />}  />
                        <IconView variant={'gradient'} label={'Reset Member Days to zero'} onClick={handleUserDays} icon={<IconUsers size={18}  color={'white'} />}  />
                        <IconView variant={'gradient'} label={'Format Messages'} onClick={handleFormatMessage} icon={<IconMessage size={18}  color={'white'} />}  />
                        {/*{activeFeatures?.includes("Assignment") && <IconView variant={'gradient'} label={'Create Asssignment Password For Missed'} onClick={() => this.setState({assignCreate:true})} icon={<IconBook size={18}  color={'white'} />}  />}*/}
                    </Group>
                </Grid.Col>
                {isDelete &&  <Grid.Col span={12}>
                    <Group position={'apart'}>
                        <Group position={'left'}>
                            {_.pluck(members,'_id').filter(a => !selected.includes(a))?.length > 0 ? <>
                                <Group onClick={handleSelectThisPage}>
                                    <IconSquare size={16}/> Select All In this Page
                                </Group>
                            </>: <>
                                <Group onClick={handleDeSelectThisPage}>
                                    <IconSquareCheck size={16} /> Deselect All In this Page
                                </Group>
                            </>}
                            <Text weight={'bold'}>Selected: {selected?.length || 0}</Text>
                            <Button compact size={"xs"} variant={'gradient'} onClick={handleDeleteMembers}>Delete Selected</Button>
                        </Group>
                        <Group position={'apart'}>
                            <Button compact size={"xs"} variant={'gradient'} onClick={handleDeleteAll} >Delete All</Button>
                            <Button compact size={"xs"} variant={'gradient'} onClick={handleDeleteDuplicates} >Delete Duplicates</Button>
                            <Button compact size={"xs"} variant={'outline'} onClick={onCancelDelete}>Cancel</Button>
                        </Group>
                    </Group>
                </Grid.Col>}
            </Grid>
            <TableView headers={["#","Name","mobile","Day","Status","Tags","Created","Options"]}>
                {members.map((a,i)=> <tr>
                    <td>{isDelete ? <CheckBoxView text={""} isSelected={selected?.includes(a?._id)} onClick={() => handleSelect(a?._id)} />: <Text>{(((page - 1) * 10) + (i+1))}</Text>}</td>
                    <td>{a?.name}</td>
                    <td>{a?.mobile}</td>
                    <td align={"center"}>{a?.day}</td>
                    <td><Badge size={'sm'} color={statusArray[a.status]?.color} variant="light">
                        {statusArray[a.status]?.message}
                    </Badge></td>
                    <td>{a?.tags}</td>
                    <td>{moment.unix(a?.created).format('lll')}</td>
                    <td>
                        <Group>
                            <IconView iconType={"view"} label={"View Member Details"} onClick={() => setOpenDetails(a?._id)}/>
                            <IconView iconType={'edit'} label={"Edit Member"} onClick={()=> setEditDetails(a)} />
                            <IconView iconType={'delete'} label={"Delete member"} onClick={()=> onDelete(a?._id)} />
                            {/*{a?.status  === 0 && <IconView icon={<IconSend />} label={"Resend optin Message"} onClick={()=> this.ResendOptin({mobile: a?.mobile, programId: a?.programId})} />}*/}
                        </Group>
                    </td>
                </tr>)}
            </TableView>
            {openDetails && <MemberDetails id={openDetails} programId={programId} sessionId={sessionId} onClose={() => setOpenDetails()} />}
            {editDetails && <MemberEdit details={editDetails} programId={programId} sessionId={sessionId} onClose={() => setEditDetails()} onRefresh={() => fetchMembers()} />}
        </PageLoading>
    </>
}

const FilterMembers = ({filter,updateFilter,programId}) =>{
    const {sessionId,ProTags} = useSelector(state => state.active);
    const [search,setSearch] = useState(filter?.search || "");
    const [openedMenu,setOpenedMenu] = useState(false);
    const [statuses,setStatuses] = useState(filter?.statuses || []);
    const [tags,setTags] = useState(filter?.tags || []);
    const [sort,setSort] = useState(filter?.sort || "asc");
    useEffect(() =>{
        setSearch(filter?.search || "");
        setStatuses(filter?.statuses || []);
        setTags(filter?.tags || []);
        setSort(filter?.sort || "asc");
    },[openedMenu]);

    const handleFilter = () => updateFilter({search,sort,statuses,tags})
    const handleSort = async ()=>{
        await setSort(sort === 'asc' ? "desc": "asc");
        updateFilter({search,sort: sort === 'asc' ? "desc": "asc",statuses,tags})
    }
    const changeFilter = (index) =>setStatuses(statuses?.includes(index) ? statuses?.filter(a => a !== index): [...statuses,index]);
    const changeTagSelected = (tag) => setTags(tags?.includes(tag) ? tags?.filter(a => a !== tag): [...tags,tag]);
    return  <Group>
        <TextInput size={"xs"} variant={'filled'} icon={<IconSearch size={14} />} onChange={(e) => setSearch(e.target.value)} value={search} />
        <IconView variant={'gradient'} icon={<IconSearch size={18} />} onClick={() => updateFilter({search,sort,statuses,tags})} label={"Search Members"} />

        <Menu shadow="md" opened={openedMenu} onChange={() => setOpenedMenu(!openedMenu)} closeOnItemClick={false} width={200} onClose={() => setOpenedMenu(false)}>
            <Menu.Target>
                <Indicator inline color={'red'} size={16} label={filter?.statuses?.length > 0 || filter?.tags?.length > 0 ? <Text>{(filter?.statuses?.length || 0) + (filter?.tags?.length || 0)}</Text>: undefined}>
                    <IconView label={"Filter Members"} variant={'gradient'} icon={ <IconFilter size={20} />} />
                </Indicator>
            </Menu.Target>
            <Menu.Dropdown>
                <ScrollArea sx={{height: 320}}>
                    <Menu.Label>Filter Members</Menu.Label>
                    {["Pending","Duplicate","Verified","Denied","Pause","Completed"]?.map((a,i) => <Menu.Item icon={statuses?.includes(i) ? <IconSquareCheck size={16} />: <IconSquare size={16} />} onClick={() => changeFilter(i)}>{a}</Menu.Item>)}
                   <Menu.Label>Filter Tags</Menu.Label>
                    {ProTags?.[programId]?.map(a => <Menu.Item icon={tags.includes(a) ? <IconSquareCheck size={16} />:<IconSquare size={16} />} onClick={() => changeTagSelected(a)}>{a}</Menu.Item>)}
                </ScrollArea>
                <Menu.Item>
                    <Button onClick={handleFilter}>Filter Results</Button>
                </Menu.Item>
            </Menu.Dropdown>
        </Menu>
        <IconView variant={'gradient'} icon={sort === 'asc' ? <IconSortAscending /> : <IconSortDescending />} onClick={handleSort} label={"Sort Members"} />
    </Group>
}
export default Members;

const MemberDetails = ({id,programId,sessionId,onClose}) =>{
    const [isLoading,setIsLoading] = useState(true);
    const [details,setDetails] = useState(undefined);
    const {width,height} = useViewportSize();

    const getDetails = async () =>{
        const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "details", id});
        setDetails(data?.details);
        setIsLoading(false);
    }
    useEffect( () =>{
        getDetails();
    }, []);
    const getFields = () =>{
        const list = [
            {label: "Name", value: details?.name},
            {label: "Mobile", value: details?.mobile},
            {label: "Email", value: details?.email},
            {label: "Day", value: details?.day},
            {label: "Tags", value: details?.tags?.join(", ")},
            {label: "Status", value: <Badge size={'sm'} color={ProgramMemberStatus[details?.status]?.color} variant="light">
                    {ProgramMemberStatus[details?.status]?.message}
                </Badge>},
            {label: "Created", value: moment.unix(details?.created).format('lll')},
            {label: "Verified", value: details?.verified ? moment.unix(details?.verified).format('lll'): ""},
        ];
        const extra = Object.keys(details?.extraValues || {});
        for(let ex of extra){
            list.push({label: ex, value: details?.extraValues?.[ex]?.toString()})
        }
        return list;
    }

    return <Drawer
        opened={true}
        title={<Text weight={'bold'}>Member Details</Text>}
        onClose={onClose}
        position={'right'}
        padding="sm"
        size="55%"
        lockScroll={false}
    >
        <PageLoading isLoading={isLoading}>
            <ScrollArea type={'auto'} scrollbarSize={2} sx={{height: height - 100}}>
                {details && <Stack mr={10} sx={{gap: 3}}>
                    <Grid mt={10}>
                        {getFields()?.map(a => <>
                            <Grid.Col span={6} p={5} sx={{borderStyle:"solid",borderWidth: 1,borderColor:"white",color:"white",backgroundColor: "grey"}}>
                                <Text mx={5} size={"sm"}>{a?.label}</Text>
                            </Grid.Col>
                            <Grid.Col span={6} p={5} sx={{borderStyle:"solid",borderWidth: 1,borderColor:"black"}}>
                                <Text mx={5} size={"sm"}>{a?.value}</Text>
                            </Grid.Col>
                        </>)}
                    </Grid>
                </Stack>}
            </ScrollArea>
        </PageLoading>
    </Drawer>
}
const MemberEdit = ({details,onClose,sessionId,programId,onRefresh}) =>{
    useEffect(() =>{
        setOpen(details != undefined);
        setId(details?._id || "");
        setEmail(details?.email || "");
        setName(details?.name || "");
        setMobile(details?.mobile || "");
        setDay(details?.day || "");
        setStatus(details?.status?.toString() || "");
        setTags(details?.tags?.join(',') || "");
    },[details]);

    const [open,setOpen] = useState(true);
    const [id,setId] = useState('');
    const [email,setEmail] = useState('');
    const [mobile,setMobile] = useState('');
    const [name,setName] = useState('');
    const [day,setDay] = useState(0);
    const [status,setStatus] = useState('');
    const [tags,setTags] = useState('');
    const [error,setError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const onSubmit = async () =>{
        if (name.trim() !== '' && mobile.trim() !== ''&& tags.trim() !== ''){
            const isMobileEdit = details?.mobile !== mobile;
            setIsLoading(true);
            const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "update", id, update: {email,mobile,name,day,tags: [tags],status: parseInt(status,10)}});
            setIsLoading(false);
            onClose();
            onRefresh();
        }else{
            setError(true);
        }
    }

    return  <Modal opened={open} onClose={onClose} title={"Edit User"}>
        <Grid>
            <Grid.Col span={6}>
                <TextInput
                    label="Name"
                    placeholder="Enter Name Here"
                    value={name}
                    fullWidth
                    name={'name'}
                    onChange={(e) => setName(e.target.value)}
                    error={error && name?.trim() === '' ? "Name is Required" : ""}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput
                    label="Email"
                    placeholder="Enter Email Here"
                    value={email}
                    fullWidth
                    name={'email'}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput
                    label="Mobile"
                    placeholder="Enter Mobile Here"
                    value={mobile}
                    fullWidth
                    name={'mobile'}
                    onChange={(e) => setMobile(e.target.value)}
                    error={error && mobile?.trim() === '' ? "Mobile is Required" : ""}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <NumberInput label={'Day'} onChange={setDay} value={day}  />
            </Grid.Col>
            <Grid.Col span={6}>
                <Select
                    label="Select Status"
                    placeholder="Pick Status"
                    data={[{label: "Pending",value: "0"},{label: "Duplicate",value: "1"},{label: "Verified",value: "2"},{label: "Hold",value: "3"},{label: "Denied",value: "4"},{label: "Completed",value: "5"}]}
                    value={status}
                    onChange={setStatus}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput
                    label="Tag"
                    placeholder="Enter Tag Here"
                    value={tags}
                    fullWidth
                    name={'tags'}
                    onChange={(e) => setTags(e.target.value)}
                    error={error && tags?.trim() === '' ? "Tag is Required" : ""}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <Button mt={20} onClick={onSubmit} loading={isLoading}>Update Details</Button>
            </Grid.Col>
        </Grid>
    </Modal>
}

import React,{Component} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import store from "../../store/store";
import {Stack, Text} from '@mantine/core';
import {Colors} from "../../Constants/Colors";

class ExpiryPage extends Component{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div sx={{justifyContent:"center", alignItems: "center"}}>
                <Stack sx={{gap : 3}} mt={150} align={'center'} justify={'center'}>
                    <Text my={28} size={40} align={'center'} color={Colors.Primary}>
                        Your Account validity have been Expired
                    </Text>
                    <Text size={20} align={'center'}>
                        Click <a href={"https://businesssalesmachine.com"}>businesssalesmachine.com</a> & pay with same registered mail ID to extend your validity.
                    </Text>
                </Stack>
            </div>
        );
    }
}
export default function(props){
    const navigation = useNavigate();
    const {state} = useLocation();
    const dispatch = useDispatch();
    const details = {...props,dispatch,...store.getState(),navigation,params:state};
    return <ExpiryPage  {...details} />
};

import CpuChart from "./Chart";
import {Divider, Grid, Group, Loader, Paper} from "@mantine/core";
import {Header, IconView, IconWithText, PageLoading, TableView} from "../Pages/Components/PageComponents";
import {useEffect, useState} from "react";
import {AdminApi, RequestGet, RequestPost} from "../Layouts/RequestManager";
import {BoldText, SmallText} from "../Pages/Components/TextComponents";
import {DatePicker} from "@mantine/dates";
import {IconCheck, IconChecks, IconCircleX, IconInfoCircle, IconUsers} from "@tabler/icons";
const PageHeading = {
    title: "Admin Dashboard",
    subTitle: "See Admin Dashboard Major Stats Here",
    buttons: [{type: "refresh", title:"Refresh"}]
}

const AdminDashboard = () =>{
    const [isLoading,setIsLoading] = useState(false);
    const [render,setRender] = useState(1);
    useEffect(() =>{
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 500)
    },[render])

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: () => setRender(render+1)}]} />
        <PageLoading isLoading={isLoading}>
            <Grid mx={10}>
                <Grid.Col span={6}>
                    <ScheduleStats />
                </Grid.Col>
                <Grid.Col span={6}>
                    <CpuChart />
                </Grid.Col>
                <Grid.Col span={6}>
                    <MessageStats />
                </Grid.Col>

            </Grid>
        </PageLoading>
    </>
}
export default AdminDashboard;

const MessageStats = () =>{
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    const [date,setDate] = useState(new Date())
    useEffect(() =>{
       getDetails();
    },[date])
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(AdminApi.msgStats, {date});
        setDetails(data?.result);
        setIsLoading(false);

    }

    return <Paper shadow={"md"} withBorder>
        <Group position={"apart"}>
            <BoldText text={"Overall Message Stats"} mx={10} variant={"gradient"} />
            <DatePicker size={"xs"} value={date} onChange={setDate} />
        </Group>
        <Divider />
        {isLoading ? <Group position={"center"} my={50}><Loader size={"xs"} /></Group>: <>
            <Group>
                <IconWithText text={details?.notSent || 0} icon={<IconCircleX size={14} color={"red"}/>} label={"NotSent"}  />
                <IconWithText text={details?.total} icon={<IconUsers size={14} />} label={"Total"} />
                <IconWithText text={details?.sent} icon={<IconCheck size={14} />} label={"Sent"} />
                <IconWithText text={details?.delivered} icon={<IconChecks size={14} color={"lightgrey"} />} label={"Delivered"} />
                <IconWithText text={details?.read} icon={<IconChecks size={14} color={"green"} />} label={"Read"} />
                <IconWithText text={details?.failed} icon={<IconInfoCircle size={14} color={"red"} />} label={"Failed"} />
            </Group>
            <Divider />
            <TableView headers={["Error", "Count"]}>
                {details?.reason && Object.keys(details?.reason)?.map (a =><tr>
                    <td>{a}</td>
                    <td>{details?.reason?.[a]}</td>
                </tr>)}
            </TableView>

        </>}
    </Paper>

}
const ScheduleStats = () =>{
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    const [date,setDate] = useState(new Date())
    useEffect(() =>{
       getDetails();
    },[date])
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestGet(AdminApi.schStats);
        setDetails(data?.logs);
        setIsLoading(false);

    }

    return <Paper shadow={"md"} withBorder>
        <Group position={"apart"}>
            <BoldText text={"Ongoing Schedule"} mx={10} variant={"gradient"} />
            <IconView iconType={"refresh"} label={"Refresh"} onClick={getDetails} />
        </Group>
        <Divider />
        {isLoading ? <Group position={"center"} my={50}><Loader size={"xs"} /></Group>: <>
            <TableView headers={["SessionId", "Msg in Queue","Sending","Waiting"]}>
                {details?.map (a =><tr>
                    <td>{a?.sessionId}</td>
                    <td>{a?.queue}</td>
                    <td>{a?.sending}</td>
                    <td>{a?.waiting}</td>
                </tr>)}
            </TableView>

        </>}
    </Paper>

}


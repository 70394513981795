import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Button, Grid, Group, MultiSelect, NumberInput, SegmentedControl, Text} from "@mantine/core";
import {DatePicker, TimeInput} from "@mantine/dates";
import moment from "moment-timezone";
import _ from 'underscore';
import {IconSquare, IconSquareCheck} from "@tabler/icons";
import {useSelector} from "react-redux";
import {
    convertUnixToFormat,
    currentISTTime,
    formatLTTime,
    getValuesOfDate,
    getValuesOfTime
} from "../../Constants/Functions";
import {IconView} from "../Components/PageComponents";

const ScheduleOptions = forwardRef((props, ref) => {

    const [schedule,setSchedule] = useState(4);
    const [weekday,setWeekday] = useState(['0']);
    const [month,setMonth] = useState(['1']);
    const [sendDate,setDate] = useState(currentISTTime());
    const [sendTime,setTime] = useState(currentISTTime());
    const [timeError,setTimeError] = useState(false);
    const [multiDates,setMultiDates] =useState([{date: new Date(),time: new Date()}]);
    const [renderCount,setRenderCount] = useState(0);

    const [enableMaxOccur,setEnableMaxOccur] = useState(false);
    const [maxOccur,setMaxOccur] = useState(1);
    const [enableEndDate,setEnableEndDate] = useState(false);
    const [endDate,setEndDate] = useState(new Date());
    const tz = useSelector(state => state.user.tz);
    const offset = tz?.offset || 330;

    useEffect(() =>{
        console.log('calling, userEdit',props);
        if(props?.details){
            const {schedule,weekday,month,sendDate,sendTime,sendTimes,endDate,maxOccur,enableMaxOccur,enableEndDate} = props?.details|| {};
            setSchedule(schedule || 4);
            setWeekday(weekday || ['0']);
            setMonth(month || ['1']);
            setDate(sendDate ? new Date(sendDate) : new Date(moment()));
            setTime(sendTime ? new Date(sendTime) : new Date(moment()));
            setMultiDates(sendTimes?.length > 0 ? sendTimes?.map(a => ({date: new Date(moment.unix(a)),time: new Date(moment.unix(a))})) : [{date: new Date(moment()), time: new Date(moment())}])
            setEnableEndDate(enableEndDate);
            setEndDate(endDate ? new Date(endDate) : new Date(moment()));
            setEnableMaxOccur(enableMaxOccur)
            setMaxOccur(maxOccur || 1);
            setRenderCount(renderCount+1);
        }
    }, [props?.details]);
    useImperativeHandle(ref, () => ({
        isValid() {
            let output;
            const finalEndDate = enableEndDate && endDate ?  getUnixFromDateTime(endDate,sendTime): undefined;
            const finalOccur = enableMaxOccur ? maxOccur : 0;
            switch(schedule){
                case 1: {
                    const isValid = sendTime !== '';
                    const options = {time: formatLTTime(sendTime), type: schedule,endDate: finalEndDate,maxOccur: finalOccur}
                    const optionDetails = {sendTime, schedule,endDate,maxOccur,enableEndDate,enableMaxOccur};
                    output = {isValid, optionDetails, options,completed: finalEndDate};
                    break;
                }
                case 2: {
                    const isValid = weekday.length > 0;
                    const options = {time: formatLTTime(sendTime), type: schedule, day: weekday,endDate: finalEndDate,maxOccur: finalOccur}
                    const optionDetails = {sendTime, schedule, weekday,endDate,maxOccur,enableEndDate,enableMaxOccur};
                    output = {isValid, optionDetails, options,completed: finalEndDate};
                    break;
                }
                case 3: {
                    const isValid = month.length > 0;
                    const options = {time: formatLTTime(sendTime), type: schedule, date: month,endDate: finalEndDate,maxOccur: finalOccur};
                    const optionDetails = {sendTime, schedule, month,endDate,maxOccur,enableEndDate,enableMaxOccur};
                    output = {isValid, optionDetails, options,completed: finalEndDate};
                    break;
                }
                case 4: {
                    const unixTime = getUnixFromDateTime(sendDate,sendTime);
                    const t = formatLTTime(sendTime);
                    const options = {time: t, type: schedule, customDate: convertUnixToFormat(unixTime,'L'), customUnixDate: unixTime};
                    const optionDetails = {sendTime, schedule, sendDate};
                    const isValid = sendDate !== '' && unixTime > moment().unix();
                    setTimeError(!isValid);
                    output = {isValid, optionDetails, options,completed: unixTime};
                    break;
                }
                case 5:
                    output = {isValid: true, optionDetails: {schedule}, options:{type: schedule}};
                    break;
                case 6: {
                    const sendTimes = multiDates?.map(a =>  getUnixFromDateTime(a?.date,a?.time));
                    const options = {type: schedule,sendTimes: _.unique(sendTimes)};
                    const optionDetails = {schedule, multiDates,sendTimes:_.unique(sendTimes)};
                    const isValid = multiDates?.length === sendTimes?.filter(a => a > moment().unix())?.length;
                    setTimeError(!isValid);
                    output = {isValid, optionDetails, options,completed: _.max(sendTimes)};
                    break;
                }

            }
            return output;
        },
    }));
    const getUnixFromDateTime = (date, time) =>{
        const d = getValuesOfDate(date);
        const t = getValuesOfTime(time);
        const unixTime = moment().utcOffset(offset).set({date: d?.date,month: d?.month,year: d?.year,hour: t?.hour,minutes: t?.minutes,seconds: 0, milliseconds: 0}).unix();
        return unixTime;
    }
    const addDate = () => setMultiDates([...multiDates,{date: new Date(),time: new Date()}])
    const DeleteDay = (i) => {
        let m = multiDates;
        m?.splice(i,1);
        setMultiDates(m);
        setRenderCount(renderCount+1);
    }
    const ChangeDay = (i,type,value) => {
        let m = [...multiDates.slice(0, i), {...multiDates[i],[type]: value}, ...multiDates.slice(i + 1)];
        setMultiDates(m);
        setRenderCount(renderCount+1);
    }

    return <Grid>
        <Grid.Col span={12} mt={10}>
            <SegmentedControl size={'xs'} color={'violet'} size={'xs'}  value={schedule} onChange={setSchedule}
                              data={[{value: 1,label: 'Every Day'},{value: 2,label: 'Weekly'},
                                  {value: 3,label: 'Every Month'},{value: 4,label: 'Custom Date'},{value: 6,label: 'Multiple Dates'},]} />
        </Grid.Col>
        {schedule == 2 &&  <Grid.Col span={4}>
            <MultiSelect
                dropdownPosition="top"
                label={'Choose day Of Week'}
                onChange={setWeekday} value={weekday}
                data={[{value: '0', label: 'Sunday'}, {value: '1', label: 'Monday'}, {
                    value: '2',
                    label: 'Tuesday'
                }, {value: '3', label: 'Wednesday'}, {value: '4', label: 'Thursday'}, {
                    value: '5',
                    label: 'Friday'
                }, {value: '6', label: 'Saturday'}]}></MultiSelect>
        </Grid.Col>}
        {schedule == 3 &&  <Grid.Col span={4}><MultiSelect dropdownPosition="top" label={'Choose day Of Month'} onChange={setMonth} value={month} data={[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31].map(a => ({value: a?.toString(), label: `${a.toString()}`}))}></MultiSelect> </Grid.Col>}
        {schedule == 4 &&  <Grid.Col span={4}><DatePicker multiple={true}
                                                          value={sendDate} minDate={currentISTTime()}
                                                          onChange={setDate}
                                                          label={`Schedule Date`}
        /> </Grid.Col>}
        {schedule == 6 &&  <>
            {multiDates?.map((a,i) => {
                return <Grid.Col span={6}>
                    <Group>
                        <DatePicker size={"xs"} value={a?.date} minDate={currentISTTime()} onChange={(date) => ChangeDay(i,"date",date)} label={`Schedule Date`} />
                        <TimeInput size={"xs"}  value={a?.time} onChange={(time) => ChangeDay(i,"time",time)}  format={"12"} label={`Schedule Time`}/>
                        <IconView iconType={'delete'} label={"Delete Date"} onClick={() => DeleteDay(i)} />
                    </Group>
                    {timeError && getUnixFromDateTime(a?.date,a?.time) <= moment().unix() && <Text color={'red'} size={'xs'}>Please Pick Future Dates</Text>}
                </Grid.Col>
            })}
            {multiDates?.length < 10 && <Grid.Col span={6}><Group mt={20}><Button compact onClick={addDate}>Add Dates</Button></Group></Grid.Col>}
        </>}
        {![5,6].includes(schedule) && <Grid.Col span={2}>
            <TimeInput
                error={timeError ? "Please Pick Future Time" : undefined}
                value={sendTime} onChange={setTime} format={"12"} label={`Schedule Time`}/>
        </Grid.Col>}
        <Grid.Col span={12} />
        {[1,2,3].includes(schedule) && <>
            <Grid.Col span={6}>
                <Group>
                    <Group onClick={() => setEnableEndDate(!enableEndDate)}>
                        {enableEndDate ? <IconSquareCheck />: <IconSquare />}
                        <Text>Stop Recurring After Specific Date</Text>
                    </Group>
                    {enableEndDate && <DatePicker
                        size={"xs"}
                        label={`End Date`}
                        value={endDate}
                        onChange={setEndDate}
                        defaultValue={endDate}
                        minDate={new Date()}
                    />}
                </Group>

            </Grid.Col>
            <Grid.Col span={6}>
                <Group align={"center"}>
                    <Group onClick={() => setEnableMaxOccur(!enableMaxOccur)}>
                        {enableMaxOccur ? <IconSquareCheck />: <IconSquare />}
                        <Text>Limit No.of Recurrances</Text>
                    </Group>
                    {enableMaxOccur && <NumberInput
                        size={"xs"}
                        label={`Max. Occurances`}
                        value={maxOccur}
                        onChange={setMaxOccur}
                        min={1}
                    />}
                </Group>
            </Grid.Col>
        </>}
    </Grid>
});
export default ScheduleOptions;

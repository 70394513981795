import {Stack, Text} from "@mantine/core";
import React from "react";

export const ErrorText = ({text, ...props}) => <Text mt={10} color={'red'} align={"center"} weight={"bold"} {...props}>{text}</Text>
export const LightText = ({text, ...props}) => <Text size={"xs"} color={"dimmed"} {...props}>{text}</Text>
export const SmallText = ({text, ...props}) => <Text size={"xs"} {...props}>{text}</Text>
export const BoldText = ({text, ...props}) => <Text size={"xs"} weight={"bold"} {...props}>{text}</Text>
export const ParagraphText = ({text, ...props}) => {
    return <Stack sx={{gap:2}}>
        {text?.split("\n")?.map(a =>  <Text size={"xs"} {...props}>{a}</Text>)}
    </Stack>

}
export const TextWithLines = ({text, maxLine = 10, ...props}) => {
    return <Text lineClamp={maxLine} {...props}>
        {text?.split("\n")?.map(a =>  <Text size={"xs"} {...props}>{a}</Text>)}
    </Text>

}

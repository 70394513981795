import React, {useEffect} from "react";
import {Button} from "@mantine/core";
import {IconPlugConnected} from "@tabler/icons";
import queryString from "query-string";

const WhatsappEmbedded = ({sessionId,alias}) =>{
    const login = () =>{
            const oauth2Endpoint = 'https://www.facebook.com/v18.0/dialog/oauth'
            const params = {
                client_id: '3455943534717479',
                redirect_uri: `https://wba1.businesssalesmachine.com/facebook`,
                response_type: 'code',
                config_id: '357788293824154',
                state: alias
            }
            const oauthURL = `${oauth2Endpoint}?${new URLSearchParams(params).toString()}`
            let windowName = 'BSM + WHATSAPP';
            let windowFeatures = 'width=1000,height=600';
            window.open(oauthURL,windowName, windowFeatures);
    }
    return <>
        <Button compact onClick={login} leftIcon={<IconPlugConnected />}>Connect Facebook</Button>
    </>
}
export default WhatsappEmbedded;

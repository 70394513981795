import React,{Component} from "react";
import {
    Table,
    Text,
    Paper,
    Select,
    Group,
    Button,
    Grid,
    Pagination,
    ScrollArea,
    Stack,
    Divider,
    Accordion, TextInput, Drawer
} from '@mantine/core';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {FlowAppDetails} from "../../Constants/Strings";
import {useEffect, useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {CheckBoxView, Header, IconView, PageLoading, TableView} from "../Components/PageComponents";
import {DateRangePicker} from "@mantine/dates";
import {useViewportSize} from "@mantine/hooks";

const PageHeading = {
    link: {name: "BSM Flows", link: `bsm-flow`},
    title: "Logs",
    subTitle: "View of Leads Received in BSM Flows",
    buttons: [
        {type: "refresh", title: "Refresh"}
    ]
}
const FlowLogs = () =>{
    const {id} = useParams();
    const [isLoading,setIsLoading] = useState();
    const {sessionId,alias} = useSelector(s => s.active);
    const [logs,setLogs] = useState([])
    const [total,setTotal] = useState(1);
    const [page,setPage] = useState(1);
    const [filter,setFilter] = useState({date: new Date(), id, errors:  false});
    const [openDetails,setOpenDetails] = useState();

    const navigation = useNavigate();
    const dispatch = useDispatch();
    useEffect(() =>{
        getDetails();
    },[filter,page])

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.bsmFlows}/logs/${sessionId}`,{...filter,page});
        setLogs(data?.logs);
        setTotal(data?.total);
        setIsLoading(false);
    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: getDetails}]} />

        <PageLoading isLoading={isLoading}>
            <Filter details={filter} setDetails={setFilter} />
            <Group position={"apart"} mx={10}>
                <Text weight={"bold"}>Total Results: {total}</Text>
                {total > 10 && <Pagination size={"xs"} total={Math.ceil(total / 10)} page={page} onChange={setPage}/>}
            </Group>
            <TableView headers={["Title","Trigger From","Steps","Status","Time","Options"]}>
                {logs?.map(a =>{
                    return <tr>
                        <td>{a?.title}</td>
                        <td>{FlowAppDetails?.[a?.triggerApp]?.label}</td>
                        <td>{a?.steps}</td>
                        <td>{a?.status}</td>
                        <td>{moment.unix(a?.created).format('lll')}</td>
                        <td>
                            <IconView iconType={"view"} label={"View Responses"} onClick={() => setOpenDetails(a?._id)} />
                        </td>
                    </tr>
                })}
            </TableView>
            {openDetails && <FlowLogResponse id={openDetails} onClose={() => setOpenDetails()} />}
        </PageLoading>
    </>
}
export default FlowLogs;

const Filter = ({details, setDetails}) =>{

    const {flows = []} = useSelector(state => state.active);
    const [date,setDate] = useState(details?.date || new Date());
    const [id,setId] = useState(details?.id);
    const [errors,setErrors] = useState(details?.errors);

    const handleFilter = () =>{
        setDetails({date,id,errors});
    }
    const ResetFilter = () =>{}


    return <>
        <Paper shadow={"md"} withBorder p={5} mx={10} mb={10}>
            <Grid>
                <Grid.Col span={3}>
                    <DateRangePicker size={"xs"} label={"Choose Date"} allowSingleDateInRange={true} value={date} onChange={setDate}  />
                </Grid.Col>
                <Grid.Col span={3}>
                    <Select size={"xs"}  label={"Flow"} data={flows || []} value={id} onChange={setId} />
                </Grid.Col>
                <Grid.Col span={3} mt={25} >
                    <CheckBoxView text={"Errors Only"} isSelected={errors} onClick={() => setErrors(!errors)} />
                </Grid.Col>
                <Grid.Col span={3}>
                    <Group mt={25} position={'center'}>
                        <Button size={"xs"} onClick={handleFilter} variant={'gradient'}>Filter</Button>
                        <Button size={"xs"} onClick={ResetFilter} variant={"subtle"}>Reset</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </Paper>
    </>

}

const FlowLogResponse = ({id, onClose,onRefresh}) =>{
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    const {height} = useViewportSize();
    const {sessionId,alias} = useSelector(s => s.active);
    useEffect(() =>{
        getDetails();
    },[])


    const getDetails = async () =>{
        setIsLoading(true)
        const data = await RequestPost(`${CloudApi.bsmFlows}/logDetails/${sessionId}`,{id});
        setDetails(data?.details);
        setIsLoading(false)
    }

    return <Drawer
        opened={true}
        onClose={onClose}
        title={<Text weight={'bold'}>Flow Log Details</Text>}
        position={'right'}
        padding="sm"
        size="xl"
        lockScroll={false}
    >
        <PageLoading isLoading={isLoading}>
            <ScrollArea type={'auto'} scrollbarSize={2} sx={{height: height - 100}}>
                <Stack mr={10} sx={{gap: 3}}>
                    <Text>{details?.title}</Text>
                    <Divider />
                    <Accordion>
                        {details?.stepResults?.map((a,i) =>{
                            return <>
                                {a?.app && <Accordion.Item value={a?.app || i}>
                                    <Accordion.Control>{FlowAppDetails?.[a?.app]?.label}</Accordion.Control>
                                    <Accordion.Panel>
                                        <Grid mt={10}>
                                            {a?.res && Object.keys(a?.res)?.map(b => {
                                                return <>
                                                    <Grid.Col span={6}><TextInput style={{backgroundColor: "#ccc"}}
                                                                                  value={b}
                                                                                  contentEditable={"inline"}/></Grid.Col>
                                                    <Grid.Col span={6}><TextInput style={{backgroundColor: "#ccc"}}
                                                                                  value={a?.res?.[b]}
                                                                                  contentEditable={"inline"}/></Grid.Col>
                                                </>
                                            })}
                                        </Grid>
                                    </Accordion.Panel>
                                </Accordion.Item>}
                            </>
                        })}
                    </Accordion>
                </Stack>
            </ScrollArea>
        </PageLoading>
    </Drawer>
}
import React, {useEffect, useState} from "react";
import {R365Api, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {CheckBoxView, Header, IconView, PageLoading, TableView} from "../../Pages/Components/PageComponents";
import {IconSend} from "@tabler/icons";
import {Group, Pagination, Select,Button} from "@mantine/core";
import {BoldText} from "../../Pages/Components/TextComponents";
import {FilledTextInput} from "../../Pages/Sequence/CustomButton";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
const PageHeading = {
    title: "R365 Messages",
    subTitle: "it will Show Messages Of Today and Waiting",
    buttons: [{title:"Refresh", type: "refresh"}]
}
const CustomerMessages = () =>{
    const {sessionId,r365} = useSelector(state => state.active);

    const [productId,setProductId] = useState(r365?.productId?.[0]);
    const [isLoading,setIsLoading] = useState(false);
    const [filter,setFilter] = useState({onlyToday: true});
    const [page,setPage] = useState(1);
    const [data,setData] = useState([]);
    const [total,setTotal] = useState(0);
    const dispatch = useDispatch()

    const onDelete = (id) =>{
        dispatch(ActionShowPopup({
            title: "Delete Message",
            content: "Are you sure want to Delete Messages",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const data = await RequestGet(`${R365Api.DeleteCustomerMessages}/${id}`);
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
            }
        }))
    }
    const onResend = (id) =>{
        dispatch(ActionShowPopup({
            title: "Resend Message",
            content: "Are you sure want to Resend Message",
            successTitle: "Resend",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const data = await RequestGet(`${R365Api.ResendCustomerMessages}/${sessionId}/${productId}/${id}`);
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
            }
        }))
    }



    useEffect(() =>{
        getDetails();
    },[page,filter])

    const getDetails = async () =>{
        setIsLoading(true)
        const data = await RequestPost(`${R365Api.CustomerMessages}/${productId}`,{page,...filter});
        setData(data?.data);
        setTotal(data?.total);
        setIsLoading(false)
    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: getDetails}]} />
        <PageLoading isLoading={isLoading}>
            <Group position={"apart"}>
                <BoldText size={"sm"} text={`Total Results: ${total}`} />
                <Pagination size={"xs"} total={Math.ceil(total/20)} page={page} onChange={setPage} />
            </Group>
            <Filter filter={filter} setFilter={setFilter}  />
            <TableView headers={["Mobile","Part", "Time", "Status","Reason","Options"]}>
                {data?.map(a => <tr>
                    <td>{a?.mobile}</td>
                    <td>{a?.template}</td>
                    <td>{a?.localTime}</td>
                    <td>{a?.status}</td>
                    <td>{a?.reason}</td>
                    <td>
                        <Group>
                            <IconView iconType={"delete"} label={"Delete particular Message"} onClick={() => onDelete(a?._id)} />
                            <IconView icon={<IconSend />} label={"Resend"} onClick={() => onResend(a?._id)} />
                        </Group>

                    </td>
                </tr>)}
            </TableView>
        </PageLoading>
    </>
}

const Filter = ({filter,setFilter}) =>{

    const [search,setSearch] = useState(filter?.search ||"");
    const [status,setStatus] = useState(filter?.status || "all");
    const [onlyToday,setOnlyToday] = useState(filter?.onlyToday || true);
    const handleFilter = () =>  setFilter({search, status, onlyToday})

    return   <Group>
        <FilledTextInput label={"Mobile, Part Name"} value={search} onChange={setSearch} />
        <Select size={"xs"} label={"Status"} data={["all","failed","read","delivered","sent","notSent"]?.map(a => ({label:a , value: a}))} value={status} onChange={setStatus} />
        <CheckBoxView text={"Only Today Msg"} isSelected={onlyToday} onClick={setOnlyToday} />
        <Button size={"xs"} compact onClick={handleFilter}>Filter</Button>
    </Group>
}

export default CustomerMessages;
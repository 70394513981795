import React, {useEffect, useState} from "react";
import {IconBookmark, IconEdit, IconTrash} from "@tabler/icons";
import {Badge, Button, Divider, Group, Modal, Paper, Stack, Text} from "@mantine/core";
import {Handle, Position} from "reactflow";
import {
    ButtonWithHandle,
    CustomButton,
    FilledTextArea,
    FilledTextInput,
    MenuOptions
} from "../CustomButton";
import {getRandomString} from "../../../Constants/Functions";

const ListNode = ({data,id}) =>  {
    const [header,setHeader] = useState("");
    const [body,setBody] = useState("");
    const [footer,setFooter] = useState("");
    const [btnText,setBtnText] = useState("");

    const [sections,setSections] = useState([{label: "Section 1", id: getRandomString(5), rows: [{label:"Row 1", id: getRandomString(5)}]}]);
    const [render,setRender] = useState(0);
    const [onEdit,setIsEdit] = useState();
    const [isError,setIsError] = useState(false);
    const initialOpen = {
        sections: [{label: "Section 1", id: getRandomString(5), rows: [{label:"Row 1", id: getRandomString(5)}]}],
        body: "Ask your Question",
    }
    const menuOptions = () =>{
        let options = [];
        if(data?.message){
            options.push({label: "Edit",type: "item",icon:  <IconEdit size={12} />, onClick: () => setIsEdit(data?.message)});
            options.push({type: "divider"});
        }
        options.push({label: "Delete", type: "item",icon:  <IconTrash color={"red"} size={12} />, onClick: () => data?.onDelete(id)});
        options.push({type: "divider"});
        if(data?.startNode){
            options.push({label: "Remove Start Node",type: "item",icon:  <IconBookmark size={12} />, onClick: () => data?.onStartNodeChange()});
        }else{
            options.push({label: "Add Start Node",type: "item",icon:  <IconBookmark size={12} />, onClick: () => data?.onStartNodeChange(id)});
        }
        return options;
    }
    useEffect(() => {
        if(onEdit){
            setHeader(onEdit?.header || "");
            setBody(onEdit?.body || "");
            setFooter(onEdit?.footer || "");
            setBtnText(onEdit?.btnText || "");
            setSections(onEdit?.sections || []);
        }
    }, [onEdit])

    const CloseModal = () =>{
        setIsEdit();
        setHeader("");
        setSections([]);
        setBody("");
        setFooter("");
        setBtnText("");
    }
    const onSave = () =>{
        let listValid = sections?.filter(a => a?.label?.trim() !== "" && a?.rows?.filter(b => b?.label?.trim() === "")?.length === 0)?.length === sections?.length;
        const isValid = body?.trim() !== ""&& btnText?.trim() !== "" && listValid;
        if(isValid){
            setIsError(false);
            const message = {header,body,footer,btnText, sections};
            data?.onChange({message,id});
            CloseModal();
        }else{
            setIsError(true);
        }

    }
    const AddSection = () => {
        setSections([...sections, {label: "", id: getRandomString(5), rows: [{label: "Row 1", id: getRandomString(5)}]}])
        setRender(render+1);
    }
    const RemoveSection = (id) => {
        setSections(sections?.filter(a => a?.id !== id))
        setRender(render+1);
    }
    const UpdateSectionTitle = (id,label) => {
        setSections(sections?.map(a => a?.id !== id ? a : {...a,label}))
        setRender(render+1);
    }

    const AddRow = (id)=>{
        const s = sections?.map(a => {
            return a?.id === id ? {...a, rows: [...a?.rows,{label: "",id: getRandomString(5)}]}: a;
        });
        setSections(s);
        setRender(render+1);
    }
    const AddRowDescription = (id,rowId,value) =>{
        const s = sections?.map(a => {
            return a?.id === id ? {...a, rows: a?.rows?.map(b => b?.id ===  rowId ? {...b,description: value}: b)}: a;
        });
        setSections(s);
        setRender(render+1);
    }
    const AddRowLabel = (id,rowId,value) =>{
        const s = sections?.map(a => {
            return a?.id === id ? {...a, rows: a?.rows?.map(b => b?.id ===  rowId ? {...b,label: value}: b)}: a;
        });
        setSections(s);
        setRender(render+1);
    }
    const DeleteRow = (id, rowId)=>{
        const s = sections?.map(a => {
            return a?.id === id ? {...a, rows: a?.rows?.filter(c => c?.id !== rowId)}: a;
        });
        setSections(s);
        setRender(render+1);
    }

    return  <Paper shadow={"md"} withBorder px={2} pb={2} sx={{backgroundColor: "lightblue",width: 200}}>
        <Handle type="target" position={Position.Left}  style={{ background: 'red' }}  />
        <Group position={"apart"} m={3}>
            <Text size={"xs"} weight={"bold"}>List</Text>
            {data?.startNode === true && <Badge>Start</Badge>}
            <MenuOptions options={menuOptions()} />
        </Group>
        <Modal opened={onEdit !== undefined} size={"30%"} title={<b>List Message</b>} onClose={CloseModal}>
            <FilledTextInput label={"Header"}  placeholder={"Header Message Here"} value={header} onChange={setHeader} />
            <FilledTextArea  label={"Body Message"} placeholder={"Body Message Here"} value={body} onChange={setBody} error={isError && body?.trim() === ""?"Message is Required": ""} />
            <FilledTextInput label={"Footer"}  placeholder={"Footer Message Here"} value={footer} onChange={setFooter} />
            <FilledTextInput label={"Button Text"}  placeholder={"Button Text"} value={btnText} onChange={setBtnText} error={isError && btnText?.trim() === ""?"Button Text is Required": ""} />
            <Divider my={5} />
            <Stack sx={{gap: 3}} mt={5}>
                {sections?.map((a,i) =>{
                    return <Paper sx={{backgroundColor: "#ccc"}} p={5}>
                        <FilledTextInput label={`Section ${i+1} Title`} value={a?.label} error={isError && a?.label?.trim() === ""?"Title is Required": ""} onChange={(value) => UpdateSectionTitle(a?.id,value)} />
                        <Divider my={3} color={"white"} />
                        {a?.rows?.map((b,j) => <>
                                <Group position={"apart"}>
                                    <Text size={"xs"}>{`Row ${j+1}`}</Text>
                                    {!b?.description &&  <Text color={"blue"} variant={"link"} size={"xs"} onClick={() => AddRowDescription(a?.id, b?.id,"description")}>+ Add Description</Text>}
                                </Group>
                                <FilledTextInput rightSection={<IconTrash color={"red"} size={14} onClick={() => DeleteRow(a?.id,b?.id)} />} value={b?.label} error={isError && b?.label?.trim() === ""?"Row Title is Required": ""}  onChange={(value) => AddRowLabel(a?.id, b?.id,value)} />
                                {b?.description && <FilledTextInput my={3} label={`Row ${j+1} Description`} placeholder={"Description"} value={b?.description} onChange={(value) => AddRowDescription(a?.id, b?.id,value)}  />}
                                {b?.description &&<Divider my={3} color={"white"} />}
                            </>
                        )}
                        <Text color={"blue"} variant={"link"} size={"xs"} onClick={() => AddRow(a?.id)}>+ Add Row</Text>
                        <Button my={5} fullWidth variant={"outline"} compact color={"red"} onClick={() => RemoveSection(a?.id)}>Remove Section</Button>
                    </Paper>
                })}
                <Button mt={5} variant={"outline"} compact onClick={AddSection} color={"green"} fullWidth>Add Section</Button>
            </Stack>
            <CustomButton title={"Save Message"} onClick={onSave} />
        </Modal>
        {data?.message ?  <Stack sx={{gap: 1 , backgroundColor: "white"}} px={5} pb={5}>
            {data?.message?.header && <Text size={"xs"} weight={"600"}>{data?.message?.header}</Text>}
            {data?.message?.body && data?.message?.body?.split("\n")?.map(a => <Text size={"xs"}>{a}</Text>)}
            {data?.message?.footer && <Text size={"xs"} color={"dimmed"}>{data?.message?.footer}</Text>}
            <Divider my={5} />
            {data?.message?.sections?.map((a,i) =>{
                return <>
                    <Text sx={{height: 25}} size={"xs"}>{a?.label}</Text>
                    {a?.rows?.map((b,j) => <ButtonWithHandle label={b?.label} id={b?.id}/>)}
                    {/*<Divider />*/}
                </>
            })}
            <ButtonWithHandle label={"Default"} id={"default"} buttonProps={{variant: "filled"}} />
        </Stack>: <Text size={"xs"} sx={{cursor: "pointer"}} onClick={() => setIsEdit(initialOpen)} align={"center"} variant={"gradient"} my={5}>+ Add Message</Text>}
    </Paper>
}
export default ListNode;

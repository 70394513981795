import React, {useEffect, useRef, useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {Header, IconView, PageLoading, PrefCountView} from "../Components/PageComponents";
import {Grid, Group, Modal, TextInput, Button, Paper, Divider, CopyButton, Pagination} from "@mantine/core";
import {BoldText} from "../Components/TextComponents";
import MessageOptions from "../Schedule/MessageOptions";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import PreviewScheduleMessage from "../Components/PreviewScheduleMessage";
import {IconCheck, IconCopy, IconSearch} from "@tabler/icons";
import {Colors} from "../../Constants/Colors";
import {showMaxAlert} from "../../Constants/Functions";
const PageHeading = {
    title: "Message List",
    subTitle: "Create Message Once and Use it in Many Place",
    buttons: [{type: "add", title: "Create Message"},{type: "refresh", title: "Refresh"}]
}

const MessageList = () =>{
    const {sessionId,prefrences,prefUsed} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [list,setList] = useState([]);
    const [total,setTotal] = useState();
    const [page,setPage] = useState();
    const [search,setSearch] = useState();

    const [isAdd,setIsAdd] = useState();
    const dispatch = useDispatch();

    useEffect(() =>{
        getDetails()
    },[page,search]);
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.messageList}/list/${sessionId}`,{search,page});
        setList(data?.list);
        setTotal(data?.total);
        setIsLoading(false)
    }
    const onDelete = (id) =>{
        dispatch(ActionShowPopup({
            title : "Delete Message",
            content: "Are you sure want to Delete ?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const data = await RequestPost(`${CloudApi.messageList}/delete/${sessionId}`,{id});
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert({message: data?.message}));
                getDetails();
            }
        }))
    }
    const handleSearch = (search) =>{
        setSearch(search);
        setPage(1);
    }
    const handleCreate = () =>{
        if(prefrences?.messageList > prefUsed?.messageList){
            setIsAdd({type: "create"})
        }else{
            showMaxAlert({dispatch, type: "MessageList"})
        }
    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: handleCreate}, {onClick: getDetails}]}  />
        {isAdd && <AddMessageList onClose={() => setIsAdd()} onRefresh={getDetails} details={isAdd} />}
        <PageLoading isLoading={isLoading}>
            <Filter total={total} page={page} setPage={setPage} search={search} onSearch={handleSearch} />
            <Grid>
                {list?.map(a =>{
                    return <Grid.Col span={3}>
                                <Paper shadow={"md"} withBorder>
                                    <Group position={"apart"} mx={10}>
                                        <BoldText text={a?.title} size={"md"} />
                                        <Group>
                                            <CopyButton value={`M_${a?.id}`}>
                                                {({ copied, copy }) => <IconView label={"Copy Message Code.\n\n You can use it in Button Messages To trigger Message"} icon={copied ? <IconCheck color={"green"} /> :<IconCopy color={Colors.Primary} />} onClick={copy} />}
                                            </CopyButton>
                                            <IconView iconType={"edit"} label={"Edit"} onClick={() => setIsAdd(a)} />
                                            <IconView iconType={"delete"} label={"Delete"} onClick={() => onDelete(a?.id)} />
                                        </Group>
                                    </Group>
                                    <Divider />
                                    <PreviewScheduleMessage message={a?.message} />
                                </Paper>
                        </Grid.Col>
                })}
            </Grid>
        </PageLoading>
    </>
}

export default MessageList;

const Filter = ({total,page,setPage,onSearch,search}) =>{
    const [searchText,setSearchText] = useState(search);
    const onSearchText = () => onSearch(search);

    return <Grid>
        <Grid.Col span={4}>
            <PrefCountView type={"messageList"} />
        </Grid.Col>
        <Grid.Col span={8}>
            <Group position={"right"}>
                <Group>
                    <TextInput placeholder={"Search"} size={"xs"} value={searchText} onChange={(e) => setSearchText(e.target.value)}  />
                    <IconView icon={<IconSearch />} onClick={onSearchText} />
                </Group>
                {total > 20 && <Pagination size={"xs"} total={Math.ceil(total/20)} page={page} onChange={setPage} />}
            </Group>
        </Grid.Col>
    </Grid>
}


const AddMessageList = ({details, onClose,onRefresh}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const [title,setTitle] = useState(details?.title || "");
    const ref = useRef();
    const dispatch = useDispatch();


    const onSubmit = async () =>{
        const {isValid,message} =  ref.current?.isValid();
        if(isValid && title?.trim() !== ""){
            setIsError(false);
            setIsLoading(true);
            const data = await RequestPost(`${CloudApi.messageList}/create/${sessionId}`,{
                id: details?.id, title, message
            });
            dispatch(ActionShowAlert({message: data?.message}));
            onClose();
            onRefresh();
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }

    return <Modal title={<BoldText text={`${details?.id ? "Edit": "Create"} Message`} size={"md"} />} onClose={onClose} opened={"true"} size={"70%"}>
        <Grid>
            <Grid.Col span={6}>
                <TextInput label={"Title"} value={title} onChange={e => setTitle(e.target.value)} error={isError && title?.trim() === "" ? "Title is Required": ""} />
            </Grid.Col>
            <MessageOptions ref={ref} show={['template','session']} details={details?.message?.editOptions} />
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button loading={isLoading} onClick={onSubmit} size={"xs"} sx={{width: 200}}>{details?.id ? "Edit": "Create"}</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>

}
import React,{useEffect, useState} from "react";
import {Text, Paper, Image, Button, Stack, Group, Modal, Loader, Divider} from "@mantine/core";
import {BoldText, ParagraphText, SmallText, TextWithLines} from "./TextComponents";
import {CloudApi, CloudUrl, RequestGet, WBAUrls} from "../../Layouts/RequestManager";
import PreviewTemplate from "../Templates/PreviewTemplate";
import {IconView} from "./PageComponents";
import {useSelector} from "react-redux";
import {IconEye, IconEyeOff} from "@tabler/icons";
import FilePreview from "../Attachments/FilePreview";
import {Colors} from "../../Constants/Colors";
import FileLinkPreview from "../Attachments/FileLinkPreview";

const PreviewScheduleMessage = ({message}) =>{
    const [isView,setIsView] = useState(false);
    return <Stack sx={{gap:2}} px={10}>
            {message?.editOptions?.type === "session" && <SessionPreview  message={message} />}
            {message?.editOptions?.type === "sequence" && <Group my={5} position={"apart"}>
                <BoldText text={"Sequence Message"} />
                <SmallText text={message?.editOptions?.name} />
            </Group>}
            {message?.editOptions?.type === "template" && <>
                <Group my={5} position={"apart"}>
                    <BoldText text={"Template Message"} />
                    <Group>
                        <SmallText text={message?.template?.name} />
                        <IconView label={isView ? "Hide Preview": "View Preview"} icon={isView ? <IconEyeOff />: <IconEye />} onClick={() => setIsView(!isView)} />
                    </Group>
                </Group>
                {isView &&  <>
                    <Divider />
                    <ViewApprovedTemplate templateId={message?.editOptions?.message?.templateId} onClose={() => setIsView(false)} />
                </>}
            </>}
    </Stack>
}
export default PreviewScheduleMessage;

const SessionPreview = ({message}) =>{
    const renderPreview = (msgType) =>{
        switch(msgType){
            case "Text":
               return  <TextWithLines text={message?.message} maxLine={3} />
            case "Image":
                return <Stack sx={{gap: 3}}>
                    {message?.image?.link && <FileLinkPreview link={message?.image?.link} type={"Image"} />}
                    {message?.image?.code && <FilePreview code={message?.image?.code} />}
                    {message?.message && <TextWithLines text={message?.message} maxLine={3}/>}
                </Stack>
            case "Audio":
                return <Stack sx={{gap: 3}}>
                    {message?.audio?.link && <FileLinkPreview link={message?.audio?.link} type={"Audio"} />}
                    {message?.audio?.code && <FilePreview code={message?.audio?.code}/>}
                </Stack>
            case "Video":
                return <Stack sx={{gap: 3}}>
                    {message?.video?.link && <FileLinkPreview link={message?.video?.link} type={"Video"} />}
                    {message?.video?.code && <FilePreview code={message?.video?.code} />}
                    {message?.message && <TextWithLines text={message?.message} maxLine={3}/>}

                </Stack>
            case "Document":
                return  <Stack sx={{gap: 3}}>
                    {message?.document?.link && <FileLinkPreview link={message?.document?.link} type={"Document"} />}
                    {message?.document?.code && <FilePreview code={message?.document?.code} />}
                    {message?.message && <TextWithLines text={message?.message} maxLine={3}/>}
                </Stack>

            case "Buttons":
                const header = message?.header?.[message?.header?.type?.toLowerCase()];
                return <Stack sx={{gap:3}}>
                    <TextWithLines text={message?.header?.text} maxLine={3} color={"black"} weight={"bold"} />
                    {header?.code && <FilePreview code={header?.code}/>}
                    {header?.link && <FileLinkPreview link={header?.link} type={message?.header?.type} />}
                    {message?.bodyText && <TextWithLines text={message?.bodyText} maxLine={3} color={"black"}/>}
                    {message?.footerText && <TextWithLines text={message?.footerText} maxLine={3} size={"xs"} color={"dimmed"}/>}
                    <Group grow>
                        {message?.buttons?.map(a => <Button compact size={"xs"}>{a?.title}</Button>)}
                    </Group>
                </Stack>
            case "List":
                return <Stack sx={{gap:3}}>
                    <TextWithLines text={message?.header} maxLine={1} color={"black"} weight={"bold"} />
                    <TextWithLines text={message?.body} maxLine={3} color={"black"} />
                    <TextWithLines text={message?.footer} maxLine={1} size={"xs"} color={"dimmed"} />
                    <Group grow>
                        <Button compact size={"xs"}>{message?.btnText}</Button>
                    </Group>
                </Stack>
            case "Url": {
                const header = message?.header?.[message?.header?.type?.toLowerCase()];
                return <Stack sx={{gap: 3}}>
                    <TextWithLines text={message?.header?.text} maxLine={3} color={"black"} weight={"bold"}/>
                    {header?.code && <FilePreview code={header?.code}/>}
                    {header?.link && <FileLinkPreview link={header?.link} type={message?.header?.type}/>}
                    {message?.body && <TextWithLines text={message?.body} maxLine={3} color={"black"}/>}
                    {message?.footer &&
                        <TextWithLines text={message?.footer} maxLine={1} size={"xs"} color={"dimmed"}/>}
                    <Group grow>
                        <Button compact size={"xs"}>{message?.urlButton?.text}</Button>
                    </Group>
                </Stack>
            }
            case "Form": {
                const header = message?.header?.[message?.header?.type?.toLowerCase()];
                console.log("Form", message,header);
                return <Stack sx={{gap: 3}}>
                    <TextWithLines text={message?.header?.text} maxLine={3} color={"black"} weight={"bold"}/>
                    {header?.code && <FilePreview code={header?.code}/>}
                    {header?.link && <FileLinkPreview link={header?.link} type={message?.header?.type} />}
                    {message?.body && <TextWithLines text={message?.body} maxLine={3} color={"black"}/>}
                    {message?.footer &&
                        <TextWithLines text={message?.footer} maxLine={1} size={"xs"} color={"dimmed"}/>}
                    <Group grow>
                        <Button compact size={"xs"}>{message?.btnText}</Button>
                    </Group>
                </Stack>
            }
        }
    }
    return <Stack sx={{gap: 1}}>
        <Paper shadow={"md"} withBorder pt={5} p={10} sx={{backgroundColor: Colors.Primary}}>
            <BoldText size={"sm"} text={`Session (${message?.editOptions?.msgType})`} color={"white"} />
            <Stack sx={{backgroundColor: "white"}} p={5}>
                {renderPreview(message?.editOptions?.msgType)}
            </Stack>
        </Paper>
    </Stack>
}


const SessionMessage = ({image,code,message,headerText,bodyText,footerText,buttons,editOptions,header,body,footer,btnText,form,draftForm,...params}) =>{
    console.log(params,editOptions);
    return <Stack sx={{gap: 1}}>
        <BoldText text={"Session Message"} size={"xs"} my={3} variant={"gradient"} />
        <Stack sx={{gap:2, alignContent:"flex-start", justifyContent:"start"}}>
            <Group position={"left"} align={"flex-start"} sx={{justifyContent: "flex-start"}}>
                {image?.link && <Image src={image?.link} width={50} height={50} fit={"contain"} />}
                {image?.code && <FilePreview code={image?.code}/>}
            </Group>
            {(form?.formId || draftForm?.formId) && <Group>
                <BoldText color={"dimmed"} text={`FORM: ${form?.name || draftForm?.name}`} size={"xs"} my={3} />
            </Group>}
            {message && <ParagraphText text={message} />}
            {headerText && <ParagraphText text={headerText} weight={"bold"} />}
            {bodyText && <ParagraphText text={bodyText} />}
            {footerText && <ParagraphText text={footerText} color={"dimmed"} />}
            {header && typeof header === "string" && <ParagraphText text={header} weight={"bold"} />}
            {body&& <ParagraphText text={body} />}
            {footer && <ParagraphText text={footer} color={"dimmed"} />}
            {buttons && <Group>
                {buttons?.map(a => <Button compact size={"xs"}>{a?.title}</Button>)}
            </Group>}
            {form?.formId && <Group>
                <Button compact size={"xs"}>{btnText}</Button>
            </Group>}
        </Stack>
    </Stack>
}

const ViewApprovedTemplate = ({templateId,onClose}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    useEffect(() => {
        getTemplatesDetails();
    },[]);
    const getTemplatesDetails = async () => {
        setIsLoading(true);
        const details = await RequestGet(`${CloudApi.templateDetails}/${sessionId}/${templateId}`);
        if(details){
            setDetails(details?.details);
            setIsLoading(false);
        }else{
            setIsLoading(false);
            onClose()
        }
    }
    return <>
        {isLoading ? <Loader />: <PreviewTemplate message={details?.message} showVar={false} />}
    </>
}

import React, {useEffect, useState} from "react";
import {AdminApi, CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {
    Badge,
    Button,
    Divider,
    Drawer,
    Grid,
    Group,
    Image,
    Loader,
    Paper,
    ScrollArea,
    Stack,
    Table,
    Tabs,
    Text
} from "@mantine/core";
import {
    FlowErrorMsg,
    FlowSuccessMsg,
    IconView,
    PageLoading,
    ProfileCardItem, SwitchView
} from "../../Pages/Components/PageComponents";
import {
    IconBuilding,
    IconBuildingStore, IconInfoCircle,
    IconLink, IconList,
    IconLocation,
    IconMail,
    IconMessageCircle,
    IconPhoto, IconPlugConnected,
    IconRefresh,
    IconSettings, IconToggleLeft, IconToggleRight,
    IconUser
} from "@tabler/icons";
import {BoldText, LightText, SmallText} from "../../Pages/Components/TextComponents";
import {Colors} from "../../Constants/Colors";
import moment from "moment";
import {useDispatch} from "react-redux";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import AlertPopup from "../../Pages/Alerts/AlertPopup";

const SessionDetails = ({sessionId,userId,onClose}) =>{
    const [isLoading,setIsLoading] = useState(false);
    const [isMsgLoading,setIsMsgLoading] = useState(false);
    const [details,setDetails] = useState({});
    const [messages,setMessages] = useState([]);
    const [successMsg,setSuccess] = useState("");
    const [errorMsg,setErrorMsg] = useState("");
    const [alert,setAlert] = useState("");

    useEffect(() =>{
        fetchDetails();
        fetchMsgDetails();
    },[]);
    const fetchDetails = async () =>{
        setIsLoading(true);
        const details = await RequestGet(`${AdminApi.sessionDetails}/${sessionId}`);
        setDetails(details?.details);
        setIsLoading(false);
    }
    const fetchMsgDetails = async () =>{
        setIsMsgLoading(true);
        const details = await RequestGet(`${AdminApi.sessionMsgStats}/${sessionId}`);
        setMessages(details?.result);
        setIsMsgLoading(false);
    }

    return <Drawer title={<Text size={"md"} mx={10} weight={"bold"}>Session Details</Text>} opened={true} onClose={onClose} position={"right"} size={"50%"}>
        <PageLoading isLoading={isLoading}>
            <AlertPopup opened={alert} onClose={() => setAlert()} {...alert} />
            <ScrollArea sx={{height: "90%"}}>
                <Group position={"left"} my={10}>
                    {successMsg && <FlowSuccessMsg message={successMsg} onClose={() => setSuccess()}/>}
                    {errorMsg && <FlowErrorMsg message={errorMsg} onClose={() => setErrorMsg()}/>}
                </Group>
                    <Tabs defaultValue="details"  keepMounted={false} >
                        <Tabs.List grow>
                            <Tabs.Tab value="details" icon={<IconPhoto size={14} />}>Details</Tabs.Tab>
                            <Tabs.Tab value="whatsapp" icon={<IconMessageCircle size={14} />}>Whatsapp Connection</Tabs.Tab>
                            {details?.session?.connected?.whatsappId && <>
                                <Tabs.Tab value="usage" icon={<IconUser size={14} />}>Usage</Tabs.Tab>
                                <Tabs.Tab value="schedule" icon={<IconUser size={14} />}>Schedule</Tabs.Tab>
                                <Tabs.Tab value="message" icon={<IconSettings size={14} />}>Message Stats</Tabs.Tab>
                                <Tabs.Tab value="settings" icon={<IconSettings size={14} />}>Settings</Tabs.Tab>
                            </>}
                        </Tabs.List>

                        <Tabs.Panel value="details" pt="xs">
                            <UserDetails details={details} sessionId={sessionId} onSuccess={setSuccess} onError={setErrorMsg} />
                        </Tabs.Panel>
                        <Tabs.Panel value="whatsapp" pt="xs">
                            <WhatsappConnection  sessionId={sessionId}  details={details?.session} onSuccess={setSuccess} onError={setErrorMsg} onRefresh={fetchDetails} setAlert={setAlert}  />
                        </Tabs.Panel>
                        <Tabs.Panel value="usage" pt="xs">
                            <Usage usage={details?.usage} sessionId={sessionId} onSuccess={setSuccess} onError={setErrorMsg} />
                        </Tabs.Panel>
                        <Tabs.Panel value="schedule" pt="xs">
                            <Schedules sessionId={sessionId} onSuccess={setSuccess} onError={setErrorMsg} />
                        </Tabs.Panel>
                        <Tabs.Panel value="message" pt="xs">
                            {details?.session?.connected?.waNumber && <>
                                {isMsgLoading ? <Loader />: <Table>
                                    <tbody>
                                    <tr>
                                        <td colSpan={7} style={{backgroundColor: Colors.PrimaryLow}}>Message Status</td>
                                    </tr>
                                    <tr>
                                        <td style={{backgroundColor: "#ccc"}}>Date</td>
                                        <td style={{backgroundColor: "#ccc"}}>Total</td>
                                        <td style={{backgroundColor: "#ccc"}}>Read</td>
                                        <td style={{backgroundColor: "#ccc"}}>Delivered</td>
                                        <td style={{backgroundColor: "#ccc"}}>Sent</td>
                                        <td style={{backgroundColor: "#ccc"}}>Failed</td>
                                        <td style={{backgroundColor: "#ccc"}}>Received</td>
                                    </tr>
                                    {messages?.map(a => {
                                        return <tr>
                                            <td>{a?.date}</td>
                                            <td>{a?.total || 0}</td>
                                            <td>{a?.messages?.read || 0}</td>
                                            <td>{a?.messages?.delivered || 0}</td>
                                            <td>{a?.messages?.sent || 0}</td>
                                            <td>{a?.messages?.failed || 0}</td>
                                            <td>{a?.user || 0}</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </Table>}
                            </>}
                        </Tabs.Panel>
                        <Tabs.Panel value="settings" pt="xs">
                            <DeviceSettings sessionId={sessionId} details={details?.session} onRefresh={fetchDetails} setAlert={setAlert} />
                        </Tabs.Panel>

                    </Tabs>
            </ScrollArea>
        </PageLoading>
    </Drawer>
}
export default SessionDetails;

const CardView = ({header,value, width= "auto"}) =>{
  return  <Paper shadow={"md"} withBorder px={10} sx={{width}}>
        <Group position={"apart"}>
            <BoldText text={header} size={"md"} />
            <SmallText text={value} size={"md"} />
        </Group>
    </Paper>
}

const UserDetails = ({details,onSuccess,onError,onRefresh,sessionId}) =>{
    return <>
        <Group grow px={5}>
                <CardView header={"Name"} value={details?.user?.name} width={"45%"} />
                <CardView header={"Email"} value={details?.user?.email} width={"45%"} />
                <CardView header={"Password"} value={details?.user?.password} width={"45%"} />
                <CardView header={"Device name"} value={details?.session?.name} width={"45%"} />
                <CardView header={"SessionId"} value={details?.session?.sessionId} width={"45%"} />
                <CardView header={"UserId"} value={details?.session?.userId} width={"45%"} />
                <CardView header={"Plans"} value={details?.session?.activePlans} width={"45%"} />
                <CardView header={"Expiry"} value={moment.unix(details?.session?.expiry).fromNow()} width={"45%"} />
        </Group>
    </>
}
const Usage = ({sessionId,usage, onSuccess,onError,onRefresh}) =>{
    const [isTempLoading,setIsTempLoading] = useState(false);
    const [isFormLoading,setIsFormLoading] = useState(false);
    const [isUploading,setIsUploading] = useState(false);

    const SyncTemplates = async () =>{
        setIsTempLoading(true);
        const response = await RequestGet(`${CloudApi.templateSync}/${sessionId}`);
        response?.status === 1 ? onSuccess(response?.message): onError(response?.message);
        setIsTempLoading(false);
    }
    const ReuploadMedia = async () =>{
        setIsUploading(true);
        RequestGet(`${CloudApi.ReuploadMedia}/${sessionId}`);
        setIsUploading(false);
    }
    const SyncForms = async () =>{
        setIsFormLoading(true);
        const response = await RequestGet(`${CloudApi.whatsappFlows}/sync/${sessionId}`);
        response?.status === 1 ? onSuccess(response?.message): onError(response?.message);
        setIsFormLoading(false);
    }
    return  <>
        <Group position={"right"} mb={20} mx={10} sx={{gap: 3}}>
            <Button  leftIcon={<IconRefresh />} compact onClick={ReuploadMedia} loading={isUploading}>Reupload Missed Media</Button>

            <Button leftIcon={<IconRefresh />} compact onClick={SyncTemplates} loading={isTempLoading}>Sync Templates</Button>
            <Button leftIcon={<IconRefresh />} compact onClick={SyncForms} loading={isFormLoading}>Sync Forms</Button>
        </Group>
        <Group grow mx={5}>
        {usage?.map(a =>{
            return <Paper shadow={"md"} withBorder px={10}>
                <Group position={"apart"}>
                    <BoldText text={a?.key} size={"md"} />
                    <SmallText text={a?.value} size={"md"} />
                </Group>
            </Paper>
        })}
    </Group>
    </>
}
const Schedules = ({sessionId,onSuccess,onError,onRefresh}) =>{
    const [isLoading,setIsLoading] = useState(false);
    const [isKillLoading,setIsKillLoading] = useState(false);
    const [isStartLoading,setIsStartLoading] = useState(false);
    const [logs,setLogs] = useState();
    const [queue,setQueue] = useState();
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestGet(`${CloudApi.indSchedules}/${sessionId}`);
        setLogs(data?.logs);
        setQueue(data?.queueCount);
        setIsLoading(false);
    }

    const RestartQueue = async () =>{
        setIsStartLoading(true);
        const response = await RequestGet(`${CloudApi.QueueOptions}/start/${sessionId}`);
        response?.status === 1 ? onSuccess(response?.message): onError(response?.message);
        setIsStartLoading(false);
    }
    const KillOngoingQueue = async () =>{
        setIsKillLoading(true);
        const response = await RequestGet(`${CloudApi.QueueOptions}/kill/${sessionId}`);
        response?.status === 1 ? onSuccess(response?.message): onError(response?.message);
        setIsKillLoading(false);
    }


    return  <>
        <Group position={"right"} mb={20} mx={10} sx={{gap: 3}}>
            <Button leftIcon={<IconRefresh />} compact onClick={getDetails} loading={isLoading}>Refresh</Button>
            <Button leftIcon={<IconRefresh />} compact onClick={RestartQueue} loading={isStartLoading}>Start Queue</Button>
            <Button leftIcon={<IconRefresh />} compact onClick={KillOngoingQueue} loading={isKillLoading}>Kill Ongoing </Button>
        </Group>

        <Group grow mx={5}>
            <CardView header={"Msg in Queue"} value={queue || 0} />
            <CardView header={"Waiting Schedules"} value={logs?.find(a => a?._id === 0)?.count || 0} />
            <CardView header={"Sending Schedules"} value={logs?.find(a => a?._id === 1)?.count || 0} />
        </Group>
    </>
}
const WhatsappConnection = ({sessionId,details,onSuccess,onError, onRefresh,setAlert}) =>{
    const [isLoading,setIsLoading] = useState(false);
    const [profile,setProfile] = useState(details?.profile);
    const [healthStatus,setHealth] = useState(details?.health)
    const [oldConn,setOldConn] = useState(details?.oldConnection || []);
    const dispatch = useDispatch();

    const getDetails = async () =>{
        setIsLoading(true);
        const details = await RequestGet(`${CloudApi.businessProfile}/${sessionId}`);
        setProfile(details?.data);
        setIsLoading(false);
    }
    const getHealth = async () =>{
        setIsLoading(true);
        const d = await RequestGet(`${CloudApi.deviceHealth}/${details?.userId}/${sessionId}`);
        setHealth(d?.health);
        setIsLoading(false);
    }
    const ActivateOldConnections = async (data) =>{
        setAlert({
            title: "Activate Old Connection",
            content: "Ae you sure want to activate Old Connections?",
            successTitle: "Activate",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const d = await RequestPost(`${CloudApi.deviceOperations}/activateOld/${sessionId}`, {details: data});
                setAlert();
                dispatch(ActionShowAlert({message: d?.message}));
                onRefresh();
            }
        })

    }


    const disconnectWhatsapp = async () =>{
        setIsLoading(true);
        const d = await RequestGet(`${CloudApi.disconnectWhatsapp}/${sessionId}`);
        setHealth(d?.health);
        onRefresh();
        setIsLoading(false);
    }
    return  <PageLoading isLoading={isLoading}>
        {details?.connected?.whatsappId && <>
            <Group m={10} position={"right"}>
                <Button leftIcon={<IconPlugConnected />} color={"red"} compact onClick={disconnectWhatsapp}>Disconnect Whatsapp</Button>
            </Group>
            <Paper shadow={"md"} withBorder p={5} mx={10}>
                <Group position={"apart"}>
                    <Group>
                        <Image src={profile?.profile?.profile_picture_url} width={50} height={50} />
                        <Stack sx={{gap: 3}}>
                            <Group position={"apart"} sx={{width: "100%"}}>
                                <Text weight={"bold"}>{profile?.device?.verified_name}</Text>
                            </Group>
                            <Text size={"xs"} color={"dimmed"}>{profile?.device?.display_phone_number}</Text>
                        </Stack>
                    </Group>
                    <Group>
                        {isLoading ? <Loader size={"xs"} />: <IconView iconType={"refresh"} onClick={getDetails}/>}
                    </Group>
                </Group>
                <Divider />
                {profile?.profile?.email && <ProfileCardItem icon={<IconMail size={18}/>} text={profile?.profile?.email}/>}
                {profile?.profile?.address && <ProfileCardItem icon={<IconLocation size={18}/>} text={profile?.profile?.address}/>}
                {profile?.profile?.websites?.map(a =>  <ProfileCardItem icon={<IconLink size={18} />} text={a} />)}
                {profile?.profile?.description && <ProfileCardItem icon={<IconBuildingStore size={18}/>} text={profile?.profile?.description}/>}
                {profile?.profile?.about && <ProfileCardItem icon={<IconInfoCircle size={18}/>} text={profile?.profile?.about}/>}
                {profile?.profile?.vertical && <ProfileCardItem icon={<IconBuilding size={18} />} text={profile?.profile?.vertical} />}
                {profile?.device?.quality_rating && <ProfileCardItem icon={<SmallText text={"Quality"} />} text={profile?.device?.quality_rating} />}
                {profile?.device?.throughput && <ProfileCardItem icon={<SmallText text={"Throughput"} />} text={profile?.device?.throughput?.level} />}
                {profile?.nameStatus && <ProfileCardItem icon={<SmallText text={"Name"} />} text={<Badge size={"xs"}>{profile?.nameStatus?.name_status}</Badge>} />}
            </Paper>
            <Paper shadow={"md"} withBorder p={5} mx={10}>
                <Group position={"apart"}>
                    <BoldText text={"Message Status"} />
                    <Group>
                        <Badge color={healthStatus?.can_send_message === "BLOCKED" ? "red" : "green"}>{healthStatus?.can_send_message}</Badge>
                        {isLoading ? <Loader size={"xs"} />: <IconView iconType={"refresh"} onClick={getHealth}/>}
                    </Group>
                </Group>
                <Divider my={5}/>
                {healthStatus?.entities?.map(a => {
                    return <Stack sx={{gap: 3}} my={3}>
                        <Paper withBorder p={5}>
                            <Group position={"apart"}>
                                <SmallText text={a?.entity_type} />
                                <SmallText text={a?.can_send_message} color={a?.can_send_message === "BLOCKED" ? "red" : "green"} />
                            </Group>
                            {a?.errors?.length > 0 && <Divider my={3}/>}
                            {a?.errors?.map(err => <LightText text={err?.error_description} />)}
                            {a?.additional_info?.map(b => <LightText text={b} />)}
                        </Paper>
                    </Stack>
                })}

            </Paper>
        </>}
        <Paper shadow={"md"} withBorder p={5} mx={10}>
            <Group position={"apart"}>
                <BoldText text={"Old Connections"} />
            </Group>
            <Divider my={5}/>
            {oldConn?.map((a,i) =>{
                return <>
                    <Group position={"apart"}>
                        <BoldText text={`${i+1}. ${a?.waName} ${a?.waNumber}`} />
                        <Group>
                            <SmallText color={"dimmed"} text={`${moment.unix(a?.created)?.format("lll")}`} />
                            {!details?.connected?.whatsappId && <Button size={"xs"} compact onClick={() => ActivateOldConnections(a)}>Activate</Button>}
                        </Group>
                    </Group>
                    <Divider />
                </>
            })}
        </Paper>
    </PageLoading>
}
const DeviceSettings = ({sessionId,details,onSuccess,onError, onRefresh,setAlert}) =>{
    console.log(details, "details");

    const dispatch = useDispatch();
    const ChangeAlertStatus = async () =>{
        setAlert({
            title: "Change Alert Status",
            content: "Are you sure want to Change?",
            successTitle: "Change",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const d = await RequestPost(`${CloudApi.deviceOperations}/settings/${sessionId}`, {
                   settings: {"settings.alertInBsm": !details?.settings?.alertInBsm},
                });
                setAlert();
                dispatch(ActionShowAlert({message: d?.message}));
                onRefresh();
            }
        });

    }

    return  <Grid mx={10}>
            <Grid.Col span={6} >
                <Paper shadow={"md"} withBorder onClick={ChangeAlertStatus} p={5}>
                    <Group position={"apart"}>
                        <Text weight={'bold'} mx={5}>{"Send Alert When Received Message"}</Text>
                        {details?.settings?.alertInBsm ? <IconToggleRight color={'green'} /> : <IconToggleLeft color={'red'} />}
                    </Group>
                </Paper>
            </Grid.Col>
        </Grid>
}
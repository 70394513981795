import React, {Component, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import AddChatbot from "./AddChatbot";
import {Header, IconView, PageLoading, PrefCountView} from "../Components/PageComponents";
import {Grid, Group, Modal, Pagination, Paper, Text, TextInput} from "@mantine/core";
import {ChatbotKeyTypes} from "../../Constants/Strings";
import {IconSearch, IconToggleLeft, IconToggleRight} from "@tabler/icons";
import {Colors} from "../../Constants/Colors";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {BoldText} from "../Components/TextComponents";
import {useNavigate} from "react-router-dom";
import PreviewScheduleMessage from "../Components/PreviewScheduleMessage";
import {showMaxAlert} from "../../Constants/Functions";

const PageHeading = {
    title: "Chatbot",
    subTitle: "Automated Customercare(chat) to resolve most common queries of users & save your time.",
    buttons: [
        {title: "Add Chatbot",type: "add"},
        {title: "Refresh",type: "refresh"},
    ]
}
const AlertStrings = {
    delete: {
        title: "Delete Chatbot",
        content: "Are you sure want to delete Chatbot?",
        successTitle: "Delete",
        cancelTitle: "Cancel",
    },
    pause: {
        title: "Pause Chatbot",
        content: "Are you sure want to Pause the Chatbot?",
        successTitle: "Pause",
        cancelTitle: "Cancel",
    },
    resume:  {
        title: "Resume Chatbot",
        content: "Are you sure want to Resume the Chatbot?",
        successTitle: "Resume",
        cancelTitle: "Cancel",
    }
}

const Chatbot =  () =>{
    const {sessionId,alias,prefrences,prefUsed} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [bots,setBots] = useState([]);
    const [total,setTotal] = useState(1);
    const [page,setPage] = useState(1);
    const [search,setSearch] = useState("");
    const [editDetails,setEditDetails] = useState();
    const [viewMessage,setViewMessage] = useState();
    const dispatch = useDispatch();
    const navigation = useNavigate();

    useEffect(() => {
        getDetails();
    },[page,search]);

    const getDetails = async () => {
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.chatbot}/list/${sessionId}`, {page,search});
        setBots(data?.bots || []);
        setTotal(data?.total);
        setIsLoading(false);
    }

    const openDelete = async (id) =>{
        dispatch(ActionShowPopup({
            ...AlertStrings.delete,
            onSuccess: async () =>{
                const data = await RequestPost(`${CloudApi.chatbot}/delete/${sessionId}`, {
                    id
                });
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
                getDetails();
            }
        }))

    }
    const openEdit = (editDetails) =>{
        setEditDetails(editDetails);
    }
    const statusChange = async (id,status) =>{
        dispatch(ActionShowPopup({
            ...(status === true ? AlertStrings.resume: AlertStrings.pause),
            onSuccess: async () =>{
                const data = await RequestPost(`${CloudApi.chatbot}/statusChange/${sessionId}`, {
                    id,status
                });
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
                getDetails();
            }
        }))
    }
    const viewLeads = (id) =>{
        navigation(`/device/${alias}/chat-leads`,{state: {id}});
    }
    const handleCreate = () =>{
        if(prefrences?.chatbots > prefUsed?.chatbots){
            setEditDetails({})
        }else{
            showMaxAlert({dispatch, type: "Chatbots"})
        }
    }


    return <>
        <Header {...PageHeading} buttonProps={[{onClick: handleCreate},{onClick: getDetails}]} />
        {editDetails && <AddChatbot details={editDetails} sessionId={sessionId} onClose={() => setEditDetails()} onRefresh={getDetails} />}
        <Options page={page} setPage={setPage} total={total} setSearch={setSearch} />
        {viewMessage && <ViewMessage message={viewMessage} onClose={() => setViewMessage()}/>}
        <PageLoading isLoading={isLoading}>
            {bots.map((element) => {
                return <Paper my={5} shadow={"md"} withBorder sx={{borderRadius: 10,overflow:"hidden"}}>
                    <Group position={'apart'} sx={{backgroundColor: Colors.PrimaryLow}} p={5}>
                        <Text color={"white"} weight={"bold"} mx={10}>{element?.title}</Text>
                        <Group sx={{backgroundColor: "#ccc"}}>
                            <IconView color={"black"} label={"View Message"} iconType={"view"} onClick={() => setViewMessage(element?.message)} />
                            <IconView color={"black"} label={"View Leads"} iconType={'lead'} onClick={() => viewLeads(element?.id)} />
                            <IconView color={"black"} label={"Edit Chatbot"}  iconType={'edit'} onClick={() => openEdit(element)} />
                            <IconView label={"Delete Chatbot"}  iconType={'delete'} onClick={() => openDelete(element?.id)} />
                            <IconView label={element?.status ? "Disable Chatbot": "Enable Chatbot"}  icon={element?.status ? <IconToggleRight color={'green'} /> : <IconToggleLeft color={'red'} />} onClick={() => statusChange(element?.id,!element?.status)} />
                        </Group>
                    </Group>
                    <Group m={5}>
                        {element?.keys?.map(a => <Group sx={{borderWidth:1 ,borderStyle: "solid", borderRadius: 10, borderColor: Colors.Primary}} p={5}>
                            <Text size={'xs'}>{ChatbotKeyTypes[a?.type]} <b>{a?.key}</b></Text>
                        </Group>)}
                    </Group>

                </Paper>
            })}
        </PageLoading>
    </>
}

export default Chatbot;

const Options = ({total,page,setPage,setSearch}) =>{
    const[searchTerm,setSearchTerm] = useState("");
    return  <Grid>
        <Grid.Col span={4}>
            <PrefCountView type={"chatbots"} />
        </Grid.Col>
        <Grid.Col span={8}>
            <Group position={"right"}>
                <Group>
                    <TextInput size={"xs"} placeholder={"Search"} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                    <IconView icon={<IconSearch />} variant={"filled"} onClick={() => setSearch(searchTerm)} />
                </Group>
                {total > 10 && <Pagination size={"xs"} total={Math.ceil(total / 10)} page={page} onChange={setPage}/>}
            </Group>
        </Grid.Col>
    </Grid>
}
const ViewMessage = ({message,onClose}) =>{

    return  <Modal title={"Message Details"} onClose={onClose} size={"50%"} opened={true}>
            <PreviewScheduleMessage message={message} />
    </Modal>

}

import React, {useRef, useState} from "react";
import {SegmentedControl, Text, Grid, Group, NumberInput,Button} from "@mantine/core";
// import BSMSuccessMsg from "../../../BSMComponents/BSMSuccessMsg";
import MessageOptions from "../../Schedule/MessageOptions";
import {CloudApi, RequestPost} from "../../../Layouts/RequestManager";
import {ActionShowAlert} from "../../../store/actions/alert";
import {useDispatch, useSelector} from "react-redux";
import VariablesView from "./VariablesView";
import {FlowSuccessMsg} from "../../Components/PageComponents";
const WBASendMessage = ({props, value}) =>{
    const ref = useRef();
    const {sessionId} =  useSelector(state => state.active);
    const [sendType,setSendType] = useState(value?.options?.sendType ||"now");
    const [hr,setHr] = useState(value?.options?.hr || 0);
    const [min,setMin] = useState(value?.options?.min || 30);
    const [variables,setVariables] = useState(value?.variables || [{label: "mobile", type: "map", value: "",required: true}]);
    const [render,setRender] = useState(0);
    const [isError,setIsError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [successMsg,setSuccessMsg] = useState("");
    const dispatch = useDispatch();

    const onSaveStep = async () =>{
        const {isValid,message} = ref.current.isValid();
        if(sessionId && (sendType === "now" || (hr > 0  || min > 0)) && isValid){
            setIsError(false);
            const flow = {app:"sendMessage",sessionId,message, id: value?.id, options: {sendType,hr,min}, variables};
            setIsLoading(true);
            const data = await RequestPost(`${CloudApi.bsmFlows}/saveDetails/${sessionId}`,{flow,step: props?.step, id: props?.id,isWebhookListen: false});
            setSuccessMsg(data?.message);
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }
    const updateVar = (variables) =>{
        setVariables(variables);
        setRender(render+1);
    }
    return <>
        <Grid.Col span={6}>
            <Group>
                <SegmentedControl data={[{label: "Immediate", value: "now"},{label: "Later", value: "later"}]} value={sendType} onChange={setSendType} mt={20} />
                {sendType === "later" && <Group mt={15}>
                    <Text> After </Text>
                    <NumberInput size={'xs'} min={0} max={24} sx={{width: 50}} value={hr} onChange={setHr} />
                    <Text weight={'bold'}>Hrs</Text>
                    <NumberInput size={'xs'} min={0} max={59} sx={{width: 50}} value={min} onChange={setMin} />
                    <Text><b>Mins</b> </Text></Group>}
            </Group>
        </Grid.Col>
        <MessageOptions ref={ref} sessionId={sessionId} details={value?.message?.editOptions} show={['template','session']} />

        <VariablesView variables={variables} props={props} updateVariables={updateVar} addVariable={true} />

        <Grid.Col span={12}>
            <Group position={"center"}>
                <Button variant={"gradient"} onClick={onSaveStep} loading={isLoading}>Save Step</Button>'
                {successMsg && <FlowSuccessMsg message={successMsg} onClose={setSuccessMsg} />}
                {isError && <Text color={"red"} weight={"bold"}>Please Fill All Fields</Text>}
            </Group>
        </Grid.Col>
    </>
}
export default WBASendMessage;

import {Grid, Group, Image, Button, TextInput,Text} from "@mantine/core";

const TemplatePreview = ({message,variables, showVar = true}) =>{
    const renderTextWithVar = ({text,variables}) =>{
        let final = text;
        variables?.map(a => {
            const v = a?.key?.split("_")?.[1];
            final = final?.replaceAll(`{{${v}}}`,a?.value);
        })
        return <>{final?.split("\n")?.map(a => <Text size={"xs"}>{a}</Text>)}</>
    }
    return <Grid>
        <Grid.Col span={showVar ? 8 : 12}>
            {message?.header?.image && <Image src={message?.header?.image} height={200} fit={"contain"} />}
            {message?.header?.text && renderTextWithVar({text: message?.header?.text, variables: variables?.filter(a => a?.key?.includes("h_"))})}
            {message?.body?.text && renderTextWithVar({text: message?.body?.text, variables: variables?.filter(a => a?.key?.includes("b_"))})}
            {message?.footer?.text && renderTextWithVar({text: message?.footer?.text,variables: []})}
            {message?.buttons?.length > 0 && <Group grow my={5}>
                {message?.buttons?.map(a => <Button variant={"outline"} compact>{a?.text}</Button>)}
            </Group>}
        </Grid.Col>
        {showVar && <Grid.Col span={4}>
            {variables?.map(a => <TextInput value={a?.value} label={a?.key} disabled/>)}
        </Grid.Col>}


    </Grid>
}
export default TemplatePreview;

import React, {useEffect, useState} from "react";
import {
    CheckBoxView,
    Header,
    IconView,
    PageLoading,
    TableView
} from "../../Pages/Components/PageComponents";
import {AdminApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {Divider, Drawer, Group, Pagination, Paper, Badge, Text, ScrollArea, Loader,Button} from "@mantine/core";
import {FilledTextInput} from "../../Pages/Sequence/CustomButton";
import {BoldText} from "../../Pages/Components/TextComponents";
import {CreateDevice, CreatePayment, CreateUser, ViewPref} from "./UserCreate";
import UserDetails from "./UserDetails";

const Users  = () =>{
    const [isLoading,setIsLoading] = useState(false);
    const [isCreate,setIsCreate] = useState(false);
    const [isCreateDevice,setIsCreateDevice] = useState();
    const [isView,setIsView] = useState();

    const [users,setUsers] = useState([]);
    const [filtered,setFiltered] = useState([]);
    const [page,setPage] = useState(1);

    useEffect(()=>{
        getDetails();
    }, [])

    const getDetails = async () =>{
        setIsLoading(true)
        const data = await RequestPost(`${AdminApi.AdminUsers}/list`,{});
        setUsers(data?.users);
        setFiltered(data?.users);
        setIsLoading(false);
    }

    return <>
        <Header title={"Users (BSM + WBA)"}
                subTitle={"All Users in BSM + WBA"}
                buttons={[{title: "Refresh", type: "refresh"},{title: "Create User", type: "add"}]}
                buttonProps={[{onClick: getDetails}, {onClick: () => setIsCreate(true)}]}
        />
        <PageLoading isLoading={isLoading}>
            {isCreate && <CreateUser onClose={() => setIsCreate(false)} onRefresh={getDetails} />}
            {isCreateDevice && <CreateDevice userId={isCreateDevice} onClose={() => setIsCreateDevice(undefined)} onRefresh={getDetails} />}
            {isView && <UserDetails userId={isView} onClose={() => setIsView()} onRefresh={getDetails} />}
            <Filter users={users} setPage={setPage} setFiltered={setFiltered} />
            <Group position={"apart"}>
                <BoldText text={`Filtered Results: ${filtered?.length} /${users?.length}`} />
                <Pagination size={"xs"} total={Math.ceil(filtered?.length/10)} page={page} onChange={setPage} />
            </Group>
            <TableView headers={["Name","Email","Mobile","UserId","Bsm Devices"," WBA Devices","Options"]}>
                {filtered?.slice((page-1) * 10, page * 10)?.map(a =>{
                    return <tr>
                        <td width={"20%"}>{a?.name}</td>
                        <td width={"20%"}>{a?.email}</td>
                        <td width={"10%"}>{a?.mobile}</td>
                        <td width={"10%"}>{a?.id}</td>
                        <td width={"10%"}>{a?.bsm?.active} / {a?.bsm?.count}</td>
                        <td width={"10%"}>{a?.wba?.active} / {a?.wba?.count}</td>
                        <td width={"20%"}>
                            <Group>
                                <IconView iconType={"add"} label={"Add Device"} onClick={() => setIsCreateDevice(a?.id)} />
                                <IconView iconType={"view"} label={"View User"} onClick={() => setIsView(a?.id)}/>
                            </Group>
                        </td>
                    </tr>
                })}
            </TableView>
        </PageLoading>
    </>
}
export default Users;

const Filter = ({setPage,setFiltered, users}) =>{
    const [search,setSearch] = useState("");
    const [bsmActive,setBsmActive] = useState(false);
    const [wbaActive,setWBAActive] = useState(false);
    useEffect(() =>{
        handleFilter();
    },[search,bsmActive,wbaActive])

    const handleFilter = () =>{
        let filteredUsers = users;
        if(bsmActive) filteredUsers  = filteredUsers?.filter(a => a?.bsm?.active > 0);
        if(wbaActive) filteredUsers  = filteredUsers?.filter(a => a?.wba?.active > 0);
        if(search?.trim() !== ""){
            let key = search?.toLowerCase()?.trim();
            filteredUsers  = filteredUsers?.filter(a => a?.email?.toString()?.toLowerCase()?.includes(key) || a?.name?.toString()?.toLowerCase()?.includes(key)|| a?.id?.toLowerCase()?.includes(key)|| a?.mobile?.toLowerCase()?.includes(key));
        }
        setFiltered(filteredUsers);
        setPage(1);
    }
    
    
    return <Paper shadow={"md"} withBorder p={5}>
        <Group>
            <FilledTextInput label={"Search Email, name, mobile, id"} value={search} onChange={setSearch} />
            <CheckBoxView text={"BSM Active"} isSelected={bsmActive} onClick={setBsmActive} />
            <CheckBoxView text={"WBA Active"} isSelected={wbaActive} onClick={setWBAActive} />
        </Group>
    </Paper>

}


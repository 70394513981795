import {useEffect, useRef, useState} from "react";
import {Button, Grid, Group, Modal, Paper, SegmentedControl, Stack, Text, TextInput} from "@mantine/core";
import React from "react";
import {Colors} from "../../Constants/Colors";
import ScheduleOptions from "./ScheduleOptions";
import {IconEdit, IconPlus, IconTrash, IconUsers} from "@tabler/icons";
import PickNumbers from "./PickNumbers";
import {useDispatch, useSelector} from "react-redux";
import MessageOptions from "./MessageOptions";
import {getValidBadge, Header, IconView, PageLoading} from "../Components/PageComponents";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {useLocation, useNavigate} from "react-router-dom";
import {ActionShowAlert} from "../../store/actions/alert";
import PreviewScheduleMessage from "../Components/PreviewScheduleMessage";
const PageHeading = {
    title: "Send/Schedule Message",
    subTitle: "You can Send/Schedule Message Here",
    buttons: [{type: "list", title: "Schedules"}]
}
const CreateMessage = () =>{
    const {state} = useLocation();
    const [id,setId] = useState(state?.id);
    const [isClone,setIsClone] = useState(state?.isClone ? state?.isClone : false);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const [isLoading,setIsLoading] = useState(!!state?.id);
    const {sessionId,userId,alias} =  useSelector(state => state.active);
    const  [title,setTitle] = useState("");
    const  [contacts,setContacts] = useState({});
    const  [message,setMessage] = useState();
    const  [options,setOptions] = useState({options: {sendType: "now"}});
    const [isError,setIsError] = useState(false);
    const [render,setRender] = useState(1)

    useEffect(() =>{
        if(id) fetchDetails();
    },[id])
    const fetchDetails = async () =>{
        setIsLoading(true);
        const details = await RequestGet(`${CloudApi.ScheduleDetails}/${sessionId}/${id}`);
        const {contacts,title,message,options,editDetails} = details?.schedule;
        setTitle(title);
        setContacts({users: contacts, details: editDetails?.contacts});
        setMessage(message);
        setOptions({options: options, optionDetails: editDetails?.options});
        setRender(render+1);
        setIsLoading(false);
    }
    const saveSchedule = async () =>{
        if(title?.trim() !== "" && message && contacts && options){

            let q = {
                id: isClone ? undefined: id,
                title,
                sessionId,
                contacts: contacts?.users,
                message,
                options: options?.options,
                editDetails: {contacts: contacts?.details,message: message,options: options?.optionDetails},
                userId
            };
            console.log(message,isClone, q);
            const details = await RequestPost(CloudApi.CreateSchedule, q);
            dispatch(ActionShowAlert({message: details?.message}));
            openSchedule();
        }else{
            setIsError(true);
        }
    }
    const openSchedule = () =>{
        navigation(`/device/${alias}/schedules`)
    }
    return <>
        <Header {...PageHeading} buttonProps={[{onClick: openSchedule}]} />
        <PageLoading isLoading={isLoading}>
            <TextInput
                label="Schedule Title"
                placeholder="Title For Schedule"
                required
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                sx={{width: 300}}
                mx={10}
                error={isError && title.trim() === '' ? "Title is Required" : ""}
            />
            <ScheduleContacts setContacts={setContacts} contacts={contacts}  />
            <ScheduleMessage message={message} setMessage={setMessage} />
            <ScheduleType options={options} setOptions={setOptions} />
            <Group position={'center'} my={20}>
                <Button variant={'gradient'} onClick={saveSchedule}>Schedule Message</Button>
            </Group>
        </PageLoading>
    </>
}
export default CreateMessage;

const ScheduleContacts = ({contacts, setContacts}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [open,setOpen] = useState(false);
    const contactPicker = useRef();
    const[isError,setIsError] = useState(false);
    const onSave = () =>{
        const {isValid,userDetails,users}  = contactPicker.current.isValid();
        if(isValid){
            setIsError(false);
            setContacts({users, details: userDetails});
            setOpen(false);
        }else{
            setIsError(true);
        }
    }
    return  <Paper shadow={'md'} withBorder mx={10} p={10} mt={10}>
        <Group position={'apart'}>
            <Group sx={{gap: 3}}>
                <Text weight={'bold'} color={Colors.Primary}>Select Numbers You want to Send </Text>
                {getValidBadge({valid: !!contacts?.users, error: isError})}
            </Group>
            {contacts?.users ?  <Group>
                <Group sx={{gap: 3}}>
                    <IconUsers size={18} color={Colors.Primary} />
                    <Text> {contacts?.users?.count || contacts?.users?.label} - {contacts?.users?.type}</Text>
                </Group>
                <IconView iconType={"edit"} onClick={() =>setOpen(true)} />
            </Group>:
            <Button size={'xs'} variant={'gradient'} leftIcon={<IconPlus />} onClick={() =>setOpen(true)}>Pick Contacts</Button>}
        </Group>
       <Modal opened={open} size={"90%"} title={<Text weight={"bold"}>{"Pick Contacts"}</Text>} onClose={() => setOpen(false)}>
            <Grid mt={20}>
                <PickNumbers ref={contactPicker} details={contacts?.details} sessionId={sessionId}/>
                <Grid.Col span={12} mt={10}>
                    <Group position={'center'}>
                        <Button variant={'gradient'} onClick={onSave}>Save Numbers</Button>
                    </Group>
                </Grid.Col>
            </Grid>
        </Modal>
        {contacts?.users?.headers?.length > 0 && <Group>
            <Text color={'dimmed'}>Available Variable(s) that can be used:</Text><Text>{contacts?.users?.headers?.map(a => ` {${a}} `)}</Text>
        </Group>}
    </Paper>
}
const ScheduleMessage = ({message,setMessage})  =>{
    const [open,setOpen] = useState(false);
    const[isError,setIsError] = useState(false);
    const messageRef = useRef();
    const onSave = () => {
        const validMessage = messageRef.current.isValid();
        const {isValid, message} = validMessage;
        if (isValid) {
            setMessage(message);
            setOpen(false);
            setIsError(false);
        }else{
            setIsError(true);
        }
    }
    return <Paper shadow={'md'} withBorder mx={10} p={10} mt={10}>
        <Group position={'apart'}>
            <Group sx={{gap: 3}}>
                <Text weight={'bold'} color={Colors.Primary}>Add Message You want to Send </Text>
                {getValidBadge({valid:  !!message, error: isError})}
            </Group>
            {message ?
            <Group position={'right'}>
                <IconEdit size={20} color={Colors.Primary} onClick={() => setOpen(true)} />
            </Group> : <Button size={'xs'} variant={'gradient'} leftIcon={<IconPlus />} onClick={() => setOpen(true)}>Add Message</Button>}
        </Group>
        {message && <Stack sx={{width: "50%"}}>
            <PreviewScheduleMessage message={message} />
        </Stack>}
        <Modal opened={open} size={"90%"} title={<Text weight={"bold"}>{"Add Message"}</Text>} onClose={() => setOpen(false)}>
            <Grid mt={20}>
                <MessageOptions ref={messageRef} details={message?.editOptions} show={["template","session"]} />
                <Grid.Col span={12} mt={10}>
                    <Group position={'center'}>
                        <Button variant={'gradient'} onClick={onSave}>Save Message</Button>
                    </Group>
                </Grid.Col>
            </Grid>

        </Modal>
    </Paper>
}
const ScheduleType = ({options,setOptions}) =>{
    const types= ['','Every Day','Weekly','Every Month','Custom Date','','Multiple Dates']
    const optionsRef = useRef();
    const [open,setOpen] = useState(false);
    const onSave = () => {
        const validMessage = optionsRef.current.isValid();
        const {isValid, options,optionDetails} = validMessage;
        if (isValid) {
            setOptions({options: {...options,sendType: "schedule"},optionDetails});
            setOpen(false);
        }
    }
    return <Paper shadow={'md'} withBorder mx={10} p={10} mt={10}>
        <Group position={'apart'} sx={{gap: 3}}>
            <Group>
                <Text weight={'bold'} color={Colors.Primary}>Send/Schedule Your Messages</Text>
                {getValidBadge({valid: options?.options?.sendType === "now" || (options?.options?.sendType === "schedule" && options?.options?.type), error: true})}
            </Group>
             <Group>
                <SegmentedControl size={"xs"} data={[{label: "Send Now", value: "now"},{label: "Schedule", value: "schedule"}]} value={options?.options?.sendType} onChange={(value)=> setOptions({options: {sendType: value}})} />
                {options?.options?.sendType === "schedule" && <Button size={'xs'} variant={'gradient'} onClick={() => setOpen(true)}>Pick Timings</Button>}
            </Group>
        </Group>
        {options?.options?.type && <Group>
            {types?.[options?.options?.type]} @ {options?.options?.customDate} {options?.options?.time}
        </Group>}
         <Modal opened={open} size={"90%"} title={<Text weight={"bold"}>{"Pick Schedule Timings"}</Text>} onClose={() => setOpen(false)}>
            <ScheduleOptions ref={optionsRef} details={options?.optionDetails}/>
             <Group position={'center'}>
                 <Button variant={'gradient'} onClick={onSave}>Save Timings</Button>
             </Group>
        </Modal>
    </Paper>
}

import {Divider, Drawer, Group, Text,Badge} from "@mantine/core";
import {useEffect, useState} from "react";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {useSelector} from "react-redux";
import {PageLoading} from "../Components/PageComponents";
import PreviewScheduleMessage from "../Components/PreviewScheduleMessage";
import {SmallText} from "../Components/TextComponents";
import {convertUnixToFormat} from "../../Constants/Functions";

const ScheduleDetails = ({onClose, schId,id}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(true);
    const [details,setDetails] = useState();

    useEffect(() =>{
        getDetails();
    },[schId])

    const getDetails = async () =>{
        setIsLoading(true);
        const details = await RequestGet(`${CloudApi.planSchDetails}/${sessionId}/${id}`);
        console.log(details);
        setDetails(details?.schedule);
        setIsLoading(false);
    }

    return <Drawer opened={true} onClose={onClose} position={"right"} size={"50%"} title={<b>Schedule Details</b>}>
        <PageLoading isLoading={isLoading}>
            <Text mx={10}>{details?.title}</Text>
            <Group position={"apart"} mx={10}>
                <SmallText text={convertUnixToFormat(details?.sendTime)} />
                <Badge>{details?.type}</Badge>
            </Group>
            <Divider label={"Users"} />

            <Divider label={"Messages"} />
            <PreviewScheduleMessage message={details?.message} />
        </PageLoading>
    </Drawer>
}
export default ScheduleDetails;

import React, {useEffect, useState} from "react";
import {Modal, Text, TextInput, Group, Button, CopyButton} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {Header, IconView, PageLoading, PrefCountView, TableView} from "../Components/PageComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {IconCheck, IconCopy} from "@tabler/icons";
import {Colors} from "../../Constants/Colors";
import {showMaxAlert} from "../../Constants/Functions";
import {ErrorText} from "../Components/TextComponents";

const PageHeading = {
    title: "Sequence",
    subTitle: "Sequence of Messages Will Trigger",
    buttons:[{type:"refresh", title: "Refresh"},{type:"add", title: "Add Sequence"}]
}

const  Sequence = () =>{
    const {sessionId,alias,prefrences,prefUsed} = useSelector(state => state.active);
    const dispatch = useDispatch();
    const navigation = useNavigate();

    const [isLoading,setIsLoading] = useState(false);
    const [sequences,setSequences] = useState([]);
    const [isAdd,setIsAdd] = useState(false);

    useEffect(() =>{
        getSequences();
    },[])

    const getSequences = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.sequence}/list/${sessionId}`, {});
        setSequences(data?.list || []);
        setIsLoading(false);

    }
    const onDelete = (id) =>{
        dispatch(ActionShowPopup({
            title: "Delete Sequence",
            content: "Are you sure want to Delete Sequence ?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.sequence}/delete/${sessionId}`, {id});
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert(data?.message));
                getSequences();
            }
        }))
    }
    const onEdit = (id) =>{
        navigation(`/device/${alias}/sequence-edit/${id}`);
    }
    const onLeadClick = (id) =>{
        navigation(`/device/${alias}/sequence-leads/${id}`);
    }
    const handleCreate = () =>{
        if(prefrences?.sequence > prefUsed?.sequence){
            setIsAdd(true)
        }else{
            showMaxAlert({dispatch, type: "Sequences"})
        }
    }


    return <>
        <Header{...PageHeading} buttonProps={[{onClick: getSequences},{onClick: handleCreate}]}/>
        {isAdd && <AddSequence onRefresh={getSequences} onClose={ () => setIsAdd(false)} />}
        <PageLoading isLoading={isLoading}>
            <PrefCountView type={"sequence"} />
            <TableView headers={["Title","Total Triggers","Created","Options"]}>
                {sequences?.map(a =>{
                    return <tr>
                        <td width={"50%"}>{a?.title}</td>
                        <td width={"10%"}>0</td>
                        <td width={"20%"}>{moment.unix(a?.created).format("lll")}</td>
                        <td width={"20%"}>
                            <Group>
                                <CopyButton value={`S_${a?.id}`}>
                                    {({ copied, copy }) => <IconView label={"Copy Sequence Code.\n\n You can use it in Button Messages To trigger Sequence"} icon={copied ? <IconCheck color={"green"} /> :<IconCopy color={Colors.Primary} />} onClick={copy} />}
                                </CopyButton>
                                <IconView iconType={"lead"} onClick={() => onLeadClick(a?.id)} />
                                <IconView iconType={"edit"} onClick={() => onEdit(a?.id)} />
                                <IconView iconType={"delete"} onClick={() => onDelete(a?.id)} />

                            </Group>
                        </td>
                    </tr>
                })}

        </TableView>
        </PageLoading>
    </>
}
export default Sequence;

const AddSequence = ({onClose,onRefresh}) =>{
    const [title,setTitle] = useState("");
    const [error,setError] = useState("");
    const {sessionId,userId} = useSelector(state => state.active);
    const dispatch = useDispatch();

    const CreateSequence  = async () =>{
        setError();
        const data = await RequestPost(`${CloudApi.sequence}/create/${sessionId}`, {title,userId});
        if(data?.status === 1){
            onClose();
            onRefresh();
            dispatch(ActionShowAlert(data?.message));
        }else{
            setError(data?.message);
        }

    }

    return  <Modal title={<b>Create Sequence</b>} opened={true} size={"30%"} onClose={onClose}>
        <TextInput label={"Sequence Name"} value={title} onChange={e => setTitle(e.target.value)} />
        {error && <ErrorText text={error} />}
        <Group position={"center"} my={10}>
            <Button variant={"gradient"} compact onClick={CreateSequence} disabled={title?.trim() === ""}>Create Sequence</Button>
        </Group>
    </Modal>
}

import {Accordion, Grid, Select, Text, Paper, TextInput, NumberInput, Group, Button, Stack} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {TimeArray, WBASelects} from "../../Constants/Strings";
import ProductSelect from "./ProductSelect";
import {R365Api, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {BoldText, ErrorText} from "../../Pages/Components/TextComponents";
import {FlowErrorMsg, FlowSuccessMsg} from "../../Pages/Components/PageComponents";
import {Colors} from "../../Constants/Colors";
const MiscList = [
    {type: "missed",label: "Send Missed Read365 Message of Specific Time" ,btnTitle: "Send Message to All"},

    {type: "startQueue",label: "Restart Struct Messages" ,btnTitle: "Restart Struct Messages"},
    {type: "startNew",label: "Start Books From Begining" ,btnTitle: "Restart Books"},

    {type: "newPlan",label: "Create New Read365 Plan", btnTitle: "Create User"},
    {type: "testTemplate",label: "Send Book Parts to User", btnTitle: "Send Message"},
    // {type: "downloadUser",label: "Download Users", btnTitle: "Download User"},
    // {type: "downloadUserPay",label: "Download Users Pay Details", btnTitle: "Download User Pay Details"},

    {type: "nextBook",label: "Send Next Book Tomorrow",btnTitle: "Send Next Book Tomorrow", alert: {   title: "Send Next Book Tomorrow",
            content: `It will Start Next Book From Tomorrow.. Shall We Send ?`,
            successTitle:"Send",
            cancelTitle: "Cancel",}},
    {type: "convertPlan",label: "Convert One Plan to Other",btnTitle: "Convert Plan",alert: {  title: "Convert Plan",
            content: `Are you Sure want to Convert Plan ?. It will Delete Old Plan and New Plan Days Based on Remaining Days.`,
            successTitle: "Convert",
            cancelTitle: "Cancel"
        }},
    {type: "unsubscribe",label: "Unsubscribe User", btnTitle: "Unsubscribe", alert: {title: "Unsubscribe",
            content: "Are you Sure want to Unsubscribe?. It will Delete User Plan.. Cannot be retrived.",
            successTitle:"Delete",
            cancelTitle:"Cancel"}},
    {type: "changeNumber",label: "Change User Mobile Number", btnTitle: "Change Number", alert:{     title: "Change Number",
            content: "Are you Sure want to Change Number?",
            successTitle:"Change",cancelTitle: "Cancel"}},
    // {type: "bsmMessage",label: "Send BSM Message For Single Tick in Wati", btnTitle: "Send Message"},
    {type: "clearMessages",label: "Clear User Today Messages", btnTitle: "Delete Today Messages", alert: { title: "Clear Messages",
            content: `It will Clear Today Messages Of Choosed Product. Are you sure ?`  ,
            successTitle:"Clear",
            cancelTitle: "Cancel"}},
    {type: "updateDate",label: "Update User Days & Format Messages",btnTitle: "Format Message & Update Date to Users", alert: { title: "Clear Messages",
            content: `It will Update user days and Refetch Messages. Are you sure ?`  ,
            successTitle: "Refetch",
            cancelTitle: "Cancel"}},
];

const MiscOperations = () =>{
    const {r365} = useSelector(state => state.active);
    const [productId,setProductId] = useState(r365?.productId?.[0]);

    return <>
        <ProductSelect productId={productId} setProductId={setProductId} />
        <Accordion>
            {MiscList?.map((a,i) => <Accordion.Item key={a?.type} value={a?.type}>
                <Accordion.Control>
                    <BoldText text={`${i+1}. ${a?.label}`} />
                </Accordion.Control>
                <Accordion.Panel sx={{backgroundColor: "lightgray"}}>
                    <MiscDetails type={a?.type} productId={productId} />
                </Accordion.Panel>
            </Accordion.Item>)}
        </Accordion>
    </>
}

export default MiscOperations;
const MiscDetails = ({type, productId}) =>{
    const {sessionId,books} = useSelector(state => state.active);
    const [isLoading,setIsLoading] =useState(false);
    const [details,setDetails] =useState("");
    const [mobile,setMobile] =useState("");
    const [mobile1,setMobile1] =useState("");
    // const [productId,setProductId] = useState(r365?.productId?.[0]);
    const [productId1,setProductId1] = useState("");
    const [status,setStatus] = useState("");
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [totalDays,setTotalDays] = useState("");
    const [hint,setHint] = useState("");
    const [time,setTime] = useState("6:00 AM");

    const [error,setError] = useState("");
    const [successMsg,setSuccessMsg] = useState("");

    const [amount,setAmount] = useState(0);
    const [reason,setReason] = useState("");
    const BookList = [];

    const [bookId,setBookId] = useState("");
    const [tempName,setTempName] = useState("");
    const [lang,setLang] = useState("en");

    const dispatch = useDispatch();

    useEffect(()=>{
        const d = MiscList.find(a => a.type === type);
        setDetails(d);
    },[type])


    const handleAlert = () =>{
        if(details?.alert){
            dispatch(ActionShowPopup({...details?.alert, onSuccess: handleSubmit, onCancel: () => dispatch(ActionHidePopup())}));
        }else{
            handleSubmit()
        }
    }
    const handleSubmit = async () =>{

        let payload = {};
        let isValid = true;
        let diffHandle = false;
        switch(type){
            case 'unsubscribe': {
                isValid = mobile?.trim() !== "" && amount && reason?.trim() !== "";
                payload = {mobile, amount, reason};
                break;
            }
            case 'nextBook':
            case 'clearMessages':
            case 'startNew': {
                isValid = mobile?.trim() !== "";
                payload = {mobile};
                break;
            }
            case 'changeNumber': {
                isValid = mobile?.trim() !== "" && mobile1?.trim() !== "";
                payload = {mobile, newNumber: mobile1};
                break;
            }
            case 'convertPlan': {
                isValid = mobile?.trim() !== "" && productId1?.trim() !== "";
                payload = {mobile, existPlan: productId, newPlan: productId1};
                break;
            }
            case 'newPlan': {
                isValid = name?.trim() !== "" && email?.trim() !== ""&& mobile?.trim() !== ""&& totalDays?.trim() !== "" && hint?.trim() !== "";
                payload = {name, email, mobile, totalDays, hint};
                break;
            }
            case 'missed': {
                isValid = time?.trim() !== "";
                payload = {time};
                break;
            }
            case 'startQueue': {
                isValid = true;
                payload = {sessionId};
                break;
            }
            case "testTemplate": {
                const isValid = !!bookId && !!tempName && !!lang && mobile?.trim() !== "";
                console.log("isValid",isValid);
                diffHandle = true;
                if(!isValid){
                    console.log("Calling error")
                    setError("Please Fill All Fields");
                    return;
                }else{
                    console.log("isValid insie",isValid);
                    setIsLoading(true);
                    const response = await RequestGet(`${R365Api.triggerBooks}/${productId}/${tempName}/${mobile}/${lang}`)
                    if (response?.status === 1) {
                        setSuccessMsg("Sent Successfully");
                    } else {
                        setError(response?.message);
                    }
                    setTimeout(() =>{
                        setError("");
                        setSuccessMsg("")
                    },1000)
                    setIsLoading(false);
                    return;
                }

                break;
            }
            case 'bsmMessage': {
                // const response = await RequestGet(`http://whatsapp.businesssalesmachine.com/client/send-R365-initiate/${productId}/${mobile}/d`)
                // if (response?.statusCode === 0) {
                //     dispatch(ActionShowAlert({message: "Sent Successfully", color: "green"}));
                // } else {
                //     dispatch(ActionShowAlert({
                //         message: `Something went wrong. Code - ${response?.statusCode}`,
                //         color: "green"
                //     }));
                // }
                break;
            }

        }
        if(!isValid){
            console.log("Calling error")
            setError("Please Fill All Fields");
        }
        else if(!diffHandle){
            setIsLoading(true);
            const data = await RequestPost(`${R365Api.UpdateMisc}/${type}/${productId}`, payload);
            setIsLoading(false);
            dispatch(ActionHidePopup())
            if(data?.status === 1){
                setSuccessMsg(data?.message);
            }else{
                setError(data?.message);
            }
            setTimeout(() =>{
                setError("");
                setSuccessMsg("")
            },1000)
        }
    }

    return <Grid>
            {['unsubscribe','nextBook','clearMessages','bsmMessage','convertPlan','changeNumber','newPlan',"startNew","testTemplate"].includes(type) && <Grid.Col span={6}><TextInput name={'mobile'} label={"Mobile"} value={mobile} onChange={e => setMobile(e.target.value)} /></Grid.Col>}
            {['convertPlan'].includes(type) &&<Grid.Col span={6}><Select label={"Choose New Product"} data={BookList} value={productId1} onChange={setProductId1} /></Grid.Col>}
            {['changeNumber'].includes(type) &&<Grid.Col span={6}><TextInput name={'mobile'} label={"New Number"} value={mobile1} onChange={e => setMobile1(e.target.value)} /></Grid.Col>}
            {['downloadUser'].includes(type) &&<Grid.Col span={6}>
                <Select
                    label={"Choose Status"}
                    value={status}
                    onChange={setStatus}
                    data={['Invalid','Valid','Duplicate','Paused','Completed','Not Read','All']?.map((a,i) => ({label:a,value: i.toString()}))}
                />
            </Grid.Col>}
            {['newPlan'].includes(type) && <>
                <Grid.Col span={6}>
                    <TextInput label={"Name"} value={name} onChange={(e) => setName(e.target.value)} required />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput label={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} required />
                </Grid.Col>

                <Grid.Col span={6}>
                    <TextInput label={"Total Days"} value={totalDays} onChange={(e) => setTotalDays(e.target.value)} required />
                </Grid.Col>
                <Grid.Col span={6}>
                    <TextInput label={"Hint"} value={hint} onChange={(e) => setHint(e.target.value)} required />
                </Grid.Col>
            </>}
            {['missed'].includes(type) &&<>
                <Grid.Col span={4}>
                    <Stack sx={{gap: 3}}>
                        <Select
                            label={"Choose Time"}
                            data={TimeArray?.map(a => ({label:a,value:a}))}
                            value={time}
                            onChange={setTime}
                        />
                        <Text color={"orange"}>It will Send {time} Message to All {productId} users. </Text>
                    </Stack>
                </Grid.Col>
            </>}
            {['unsubscribe'].includes(type) &&<>
                <Grid.Col span={6}>
                    <NumberInput label={"amount"} value={amount} onChange={setAmount} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <TextInput label={"Reason"} value={reason} onChange={e => setReason(e.target.value)} />
                </Grid.Col>
                <Grid.Col span={12}>
                    <Text>You can Check Refund Users in Customer Actions --> Action: Unsubscribe</Text>
                </Grid.Col>
            </>}
            {type === "testTemplate" && productId && <>
                <Grid.Col span={4}>
                    <Select size={"xs"} searchable label={"Select Book"} data={books?.[productId] || []} value={bookId} onChange={setBookId}
                    />
                </Grid.Col>
                {bookId && <>
                    <Grid.Col span={4}>
                        <Select  size={"xs"} searchable label={"Select Template"}
                                 data={books?.[productId]?.find(a => a?.value === bookId)?.temp || []}
                                 value={tempName}
                                 onChange={setTempName}
                        />
                    </Grid.Col>
                </>}
                <Grid.Col span={4}>
                    <Select  size={"xs"} searchable label={"Select Language"}
                             data={WBASelects.BookLang}
                             value={lang}
                             onChange={setLang}
                    />
                </Grid.Col>
            </>}
            <Grid.Col span={4}>
                <Group position={'center'}>
                    <Button onClick={handleAlert} mt={20} loading={isLoading}>{details?.btnTitle || "Update"}</Button>
                    {successMsg && <FlowSuccessMsg message={successMsg} onClose={() => setError()}/>}
                    {error && <FlowErrorMsg message={error} onClose={() => setError()}/>}
                </Group>
            </Grid.Col>
        </Grid>
}
import React from "react";
import {Button, Group, Image, Stack, Text,Paper} from "@mantine/core";
const MessageView = ({details}) =>{
    const {header,body,footer,buttons,sections,image} = details;
    return <Stack sx={{gap: 1}} p={5}>
        {image && <Image src={image} height={100} />}
        {header?.map(a => <Text weight={"bold"} size={"xs"}>{a}</Text>)}
        {body?.map(a => {
            return <>
                {a?.toString()?.split("\n")?.map(b => {
                    return <Text size={"xs"}>{b}</Text>
                })}
            </>
        })}
        {footer?.map(a => <Text size={"xs"} color={"dimmed"}>{a}</Text>)}
        {buttons?.map(a =>(<Button compact variant={"outline"} >{a?.title}</Button>))}
        {sections && sections?.map(a =>{
            return <Paper shadow={"md"} withBorder>
                <Text size={"xs"}>{a?.title}</Text>
                <Stack sx={{gap: 3}}>
                    {a?.list?.map(b => {
                        return <Button compact variant={"outline"}>{b?.title}</Button>
                    })}
                </Stack>
            </Paper>
        })}
    </Stack>
}

export default MessageView;

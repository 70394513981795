import React, {Component, useEffect, useRef, useState} from "react";
import {
    Button,
    Grid,
    Group,
    Modal,
    Select,
    Paper,
    Stack,
    TextInput,
    Text,
    Image,
    Drawer,
    Divider,
    SegmentedControl, Autocomplete
} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
// import TemplatePreview from "./TemplatePreview";
import {R365Api, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {IconView, PageLoading} from "../../Pages/Components/PageComponents";
import {WBASelects} from "../../Constants/Strings";
import {Colors} from "../../Constants/Colors";
import MessageOptions from "../../Pages/Schedule/MessageOptions";
import PreviewScheduleMessage from "../../Pages/Components/PreviewScheduleMessage";

const EditBookTemplates = ({productId,name,onClose}) =>{
    const {userId} = useSelector(state => state.active);

    const [isLoading,setIsLoading] = useState(false);
    const [template,setTemplate] = useState(undefined);
    const [lang,setLang] = useState("en");

    useEffect(() =>{
        getBookTemplate()
    },[])
    const getBookTemplate = async () =>{
        setIsLoading(true);
        const template = await RequestGet(`${R365Api.bookTemplateDetails}/${userId}/${productId}/${name}`)
        setTemplate(template);
        setIsLoading(false);
    }
    return <Drawer opened={true} size={"80%"} position={"right"} onClose={onClose}>
            <PageLoading isLoading={isLoading}>
                <Stack sx={{gap:3}} px={10}>
                    <Text weight={"bold"} variant={"gradient"}>{template?.name || "Not Available"} ({template?.bookId})</Text>
                    <Divider />
                    <SegmentedControl size={"xs"} fullWidth data={WBASelects.BookLang} value={lang} onChange={setLang} />
                    <LangWiseEdit lang={lang} details={template?.[lang]} template={template} onRefresh={getBookTemplate} />
                </Stack>
           </PageLoading>
    </Drawer>
}


const LangWiseEdit = ({details,lang,template,onRefresh}) =>{
    const [isEdit,setIsEdit] = useState(false);
    const [isDelete,setIsDelete] = useState(false);
    const [isDeleteLoading,setIsDeleteLoading] = useState(false);
    const dispatch = useDispatch();
    const hideEdit = (isRefresh = false) =>{
        setIsEdit(true);
        if(isRefresh) onRefresh();

    }
    useEffect(() =>{
        setIsEdit(false)
    },[lang]);
    const RemoveBookTemplate = async ()=>{
        setIsDeleteLoading(true);
        const data = await RequestGet(`${R365Api.DeleteUpdateBookTemplate}/${template?.productId}/${template?.name}/${lang}`);
        setIsDeleteLoading(false);
        setIsDelete(false);
        onRefresh();
    }

    return <>
        {!isEdit && details && <>
            <Paper shadow={"md"} withBorder>
                <Group position={"apart"}>
                    <Text variant={"gradient"}><b>{details?.template?.name}</b></Text>
                    <Group>
                        <IconView iconType={"edit"} label={"Change Template"} color={Colors.Primary} onClick={() => setIsEdit(true)} />
                        <IconView iconType={"delete"} label={"Remove This Template"} color={"red"} onClick={() => setIsDelete(true)} />
                    </Group>
                </Group>
            </Paper>
            {isDelete && <Group position={"apart"} sx={{backgroundColor: "red", color: "white"}}>
                <Text>Are you sure want to Delete? </Text>
                <Group>
                    <Button variant={"outline"} compact onClick={RemoveBookTemplate} loading={isDeleteLoading}>Delete</Button>
                    <Button variant={"outline"} compact onClick={() => setIsDelete(true)}>Cancel</Button>
                </Group>
            </Group>}
             <PreviewScheduleMessage message={details} />
        </>}
        {(isEdit || !details) && <>
            <AddTemplate lang={lang} productId={template?.productId} bookId={template?.bookId} templateName={template?.name} editDetails={details} onClose={hideEdit} />
        </>}
    </>
}
const AddTemplate = ({productId,lang, templateName,bookId,editDetails,onClose}) =>{
    const ref1 = useRef()
    const updateTemplate = async () =>{
        const details = ref1.current?.isValid();
        if(details?.isValid){
            const result = await RequestPost(R365Api.UpdateBookTemplate,{message:details?.message, lang, templateName, bookId,productId});
        }
        onClose(true);
    }
    return <>
        <Grid>
            <MessageOptions ref={ref1} details={editDetails?.editOptions} show={['template',"session"]} />
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={updateTemplate}>Update Template</Button>
                    <Button onClick={onClose}>Cancel</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </>
}

export default EditBookTemplates;

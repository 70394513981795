import React, {useEffect, useState} from 'react';
import {CloudApi, RequestGet, WBAUrls} from "../../Layouts/RequestManager";
import {
    MultiSelect,
    Pagination,
    TextInput,
    Group,
    Text,
    Select,
    Badge, Modal, Loader,
} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {WBASelects} from "../../Constants/Strings";
import {Header, IconView, PageLoading, TableView} from "../Components/PageComponents";
import CreateTemplate from "./CreateTemplate";
import PreviewTemplate from "./PreviewTemplate";
import {IconCopy} from "@tabler/icons";

const PageHeading = {
    title: "Whatsapp Templates",
    subTitle: "Approved/Rejected Templates will Show Here",
    buttons: [{type:"refresh", title: "Refresh"},{type:"add", title: "Add Templates"},{type:"refresh", title: "Sync Templates"}]
}
const DeletePopup = {
    title: "Delete Whatsapp Templates",
    content: "It will Delete the whatsapp template. If any Schedules added With that Will Not Work. Are you Sure?",
    successTitle: "Delete",
    cancelTitle: "Cancel"
}
const statusColor = {
    APPROVED: "green",
    REJECTED: "red"
}
const ApprovedTemplates = () =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [templates,setTemplates] = useState([]);
    const [filtered,setFiltered] = useState([]);
    const [page, setPage] = useState(1);
    const [isAdd,setIsAdd] = useState(false);
    const [isView,setIsView] = useState(undefined);
    const [isSync,setIsSync] = useState(false);
    const [isClone,setIsClone] = useState();
    const dispatch= useDispatch();

    const fetchTemplates = async  () =>{
        setIsLoading(true);
        const response = await RequestGet(`${CloudApi.templateList}/${sessionId}`);
        setTemplates(response?.templates || []);
        setIsLoading(false);
    }
    const SyncTemplates = async  () =>{
        setIsSync(true);
        const response = await RequestGet(`${CloudApi.templateSync}/${sessionId}`);
        setIsSync(false);
        dispatch(ActionShowAlert({message: response?.message}));
        fetchTemplates();
    }
    useEffect(() => {
        fetchTemplates()
    },[sessionId]);
    const onView = (id) => setIsView(id);
    const onDelete = async (id,name) => {
        const result = await RequestGet(`${CloudApi.templateDelete}/${sessionId}/${id}/${name}`);
        dispatch(ActionShowAlert({message: result?.message, color: result?.status === 1 ? "green": "red"}))
        dispatch(ActionHidePopup());
        fetchTemplates();
    }

    return <>
        <Header {...PageHeading}  buttonProps={[{onClick: fetchTemplates},{onClick: () => setIsAdd(true)},{onClick:SyncTemplates, isLoading: isSync}]}/>
        <PageLoading isLoading={isLoading}>
           <FilterTemplates templates={templates} setFiltered={setFiltered} setPage={setPage} />
            <Group position={"apart"} my={5}>
                <Text size={"xs"} mx={10}>Showing {filtered?.length} / {templates?.length} Results</Text>
                <Pagination size={"xs"} total={Math.ceil(filtered?.length/ 10)} value={page} onChange={setPage} />
            </Group>
            <TableView headers={["Name","Category","Language","Media","Status","Options"]}>
                {filtered?.slice((page-1) * 10, page * 10)?.map(template => <TemplateListRender template={template} onView={onView} onDelete={onDelete} onClone={setIsClone}/>)}
            </TableView>
            {isAdd && <CreateTemplate sessionId={sessionId} onClose={() =>setIsAdd(false)} />}
            {isClone && <CreateTemplate sessionId={sessionId} onClose={() =>setIsClone()} cloneId={isClone} />}
            {isView && <ViewApprovedTemplate sessionId={sessionId} templateId={isView} onClose={() => setIsView(undefined)} />}
        </PageLoading>
    </>
}

export default ApprovedTemplates;

const FilterTemplates = ({templates,setFiltered,setPage})=>{
    const [search,setSearch] = useState("");
    const [status,setStatus] = useState("all");
    const [lang,setLang] = useState([]);
    const [category,setCategory] = useState([]);
    const [noMedia,setNoMedia] = useState(false) ;
    const [render,setRender] = useState(0);
    useEffect(() =>{
        let filtered = templates;
        if(lang?.length > 0) filtered = filtered?.filter(a => lang?.includes(a?.language));
        if(category?.length > 0) filtered = filtered?.filter(a => category?.includes(a?.category));
        if(search?.trim() !== "") filtered = filtered?.filter(a => a?.name?.includes(search?.toLowerCase()));
        if(status !== "all") filtered = filtered?.filter(a => a?.status === status);
        // if(noMedia) filtered = filtered?.filter(a => !a?.media)
        setFiltered(filtered);
        setRender(render+ 1);
        setPage(1)

    },[search,lang, templates,status, noMedia,category])
    return <Group my={10}>
        <TextInput variant={"filled"} size={"xs"} placeholder={"Search Name"} value={search} onChange={e => setSearch(e.target.value)} />
        <MultiSelect variant={"filled"} placeholder={"Choose Language"} size={"xs"} data={WBASelects.language} value={lang} onChange={setLang} />
        <MultiSelect variant={"filled"} placeholder={"Choose Category"} size={"xs"} data={WBASelects.tempCategory} value={category} onChange={setCategory} />
        <Select variant={"filled"} size={"xs"} data={WBASelects.tempStatus} value={status} onChange={setStatus} />
    </Group>
}
const TemplateListRender = ({template,onDelete,onView,onClone}) =>{
    const dispatch = useDispatch();
    const handleDelete = async () =>{
        dispatch(ActionShowPopup({...DeletePopup,onSuccess: () => onDelete(template?.id,template?.name)}));
    }
    return <tr>
        <td>{template?.name}</td>
        <td>{template?.category}</td>
        <td>{WBASelects.language?.find(a => a?.value === template?.language)?.label || template?.language }</td>
        <td>{template?.options?.media && <Badge color={(template?.media?.mediaId ||template?.media?.mediaLink) ? "green": "red"}>{template?.options?.media}</Badge>}</td>
        <td><Badge color={statusColor?.[template?.status] || "blue"}>{template?.status}</Badge></td>
        <td>
            <Group>
                <IconView iconType={"view"} label={"View Template"} onClick={() => onView(template?.id)}/>
                <IconView icon={<IconCopy />} label={"Clone Template"} onClick={() => onClone(template?.id)}/>
                <IconView iconType={"delete"} label={"Delete"} onClick={handleDelete} />
            </Group>
        </td>
    </tr>
}
const ViewApprovedTemplate = ({templateId,sessionId,onClose}) =>{
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    useEffect(() => {
        getTemplatesDetails();
    },[]);
    const getTemplatesDetails = async () => {
        setIsLoading(true);
       const details = await RequestGet(`${CloudApi.templateDetails}/${sessionId}/${templateId}`);
       if(details){
           setDetails(details?.details);
           setIsLoading(false);
       }else{
           setIsLoading(false);
           onClose()
       }
    }
    return <Modal title={<Text weight={"bold"}>{details?.name || "Template"}</Text>} opened={true} onClose={onClose}>
        {isLoading ? <Loader />: <PreviewTemplate message={details?.message} showVar={false} />}
    </Modal>
}

import React,{useDispatch, useSelector} from "react-redux";
import {
    IconBook,
    IconForms,
    IconGauge,
    IconGraph, IconHomeStats,
    IconInbox,
    IconList, IconLogout,
    IconMessage, IconSettings,
    IconTemplate, IconTimeline,
    IconUsers
} from "@tabler/icons";
import {Colors} from "../Constants/Colors";
import {Divider, Group, ScrollArea, Stack, Text} from "@mantine/core";

const R365SideBar = ({height,navigation}) =>{
    const {sessionId,alias,r365} = useSelector(state => state.active);

    const handleNavigation = (path) => navigation(`device/${alias}${path}`);
    const  lists =[
        {icon: <IconGauge size={20} color={Colors.Primary}  />,label: 'Dashboard',path: `/r365`},
        {icon: <IconBook size={20} color={Colors.Primary}  />,label: 'Books',path: `/r365/books`},
        {icon: <IconUsers size={20} color={Colors.Primary}  />,label: 'Customers',path: `/r365/customers`},
        {icon: <IconMessage size={20} color={Colors.Primary}  />,label: 'Today Messages',path: `/r365/messages`},
        {icon: <IconSettings size={20} color={Colors.Primary}  />,label: 'Actions',path: `/r365/actions`},
        {icon: <IconHomeStats size={20} color={Colors.Primary}  />,label: 'Stats',path: `/r365/Stats`},
    ];

    return <ScrollArea pt={10} sx={{position: 'fixed', width: 70, bottom: 0, left: 0,top: 0,backgroundColor:'rgb(90, 50, 168,0.2)', height, borderRightWidth: 2, borderRightColor: Colors.Primary,borderRightStyle: 'solid',gap: 14}} align={'center'} scrollbarSize={1} type={"auto"} >
        {/*<Stack sx={{gap: 3,cursor: "pointer"}} onClick={() => handleNavigation("/r365")}>*/}
        {/*    <Text size={"md"} weight={"bolder"} color={"violet"} my={10}>R365</Text>*/}
        {/*</Stack>*/}
        <Stack mb={50} sx={{gap: 10}} mt={10}>
            {lists.map(a => {
                    if(a?.type === 'divider') <Divider sx={{gap: 3}} color={'white'} />
                    return <Stack sx={{gap: 3,cursor: "pointer"}} onClick={() => handleNavigation(a?.path)}>
                        <Group position={"center"}>{a?.icon}</Group>
                        <Text size={"xs"} color={"violet"}>{a?.label}</Text>
                    </Stack>
                })}
        </Stack>
    </ScrollArea>
}
export default R365SideBar;
import React, {Component, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    Accordion,
    Button, Divider, Grid,
    Group,
    Modal,
    MultiSelect,
    NumberInput, Stack,
    Text,Paper
} from "@mantine/core";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import PreviewScheduleMessage from "../Components/PreviewScheduleMessage";
import {CheckBoxView, IconView, IconWithText, PageLoading} from "../Components/PageComponents";
import {
    IconCircle,
    IconCircleCheck,
    IconCopy,
    IconEdit,
    IconMessages,
    IconPlus,
    IconRefresh,
    IconSend,
    IconToggleLeft,
    IconToggleRight
} from "@tabler/icons";
import MessageOptions from "../Schedule/MessageOptions";
import {TimeInput} from "@mantine/dates";
import {formatLTTime} from "../../Constants/Functions";
import {Colors} from "../../Constants/Colors";
import {WeekDays} from "../../Constants/Strings";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";

const maxDays = 500;
const DayMessages = ({programId}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [parts,setParts] = useState([]);
    const [courses,setCourses] = useState([]);
    const [settings,setSettings] = useState([]);
    const [isAdd,setIsAdd] = useState(false);
    const [isAddMsg,setIsAddMsg] = useState(false);
    const [isClone,setIsClone] = useState(false);
    const [containsMsg,setContainsMsg] = useState(false);
    const [openMsgTag,setMsgTag] = useState();
    const dispatch = useDispatch();

    useEffect(()=>{
        getDetails();
    },[]);
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.programs}/details/${sessionId}`,{type: "days",programId});
        setIsLoading(false);
        setParts(data?.parts || []);
        setCourses(data?.courses || []);
        setSettings(data?.settings || []);
    }
    const AlertMessages ={
        pause: { title: "Pause Message",content: "It will Stop Sending Particular Message to Users. Are you Sure want to Stop ?",successTitle: "Pause",},
        resume: {  title: "Resume Message",content: "It will Start Sending Particular Message to Users From Now. Are you Sure want to Resume ?", successTitle: "Resume"},
        delete: {  title: "Delete Message",content: "Are you sure want to Delete Message?", successTitle: "Delete"}

    }


    const handleMsg = async (courseId,id, type) =>{
        dispatch(ActionShowPopup({
            ...AlertMessages?.[type],
            cancelTitle: "Cancel",
            onSuccess: async() => {
                const data = await RequestPost(`${CloudApi.programDayMsg}/${sessionId}/${type}`,{
                    programId,courseId,id
                });
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
                getDetails();
            }
        }));

    }


    return <div>
        <Group position={'apart'} mx={5}>
            <CheckBoxView onClick={setContainsMsg} text={"Only Have Messages"} isSelected={containsMsg} />
            {settings && <Group sx={{cursor: "pointer"}} onClick={() => setMsgTag(true)}>
                <Text weight={"bold"}>Send Messages To :</Text>
                <Text>{settings?.msgTags?.length > 0 ? `${settings?.msgTags?.slice(0, 3).map(a => `${a}`)?.join(", ")}${settings?.msgTags?.length > 3 ? ` & ${settings?.msgTags?.length - 3} more` : ""}`: 'All'} </Text>
                <IconEdit/>
                {openMsgTag &&  <MessageTagHandle programId={programId} tags={settings?.msgTags || []} onClose={() => setMsgTag(false)} onRefresh={getDetails} />}
            </Group>}
            <Group>
                <Button leftIcon={<IconPlus />} onClick={() => setIsAdd(true)} variant={'gradient'} compact>Add Day</Button>
                <Button leftIcon={<IconRefresh />} onClick={getDetails} variant={'gradient'} compact>Refresh</Button>
            </Group>

        </Group>
        {isAdd && <AddDay programId={programId} onClose={() => setIsAdd(false)} onRefresh={getDetails} />}
        {isAddMsg && <AddDayMsg programId={programId} details={isAddMsg} onClose={() => setIsAddMsg()} onRefresh={getDetails} />}
        {isClone && <CloneMsg programId={programId}  onRefresh={getDetails} onClose={() => setIsClone()} details={isClone} courses={courses} parts={parts} />}
       <PageLoading isLoading={isLoading}>
           <Accordion>
               {parts?.map((a,i) =>{
                   const course = courses.find(b => b.id === a);
                   if(containsMsg && [0,undefined].includes(course?.modules?.length)) return ;
                   if(!course) return
                   return <Accordion.Item value={i.toString()}>
                       <Accordion.Control>
                           <Grid>
                               <Grid.Col span={8}>
                                   <Stack sx={{gap: 3}}>
                                       <Text>Day {i + 1}</Text>
                                       {i === 0 && settings?.firstDay?.length > 0 && <Text size={'xs'} color={'dimmed'}>Message will send on: {settings?.firstDay?.map(a => WeekDays[a])?.join(',')}</Text>}
                                   </Stack>
                               </Grid.Col>
                               <Grid.Col span={4}>
                                   <Group direction={'row'} position={'right'} sx={{gap: 3}}>
                                       <IconWithText text={course?.modules?.length || "0"} icon={<IconMessages />} label={"Messages Count"} />
                                       <IconView label={"Add Message"} iconType={'add'} onClick={(event) => {
                                           event.stopPropagation();
                                           setIsAddMsg({courseId: course?.id})
                                       }}  />
                                       <IconView label={"Clone Day Message"} icon={<IconCopy />} onClick={(event) => {
                                           event.stopPropagation();
                                           setIsClone({id: course?.id, index: i})
                                       }}  />
                                   </Group>
                               </Grid.Col>
                           </Grid>
                       </Accordion.Control>
                       <Accordion.Panel>
                           <Grid>
                               {course?.modules && course?.modules?.map(b => <Grid.Col span={4}>
                                   <Paper shadow={'md'}  withBorder>
                                       <Group position={'apart'} sx={{backgroundColor: Colors.Primary,color:Colors.Secondary}} px={5}>
                                           <Text mx={10}>{b?.time}</Text>
                                           <Group position={'left'}>
                                               {/*<IconWithText icon={< IconSend />} text={`(${msg})`}  label={"Send Message"} />*/}
                                               <IconView label={"Edit Message"} color={'violet'} iconType={'edit'} onClick={() => setIsAddMsg({courseId:course?.id,...b})} />
                                               <IconView label={"Delete Message"} iconType={'delete'} onClick={() => handleMsg(course?.id,b?.id,"delete")} />
                                               <IconView variant={"default"} label={b?.status !== 0 ?  "Pause Message": "Resume Message"} icon={b?.status !== 0 ? <IconToggleRight  color={'green'} /> : <IconToggleLeft color={'red'} />} onClick={() =>handleMsg(course?.id,b?.id,b?.status !== 0 ? "pause": "resume")} />
                                           </Group>
                                       </Group>
                                       <Divider />
                                       <PreviewScheduleMessage message={b?.message} />
                                   </Paper>
                               </Grid.Col>)}
                           </Grid>
                       </Accordion.Panel>
                   </Accordion.Item>
               })}
           </Accordion>
       </PageLoading>
    </div>
}

export default DayMessages;


const AddDay = ({onClose,programId,onRefresh}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [day,setDay] = useState(1);
    const [isLoading,setIsLoading] = useState(false);
    const AddDayInProgram = async () =>{
        setIsLoading(true);
        await RequestGet(`${CloudApi.programAddDay}/${sessionId}/${programId}/${day}`);
        setIsLoading(false);
        onClose();
        onRefresh();
    }

    return <Modal opened={true} onClose={onClose} title={<Text weight={"bold"} variant={"gradient"}>Add Day to Program</Text>}>
        <Group>
            <Text weight={"bold"}>Program - Day </Text>
            <NumberInput sx={{width: 70}} size={'xs'} min={1} max={maxDays}  value={day} onChange={setDay} />
        </Group>
        <Group position={'center'} mt={20}>
            <Button onClick={AddDayInProgram} variant={'gradient'} loading={isLoading}>Add Day</Button>
        </Group>
    </Modal>
}
const AddDayMsg = ({onClose,programId,onRefresh,details}) =>{
    const {sessionId} = useSelector(state => state.active);
    const ref = React.createRef();
    const [isLoading,setIsLoading] = useState(false);
    const [time,setTime] = useState(new Date());
    const AddDayMessage = async () =>{
        const {isValid,message} = ref.current.isValid();
        if(isValid){
            await RequestPost(`${CloudApi.programDayMsg}/${sessionId}/${details?.id ? "edit": "add"}`,{
                message, programId,time: formatLTTime(time),courseId: details?.courseId,id: details?.id
            });
            onClose();
            onRefresh();
        }

    }
    return <Modal size={"80%"} opened={true} onClose={onClose} title={<Text weight={"bold"} variant={"gradient"}>Add Day to Program</Text>}>
        <Grid>
            <Grid.Col span={4}>
                <TimeInput
                    name={'time'}
                    value={time}
                    label="Pick time"
                    format="12"
                    required
                    clearable
                    onChange={setTime}
                />
            </Grid.Col>
            <MessageOptions ref={ref} details={details?.message?.editOptions} />
        </Grid>

        <Group position={'center'} mt={20}>
            <Button onClick={AddDayMessage} variant={'gradient'} loading={isLoading}>Add Message</Button>
        </Group>
    </Modal>
}

const MessageTagHandle = ({programId,tags,onClose,onRefresh}) =>{
    const {ProTags,sessionId} = useSelector(state => state.active);
    const [msgTags,setMsgTags] = useState(tags || []);
    const [isLoading,setIsLoading] = useState(false);
    useEffect(() =>{
        setMsgTags(tags);
    },[tags]);
    const updateMessageTags = async() =>{
        setIsLoading(true);
        const data =  await RequestPost(`${CloudApi.programSettings}/${sessionId}/${programId}`, {type: "msgTags",msgTags});
       setIsLoading(false);
       onClose();
       onRefresh();
    }
    return <Modal opened={true} onClose={onClose} title={<Text weight={"bold"}>Send Messages only to Specific Tags:</Text>}>
            <MultiSelect label={"Messages Will Sent to."}
                         data={ProTags?.[programId]?.map(a =>({value: a,label: a})) || []}
                         value={msgTags}
                         onChange={setMsgTags} />
            <Group>
                <Button  mt={10} onClick={updateMessageTags} loading={isLoading}>Update</Button>
            </Group>
        </Modal>
}

const CloneMsg = ({details,onClose,courses,parts,programId,onRefresh}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [clonedDay,setClonedDay] = useState(1);
    const [cloneType,setCloneType] = useState("merge");
    const [isLoading,setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const CloneDayMessage = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.programCloneDay}/${sessionId}/${programId}`,{from:details?.id, day: clonedDay, type:cloneType});
        dispatch(ActionShowAlert({message: data?.message}));
        setIsLoading(false);
        onClose()
        onRefresh();
    }

   return <Modal size={"80%"} opened={true} onClose={onClose} title={<b>Clone Day Messages</b>}>
        <Group>
            <Text> Clone <b>Day {(details?.index || 0) + 1}</b> Messages To Day</Text>
            <NumberInput size={"xs"} value={clonedDay} min={1} onChange={setClonedDay} />
        </Group>
        {(details?.index + 1) === clonedDay ? <Text color={"red"} size={"xs"}>Please choose Different day to Clone</Text> :<>
            {courses?.find(b => b.id === parts?.[clonedDay - 1]) && <>
                <Group my={5}>
                    <Group onClick={() => setCloneType("merge")}>{cloneType === "merge" ? <IconCircleCheck size={"18"} /> :<IconCircle size={"18"}/>}<Text>Merge new Messages With Old</Text></Group>
                    <Group  onClick={() =>  setCloneType("override")}>{cloneType === "override" ? <IconCircleCheck size={"18"} /> :<IconCircle size={"18"}/>}<Text>Delete Old and Create New</Text></Group>
                </Group>
                <Text size={"sm"} color={"dimmed"}>
                    {cloneType === "merge" ? "It will Create New Messages with Existing Old Messages" : "It will Delete Old Messages and Create New Messages only"}
                </Text>
            </>}
        </>}
        <Group position={"center"} mt={10}>
            <Button onClick={CloneDayMessage} loading={isLoading} disabled={!clonedDay || clonedDay <= 0}> Clone Day Messages </Button>
        </Group>
    </Modal>
}

import React, {useEffect, useRef, useState} from 'react';
import {
    Modal,
    Textarea,
    TextInput,
    Button,
    Grid,
    MultiSelect,
    Select,
    Text,
    Group,
    FileButton,
    SegmentedControl, Paper, Menu, Stack, Divider, ScrollArea, Loader
} from "@mantine/core";
import {WBASelects} from "../../Constants/Strings";
import {
    IconArrowForward,
    IconCircle,
    IconCircleCheck, IconCopy, IconFile, IconList, IconLogout, IconPhoneCall, IconPhoto,
    IconPlus, IconVideo
} from "@tabler/icons";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from "../../Constants/Colors";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {ActionShowAlert} from "../../store/actions/alert";
import {IconView, IconWithText, PageLoading} from "../Components/PageComponents";
import {BoldText, LightText, SmallText} from "../Components/TextComponents";
import {findVariables} from "../../Constants/Functions";

const FileOptions = {
    "IMAGE": {
        create: "Upload Image",
        edit: "Change Image",
        accept: "image/*"
    },
    "VIDEO": {
        create: "Upload Video",
        edit: "Change Video",
        accept: "video/*"
    },
    "DOCUMENT": {
        create: "Upload Document",
        edit: "Change Document",
        accept: "*"
    }
}

const CreateTemplate = ({sessionId, onClose,cloneId}) =>{
    const [language,setLang]= useState("en");
    const [category,setCategory]= useState("MARKETING");
    const [name,setName]= useState("");
    const [type,setType] = useState("NONE");
    const [text,setText] = useState("");
    const [file,setFile] = useState(undefined);
    const [link,setLink] = useState("");

    const [variables,setVariables] = useState({});
    const [message,setMessage]= useState("");
    const [buttons,setButtons] = useState([]);
    const [footer,setFooter]= useState("");
    const [bodyFields,setBodyFields]= useState({});
    const [isError,setIsError]= useState(false);
    const [error,setError] = useState("");
    const [isAdding,setIsAdding] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const dispatch = useDispatch();

    useEffect(() =>{
        if(cloneId) getTemplateDetails();
     },[cloneId])

    const getTemplateDetails = async () =>{
        setIsLoading(true)
        const details = await RequestGet(`${CloudApi.templateRawDetails}/${sessionId}/${cloneId}`);
        const {category,language,name,components} = details?.details || {};
        setCategory(category);
        setLang(language);
        setName(name);
        components?.map(a =>{
            switch (a?.type){
                case "HEADER":
                    setType(a?.format);
                    setText(a?.text);
                    break;
                case "BODY":
                    setMessage(a?.text);
                    let body = [];
                    a?.example?.body_text?.[0]?.map((a,i) => body[i+1] = a);
                    setBodyFields(body);
                    break;
                case "FOOTER":
                    setFooter(a?.text)
                    break;
                case "BUTTONS":
                    const b = a?.buttons?.map(button =>{
                        switch (button?.type){
                            case "QUICK_REPLY":
                                return button;
                            case "COPY_CODE":
                                return {...button,coupon_code: button?.example?.[0]}
                            case "PHONE_NUMBER":
                                return {...button,mobile: button?.phone_number};
                            case "URL":
                                return({...button, value: button?.url, urlType: button?.example ? "Dynamic": "Static"});
                            case "FLOW":
                                return {...button,mobile: button?.phone_number};
                            default:
                                return({...button, value: button?.url});
                        }
                    });
                    setButtons(b);
                    break;
            }
        })
        setIsLoading(false)
    }
    const handleFile = (file) =>{
        const reader = new FileReader();
        reader.onload = function (event) {
            const base64Data = event.target.result;
            setFile({name: file?.name, size: file?.size, file: base64Data, type: file?.type});
        };

        reader.readAsDataURL(file);
    };
    const headerValid = () =>{
        switch (type){
            case "NONE":
                return {isValid: true, header: undefined};
            case "TEXT":
                const varList = findVariables(text);
                let isValid = varList?.length === 0 && text?.trim() !== "";
                if(varList?.length > 0) isValid = variables?.["1"]?.trim() !== "";
                return {isValid, header: {format: type,text, example: varList?.length > 0 ? {header_text: [variables?.[1]]}: undefined}}
            default:
                return {isValid: link?.trim() !== "" || file,header:{format: type},file,link}
        }
    }
    const PrepareComponents = async () =>{
            const components = [];
            const headerDetails = headerValid();
            const messageValid = message?.trim() !== "" && findVariables(message)?.length === Object.values(bodyFields)?.filter(a => a?.trim() !== "")?.length;
            if(name?.trim() !== "" && headerDetails?.isValid && messageValid){
                setIsError(false);
                setError("");
                setIsAdding(true);
                let variableMap = [];
                if(headerDetails?.header) components.push({ ...headerDetails?.header,"type": "HEADER" });
                if(message){
                    const body_text = [];
                    let text = message;
                    findVariables(message)?.map((a,i) => {
                        variableMap.push({key: a, value: `${i+1}`});
                        body_text?.push(bodyFields?.[a]);
                        text = text?.replace(`{{${a}}}`,`{{${i+1}}}`)
                    });
                    let q = {type: "BODY", text};
                    if(body_text?.length > 0) q ={...q, example: {body_text: [body_text]}}
                    components.push(q);
                }
                if(footer) components.push({ "type": "FOOTER", "text": footer});
                if(buttons?.length > 0){
                    const list = buttons?.map( a =>{
                        switch (a?.type){
                            case "URL":
                                return  { "type": "URL","text": a?.text,"url": a?.value,"example":[a?.example]}
                            case "QUICK_REPLY":
                                return {...a,"type": "QUICK_REPLY" };
                            case "PHONE_NUMBER":
                                return  {type: "PHONE_NUMBER", text: a?.text, phone_number: a?.mobile}
                            case "COPY_CODE":
                                return  {type: "COPY_CODE",  example: a?.coupon_code}
                            case "FLOW":
                                return {
                                    "type": "FLOW",
                                    text: a?.text,
                                    "flow_id": a?.flowId,
                                    navigate_screen: a?.screenName,
                                    "flow_action": "NAVIGATE"
                                }
                            default:
                                return a;
                        }
                    });
                    components.push({type: "Buttons", buttons: list});
                }
                let template = {name,footer, language, category,components};
                setTimeout(() => {
                    setIsAdding(false);
                    dispatch(ActionShowAlert({message: "Timeout", color: "red"}));
                }, 5000);
                const result = await RequestPost(`${CloudApi.templateCreate}/${sessionId}`,
                    {file: headerDetails?.file, link: headerDetails?.link, template,variableMap}
                ).catch(err =>{
                    setIsAdding(false);
                    dispatch(ActionShowAlert({message: "Something went wrong", color: "red"}));
                });

                if(result?.status === 1){
                    onClose()
                    dispatch(ActionShowAlert({message: "Created Successfully"}));
                }else if(result?.status === 0){
                    setError(result?.message);
                }else{
                    dispatch(ActionShowAlert({message: "Something went wrong", color: "red"}));
                }
                setIsAdding(false);
            }else{
                setIsError(true);
            }
    }

    const addButton = () =>{
        setButtons([...buttons, {type: "QUICK_REPLY", text: ""}]);
    }
    const removeButton = (i) =>{
        const b = buttons?.filter((a,index) => index !== i);
        setButtons(b);
    }
    const changeButtonValues = (i, name,value) =>{
        const b = buttons?.map((a,index) => index === i ? {...a, [name]:value} : a);
        setButtons(b);
    }

    return  <Modal opened={true} size={"95%"} title={<b>{cloneId ? "Clone" : "Create"} Template</b>} onClose={onClose}>
            <PageLoading isLoading={isLoading}>
                <ScrollArea type={"auto"} sx={{height: 450, width: "100%"}}>
                    <Grid>
                        <Grid.Col span={8}>
                            <Grid px={10}>
                                <Grid.Col span={3}>
                                    <Select label={"Category"} data={WBASelects.tempCategory} value={category} onChange={setCategory} />
                                </Grid.Col>
                                <Grid.Col span={3}>
                                    <Select label={"Language"} data={WBASelects.language} value={language} onChange={setLang} searchable />
                                </Grid.Col>
                                <Grid.Col span={3}>
                                    <TextInput label={"Name"} value={name} onChange={(e) => setName(e.target.value)} error={isError && name?.trim() === "" ? "Name is Required ": ""}/>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Group>
                                        <Text weight={"bold"} size={"md"}>Header</Text>
                                        <Group my={3}>{["NONE","TEXT","IMAGE","VIDEO","DOCUMENT"]?.map(a => <IconWithText onClick={() => setType(a)} icon={type === a ? <IconCircleCheck size={14} color={Colors.Primary} /> : <IconCircle color={Colors.Primary} size={14} />} text={a} textProps={{size: "sm"}} />)}</Group>
                                    </Group>
                                    {type === "TEXT" &&  <Group>
                                        <TextInput
                                            size={"xs"}
                                            sx={{width: "80%"}}
                                            placeholder={"Header Message"}
                                            value={text}
                                            onChange={e => setText(e.target.value)}
                                            rightSection={<Text color={"dimmed"} size={"xs"}>{`${text?.length || 0}/60`}</Text>}
                                            error={isError && text?.trim() === "" ? "Message is Required For Text Type": ""}
                                        />
                                        <Button variant={"outline"} compact disabled={findVariables(text)?.length > 0} onClick={() => setText(`${text}{{1}}`)}>Add Variable</Button>
                                    </Group>}
                                    {type === "TEXT" &&  findVariables(text)?.length > 0  && <>
                                        <Text weight={"bold"} mt={10} size={"sm"}>Sample Content For Variables</Text>
                                        <Group>
                                            {findVariables(text)?.map((a) => <TextInput variant={"filled"}  size={"sm"} placeholder={`Sample Content For {{${a}}}`} value={variables?.[a]} onChange={e => setVariables({...variables,[a]: e.target.value})} error={isError && variables?.[a]?.trim()=== ""? "This field is Required":""} />)}
                                        </Group>
                                    </>}
                                    {!["NONE","TEXT"]?.includes(type) && <>
                                        {isError && (link?.trim() === "" && !file) && <Text size={"xs"} color={"red"}>File or File Link is Required</Text>}
                                        <Group position={"apart"} my={5}>
                                            <TextInput
                                                size={"xs"}
                                                sx={{width: "60%"}}
                                                placeholder={"Enter Link for File"}
                                                value={link}
                                                onChange={e => setLink(e.target.value)}
                                                rightSection={<Text color={"dimmed"} size={"xs"}>{`${link?.length || 0}/60`}</Text>}
                                            />
                                            <Text>OR</Text>
                                            <Group>
                                                {file && <Group>
                                                    <Text size={"sm"} variant={"gradient"} weight={"bold"}>{file?.name}</Text>
                                                    <IconView iconType={"delete"} onClick={()=> setFile()}/>
                                                </Group>}
                                                <FileButton onChange={handleFile} accept={FileOptions?.[type]?.accept}>
                                                    {(props) => <Button compact variant={"outline"} sx={{cursor: "pointer",color: Colors.Primary}} {...props}>
                                                        {file ? FileOptions?.[type]?.edit : FileOptions?.[type]?.create}
                                                    </Button>}
                                                </FileButton>
                                            </Group>

                                        </Group>
                                    </>}
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Group position={"apart"} mb={2}>
                                        <BoldText text={"Message"} />
                                        <SmallText text={`${message?.length}/1024`} color={message?.length < 1024 ? "dimmed": "red"} />
                                    </Group>
                                    <Textarea minRows={6} maxRows={12}
                                              variant={"filled"}
                                              value={message}
                                              onChange={(e) => setMessage(e.target.value?.slice(0,1024))}
                                              error={isError && message?.trim() === "" ? "Message is Required": ""}/>
                                    <Group position={"apart"}>
                                        <Text size={"xs"} color={"dimmed"}>{`Add Variables Like {{1}} {{2}}... and Add Example Values For those`}</Text>
                                    </Group>
                                    <Grid>
                                        {findVariables(message)?.map((a,i) =>{
                                            return <Grid.Col span={4}>
                                                <TextInput
                                                    label={`Sample Value for {{${a}}}`}
                                                    variant={"filled"}
                                                    value={bodyFields?.[a]}
                                                    onChange={e => setBodyFields({...bodyFields,[a]: e.target.value})}
                                                    error={isError && bodyFields?.[a]?.trim() === ""? "This field is Required":""}
                                                />
                                            </Grid.Col>
                                        })}
                                    </Grid>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <TextInput variant={"filled"} size={"xs"} label={"Footer"} value={footer} onChange={(e) => setFooter(e.target.value)} maxLength={60} rightSection={<Text size={"xs"} color={"dimmed"}>{footer?.length}/60</Text>} />
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Button compact onClick={addButton} disabled={buttons?.length >= 10} variant={"gradient"} leftIcon={<IconPlus/>}>Add Buttons</Button>
                                </Grid.Col>
                                {buttons?.map((a,i) =>{
                                    const types = buttons?.map(b => b?.type);
                                    const remove = [];
                                    if(types?.includes("PHONE_NUMBER") && a?.type !== "PHONE_NUMBER") remove.push("PHONE_NUMBER")
                                    if(types?.includes("FLOW") && a?.type !== "FLOW") remove.push("FLOW")
                                    if(types?.includes("COPY_CODE") && a?.type !== "COPY_CODE") remove.push("COPY_CODE")
                                    if(types?.includes("URL") && types?.filter(b => b === "URL")?.length > 1 && a?.type !== "URL") remove.push("URL")

                                    return  <Grid.Col span={12}>
                                        <Paper withBorder p={2} sx={{backgroundColor: "#ccc"}}>
                                            <Group>
                                                <Select size={"xs"} data={WBASelects.templateButtons?.filter(b => !remove.includes(b?.value))} value={a?.type} onChange={(value) => changeButtonValues(i, "type", value)} />
                                                {["QUICK_REPLY","URL","PHONE_NUMBER","FLOW"].includes(a?.type) && <TextInput size={"xs"} placeholder={"Button Text"} value={a?.text} onChange={(e) => changeButtonValues(i, "text", e.target.value)} />}
                                                {["URL"].includes(a?.type) && <>
                                                    <Select size={"xs"} placeholder={"Url Type"} data={["Static","Dynamic"]?.map(a =>({label: a,value:a}))} value={a?.urlType} onChange={(value) => changeButtonValues(i, "urlType", value)} />
                                                    <TextInput size={"xs"} placeholder={"Url"} description={"For Dynamic Url add {{1}}"} onChange={(e) => changeButtonValues(i, "value", e.target.value)}  value={a?.value} />
                                                    {a?.urlType === "Dynamic" &&  <TextInput size={"xs"} placeholder={"Example Field"} onChange={(e) => changeButtonValues(i, "example", e.target.value)}  value={a?.example} />}
                                                </>}
                                                {["PHONE_NUMBER"].includes(a?.type) &&  <TextInput size={"xs"} placeholder={"Number with country code"} value={a?.mobile} onChange={(e) => changeButtonValues(i, "mobile", e.target.value)} />}
                                                {["COPY_CODE"].includes(a?.type) &&  <TextInput size={"xs"} placeholder={"Enter Coupon Code to Copy"} value={a?.coupon_code}  onChange={(e) => changeButtonValues(i, "coupon_code", e.target.value)}/>}

                                                {["FLOW"].includes(a?.type) && <AddFlowButton sessionId={sessionId} value={a} onChange={changeButtonValues} index={i} />}

                                                <IconView iconType={"delete"}  onClick={() => removeButton(i)} label={"Delete Button"} />
                                            </Group>
                                        </Paper>
                                    </Grid.Col>
                                })}
                                <Grid.Col span={12}>
                                    <Text color={"red"}>{error}</Text>
                                </Grid.Col>
                                <Grid.Col span={12}>
                                    <Group position={"center"}>
                                        <Button variant={"gradient"} onClick={PrepareComponents} loading={isAdding}>Submit Approval</Button>
                                    </Group>
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>
                        <Grid.Col span={4}>
                            <MessageView header={{type,file,text,link,variables}} message={message} bodyFields={bodyFields}  footer={footer} buttons={buttons} />
                        </Grid.Col>
                    </Grid>
                </ScrollArea>
            </PageLoading>
        </Modal>
}


const AddFlowButton = ({value, onChange, index,sessionId}) =>{
    const [isLoading,setIsLoading] = useState(false);
    const [flows,setFlows] = useState([]);
    const [screens,setScreens] = useState([]);
    useEffect(() =>{
        getFlows();
    },[]);

    const getFlows = async () =>{
        setIsLoading(true);
        const data = await RequestGet(`${CloudApi.whatsappFlows}/list/${sessionId}`);
        setFlows(data?.flows);
        setIsLoading(false);
    }
    const getFlowScreens = async (flowId) =>{
        const data = await RequestGet(`${CloudApi.whatsappFlows}/screens/${sessionId}/${flowId}`);
        setScreens(data?.screens || []);
    }
    const onChangeFlow = (value) =>{
        onChange(index, "flowId", value);
        getFlowScreens(value);
    }

    return <>
        {isLoading ? <Loader /> : <>
            <Select size={"xs"} data={flows?.map(a => ({label: a?.name,value: a?.id}))} placeholder={"Select Form"} value={value?.flowId} onChange={onChangeFlow} />
            <Select size={"xs"} data={screens?.map(a => ({label: a,value: a}))} placeholder={"Select Form Screen"} value={value?.screenName} onChange={(a) => onChange(index, "screenName", a)} />
        </>}
     </>
}
export default CreateTemplate;
const MessageView = ({header,message,bodyFields,footer,buttons}) =>{
    const renderHeader = () =>{
        switch (header?.type){
            case "NONE":
                return <></>;
            case "TEXT":
                const vari = findVariables(header?.text);
                let text = header?.text || "";
                if(vari?.length > 0 && header?.variables?.["1"]){
                    text = text?.replace("{{1}}",header?.variables?.["1"])
                }
                return <Text size={"xs"} weight={"bold"}>{text}</Text>;
            default:
                return <Paper sx={{backgroundColor:"#ccc",alignItems: "center",justifyContent: "center"}}>
                        <Group my={40} align={"center"} position={"center"}>
                            {header?.type === "IMAGE" && <IconPhoto size={"50"} color={"white"}/>}
                            {header?.type === "VIDEO" && <IconVideo size={"50"} color={"white"}/>}
                            {header?.type === "DOCUMENT" && <IconFile size={"50"} color={"white"}/>}
                        </Group>
                    </Paper>
        }
    }
    const renderButtons = () =>{

        return  <Group>
            {buttons?.slice(0,3)?.map((a,i) =>{
                if(i === 2 && buttons?.length > 3){
                    return <Button sx={{width: "40%"}} variant={"outline"} compact leftIcon={<IconList />}>See All Options</Button>
                }
                switch (a?.type){
                    case "QUICK_REPLY":
                        return <Button sx={{width: "40%"}} variant={"outline"} compact leftIcon={<IconArrowForward />}>{a?.text}</Button>
                    case "URL":
                        return <Button sx={{width: "40%"}} variant={"outline"} compact leftIcon={<IconLogout />}>{a?.text}</Button>
                    case "PHONE_NUMBER":
                        return <Button sx={{width: "40%"}} variant={"outline"} compact leftIcon={<IconPhoneCall />}>{a?.text}</Button>
                    case "COPY_CODE":
                        return <Button sx={{width: "40%"}} variant={"outline"} compact leftIcon={<IconCopy />}>{a?.text}</Button>
                    case "FLOW":
                        return <Button sx={{width: "40%"}} variant={"outline"} compact>FLOW: {a?.text}</Button>
                    default:
                        return <></>
                }
            })}
        </Group>

        for(let i = 0; i< buttons?.length; i++ ){
            const {type,text} = buttons?.[i];
            switch (type){
                case "QUICK_REPLY":
                    return <Button variant={"outline"} compact leftIcon={<IconArrowForward />}>{text}</Button>
                case "URL":
                    return <Button variant={"outline"} compact leftIcon={<IconLogout />}>{text}</Button>
                case "PHONE_NUMBER":
                    return <Button variant={"outline"} compact leftIcon={<IconPhoneCall />}>{text}</Button>
                case "COPY_CODE":
                    return <Button variant={"outline"} compact leftIcon={<IconCopy />}>{text}</Button>
                default:
                    return <></>
            }
        }

    }
    const renderBody = () =>{
        const vari = findVariables(message);
        let text = message || "";
        vari?.map(a =>{
            if(bodyFields?.[a]) text = text?.replace(`{{${a}}}`,bodyFields?.[a])
        });
        return <>{text?.split("\n")?.map(a => <SmallText text={a} />)}</>
    }

   return <Paper withBorder shadow={"md"} p={10} mt={25}>
        <Stack sx={{gap: 3}}>
            <Text weight={"bold"} variant={"gradient"} size={"md"}>Preview</Text>
            {renderHeader()}
            {renderBody()}
            {footer && <LightText text={footer} />}
            {buttons?.length > 0 && renderButtons()}
        </Stack>
    </Paper>

}

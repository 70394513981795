import React, {forwardRef, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ActionShowAlert} from "../../../store/actions/alert";
import {Button, CopyButton, Divider, Grid, Group, Loader, Select, Text} from "@mantine/core";
import ResponseView from "./ResponseView";
import IntegrationDrawer from "../IntegrationDrawer";
import {CloudApi, RequestPost} from "../../../Layouts/RequestManager";
import {socket} from "../../Alerts/Socket";
import {FlowWebhookButton} from "../../Components/PageComponents";
import {IconPlugConnected} from "@tabler/icons";

const RazorPay = ({props,value}) =>{
    const app ='razorpay';
    const [connId,setConnId] = useState(value?.connId || undefined);
    const [openConn,setOpenConn] = useState(false);
    const [waitingWH,setWaitingWh] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [sampleResponse,setSampleResponse] = useState(value?.webhookRes || undefined)
    const dispatch = useDispatch();
    const {userId,sessionId} = useSelector(state => state.active);

    const updateConnection = (conn) =>{
        setConnId(conn);
        setOpenConn(false);
        setWaitingWh(false);
    }
    const handleSaveFlow = async () =>{
        setIsLoading(true);
        const flow = {app,connId};
        const data = await RequestPost(`${CloudApi.bsmFlows}/saveDetails/${sessionId}`,{flow,step: props?.step, id: props?.id,isWebhookListen: true});
        dispatch(ActionShowAlert({message: data?.message}));
        triggerWebhookListener();
    }
    const triggerWebhookListener = async () =>{
        setWaitingWh(true);
        await RequestPost(`${CloudApi.bsmFlows}/WebhookListen/${sessionId}`,{step: props?.step, id: props?.id});
        socket.on(`Flow${props?.id}_STEP${props?.step}`, handleWebhook);
    }
    const handleWebhook = (data) =>{
        dispatch(ActionShowAlert({message: "Webhook Captured", color: "green"}));
        setSampleResponse(data);
        setWaitingWh(false);
    }

    return <>
        {openConn && <IntegrationDrawer app={'razorpay'} connection={connId} updateConnection={updateConnection} onClose={() => setOpenConn(false)}/>}
        <Grid.Col span={3}>
            <FlowWebhookButton id={props?.id} />
        </Grid.Col>
        {app && !connId && <Grid.Col span={3} mt={25}>
            <Button onClick={() => setOpenConn(true)} variant={'outline'} leftIcon={<IconPlugConnected />}>Connect With Razorpay</Button>
        </Grid.Col>}
        {connId && <>
            <Grid.Col span={3}>
                <Button mt={25} color={'green'} onClick={() => setOpenConn(true)} leftIcon={<IconPlugConnected />}>Connected</Button>
            </Grid.Col>
            <Grid.Col span={6}>
                <Group position={'apart'} my={15}>
                    {!waitingWH ? <Button onClick={handleSaveFlow} loading={isLoading} variant={'gradient'}>Save & Send Request</Button> :
                        <Button loading={true}>Waiting For Webhook</Button>}
                </Group>
            </Grid.Col>
        </>}
        {sampleResponse && <ResponseView response={sampleResponse} onRefresh={triggerWebhookListener}/>}
    </>
}
export default RazorPay;

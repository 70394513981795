import React, {useImperativeHandle, forwardRef, useState, useEffect} from "react";
import {
    Grid, Group,
    MultiSelect,
    Select,
    Text,
    Textarea,
} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import * as XLSX from "xlsx";
import _ from "underscore";
import NumberSelectView from "./NumberSelectView";
import {PlanStatus} from "../../Constants/Strings";
import {CheckBoxView} from "../Components/PageComponents";

const importTypes = [
    {value: 'Tags', label: 'Tags'},
    {value: 'Excel', label: 'Excel'},
    {value: 'Numbers', label: 'Numbers'},
]
const PickNumbers =  forwardRef(({details}, ref)=>{
    const {r365} = useSelector(state => state.active);
    const [type,setType] = useState("Excel");
    const types = r365?.productId?.length > 0 ? [...importTypes, {label: "R365", value: "R365"}]: importTypes;
    const [selected,setSelected] = useState([]);
    const [selectedTags,setSelectedTags] = useState([]);
    const [list,setList] = useState([]);
    const [headers,setHeaders] = useState([]);
    const [status,setStatus] = useState([]);
    const [sessionActive,setSessionActive] = useState(false);

    const [error,setError] = useState([]);
    const [isError,setIsError] = useState(false);



    useEffect(() =>{
        if(details){
            setType(details?.type);
            setSelected(details?.selected);
            setList(details?.list || []);
            setHeaders(details?.headers || []);
            setSelectedTags(details?.selectedTags || []);
            setStatus(details?.status || []);
            setSessionActive(details?.sessionActive || false);
        }
    },[details]);


    // const [status,setStatus] = useState([]);
    // const [tags,setTags] = useState([]);
    useImperativeHandle(ref, () => ({
        isValid() {
            let isValid = false;
            let users = undefined;
            let userDetails = undefined;
            switch (type){
                case 'ContactFolder': {
                    const numbers = selected.map(a => ({id: a,label: contactFolders?.find(b => b.value === a)?.label}));
                    users = {type,numbers,headers: ['mobile','name'], id: selected, count:selected.length };
                    userDetails = {type, selected};
                    isValid = selected.length > 0;
                    break;
                }
                case 'Numbers': {
                    const numbers = selected.filter(a => a?.trim() != '');
                    users = {type,headers: ['mobile'], numbers,count: numbers.length}
                    userDetails = {type, selected: numbers}
                    isValid = numbers.length > 0;
                    break;
                }
                case 'Excel': {
                    const numbers = list.filter(a => selected.includes(a?.mobile)).map(a => ({
                        ...a,
                        id: `${a?.mobile}@s.whatsapp.net`,
                    }));
                    users = {type, numbers,headers, count: numbers?.length};
                    userDetails = {type,list,headers,selected};
                    isValid = numbers?.length > 0;
                    break;
                }
                case 'Tags': {
                    users = {type, tags: selectedTags, count: selectedTags?.length};
                    userDetails = {type, selectedTags};
                    isValid = selectedTags?.length > 0;
                    break;
                }
                case 'R365': {
                    users = {type, status,sessionActive, label: `${status?.map(a => PlanStatus?.[a])?.join(",")} ${sessionActive ? "( SessionActive )": "( All )"}`};
                    userDetails = {type,status,sessionActive};
                    isValid = status?.length > 0;
                  break;
                }
            }
            setError(!isValid);
            return {isValid,userDetails,users};
        }
    }));
    const {contactFolders,programs,ProGroups,ProTags,tags} = useSelector(state => state.active);
    const handleUpload = (e) => {
        console.log('upload calling');
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            let result = XLSX.utils.sheet_to_json(ws, {header:1});

            const headers = result[0]?.map(a => a.trim());
            const jsonData = result.slice(1).reduce((acc, curr) => {
                const obj = {};
                headers.forEach((header, i) => {
                    obj[header] = curr[i]?.toString() || "";
                });
                acc.push(obj);
                return acc;
            }, []);
            let error = [];
            if(!headers.includes('mobile')) error.push('mobile Required');
            if(error.length > 0){
                // this.setState({error});
                setError(error);
            }else{
                const excelContacts = jsonData.filter(a => ![null,undefined,''].includes(a?.mobile));
                const selected = _.unique(_.pluck(excelContacts,'mobile'));
                setList(excelContacts);
                setSelected(selected);
                setHeaders(headers);
                setError([])
                // this.setState({excelContacts,excelHeaders: headers, ExSelected: selected,fileUploaded: true,error: []});
            }
        };
        reader.readAsBinaryString(f)
    }
    const handleTypeChange= (type) =>{
        setError([]);
        setSelected([]);
        setList([]);
        setHeaders([]);
        setType(type)
    }
    return <>
        <Grid.Col span={4}>
            <Select
                label="Import From"
                placeholder="Import From"
                required
                value={type}
                data={types}
                onChange={handleTypeChange}
            />
        </Grid.Col>

        {type === 'Tags' && <Grid.Col span={4}>
            <MultiSelect
                label="Tags"
                placeholder="Pick Tags Here"
                data={tags}
                required
                multiline
                maxRows={4}
                fullWidth
                value={selectedTags}
                onChange={setSelectedTags}
                error={isError && selectedTags?.length === 0? 'Tags is Required': ''}
        /></Grid.Col>}
        {type === 'Numbers' && <Grid.Col span={4}><Textarea
            id="outlined-textarea"
            label="Numbers (Format : +919876543210)"
            placeholder="Enter Numbers Here"
            required
            multiline
            maxRows={4}
            fullWidth
            value={selected?.join("\n")}
            name={'numbers'}
            onChange={(e) => setSelected(e.target.value?.split("\n"))}
            helperText={"Numbers in one per line format"}
            error={isError && selected?.length === 0? 'Numbers is Required': ''}
        /></Grid.Col>}
        {type === 'ContactFolder' && <NumberSelectView error={error}  data={contactFolders} selected={selected} maxLimit={3} updateValues={setSelected}  />}
        {type === 'Excel' && <Grid.Col span={8}>
            <Text weight={'bold'}>Choose File</Text>
            <input type="file" onChange={handleUpload} style={{"padding": "10px"}}/>
            <a href={`https://coach.businesssalesmachine.com:3002/api/download-sample/contactPicker`}>Download Sample Excel</a>
            <Text color={'yellow'}>Use <b>mobile</b> for Mobile Number as Header in Excel</Text>
            {error?.length > 0 && <Text color={'red'}> Errors: {error.map(a => <div>{a}</div>)}</Text>}
        </Grid.Col>}
        {type === 'Excel' && <NumberSelectView showValue={true} error={error} data={list?.map(a => ({label: a?.name, value: a?.mobile}))} selected={selected}  updateValues={setSelected}  />}

        {type === 'R365' &&  <>

            <Grid.Col span={4}>
                <MultiSelect
                    label="Choose Status"
                    placeholder="Choose Status"
                    value={status}
                    data={PlanStatus?.map((a,i) => ({label: a, value: i}))}
                    searchable={true}
                    onChange={setStatus}
                    error={isError && status?.length === 0 ? 'Status is Required': ''}
                />
            </Grid.Col>
            <Grid.Col span={4}>
                <Group mt={25}>
                    <CheckBoxView isSelected={sessionActive} text={"Send Only to Session Active"} onClick={setSessionActive} />
                </Group>
            </Grid.Col>


            </>}


        {/*{type === 'Programs' && selected?.length > 0 && <>*/}
        {/*    <Grid.Col span={4}>*/}
        {/*        <MultiSelect*/}
        {/*            label="Choose Status"*/}
        {/*            placeholder="Choose Status"*/}
        {/*            value={status}*/}
        {/*            data={ProGroups || []}*/}
        {/*            searchable={true}*/}
        {/*            onChange={setStatus}*/}
        {/*            error={isError && status.length === 0 ? 'Status is Required': ''}*/}
        {/*        />*/}
        {/*    </Grid.Col>*/}
        {/*    <Grid.Col span={4}>*/}
        {/*        <MultiSelect*/}
        {/*            label="Choose Tags"*/}
        {/*            placeholder="Choose Tags"*/}
        {/*            value={tags}*/}
        {/*            data={ProTags || []}*/}
        {/*            searchable={true}*/}
        {/*            onChange={setTags}*/}
        {/*            error={isError && tags.length === 0 ? 'Tags is Required': ''}*/}
        {/*        />*/}
        {/*    </Grid.Col>*/}
        {/*</> }*/}
        {/*{type === 'Programs' && <NumberSelectView error={error} data={programs} selected={selected} maxLimit={3} updateValues={setSelected}  />}*/}

    </>

});
export default PickNumbers;

import React, {useEffect, useState} from "react";
import {Group, Image, Loader, Paper, Stack} from "@mantine/core";
import {BoldText, SmallText} from "../Components/TextComponents";
import {IconView} from "../Components/PageComponents";
import {CloudApi, RequestGet} from "../../Layouts/RequestManager";
import {useSelector} from "react-redux";
import {getFileTypefromMime} from "../../Constants/Functions";
import {IconDownload} from "@tabler/icons";

const FilePreview = ({code}) =>{
    const {sessionId} = useSelector(state => state.active);

    const [isLoading,setIsLoading] = useState(false);
    const [isPreview,setIsPreview] = useState(false);
    const [details,setDetails] = useState();
    const [previewCode,setPreviewCode] = useState();

    useEffect(()=>{
        setDetails();
        setPreviewCode();
        getDetails();
    },[code])

    const getDetails = async () => {
        if (code && !code?.startsWith("http")) {
            setIsLoading(true);
            const details = await RequestGet(`${CloudApi.mediFileDetails}/${sessionId}/${code}`);
            setDetails(details?.file);
            setIsLoading(false);
        }
    }
    const getImagePreview = async (id) => {
        setIsPreview(true);
        const details = await RequestGet(`${CloudApi.mediaIdPreview}/${sessionId}/${id}`);
        setPreviewCode(details?.image);
        setIsPreview(false);
    }
    const getFileDownload = async (id) => {
        window.open(`${CloudApi.mediaIdDownload}/${sessionId}/${id}`)
    }

    return <>
        {isLoading ? <Loader size={"xs"} />: <>
            {details && <Stack p={5} sx={{border: "1px solid #ccc", borderRadius: 5}}>
                {details?.mediaLink ? <Image src={details?.mediaLink} height={100} fit={"contain"}/>:<Group position={"apart"}>
                    <BoldText text={getFileTypefromMime(details?.type)}/>
                    <Group>
                        <SmallText text={details?.name}/>
                        {details?.type?.includes("image/") &&
                            <IconView iconType={"view"} onClick={() => getImagePreview(details?.mediaId)}/>}
                        <IconView icon={<IconDownload/>} onClick={() => getFileDownload(details?.mediaId)}/>
                    </Group>
                </Group>}
                {(isPreview || previewCode) && <Group position={"center"}>
                    {isPreview ? <Loader size={"xs"}/> : previewCode &&
                        <Image src={previewCode} height={200} fit={"contain"}/>}
                </Group>}
            </Stack>}
        </>}
    </>
}

export default FilePreview;
import {Button, Divider, Drawer, Grid, Group, SegmentedControl, Select, Text, Textarea, TextInput} from "@mantine/core";
import React, {useRef, useState} from "react";
import {IconPlus, IconSquare, IconSquareCheck} from "@tabler/icons";
import MessageOptions from "../Schedule/MessageOptions";
import {CheckBoxView, IconView} from "../Components/PageComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {ActionShowAlert} from "../../store/actions/alert";
import {useDispatch} from "react-redux";
import {ErrorText} from "../Components/TextComponents";

const AddChatbot = ({details,onClose,sessionId,onRefresh}) =>{
    const [title,setTitle] = useState(details?.title || "");
    const [keys,setKeys] = useState(details?.keys || [{key:"",type:"exact",lower: true}]);
    const [isError,setIsError] = useState();
    const [error,setError] = useState();
    const [noMessage,setNoMessage] = useState(details?.noMessage ? details?.noMessage : false);
    const [isLoading,setIsLoading] = useState();
    const messageRef = useRef();
    const dispatch = useDispatch();

    const OnSubmit = async () =>{
        setError();
        let isValid = true;
        let message;
        if(!noMessage) {
            const result = messageRef?.current?.isValid();
            isValid = result?.isValid;
            message = result?.message;
        }
        if (isValid && title?.trim() !== '' && keys?.length > 0 && keys?.filter(a => a?.key?.trim() === "")?.length === 0) {
            setIsError( false);
            setIsLoading(true);
            const data = await RequestPost(`${CloudApi.chatbot}/create/${sessionId}`, {
                    id: details?.id,
                    title: title?.toUpperCase()?.trim(),
                    keys,
                    message,
                    noMessage
            });
            if(data?.status === 1){
                dispatch(ActionShowAlert({message: data?.message}))
                onClose();
                onRefresh();
                setIsLoading(false);
            }else{
                setError(data?.message);
                setIsLoading(false);
            }

        } else {
            setIsError(true);
        }
    }
    return <Drawer opened={true} size={"80%"} position={"right"} title={<Text mx={5}><b>Add Chatbot</b></Text>} onClose={onClose}>
            <Grid mx={5}>
                {error && <Grid.Col span={12}>
                    <ErrorText text={error}/>
                </Grid.Col>}
                <Grid.Col span={4}>
                    <TextInput size={"xs"} label={"Title"} required value={title}
                               onChange={(e) => setTitle(e.target.value)}
                        error={isError && title?.trim() === "" ? "Title is Required": ""}
                    />
                </Grid.Col>
                <Grid.Col span={4} pt={30}>
                    <CheckBoxView text={`Don't Send Message to Users`} isSelected={noMessage} onClick={setNoMessage} />
                </Grid.Col>
                {!noMessage && <MessageOptions ref={messageRef} details={details?.message?.editOptions} />}
                <ChatbotKeys keys={keys} setKeys={setKeys} isError={isError} />
                <Grid.Col span={12}>
                    <Group position={"center"} my={20}>
                        <Button loading={isLoading} fullWidth variant={"gradient"} onClick={OnSubmit}>Submit</Button>
                    </Group>
                </Grid.Col>
            </Grid>
    </Drawer>
}

export default AddChatbot;

const ChatbotKeys = ({keys,setKeys ,isError}) =>{

    const [renderCount,setRenderCount] = useState(0);
    const addTag = () => setKeys([...keys,{key:"",type:"exact",lower: true}])
    const DeleteTag = (i) => {
        let m = keys;
        m?.splice(i,1);
        setKeys(m);
        setRenderCount(renderCount+1);
    }
    const ChangeTag = (i,type,value) => {
        let m = [...keys.slice(0, i), {...keys[i],[type]: value}, ...keys.slice(i + 1)];
        setKeys(m);
        setRenderCount(renderCount+1);
    }

    return  <>
        <Grid.Col span={12}>
            <Group position={"apart"}>
                <Text weight={"bold"} size={"xl"} variant={"gradient"}>Keys</Text>
                {keys?.length < 10 && <Group position={'center'}>
                    <Button compact mt={10} onClick={addTag} variant={"gradient"} leftIcon={<IconPlus />}>Add New Key</Button>
                </Group>}
            </Group>
        </Grid.Col>
        {keys?.map((a,i) => {
            return <Grid.Col span={6}>
                <Group>
                    <TextInput size={"xs"} placeholder={"Tag"}  onChange={(e) => ChangeTag(i,"key",e.target.value)}
                               value={a?.key}
                               error={isError && a?.key?.trim() === "" ? "Tag is Required": ""}
                    />
                    <Select
                        size={"xs"}
                        placeholder={"Match"}
                        data={[
                            {label: "Start With", value: "start"},
                            {label: "End With", value: "end"},
                            {label: "Contains", value: "contains"},
                            {label: "Exact", value: "exact"},
                        ]}
                        onChange={(type) => ChangeTag(i,"type",type)}
                        value={a?.type}
                    />
                    <Group sx={{cursor: "pointer"}} onClick={() => ChangeTag(i,"lower",!a?.lower)}>
                        {a?.lower ? <IconSquareCheck/>: <IconSquare />} Case Insensitive
                    </Group>
                    <IconView iconType={'delete'} onClick={() => DeleteTag(i)} />
                </Group>
            </Grid.Col>
        })}
    </>
}

import {useEffect, useState} from "react";
import {
    Drawer,
    FileButton,
    Group,
    List,
    Button,
    Text,
    Grid,
    Paper,
    Modal,
    Stack,
    SegmentedControl, Pagination
} from "@mantine/core";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {IconView, PageLoading} from "../Components/PageComponents";
import {BoldText, SmallText} from "../Components/TextComponents";
import React from "react";
import {ActionShowAlert} from "../../store/actions/alert";
import AlertPopup from "../Alerts/AlertPopup";
import * as XLSX from "xlsx";
import _ from "underscore";
import NumberSelectView from "../Components/NumberSelectView";
import {FilledTextInput} from "../Sequence/CustomButton";
import {IconPlus} from "@tabler/icons";

const Contacts = ({onClose,details}) =>{

    const {sessionId,...active} = useSelector(state =>state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [contacts,setContacts] = useState([]);
    const [onDelete,setOnDelete] = useState();
    const [isAdd,setIsAdd] = useState();
    const [page,setPage] = useState(1);


    const dispatch = useDispatch();

    useEffect(() =>{
        getContacts();
    },[]);

    const getContacts = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.ContactFolders}/contacts/${sessionId}`, {folderId: details?.id});
        setContacts(data?.contacts);
        setIsLoading(false);
    }
    const DeleteContact = (mobile) =>{
        setOnDelete({
            title: "Delete Contacts",
            content: "It will Delete Contact. Are you sure?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () => {
                console.log(mobile,"delete")
                const data = await RequestPost(`${CloudApi.ContactFolders}/deleteNumber/${sessionId}`, {folderId: details?.id, mobile});
                dispatch(ActionShowAlert({message: data?.message, color: data?.status === 1? "green": "red"}));
                setOnDelete();
                getContacts();
            }
        })
    }
    return <Drawer onClose={onClose} opened={true} position={"right"}  size={"80%"} title={<BoldText text={details?.title} mx={10} size={"md"} />}>
        <PageLoading isLoading={isLoading}>
            {!isAdd ?  <Group position={"right"} mx={10}>
                <Button leftIcon={<IconPlus />} onClick={() => setIsAdd(true)}>Add Contacts</Button>
            </Group> : <UploadContacts onRefresh={getContacts} sessionId={sessionId} folderId={details?.id} onClose={() => setIsAdd(false)} />}
            {onDelete && <AlertPopup opened={true} {...onDelete} onClose={() => setOnDelete()} />}
            {!isAdd && <Grid mt={20} mx={10}>
                <Grid.Col span={12}>
                    <Group position={"apart"}>
                        <BoldText size={"md"} text={`Total Results:  ${contacts?.length}`} />
                        <Pagination size={"xs"} total={Math.ceil(contacts?.length/20)} page={page} onChange={setPage} />
                    </Group>
                </Grid.Col>
                {contacts?.slice((page-1) * 20, page * 20)?.map(a => {
                    return <Grid.Col span={3}>
                        <Paper shadow={"md"} p={5} withBorder>
                            <Group position={"apart"}>
                                <BoldText text={a?.name}/>
                                <Group>
                                    <SmallText text={a?.mobile}/>
                                    <IconView iconType={"delete"} onClick={() => DeleteContact(a?.mobile)}/>
                                </Group>
                            </Group>
                        </Paper>
                    </Grid.Col>
                })}
            </Grid>}
        </PageLoading>
    </Drawer>
}
export default Contacts;
const UploadContacts = ({onRefresh,sessionId,folderId, onClose}) =>{
    const [type,setType] = useState("Excel");
    const [isLoading,setIsLoading] = useState(false);
    const [data,setData] = useState([]);
    const [selected,setSelected] = useState([]);
    const [render,setRender] = useState(1);

    const handleUpload = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            let [title,...dataparsed] = XLSX.utils.sheet_to_json(ws, {header:1});
            const numberIndex = title.indexOf('mobile');
            const nameIndex = title.indexOf('name');
            let excelNumbers = dataparsed.map((a,i)=> {
                return {mobile: a?.[numberIndex]?.toString(),name: a?.[nameIndex]}
            });
            excelNumbers = excelNumbers.filter(a => ![null,undefined,''].includes(a?.mobile));
            const uniqueObjects = _.uniq(excelNumbers, false, obj => obj["mobile"]);
            setData(uniqueObjects?.map(a => ({label: a?.name, value: a?.mobile})));
            setSelected([]);

        };
        reader.readAsBinaryString(f)
    };

    useEffect(() =>{
        setSelected(type === "Manual" ? [{name: "",mobile: ""}]: [])
    },[type])
    const AddSelected = () => setSelected([...selected, {name: "", mobile: ""}]);
    const RemoveSelected = (index) => setSelected(selected?.filter((a,i) => i !== index));
    const onSelectedChange = (index,key,value) => {
        const s = selected;
        s[index] ={...s?.[index], [key]:value};
        setSelected(s);
        setRender(render+1);
    };
    const OnUpdateContacts = async () =>{
        setIsLoading(true);
        let numbers = selected;
        if(type === "Excel") numbers = data?.filter(a =>selected?.includes(a?.value)) ?.map(a => ({mobile: a?.value, name: a?.label}));
        numbers = numbers?.filter(a => a?.mobile?.trim() !== "");
        await RequestPost(`${CloudApi.ContactFolders}/addNumbers/${sessionId}`, {folderId, numbers});
        setIsLoading(false);
        onClose();
        onRefresh();
    }

    return <Paper shadow={"md"} withBorder p={5}>
        <Grid mx={10}>
        <Grid.Col span={6}>
            <SegmentedControl  data={["Excel","Manual"]?.map(a =>({label: a, value: a}))} value={type} onChange={setType} />
        </Grid.Col>
        {type === "Excel" && <>
            <Grid.Col span={6}>
                <Group position="left" style={{marginBottom: 5, marginTop: 10}}>
                    <Stack sx={{gap: 3}}>
                        <input type="file" onChange={handleUpload} style={{"padding": "10px"}}/>
                        <Text color={'orange'}>Use <b>name</b> for User Name,<b>mobile</b> for Mobile Number as Header
                            in Excel</Text>
                        <Text color={'dimmed'}>Duplicate Numbers Will Be Removed</Text>
                    </Stack>
                </Group>
            </Grid.Col>
            <NumberSelectView data={data} selected={selected} updateValues={setSelected}/>
        </>}
        {type === "Manual" && <>
            <Grid.Col span={6}>
                <Group position={"right"} mx={10}>
                    <Button disabled={selected?.length >= 10} onClick={AddSelected}>Add Numbers</Button>
                </Group>
            </Grid.Col>
            {selected?.map((a,i) => <Grid.Col span={6}>
                <Group position="left" style={{marginBottom: 5, marginTop: 10}}>
                    <FilledTextInput label={"Name"} value={a?.name} onChange={(value) => onSelectedChange(i,"name", value) } />
                    <FilledTextInput label={"Mobile"} value={a?.mobile} onChange={(value) => onSelectedChange(i,"mobile", value)} />
                    <IconView iconType={"delete"} onClick={() => RemoveSelected(i)} />
                </Group>
            </Grid.Col>)}
        </>}
         <Grid.Col span={12}>
            <Group position={"center"} mx={50} >
                <Button sx={{width: 400}} onClick={OnUpdateContacts} loading={isLoading} disabled={selected?.length === 0}>Upload</Button>
                <Button sx={{width: 400 ,backgroundColor: "#ccc"}} onClick={onClose} loading={isLoading}>Cancel</Button>
            </Group>
        </Grid.Col>
    </Grid>
    </Paper>
}

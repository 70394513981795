import React, {Component, useEffect, useState} from "react";
import {Modal, Text, TextInput, Button, Group, Table} from '@mantine/core';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import moment from "moment";
import {IconBuildingCommunity, IconToggleLeft, IconToggleRight} from "@tabler/icons";
import {Header, IconView, PageLoading, TableView} from "../Components/PageComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
const PageHeading = {
    title: "BSM Flows",
    subTitle: "You can Create Flows to Automate Your Integrations",
    buttons: [
        {type: "add", title: "Create Flow"},
        {type: "refresh", title: "Refresh"},
    ]
}

const FlowList = () =>{
    const [isLoading,setIsLoading] = useState();
    const {sessionId,alias} = useSelector(s => s.active);
    const [flows,setFlows] = useState([])
    const [isAdd,setIsAdd] = useState(false)
    const navigation = useNavigate();
    const dispatch = useDispatch();
    useEffect(() =>{
        getDetails();
    },[])

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.bsmFlows}/list/${sessionId}`,{});
        setFlows(data?.flows);
        setIsLoading(false);


    }

    const openDelete = (id) =>{
        dispatch(ActionShowPopup({
            title: "Delete Flow",
            content: "It will Delete The Flow and Not Recoverable.Are you sure want to Delete ?",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.bsmFlows}/delete/${sessionId}`,{id});
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
                getDetails();
            },
            successTitle:"Delete",
            cancelTitle: "Cancel"
        }))
    }
    const openLogs = (id) =>{
       navigation(`/device/${alias}/bsm-flow/logs/${id}`);
    }
    const openEdit = (id) =>{
        navigation(`/device/${alias}/bsm-flow/edit/${id}`);
    }

    const changeStatus = (id,status) =>{
        dispatch(ActionShowPopup({
            title: "Change status",
            content: status === 1 ? "It will Stop Processing Flow and WebHook Responses Will Be ignored. Are you sure?":"It will Start Listening this flow and Steps Will Be Executed. Are you sure?",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.bsmFlows}/changeStatus/${sessionId}`,{id,status:  status === 1 ? 0 : 1});
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
                getDetails();
            },
            successTitle:"Continue",
            cancelTitle: "Cancel"
        }))
    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: () => setIsAdd(true)},{onClick: getDetails}]} />
        {isAdd && <CreateFlow onClose={() => setIsAdd(false)} onRefresh={getDetails} />}
        <PageLoading isLoading={isLoading}>
            <TableView headers={["Title","Last triggered","Total Triggers","Created","Options"]}>
                {flows?.map(a => <tr>
                    <td width={"40%"}>{a?.title}</td>
                    <td></td>
                    <td></td>
                    <td>{moment.unix(a?.created).format('lll')}</td>
                    <td width={"20%"}>
                        <Group>
                            <IconView icon={a?.status === 0 ? <IconToggleLeft color={"red"} />: <IconToggleRight color={'green'} />} label={"Change Flow Status"} onClick={() => changeStatus(a?.id,a?.status)} />
                            <IconView label={"Edit Flow"} iconType={"edit"} onClick={() => openEdit(a?.id)} />
                            <IconView label={"Delete Flow"} iconType={"delete"} onClick={() => openDelete(a?.id)} />
                            <IconView label={"Logs"} icon={<IconBuildingCommunity />} onClick={() => openLogs(a?.id)} />
                        </Group>
                    </td>
                </tr>)}
            </TableView>
        </PageLoading>
    </>
}

export default FlowList;

const CreateFlow = ({onClose,onRefresh}) =>{
    const {sessionId,userId} = useSelector(s => s.active);

    const [title,setTitle] = useState("");
    const [flowError,setFlowError] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const createFlow = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.bsmFlows}/create/${sessionId}`,{title: title?.trim()?.toUpperCase(),userId});
        setIsLoading(false);
        if(data?.status === 1){
            dispatch(ActionShowAlert({message: data?.message}));
            onClose();
            onRefresh()
        }else{
            setFlowError(data?.message)
        }

    }

    return <>
        <Modal title={<Text weight={"bold"}>Create Flow</Text>} opened={true} onClose={onClose}>
            <TextInput required label={"Flow Name"} value={title} onChange={e => setTitle(e.target.value)} />
            {flowError && <Text color={"red"}>{flowError}</Text>}
            <Group position={'center'} my={20}>
                <Button disabled={title?.trim() === ""} onClick={createFlow} loading={isLoading} variant={'gradient'}>Create Flow</Button>
            </Group>
        </Modal>
    </>

}
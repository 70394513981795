export const ActionShowAlert = (alert) => (dispatch) => {
    dispatch({type: "showAlert",alert});
    setTimeout(() => {
        dispatch({type: "hideAlert"})
    }, alert?.delay || 3000);
};
export const ActionHideAlert = () => (dispatch) => {
    dispatch({type: "hideAlert"});
};

export const ActionShowPopup = (alert) => (dispatch) => {
    dispatch({type: "showPopup",alert});
};
export const ActionHidePopup = () => (dispatch) => {
    dispatch({type: "hidePopup"});
};
export const ActionQueueLength = (len) => (dispatch) => {
    dispatch({type: "queueLength",len});
};
export const ActionOptinQueueLength = (len) => (dispatch) => {
    dispatch({type: "optinQueueLength",len});
};
export const ActionConnectionChange = (sessionId,connection) => (dispatch) => {
    dispatch({type: "connectionChange",sessionId,connection});
};

import React, {useEffect, useRef, useState} from "react";
import {useMediaQuery, useViewportSize} from "@mantine/hooks";
import {
    Group,
    Image,
    Loader,
    Paper,
    Button,
    Stack,
    Text,
    Grid,
    Textarea,
    Divider,
    Modal,
    Badge,
    Select
} from "@mantine/core";
import {
    IconArrowBack,
    IconArrowBackUp, IconArrowForward, IconBrandWhatsapp,
    IconCheck,
    IconChecks, IconChevronLeft, IconClock,
    IconDownload,
    IconInfoCircle, IconList, IconLogout,
    IconToggleLeft,
    IconToggleRight, IconX
} from "@tabler/icons";
import moment from "moment";
import {IconView, PageLoading} from "../Components/PageComponents";
import {Colors} from "../../Constants/Colors";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import TemplatePreview from "../Templates/PreviewTemplate";
import {convertSecondsToHoursMinutes} from "../../Constants/Functions";
import MessageOptions from "../Schedule/MessageOptions";
import MessageView from "./MessageView";
const UserChatStatus = [
    {label: "NotAssigned", value: ""},
    {label: "Open", value: "open"},
    {label: "Closed", value: "close"},
]

const UserMessages = ({mobile,sessionId, onClose}) =>{

    const [isLoading,setIsLoading] = useState(false);
    const [isOldLoading,setIsOldLoading] = useState(false);
    const [messages,setMessages] = useState([]);
    const [noLoadMore,setNoLoadmore] = useState(false);
    const [token,setToken] = useState(undefined);
    const [sessionEnd,setSessionEnd] = useState(undefined);
    const [userMsg,setUserMsg] = useState(false);
    const [chatStatusLoading,setChatStatusLoading] = useState(false);
    const [chatStatus,setChatStatus] = useState("");
    const [reply,setReply] = useState();
    const scrollAreaRef = useRef();
    const {width,height} = useViewportSize();

    const scrollToBottom = () => {
        if (scrollAreaRef.current) {
            // Scroll to the bottom of the scroll area
            scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight;
        }
    }
    const onRefresh = () =>{
        getMessages();
        getUserStatus();
    }

    useEffect(() =>{
       onRefresh();
    },[mobile,userMsg])

    const getMessages = async ()=>{
        setIsLoading(true);
        setNoLoadmore(false);
        setMessages([]);
        const response = await RequestPost(`${CloudApi.InboxMessages}/${sessionId}`, {mobile,userMsg});
        setMessages(response?.messages?.reverse() || []);
        setNoLoadmore(response?.messages?.length < 50)
        setIsLoading(false);
        scrollToBottom();
    }
    const getOlderMessages = async  () => {
         setIsOldLoading(true);
         const response = await RequestPost(`${CloudApi.InboxMessages}/${sessionId}`, {mobile,msgLength: messages?.length});
         setMessages([...response?.messages?.reverse(), ...messages]);
         setIsOldLoading(false);
         setNoLoadmore(response?.messages?.length < 50)
    }
    const getUserStatus = async () =>{
        setChatStatusLoading(true);
        const response = await RequestGet(`${CloudApi.UserStatus}/${sessionId}/${mobile}`);
        setChatStatusLoading(false);
        setChatStatus(response?.chatStatus);
        setSessionEnd(response?.sessionEnd);
    }
    const handleChatStatus = async (status) =>{
        setChatStatusLoading(true);
        const response = await RequestGet(`${CloudApi.ChangeUserStatus}/${sessionId}/${status}/${mobile}`);
        setChatStatusLoading(false);
        setChatStatus(response?.status);
    }

    const isMobile = useMediaQuery('(max-width: 700px)');
    return <>
        <Paper shadow={"md"} withBorder p={10}>
            <Group position={"apart"}>
                <Group>
                    {isMobile && <IconView icon={<IconChevronLeft />} onClick={() => onClose(undefined)} />}
                    <Text weight={"bold"} variant={"gradient"} size={isMobile ? "sm": "md"}>{mobile}</Text>
                </Group>
                {!isMobile ?  <Group mx={10}>
                    <Group sx={{border: 1, gap: 2}} onClick={() => setUserMsg(!userMsg)}>
                        {!userMsg ? <IconToggleLeft color={"red"}/> : <IconToggleRight color={"green"}/>}
                        <Text>Only User Messages</Text>
                    </Group>
                    {sessionEnd && <SessionTimer time={sessionEnd}/>}
                    {chatStatusLoading ? <Loader size={"sm"}/> :
                        <Select size={"xs"} sx={{width: 120}} data={UserChatStatus} value={chatStatus}
                                onChange={handleChatStatus}/>}
                    <IconView iconType={"refresh"} label={"Refresh Messages"} onClick={onRefresh}/>
                </Group>: <Group mx={10}>
                    {sessionEnd && <SessionTimer time={sessionEnd}/>}
                    <IconView iconType={"refresh"} label={"Refresh Messages"} onClick={onRefresh}/>
                </Group>}
            </Group>
        </Paper>
        <PageLoading isLoading={isLoading}>
            <div ref={scrollAreaRef} style={{ overflowY: 'scroll', height: height - 220}}>
                {!noLoadMore && <Group position={"center"} sx={{cursor: "pointer", backgroundColor: "#ccc"}}>
                    {isOldLoading ? <Loader size={"xs"}/> :
                        <Text onClick={getOlderMessages} style={{color: Colors.Primary, textDecoration: "underline"}}>Load Previous Messages</Text>}
                </Group>}
                <MessageListView mobile={mobile} messages={messages} token={token} setReply={setReply} />
            </div>
            <div style={{height: 50}}>
                <SendMessage mobile={mobile} sessionId={sessionId} refresh={getMessages} isExpired={sessionEnd === undefined || sessionEnd <= moment().unix()} reply={reply} setReply={setReply} />
            </div>
        </PageLoading>
    </>
}
export default UserMessages;


const MessageListView = ({mobile,messages,token, setReply}) =>{
    const [details,setDetails] = useState();
    const isMobile = useMediaQuery('(max-width: 700px)');
    const renderStatus = (status, reason ="UnKnown") =>{
        switch (status){
            case 'triggered':
                return <IconClock size={"16"} />
            case 'sent':
                return <IconCheck size={"16"} />
            case 'delivered':
                return <IconChecks size={"16"} color={"lightgray"} />
            case 'read':
                return <IconChecks color={"green"} size={"16"} />
            case 'failed':
                return  <IconView icon={<IconInfoCircle size={"16"} color={"red"} />} label={reason} />
            case 'notSent':
                return  <IconView icon={<IconInfoCircle size={"16"} color={"red"} />} label={reason} />
            default:
                return <></>
        }
    }
    return <>
        {/*{details && <MessageFullView {...details} onClose={() => setDetails()} />}*/}
        {messages.map(a =>{
            return <Group position={a?.msgType === 1 ? "left" : "right"} my={5} onClick={() => setDetails({whatsappId: a?.whatsappId,messageId: a?.messageId})}>
                <Paper shadow={"md"} withBorder sx={{width: isMobile ? "80%": "40%",borderRadius: 5}} p={5}>
                    <MessageView message={a?.message} />
                    <Divider my={5} />
                    {a?.msgType === 0 && <Group position={"right"} sx={{gap: 1}} mx={2}>
                        <Text size={10} color={"dimmed"}>{moment.unix(a?.created).format("lll")}</Text>
                        {renderStatus(a?.status,a?.reason)}
                        {a?.messageId && <IconView icon={<IconArrowBackUp />} onClick={() => setReply({message: a?.message, message_id: a?.messageId})}/>}
                    </Group>}
                    {a?.msgType === 1 && <Group>
                        {a?.messageId && <IconView icon={<IconArrowBackUp/>} onClick={() => setReply(({message: a?.message, message_id: a?.messageId}))} />}
                        <Text size={"xs"} color={"dimmed"}>{moment.unix(a?.created).format("lll")}</Text>
                    </Group>}
                </Paper>

            </Group>
        })}
    </>
}
//
// const MessageFullView =  ({whatsappId,messageId, onClose}) =>{
//     const [details,setDetails] = useState()
//     const getDetails = async () => {
//         const details = await RequestGet(`${WBAUrls.msgDetails}/${whatsappId}/${messageId}`);
//         console.log("Details",details)
//         setDetails(details?.data)
//     }
//     useEffect(() =>{
//         if(messageId) getDetails();
//     },[messageId])
//     return <Modal opened={true} title={<b>Message Details</b>} size={"60%"} onClose={onClose}>
//         {details && <Grid>
//             <Grid.Col span={6}>
//                 <Paper shadow={"md"} withBorderp={5} p={5} sx={{backgroundColor: "#ccc"}}>
//                     <UserMessageView message={details?.message}/>
//                 </Paper>
//             </Grid.Col>
//             <Grid.Col span={6}>
//                 <Stack sx={{gap: 3}}>
//                     {Object.keys(details?.statuses)?.map(a => <Group position={"apart"}>
//                         <Badge>{a}</Badge>
//                         {moment.unix(details?.statuses?.[a]).format("lll")}
//                     </Group>)}
//                 </Stack>
//                 <Divider />
//                 <Text color={"red"}>{details?.reason}</Text>
//             </Grid.Col>
//         </Grid>}
//     </Modal>
// }
//
const SendMessage = ({mobile,sessionId,isExpired, reply,setReply}) =>{
    const [message,setMessage] = useState("");
    const [isSending,setIsSending] = useState(false);
    const [sendTemplate,setSendTemplate] = useState(false);
    const isMobile = useMediaQuery('(max-width: 700px)');
    const SendMessage = async () =>{
        setIsSending(true);
        const response = await RequestPost(`${CloudApi.sendMessageSingle}/${sessionId}`,{
            contact: {waId: mobile},
            message: {message, reply},
            type:"Inbox"
        });
        setIsSending(false);
        setMessage("");
        setReply();

    }
    return  <>
        <Divider mb={10} size={"md"} />
        {sendTemplate && <SendTemplate setReply={setReply} sessionId={sessionId} mobile={mobile} reply={reply} onClose={() => setSendTemplate(false)} />}
        {reply && <ReplyBar reply={reply} setReply={setReply} />}
        {!isExpired ? <Grid mx={10} style={{height: 30}}>
                <Grid.Col span={6}>
                    <Textarea size={"xs"} placeholder={"message"} value={message}
                              onChange={(e) => setMessage(e.target.value)} maxRows={1}/>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Group position={"apart"} mt={20}>
                        <Button compact disabled={message?.trim() === ""} onClick={SendMessage} loading={isSending}>Send Message</Button>
                        <Button compact leftIcon={<IconBrandWhatsapp />} onClick={() => setSendTemplate(true)}>Send Other Type</Button>
                    </Group>
                </Grid.Col>
            </Grid> : <Grid mx={10} style={{height: 30}}>
            {!isMobile && <Grid.Col span={8}>
                <Text><b>This is a broadcast only chat.</b>Until you receive a message from the customer, WhatsApp
                    allows only template messages to be sent in these chats.</Text>
            </Grid.Col>}
                <Grid.Col span={12} lg={4} xl={4} md={6} >
                    <Group position={'right'}>
                        <Button onClick={() => setSendTemplate(true)}>Select Template</Button>
                    </Group>
                </Grid.Col>
            </Grid>}
    </>
}
const ReplyMessageHeader = (message) =>{
    const {type,text,image,file,audio,video,interactive} = message;
    const renderInteractive = () => {
        const {header, body,footer,} = interactive;
        let  text = "";
        if(header?.text) text += `${header?.text}\n`;
        if(body?.text) text += `${body?.text}\n`;
        if(footer?.text) text += `${footer?.text}\n`;
        return text
    }
    switch (type){
        case 'text':
            return text?.body;
        case 'image':
            return image?.caption;
        case 'audio':
        case 'video':
        case 'document':
            return message?.[type]?.filename || type?.toUpperCase()
        case 'template':
            return message?.template?.name;
        case 'interactive':
            return renderInteractive()
        default:
            return type?.toUpperCase();
    }
}
const ReplyBar = ({reply,setReply}) =>{
    return <Grid.Col span={12}>
        <Paper shadow={"md"} sx={{backgroundColor: "#ccc"}}>
            <Group position={"apart"} mx={10}>
                <Group>
                    <Text size={"xs"} color={"dimmed"}>Reply :</Text>
                    <div className="clamped-text">
                        {ReplyMessageHeader(reply?.message)?.replaceAll("\n","  ")}
                    </div>
                </Group>
                <IconView icon={<IconX />} onClick={() => setReply()} />
            </Group>
        </Paper>
    </Grid.Col>
}
const SendTemplate = ({sessionId,mobile, onClose,reply,setReply}) =>{
    const messageRef = useRef();
    const [isLoading,setIsLoading] = useState(false);
    const sendMesage = async () =>{
        const {isValid,message} = messageRef.current.isValid();
        if(isValid){
            setIsLoading(true);
            if(message?.type === "sequence"){
                const response = await RequestGet(`${CloudApi.sequenceTrigger}/${sessionId}/${message?.seqId}/${mobile}/Inbox`);
            }else{
                const response = await RequestPost(`${CloudApi.sendMessageSingle}/${sessionId}`,{
                    contact: {waId: mobile},
                    message: {...message,reply},
                    type:"Inbox"
                });
            }
            setIsLoading(false);
            onClose();
        }
    }

    return <Modal size={"90%"} opened={true} title={<Text weight={"bold"}>Send Message</Text>} onClose={onClose}>
        <Grid>
            {reply && <ReplyBar reply={reply} setReply={setReply} />}
            <MessageOptions ref={messageRef} />
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={sendMesage} loading={isLoading}>Send Message</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}
const SessionTimer = ({time})=>{
    const [secs,setSecs] = useState();
    const calcSecs = ()=>{
        const t = time - moment().unix();
        setSecs(t);
    }
    useEffect(() => {
        calcSecs();
        const intervalId = setInterval(calcSecs, 30000);
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array ensures that it runs only once on mount

    return <>
        {secs > 0 ? <Badge color={"green"}>{convertSecondsToHoursMinutes(secs)}</Badge> : <Badge color={"red"}>expired</Badge>}
    </>
}


import {useEffect, useState} from "react";
import {AdminApi, CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {PageLoading, TableView, Header, IconView, CheckBoxView} from "../../Pages/Components/PageComponents";
import {
    Group,
    Pagination,
    TextInput,
    Text,
    Badge,
    Paper,
    Grid,
    Select,
    Drawer,
    Table,
    Loader,
    ScrollArea, Tabs,Button
} from "@mantine/core";
import moment from "moment";
import {DatePicker, DateRangePicker} from "@mantine/dates";
import React from "react";
import SessionDetails from "./SessionDetails";
import UserDetails from "./UserDetails";
import {IconUser} from "@tabler/icons";
import {PaymentPlanList} from "../../Constants/Strings";
const HealthStatus = {
    LIMITED: "orange",
    BLOCKED: "red",
}
const AdminSessions = () =>{
    const [isLoading,setIsLoading] = useState(false);
    const [isQueueLoading,setIsQueueLoading] = useState(false);
    const [sessions,setSessions] = useState([]);
    const [msgLogs,setMsgLogs] = useState([]);
    const [page,setPage] = useState(1);
    const [filtered,setFiltered] = useState([]);
    const [isUserView,setIsUserView] = useState();
    const [show,setShow] = useState();
    useEffect(() =>{
        getSessions();
        getDetails();
    },[])
    const  getSessions = async () =>{
        setIsLoading(true);
        const data = await RequestGet(AdminApi.sessions);
        setSessions(data?.sessions || []);
        setFiltered(data?.sessions || []);
        setIsLoading(false);

    }
    const getDetails = async () =>{
        setIsQueueLoading(true);
        const data = await RequestGet(AdminApi.schStats);
        setMsgLogs(data?.logs);
        setIsQueueLoading(false);

    }
    return <>
        <PageLoading isLoading={isLoading}>
            <Header title={"WBA Session List"} subTitle={"It will Show All WBA Sessions"}
                    buttons={[
                        {type: "refresh", title: "Refresh"},
                        {type: "refresh", title: "Sch Refresh"}
                    ]}
                    buttonProps={[
                        {onClick: getSessions},
                        {onClick: getDetails},
                    ]} />
            <SessionFilter setFiltered={setFiltered} sessions={sessions} setPage={setPage} msgLogs={msgLogs} />
            {show && <SessionDetails sessionId={show} onClose={() => setShow(undefined)} />}
            {isUserView && <UserDetails userId={isUserView} onClose={() => setIsUserView(undefined)} onRefresh={getDetails} />}
            <Group position={"apart"} my={10}>
                 <Text>Filtered results: {filtered?.length}/ {sessions?.length}</Text>
                <Pagination size={"xs"} page={page} onChange={setPage} total={Math.ceil(filtered?.length / 10)} />
            </Group>
            <TableView headers={["SessionId","DeviceName","Name","Email","Active Plan","Expiry","Connection","Health","Msg","Sch","options"]}>
                {filtered?.slice((page-1) * 10, page * 10)?.map(a =>{
                    const queue = msgLogs?.find(b => b?.sessionId === a?.sessionId)
                    return <tr>
                        <td>{a?.sessionId}</td>
                        <td>{a?.deviceName}</td>
                        <td>{a?.name}</td>
                        <td>{a?.email}</td>
                        <td>{a?.activePlans?.length > 0 ? <Badge color={"green"}>{a?.activePlans?.join(",")}</Badge>: <Badge color={"red"}>{a?.lastExpired}</Badge>}</td>
                        <td>{a?.expiry ? <Badge color={a?.expiry > moment().unix() ?"green":"red"}>{moment.unix(a?.expiry).fromNow()}</Badge>: <Badge color={"red"}>No Active Plan</Badge>}</td>
                        <td>{a?.connected?.waNumber ? <Badge color={"green"}>{a?.connected?.waNumber}</Badge>: <Badge color={"red"}> Not Connected</Badge>}</td>
                        <td><Badge color={HealthStatus?.[a?.health?.can_send_message] || "blue"}>{a?.health?.can_send_message || "Not Avialble"}</Badge></td>
                        <td>{queue?.queue || 0}</td>
                        <td>
                            <Group>
                                <Badge color={"orange"}>W:  {queue?.waiting || 0}</Badge>
                                <Badge color={"blue"}>S:  {queue?.sending || 0}</Badge>
                            </Group>
                        </td>
                        <td>
                            <Group>
                                <IconView iconType={"view"} label={"View Device"} onClick={() => setShow(a?.sessionId)} />
                                <IconView icon={<IconUser />} label={"View User"} onClick={() => setIsUserView(a?.userId)} />
                            </Group>
                        </td>
                    </tr>
                })}
            </TableView>
        </PageLoading>
    </>
}
export default AdminSessions;
const SessionFilter = ({sessions,setFiltered,setPage,msgLogs}) =>{
    const [active,setActive] = useState(false);
    const [inActive,setInActive] = useState(false);
    const [inQueue,setInQueue] = useState(false);
    const [waiting,setWaiting] = useState(false);
    const [isError,setError] = useState(false);
    const [search,setSearch] = useState('');
    const [status,setStatus] = useState('0');
    const [expiry,setExpiry] = useState(undefined);
    const [plan,setPlan] = useState("all");
    const [userType,setUserType] = useState("all");
    const [healthStatus,setHealthStatus] = useState("all");

    const handleActive = () =>setActive(!active)
    const handleInActive = () => setInActive(!inActive)
    const handleSearch = (e) =>{setSearch(e.target.value);}

    useEffect(() =>{
        handleFilter();
    },[active,search,isError,status,expiry,plan,inActive,healthStatus,inQueue,waiting, userType]);
    const handleFilter = () =>{
        let filtered = sessions;
        if(active) filtered = filtered.filter(a => !!a?.connected?.waNumber)
        if(inActive) filtered = filtered.filter(a => !a?.connected?.waNumber)
        if(inQueue) {
            const queueIds = msgLogs?.filter(a => a?.queue && a?.queue > 0)?.map(a => a?.sessionId);
            filtered = filtered.filter(a => queueIds?.includes(a?.sessionId));
        }
        if(waiting) {
            const queueIds = msgLogs?.filter(a => a?.waiting > 0 || a?.sending > 0)?.map(a => a?.sessionId);
            filtered = filtered.filter(a => queueIds?.includes(a?.sessionId));
        }
        if(userType !== "all"){
            filtered = filtered.filter(a => userType === "isOurs" ? a?.isOurs === true : a?.isOurs !== true);
        }


        switch(status){
            case '0':
            default:
                break;
            case '1':
                filtered = filtered?.filter(a => a?.status === 1)
                break;
            case '2':
                filtered = filtered?.filter(a => a?.status === 2)
                break;
            case '3':
                filtered = filtered?.filter(a => a?.status === 3)
                break;
        }
        if(healthStatus !== "all"){
            switch(healthStatus){
                case 'LIMITED':
                case 'BLOCKED':
                    filtered = filtered?.filter(a => a?.health?.can_send_message === healthStatus);
                    break;
                case 'NA':
                    filtered = filtered?.filter(a => !a?.health?.can_send_message)
                    break;
            }
        }
        if(expiry?.length === 2){
            const [start,end] = expiry;
            const startDate = moment(start).startOf('day').unix();
            const endDate = moment(end).endOf('day').unix();
            filtered = filtered.filter(a => a?.expiry >= startDate && a?.expiry <= endDate);
        }
        if(search !== ''){
            const value = search?.toLowerCase();
            filtered = filtered.filter(a => a?.name?.toString()?.toLowerCase()?.includes(value) || a?.email?.toLowerCase()?.includes(value)|| a?.sessionId?.toLowerCase()?.includes(value)||a?.userId?.toLowerCase()?.includes(value))
        }
        if(plan != "all"){
            filtered = filtered.filter(a => a?.activePlans?.includes(plan) || (a?.activePlans?.length === 0  && a?.lastExpired === plan) )
        }
        setFiltered(filtered);
        setPage(1);
    }


    return  <Paper shadow={"md"} withBorder>
            <Group m={5}>
                <TextInput
                    name={'search'}
                    variant="filled"
                    placeholder={'search'}
                    fullWidth
                    value={search}
                    onChange={handleSearch}
                    size={"xs"}
                />
                <Select size={"xs"} label={"Health Status"} value={healthStatus} onChange={setHealthStatus} data={[{label: "All",value: "all"},{label: "Limited",value: "LIMITED"},{label: "Blocked",value: "BLOCKED"},{label: "NotAvail",value: "NA"}]} />
                <CheckBoxView text={"Whatsapp Active"} isSelected={active} onClick={handleActive} />
                <CheckBoxView text={"Whatsapp InActive"} isSelected={inActive} onClick={handleInActive} />

                <Select size={"xs"} label={"User Type"}
                        value={userType}
                        onChange={setUserType}
                        data={[{label: "All",value: "all"},{label: "IsOurs",value: "isOurs"},{label: "Paid",value: "Paid"}]} />
                <Select size={"xs"} label={"User Plan"}
                        value={plan}
                        onChange={setPlan}
                        data={[{label: "All",value: "all"},...PaymentPlanList]} />

                  <DateRangePicker my={5}  size={"xs"} label={"Expiry Date"} value={expiry} onChange={setExpiry} allowSingleDateInRange={true} />
                <CheckBoxView text={"Msg in Queue"} isSelected={inQueue} onClick={() => setInQueue(!inQueue)} />
                <CheckBoxView text={"Schedules in Queue"} isSelected={waiting}  onClick={() => setWaiting(!waiting)}  />
            </Group>
    </Paper>
}


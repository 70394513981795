import React, {useEffect, useState} from "react";
import {Header, IconView, PageLoading, PrefCountView} from "../Components/PageComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {Group, Modal, TextInput, Button, Grid,Paper} from "@mantine/core";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {BoldText, ErrorText} from "../Components/TextComponents";
import Attachments from "./Attachments";
import {showMaxAlert} from "../../Constants/Functions";

const PageHeading = {
    title: "Attachments",
    subTitle: "You can Store your Files For Easy Access",
    buttons: [{type: "add", title: "Create Folder"},{type: "refresh", title: "Refresh"}]
}

const Folders = () => {
    const {sessionId,prefrences,prefUsed} = useSelector(state =>state.active)
    const [isLoading,setIsLoading] = useState(false);
    const [isCreate,setIsCreate] = useState(false);
    const [open,setOpen] = useState();
    const [folders,setFolders] = useState([]);
    const dispatch = useDispatch();

    useEffect(() =>{
        getFolders();
    },[])
    const getFolders = async() =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.Attachments}/folders/${sessionId}`, {});
        setFolders(data?.folders);
        setIsLoading(false);
    }
    const DeleteFolder = async (folderId) =>{
        dispatch(ActionShowPopup({
            title: "Delete Folder",
            content: "It will Delete All Files In This Folder. You can't use it here after?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const data = await RequestPost(`${CloudApi.Attachments}/deleteFolder/${sessionId}`, {folderId});
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert({message: data?.message, color: data?.status === 1? "green": "red"}));
                getFolders();
            }
        }))
    }

    const handleCreate = () =>{
        if(prefrences?.folders > prefUsed?.folders){
            setIsCreate({})
        }else{
            showMaxAlert({dispatch, type: "Folders"})
        }
    }



    return <>
        <Header {...PageHeading} buttonProps={[{onClick: handleCreate },{onClick: getFolders}]} />
        <PageLoading isLoading={isLoading}>
            {isCreate && <CreateFolder details={isCreate} onClose={() => setIsCreate()} onRefresh={getFolders} />}
            {open && <Attachments details={open} onClose={() => setOpen()} onRefresh={getFolders} />}
            <PrefCountView type={"attachments"} />
            <Grid>
                {folders?.map(a => {
                    return <Grid.Col span={3}>
                        <Paper shadow={"md"} p={5} withBorder onClick={() => setOpen(a)}>
                            <Group position={"apart"}>
                                <BoldText text={a?.title} />
                                <Group onClick={(e) => {e.stopPropagation()}}>
                                    <IconView iconType={"edit"} label={"Edit"} onClick={() => setIsCreate(a)} />
                                    <IconView iconType={"delete"} label={"Delete"} onClick={() => DeleteFolder(a?.id)} />
                                </Group>
                            </Group>
                        </Paper>
                    </Grid.Col>
                })}
            </Grid>
        </PageLoading>
    </>
}

export default Folders;


const CreateFolder = ({details,onClose,onRefresh}) =>{
    const {sessionId} = useSelector(state =>state.active)
    const [title,setTitle] = useState(details?.title || "");
    const [isError,setIsError] = useState(false);
    const [error,setError] = useState();
    const [isLoading,setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleCreate = async () =>{
        if(title?.trim() !== ""){
            setIsLoading(true);
            setIsError(false);
            const data = await RequestPost(`${CloudApi.Attachments}/createFolder/${sessionId}`, {title, folderId: details?.id});
            if(data?.status === 1){
                onClose();
                onRefresh();
                dispatch(ActionShowAlert({message: data?.message}));
            }else{
                setError(data?.message);
            }
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }

    return <Modal title={<b>{details?.id ? "Edit" : "Create"} Folder</b>} opened={true} size={"40%"} onClose={onClose}>
        <TextInput label={"Title"} required value={title} maxLength={20} onChange={e => setTitle(e.target.value)} error={isError && title?.trim() === "" ? "Title is Required": ""} />
        {error && <ErrorText text={error} />}
        <Group position={"center"} mt={30}>
            <Button loading={isLoading} sx={{width: 200}} onClick={handleCreate}> {details?.id ? "Update" : "Create"} Folder </Button>
        </Group>
    </Modal>

}
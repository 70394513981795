import React, {useState,useEffect} from "react";
import {Group, Tabs, Paper, CopyButton, SegmentedControl, Button, Modal,Text} from "@mantine/core";
import {
    IconCopy, IconEdit,
    IconMessageCircle,
    IconPhoto,
    IconSend,
    IconSettings, IconSquare,
    IconSquareCheck,
    IconUser,
    IconUsers
} from "@tabler/icons";
import {useLocation, useParams} from "react-router-dom";
import {CheckBoxView, Header, PageLoading} from "../Components/PageComponents";
import OptinMessages from "./OptinMessages";
import DayMessages from "./DayMessages";
import ProgramSettings from "./ProgramSettings";
import Members from "./Members";
import ProgramSchedules from "./ProgramSchedules";
import moment from "moment";
import {Colors} from "../../Constants/Colors";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import ProgramCreate from "./ProgramCreateEdit";

const ProgramDetails = () =>{
    const {sessionId,userId} = useSelector(state => state.active);
    const {state} = useLocation();
    const [isLoading,setIsLoading] = useState(false);
    const programId = state?.id;
    const [title,setTitle] = useState(state?.title);
    const [details,setDetails] = useState();
    const [show,setShow] = useState(false);
    const [msgToday,setMsgToday] = useState(false);
    const [load,setLoad] = useState(false);
    const [isEdit,setIsEdit] = useState(false);
    const dispatch = useDispatch();

    const handlePauseResume = async (type) =>{
        setLoad(true);
        const data = await RequestPost(`${CloudApi.programs}/${type}/${sessionId}`,{programId,msgToday});
        dispatch(ActionShowAlert({message: data?.message}));
        setLoad(false);
        setShow(false);
        getDetails();
    }
    const handleActivate = () =>{
        dispatch(ActionShowPopup({
            title: "Activate program",
            content: "From Tomorrow Daily Messages Will start Sending . Are You sure ?",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.programs}/activate/${sessionId}`,{programId});
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert({message: data?.message}));
                getDetails();
            },
            successTitle: "Activate",
            cancelTitle:"Cancel",
        }))
    }

    useEffect(()=>{
        getDetails();
    },[]);
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.programs}/details/${sessionId}`,{type: "details",programId});
        setDetails(data?.details || {});
        setIsLoading(false);
    }
    return <>
        <Header
            link={{name: "Programs", link: `programs`}}
            title={details?.title || title} subTitle={"All Of your Program Details, Messages, Members and Settings"} buttons={[{type: "refresh",title: "Refresh"}]} buttonProps={[{onClick: getDetails}]} />
        <PageLoading isLoading={isLoading}>
            <Group position={'apart'}>
                <Group>
                    {['group', 'single'].includes(details?.programType) ? <Paper shadow={'md'} withBorder px={10}>
                        {details?.programType === 'group' ? <Group>
                            <IconUsers size={20} px={1} mx={1} color={Colors.Primary}/>
                            <Text weight={'bold'} mr={10}>{details?.groupName}</Text>
                        </Group> : <Group>
                            <IconUser size={20} px={1} mx={1} color={Colors.Primary}/>
                            <Text weight={'bold'} mr={10}>Individual</Text>
                        </Group>}
                    </Paper> : <></>}
                    {!!details?.settings?.programComplete && details?.status !== 2 && <Paper shadow={'md'} withBorder px={10}><Text>Program Completion Date: <b>{moment.unix(details?.settings?.programComplete).format('ll')}</b></Text></Paper>}
                </Group>
                <Group position={'right'} mr={10}>
                    <Button compact size={"xs"} variant={'gradient'}  onClick={() => setIsEdit(true)} leftIcon={<IconEdit size={20}/>}>
                       Edit Program
                    </Button>
                    <CopyButton
                        value={`https://wba1.businesssalesmachine.com:3005/public/payments/${userId}/${programId}`}
                        timeout={2000}>
                        {({copied, copy}) => (
                            <Button compact size={"xs"} variant={'gradient'} color={copied ? 'teal' : 'violet'} onClick={copy} leftIcon={<IconCopy size={20}/>}>
                                {copied ? 'Copied Webhook' : 'Copy Webhook'}
                            </Button>
                        )}
                    </CopyButton>
                    {details?.status === 2 ? <Button variant={'gradient'} onClick={handleActivate}>Activate Program</Button>:
                        <SegmentedControl
                              size={'xs'}
                              onChange={() => setShow(true)}
                              color={details?.status === 1 ? 'green' : 'red'}
                              data={[
                                  {value: '1', label: details?.status === 1 ? 'Active' : 'Resume'},
                                  {value: '0', label: 'Pause'}
                              ]}
                              my={10}
                              value={details?.status?.toString()}
                        />}
                    <Modal opened={show} size={"60%"} onClose={() => setShow(false)} title={<Text weight={'bold'}>Change Program Status</Text>}>
                        {details?.status === 1 ? <>
                            <Text weight={'bold'} size={'sm'}> It will Affect Following Things</Text>
                            <ol>
                                <li>No Day wise Messages will Sent to users</li>
                                <li>No Days will update for Members</li>
                                <li>Add Members and Program Optin will work same as Earlier</li>
                            </ol>
                            <CheckBoxView text={"Send Messages From now Itself"} onClick={setMsgToday} isSelected={msgToday}/>
                            <Text  mt={10}  weight={'bold'}>Are you Sure want to Pause ?</Text>
                            <Group mt={10} position={'center'}>
                                <Button mt={5} loading={load} onClick={() => handlePauseResume('pause')}>Pause</Button>
                            </Group>
                        </> : <>
                            <Text weight={'bold'} size={'sm'}>It will Affect Following Things</Text>
                            <ol>
                                <li>Day wise Messages will Sent to users from Tomorrow</li>
                                <li>Days will update for Members From Tomorrow</li>
                            </ol>
                            <CheckBoxView text={"Send Messages From now Itself"} onClick={setMsgToday} isSelected={msgToday}/>
                            <Text  mt={10} weight={'bold'}>Are you Sure want to Resume ?</Text>
                            <Group mt={10} position={'center'}>
                                <Button mt={5} loading={load} onClick={() => handlePauseResume('resume')}>Resume</Button>
                            </Group>
                        </>}
                    </Modal>
                </Group>
                {isEdit && <ProgramCreate onClose={() => setIsEdit(false)} details={details} isEdit={true} onRefresh={getDetails} />}
            </Group>
            <Tabs defaultValue="optin"  keepMounted={false} >
                <Tabs.List grow>
                    <Tabs.Tab value="optin" icon={<IconPhoto size={14} />}>Optin Messages</Tabs.Tab>
                    <Tabs.Tab value="messages" icon={<IconMessageCircle size={14} />}>Daily Messages</Tabs.Tab>
                    <Tabs.Tab value="members" icon={<IconUser size={14} />}>Members</Tabs.Tab>
                    <Tabs.Tab value="settings" icon={<IconSettings size={14} />}>Settings</Tabs.Tab>
                    <Tabs.Tab value="scheduled" icon={<IconSend size={14} />}>Scheduled messages</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="optin" pt="xs">
                    <OptinMessages programId={programId}  />
                </Tabs.Panel>
                <Tabs.Panel value="messages" pt="xs">
                    <DayMessages programId={programId}  />
                </Tabs.Panel>
                <Tabs.Panel value="members" pt="xs">
                    <Members programId={programId} />
                </Tabs.Panel>
                <Tabs.Panel value="settings" pt="xs">
                    <ProgramSettings programId={programId} />
                </Tabs.Panel>
                <Tabs.Panel value="scheduled" pt="xs">
                   <ProgramSchedules programId={programId} />
                </Tabs.Panel>
            </Tabs>
        </PageLoading>
    </>
}

export default ProgramDetails;

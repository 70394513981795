// CustomEdge.js
import React, { useState } from 'react';
import {EdgeLabelRenderer, getBezierPath,  BaseEdge,useReactFlow,} from 'reactflow';
import {IconView} from "../Components/PageComponents";

const CustomEdge = ({
                        id,
                        sourceX,
                        sourceY,
                        targetX,
                        targetY,
                        sourcePosition,
                        targetPosition,
                        style = {},
                        arrowHeadType,
                        markerEnd,
                        data,
                    }) => {
    const { setEdges } = useReactFlow();
    const [edgePath, labelX, labelY] = getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });

    const onEdgeClick = () => {
        setEdges((edges) => edges.filter((edge) => edge.id !== id));
    };

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <div
                    style={{
                        position: 'absolute',
                        transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                        fontSize: 12,
                        pointerEvents: 'all',
                    }}
                    className="nodrag nopan"
                >
                    <IconView label={"Delete"} iconType={"delete"} onClick={onEdgeClick} />
                </div>
            </EdgeLabelRenderer>
        </>
    );
};

export default CustomEdge;

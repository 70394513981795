import React, {useEffect, useState} from "react";
import {useViewportSize} from "@mantine/hooks";
import {AdminApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {Badge, Button, Divider, Drawer, Group, Loader, Paper, ScrollArea, Text} from "@mantine/core";
import {CreateDevice, CreatePayment, ViewPref} from "./UserCreate";
import AlertPopup from "../../Pages/Alerts/AlertPopup";
import {CheckBoxView, IconView, PageLoading, TableView} from "../../Pages/Components/PageComponents";
import moment from "moment";
import {IconPlus, IconRefresh} from "@tabler/icons";
import {BoldText} from "../../Pages/Components/TextComponents";
import {convertUnixToFormat} from "../../Constants/Functions";

const UserDetails = ({userId,onClose}) =>{
    const [isLoading,setIsLoading] = useState(false);
    const [user,setUser] = useState();
    const [devices,setDevices] = useState([]);
    const {height} = useViewportSize();
    const [isViewPref,setViewPref] = useState()
    const [isPay,setIsPay] = useState();
    const [isRefresh,setIsRefresh] = useState(false);
    const [popup,setPopup] = useState();
    const [isCreateDevice,setIsCreateDevice] = useState();
    const [deviceTypes,setDeviceTypes] = useState(["BSM","WBA"]);
    const [render,setRender] = useState(0)

    useEffect(()=>{
        getDetails();
    }, [])

    const getDetails = async () =>{
        setIsLoading(true)
        const data = await RequestPost(`${AdminApi.AdminUsers}/userDetails`,{userId});
        setUser(data?.user);
        setDevices(data?.devices || []);
        setIsLoading(false);
    }
    const markAsOurs = async () =>{
        setPopup({
            title: "Chane Account Status",
            content: "Are you sure want to Change Account Status ?",
            successTitle: "Change",
            cancelTitle: "Cancel",
            onCancel: () => setPopup(undefined),
            onSuccess: async () =>{
                await RequestPost(`${AdminApi.AdminUsers}/isOurs`,{userId, isOurs: !user?.isOurs});
                setPopup(undefined);
                getDetails();
            }
        })
    }
    const FetchPref = async (sessionId) =>{
        setIsRefresh(true)
        await RequestGet(`${AdminApi.formatPref}/${userId}/${sessionId}`);
        getDetails();
        setIsRefresh(false);
    }
    const DeletePay = async (sessionId,_id) =>{
        setPopup({
            title: "Delete",
            content: "Are you sure want to Delete ?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onCancel: () => setPopup(undefined),
            onSuccess: async () =>{
                const data = await RequestPost(`${AdminApi.AdminUsers}/deletePay`,{sessionId,_id,userId});
                setPopup(undefined);
                getDetails();
            }
        })


    }
    const renderStatus = (status) => {
        switch(status){
            case 1:
                return <Badge color={'green'}>Active</Badge>
            case 2:
                return <Badge color={'orange'}>Completed</Badge>
            case 3:
                return <Badge color={'red'}>Deleted</Badge>
            default:
                return <Badge>{status}</Badge>
        }

    }
    const handleDeviceType = (type) =>{
        const types = deviceTypes?.includes(type) ? deviceTypes?.filter(a => a !== type): [...deviceTypes,type];
        setDeviceTypes(types);
        setRender(render+1);
    }


    return <Drawer opened={true} size={"80%"} position={"right"} title={<b>User Details</b>} onClose={onClose}>
        {isCreateDevice && <CreateDevice userId={userId} onClose={() => setIsCreateDevice(false)} onRefresh={getDetails} />}
        {popup && <AlertPopup opened={true} {...popup} onClose={() => setPopup(undefined)} />}
        {isViewPref && <ViewPref details={isViewPref} onClose={() => setViewPref()}  />}
        {isPay && <CreatePayment details={isPay} onClose={() => setIsPay()} onRefresh={getDetails} />}
        <PageLoading isLoading={isLoading}>
            <Paper shadow={'md'} withBorder p={5} mr={5}>
                <Group position={'apart'} mb={5}>
                    <Text>{user?.name}</Text>
                    <Text>{user?.email}</Text>
                    <Text>{user?.mobile}</Text>

                    <Text>UserId: <b>{user?.id}</b></Text>
                    <Text>Password: <b>{user?.password}</b></Text>
                    <Text color={'dimmed'}>{moment(user?.createdAt)?.format('lll')}</Text>
                </Group>
            </Paper>
            <Group position={"apart"}>
                <Group sx={{gap: 15}}>
                    Devices:
                    <CheckBoxView text={`WBA - ${devices?.filter(a => a?.type === "WBA")?.length || 0}`} isSelected={deviceTypes?.includes("WBA")} onClick={() => handleDeviceType("WBA")} />
                    <CheckBoxView text={`BSM - ${devices?.filter(a => a?.type === "BSM")?.length || 0}`} isSelected={deviceTypes?.includes("BSM")} onClick={() => handleDeviceType("BSM")}  />
                </Group>

                <Group position={"right"} m={10}>
                    <Button compact onClick={markAsOurs}>Mark as {user?.isOurs ? "Paid": "Ours"}</Button>
                    <Button compact leftIcon={<IconRefresh />} onClick={getDetails}>Refresh</Button>
                    <Button compact leftIcon={<IconPlus />} onClick={() => setIsCreateDevice(true)}>Create Device</Button>
                </Group>

            </Group>


            <ScrollArea sx={{height: height - 100}} type={"auto"}>
                {devices?.filter(a => deviceTypes?.includes(a?.type))?.map(a =>{
                    return <Paper shadow={"md"} withBorder p={5} my={10} ml={5} mr={10} sx={{backgroundColor: "#ccc"}}>
                        <Group position={"apart"}>
                            <Group>
                                <BoldText size={"md"} text={`${a?.device?.name} (${a?.device?.sessionId})`} />
                                <Badge>{a?.type}</Badge>
                            </Group>
                            <Group mr={20}>
                                {renderStatus(a?.device?.status)}
                                {a?.type === "WBA" && <>
                                    <IconView iconType={"add"} label={"Add Payments"}  onClick={() => setIsPay({userId: a?.device?.userId, sessionId: a?.device?.sessionId})} />
                                    {isRefresh ? <Loader size={"xs"} />: <IconView iconType={"refresh"} label={"Refresh Prefrences"}
                                                                                   onClick={() => FetchPref(a?.device?.sessionId)}/>}
                                </>}
                                <IconView iconType={"view"} label={"View Prefrences"} onClick={() => setViewPref(a?.device)} />
                            </Group>
                        </Group>
                        <Divider />
                        <TableView headers={["Plan","TotalDays","PaymentId","Created","Expiry","Status","Options"]} >
                            {a?.pay?.map(b=> <tr>
                                <td width={"20%"}>{b?.plan}</td>
                                <td width={"10%"}>{b?.totalDays}</td>
                                <td width={"10%"}>{b?.paymentId}</td>
                                <td width={"15%"}>{b?.activeDay ?convertUnixToFormat(b?.activeDay,'ll') :convertUnixToFormat(b?.created,'ll')}</td>
                                <td width={"15%"}>{convertUnixToFormat(b?.expiry,'ll')}</td>
                                <td width={"20%"}>{renderStatus(b?.status)}</td>
                                <td width={"10%"}>
                                    <Group>
                                        <IconView iconType={'view'} label={"View Prefrences"} onClick={() => setViewPref(b)} />
                                        {a?.type === "WBA" && <>
                                            <IconView iconType={'edit'} label={"Edit Plan"} onClick={() => setIsPay({...b, isEdit: true,editId: b?._id})}  />
                                            <IconView iconType={'delete'} label={"Delete Plan"} onClick={() => DeletePay(a?.device?.sessionId,b?._id)} />
                                        </>}
                                    </Group>
                                </td>
                            </tr> )}
                        </TableView>
                    </Paper>
                })}
                <Group my={100} />
            </ScrollArea>
        </PageLoading>
    </Drawer>
}

export default UserDetails;
import {Badge, Divider, Group, Loader, Paper, Stack, Text} from "@mantine/core";
import React, {useState} from "react";
import {BoldText, LightText, SmallText} from "../Components/TextComponents";
import {CloudApi, RequestGet} from "../../Layouts/RequestManager";
import {useSelector} from "react-redux";
import {IconView} from "../Components/PageComponents";

const DeviceHealth = ({health}) =>{
    const {userId,sessionId} = useSelector(state => state.active);
    const [healthStatus,setHealth] = useState(health)
    const [isLoading,setIsLoading] = useState(false);

    const getDetails = async () =>{
        setIsLoading(true);
        const details = await RequestGet(`${CloudApi.deviceHealth}/${userId}/${sessionId}`);
        setHealth(details?.health);
        setIsLoading(false);
    }


   return <Paper shadow={"md"} withBorder p={5} sx={{width: 500}}>
        <Group position={"apart"}>
            <BoldText text={"Message Status"} />
            <Group>
                <Badge color={healthStatus?.can_send_message === "BLOCKED" ? "red" : "green"}>{healthStatus?.can_send_message}</Badge>
                {isLoading ? <Loader size={"xs"} />: <IconView iconType={"refresh"} onClick={getDetails}/>}
            </Group>
        </Group>
        <Divider my={5}/>
        {healthStatus?.entities?.map(a => {
            return <Stack sx={{gap: 3}} my={3}>
                <Paper withBorder p={5}>
                    <Group position={"apart"}>
                        <SmallText text={a?.entity_type} />
                        <SmallText text={a?.can_send_message} color={a?.can_send_message === "BLOCKED" ? "red" : "green"} />
                    </Group>
                    {a?.errors?.length > 0 && <Divider my={3}/>}
                    {a?.errors?.map(err => <LightText text={err?.error_description} />)}
                    {a?.additional_info?.map(b => <LightText text={b} />)}
                </Paper>
            </Stack>
        })}

    </Paper>
}
export default DeviceHealth;

import React, {useEffect, useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useSelector} from "react-redux";
import {Drawer, Grid, Group, Paper, Stack,Badge} from "@mantine/core";
import {PageLoading} from "../Components/PageComponents";
import {BoldText, SmallText} from "../Components/TextComponents";
import UserDetails from "./UserDetails";

const CRMUser = ({mobile,onClose}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();

    useEffect(() =>{
        getContact();
    },[mobile])

    const getContact = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.CRM}/userDetails/${sessionId}`,{mobile});
        setDetails(data?.details);
        setIsLoading(false);
    }
    return <Drawer onClose={onClose} title={<BoldText text={"User Details"} size={"md"} mx={10} mt={10} />} opened={true} position={"right"} size={"60%"} px={10}>
        <PageLoading isLoading={isLoading}>
            <Stack mx={10}>
                {details && <UserDetails details={details} />}
            </Stack>
        </PageLoading>
    </Drawer>
}

export default CRMUser;
import React, {forwardRef, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ActionShowAlert} from "../../../store/actions/alert";
import {Button, Divider, Grid, Group, Loader, Select, Text} from "@mantine/core";
import ResponseView from "./ResponseView";
import {IconPlugConnected, IconRefresh} from "@tabler/icons";
import {CloudApi, RequestGet, RequestPost, serverUrls} from "../../../Layouts/RequestManager";
import {socket} from "../../Alerts/Socket";
import IntegrationDrawer from "../IntegrationDrawer";
import {IconView} from "../../Components/PageComponents";

const ConvertKit = ({props,value}) =>{
        const app ='ck';
        const [event,setEvent] = useState(value?.event || undefined);
        const [connId,setConnId] = useState(value?.connId || undefined);
        const [openConn,setOpenConn] = useState(false);
        const [tags,setTags] = useState([]);
        const [forms,setForms] = useState([]);
        const [selected,setSelected] = useState(value?.selected || undefined);
        const [waitingWH,setWaitingWh] = useState(false);
        const [sampleResponse,setSampleResponse] = useState(value?.webhookRes || undefined)
        const dispatch = useDispatch();
        const {userId,sessionId} = useSelector(state => state.active)
        const [error,setError] = useState("")
        const [loadingForms,setLoadingForms] = useState(false);
        const [loading,setLoading] = useState(false);

        useEffect(() =>{
            if(connId){
                switch (app){
                    case 'ck':
                       getFields(event);
                   break;
                }
            }
        },[event,connId]);
        useEffect(() =>{
            setSelected(value?.selected || undefined);
        },[forms,tags]);

        const onEventChange = (event) =>{
            setEvent(event);
            setConnId(undefined);
            setSelected(undefined);
            setWaitingWh(false);
        }
        const updateConnection = (conn) =>{
            setConnId(conn);
            setOpenConn(false);
            setWaitingWh(false);
        }
        const getFields= async (detailsType) =>{
           setLoadingForms(true)
            const details = await RequestPost(`${CloudApi.convertkit}/details/${sessionId}`,{userId,connId,detailsType});
            if(details?.status === 1){
                const results = details?.data?.map(a =>({label: a?.name, value: a?.id})) || [];
                event === 'tag' ? setTags(results): setForms(results);
                setSelected(value?.selected || undefined);
                setLoadingForms(false)
            }else{
                setLoadingForms(false)
            }
        }

        const handleSaveFlow = async () =>{
            setLoading(true);
            const flow = {app,event,connId,selected,id: value?.id};
            let eventDetails = {}
            if(event === 'tag'){
                eventDetails =  {"name": "subscriber.tag_add", "tag_id": selected}
            }else{
                eventDetails = {"name": "subscriber.form_subscribe", "form_id": selected}
            }
            const result = await RequestPost(`${CloudApi.convertkit}/subscribe/${sessionId}`,{userId,connId,flowId: props?.id,flow, event, eventDetails,step: props?.step})
            setLoading(false);
            if(result?.status === 2){
                setError(result?.msg);
                setWaitingWh(false);
            }else{
                triggerWebhookListener();
            }

        }
        const triggerWebhookListener = async () =>{
            setWaitingWh(true);
            await RequestPost(`${CloudApi.bsmFlows}/WebhookListen/${sessionId}`,{step: props?.step, id: props?.id});
            socket.on(`Flow${props?.id}_STEP${props?.step}`, handleWebhook);
        }
        const handleWebhook = (data) =>{
            dispatch(ActionShowAlert({message: "Webhook Captured", color: "green"}));
            setSampleResponse(data);
            setWaitingWh(false);
        }
        const SelectItem = forwardRef(({ image, label, description, ...others }, ref) => (
            <div ref={ref} {...others}>
                <Group noWrap>
                    <div>
                        <Text size="sm">{label}</Text>
                        <Text size="xs" color={"dimmed"}>
                            {description}
                        </Text>
                    </div>
                </Group>
            </div>
        ));
        const events = [{label: "New Tag Assign",description: "When New Tag assigned to email", value: "tag"},{label: "New Form Submit",description: "When User Submitted Form", value: "form"}];
        return <>
            {openConn && <IntegrationDrawer app={'ck'} connection={connId} updateConnection={updateConnection} onClose={() => setOpenConn(false)}/>}
            {app && <Grid.Col span={3}>
                <Select label={"Choose Event"}
                        itemComponent={SelectItem}
                        data={events} value={event} onChange={onEventChange} />
            </Grid.Col>}
            {app && event && !connId && <Grid.Col span={3}>
                <Button  mt={25}  onClick={() => setOpenConn(true)} variant={'outline'} leftIcon={<IconPlugConnected />}>Connect With Convertkit</Button>
            </Grid.Col>}
            {connId && <>
                <Grid.Col span={3}>
                    <Button mt={25} color={'green'} onClick={() => setOpenConn(true)} leftIcon={<IconPlugConnected />}>Connected</Button>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Divider />
                </Grid.Col>
                {event === 'form' && <>
                    <Grid.Col span={5}>
                        <Select label={"Choose Form"} data={forms} value={selected} onChange={setSelected}/>
                    </Grid.Col>
                    <Grid.Col span={1} mt={30}>
                        {!loadingForms ? <IconView icon={<IconRefresh/>} label={"Refresh Forms"} onClick={() =>getFields("form")}/> : <Loader size={"sm"} />}
                    </Grid.Col>
                </>}
                {event === 'tag' &&   <>
                    <Grid.Col span={5}>
                        <Select label={"Choose Tags"} data={tags} value={selected} onChange={setSelected}/>
                    </Grid.Col>
                    <Grid.Col span={1} mt={25}>
                        {!loadingForms ? <IconView icon={<IconRefresh />} label={"Refresh Tags"} onClick={() => getFields("tag")} /> : <Loader size={"sm"} />}
                    </Grid.Col>
                </>}
            </>}
            {selected && <Grid.Col span={6}>
                <Group position={'apart'} mt={25}>
                    {!waitingWH ?  <Button loading={loading} onClick={handleSaveFlow} variant={'gradient'}>Save & Check Response</Button>
                        : <Button loading={true}>Waiting For Webhook</Button>}
                </Group>
            </Grid.Col>}
            {error && <Grid.Col span={12}>
                <Text color={"red"}>{error}</Text>
            </Grid.Col>}
            {sampleResponse && <ResponseView response={sampleResponse} onRefresh={triggerWebhookListener}/>}
        </>
    }

export default ConvertKit;

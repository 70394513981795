import {FilledTextInput} from "../Sequence/CustomButton";
import {Grid, Paper, Button, MultiSelect, Drawer, Stack, Divider, Group} from "@mantine/core";
import {useSelector} from "react-redux";
import {DateRangePicker} from "@mantine/dates";
import {useState} from "react";
import {IconView} from "../Components/PageComponents";
import {BoldText} from "../Components/TextComponents";

const CRMFilter = ({filter,handleFilter, onClose}) =>{
    const {tags} = useSelector(state => state.active);

    const [mobile,setMobile] = useState(filter?.mobile || "");
    const [selectedTags,setTags] = useState(filter?.tags || []);
    const [created,setCreated] = useState(filter?.created);
    const [updated,setUpdated] = useState(filter?.updated);
    const [params,setParams] = useState(filter?.params || []);
    const [render,setRender] = useState(0);

    const updateFilter = () =>{
        let query = {mobile,tags:selectedTags,created,updated, params};
        handleFilter(query);
    }
    const handleParams = (index, key,value) =>{
        let p = params;
        p[index] = {...p?.[index], [key]: value};
        setParams(p);
        setRender(render+1);
    }



    return <Drawer opened={true} onClose={() => onClose(false)} position={"right"} size={"40%"}>
            <Stack sx={{gap: 3}} mx={10}>
                <FilledTextInput label={"Mobile"} value={mobile} onChange={setMobile} />
                <MultiSelect size={"xs"} label={"Choose Tags"} data={tags || []} value={selectedTags} onChange={setTags}  />
                <DateRangePicker size={"xs"} label={"Created Date"} allowSingleDateInRange value={created} onChange={setCreated} />
                <DateRangePicker size={"xs"} label={"Last Updated"} allowSingleDateInRange value={updated} onChange={setUpdated} />
                <Divider my={5} />
                <Group position={"apart"}>
                    <BoldText size={"md"} text={"Params Filter"}  />
                    <Button compact onClick={() => setParams([...params, {key: "",value: ""}])}>Add Custom Filter</Button>
                </Group>
                <Divider my={5} />
                {params?.map((a, i) =>{
                    return <Group>
                        <FilledTextInput label={"Variable Name"} value={a?.key} onChange={(value) => handleParams(i, "key", value)} />
                        <FilledTextInput label={"Value Contains"} value={a?.value} onChange={(value) => handleParams(i, "value", value)}  />
                        <IconView iconType={"delete"} onClick={() => setParams(params?.filter((b,index) => index !== i ))} />
                    </Group>
                })}
                <Button compact mt={20} onClick={updateFilter}>Filter</Button>
            </Stack>
        </Drawer>

}

export default CRMFilter;
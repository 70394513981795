const initialState = {
    alert: undefined,
    popup: undefined,
    queue: 0,
    connection: {},
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "showAlert":
            return {...state,alert: action.alert};
        case 'hideAlert':
            return {...state,alert: undefined};
        case "showPopup":
            return {...state,popup: action.alert};
        case 'hidePopup':
            return {...state,popup: undefined};
        case 'queueLength':
            return {...state, queue: action.len};
        case 'optinQueueLength':
            return {...state, optin: action.len};
        case 'connectionChange':
            const {connection,sessionId} = action
            return {...state, connection: {...state?.connection,[sessionId]:connection} || 'red'};
        default:
            return state;
    }
};

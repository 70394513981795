import {createStyles, Title, Text, Button, Container, Group, Stack} from '@mantine/core';
import {useNavigate} from "react-router-dom";
import {BoldText} from "../Components/TextComponents";
import {useSelector} from "react-redux";

const useStyles = createStyles((theme) => ({
    root: {
        paddingTop: 80,
        paddingBottom: 80,
    },
    label: {
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 220,
        lineHeight: 1,
        marginBottom: theme.spacing.xl * 1.5,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2],
        [theme.fn.smallerThan('sm')]: {
            fontSize: 120,
        },
    },
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        textAlign: 'center',
        fontWeight: 900,
        fontSize: 38,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 32,
        },
    },

    description: {
        maxWidth: 500,
        margin: 'auto',
        marginTop: theme.spacing.xl,
        marginBottom: theme.spacing.xl * 1.5,
    },
}));

export const NotFound = () => {
    const { classes } = useStyles();
    const navigation = useNavigate();
    return (
        <Container className={classes.root}>
            <div className={classes.label}>404</div>
            <Title className={classes.title}>Not Found</Title>
            <Group position="center">
                <Button variant="subtle" size="md" onClick={() => navigation('/')}>
                    Take me back to home page
                </Button>
            </Group>
        </Container>
    );
}
export const NotConnected = () => {
    const navigation = useNavigate();
    const {alias} = useSelector(state => state.active);
    return (
        <div style={{height: "100vh"}}>
            <Stack sx={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                <BoldText text={"Device Not Connected"} color={"red"} size={50} align={"center"} />
                <BoldText text={"Please Connect Device. To Access this Feature"} size={30} color={"dimmed"} />
                <Group position="center">
                    <Button variant="subtle" size="md" onClick={() => navigation(`/device/${alias}`)}>
                        Connect Device
                    </Button>
                </Group>
            </Stack>
        </div>
    );
}

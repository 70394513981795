import React, {useEffect, useState} from "react";
import {
    Drawer,
    Grid,
    Paper,
    Button,
    Group,
    ScrollArea,
    Divider,
    Stack,
    MultiSelect,
    SegmentedControl, Pagination
} from "@mantine/core";
import {FilledTextInput} from "../Sequence/CustomButton";
import {CheckBoxView, IconView, PageLoading, RadioButtonView} from "../Components/PageComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {BoldText} from "../Components/TextComponents";
import * as XLSX from "xlsx";
import _ from "underscore";
import UserDetails from "./UserDetails";
import {formatMobileNumber} from "../../Constants/Functions";
import {ActionShowAlert} from "../../store/actions/alert";
const CreateContact = ({onClose,mobileEdit, onRefresh,isEdit}) =>{

    const {sessionId,...active} = useSelector(state => state.active);
    const [createType, setCreateType] = useState("Manual");
    const [bulkData,setBulkData] = useState([]);

    const [isLoading,setIsLoading] = useState(false);
    const [isUpdating,setIsUpdating] = useState(false);
    const [isError,setIsError] = useState(false);

    const [mobile,setMobile] = useState("");
    const [params, setParams] = useState([{key: "", value: ""}]);
    const [tags, setTags] = useState(active?.tags?.map(a => ({label: a,value:a})) || []);
    const [selectedTags, setSelectedTags] = useState([]);

    const [render, setRender] = useState(0);
    const [page, setPage] = useState(1);
    const [ifExists, setIfExists] = useState("ignore");
    const dispatch = useDispatch();

    useEffect(() =>{
        if(mobileEdit) getContact();
    },[mobileEdit]);


    const getContact = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.CRM}/userDetails/${sessionId}`,{mobile:mobileEdit});
        const {mobile,params = {},tags} = data?.details || {};
        setMobile(mobile || "");
        let p = [];
        for(const key of Object.keys(params)) p.push({key, value: params?.[key]})
        setParams(p);
        setSelectedTags(tags || []);
        setIsLoading(false);
        setRender(render+1);
    }
    const addParams = () => setParams([...params,{key: "",value: ""}]);
    const deleteParams = (index) => setParams(params?.filter((a,i) => i !== index));
    const changeParamsValue = (index,key,value) => {
        const p = params;
        p[index] = {...p?.[index], [key]:value};
        setParams(p);
        setRender(render+1);
    }
    const handleSave = async () =>{
        if(mobile?.trim() === ""){
            setIsError(true);
        }else{
            setIsError(false);
            let p = {};
            params?.map(a => p[a?.key] = a?.value);
            const details = {mobile, params: p, tags: selectedTags};
            setIsUpdating(true);
            const data = await RequestPost(`${CloudApi.CRM}/singleCreate/${sessionId}`,{
                details,ifExists,isEdit
            });
            dispatch(ActionShowAlert({message: data?.message}));
            setIsUpdating(false);
            onClose();
            onRefresh({});
        }


    }
    const formatTags = (tag) =>{
        return tag?.toString()?.toLowerCase()?.replaceAll(" ","_");
    }
    const handleUpload = (e) => {
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            let [keys,...dataparsed] = XLSX.utils.sheet_to_json(ws, {header:1});
            const objectsArray = dataparsed.map((arr) => {
                let obj = {};
                keys.forEach((key, index) => {
                    obj[key] = arr[index] || null; // Handles missing data in the array
                });
                const {mobile,tags, ...params} = obj;
                let p = {};
                for(const param in params) p[formatTags(param)] = params?.[param];
                return {mobile: formatMobileNumber(mobile),tags: tags?.split(",")?.map(a => formatTags(a)), params: p};
            });



            console.log(objectsArray?.filter(a => !!a?.mobile));
            setBulkData(objectsArray?.filter(a => !!a?.mobile));
        };
        reader.readAsBinaryString(f)
    };
    const handleBulkUpload = async () =>{
        setIsUpdating(true);
        const data = await RequestPost(`${CloudApi.CRM}/bulkCreate/${sessionId}`,{ users: bulkData, ifExists });
        setIsUpdating(false);
        dispatch(ActionShowAlert({message: data?.message}));
        onClose();
        onRefresh();
    }


    return <Drawer position={"right"} opened={true} onClose={onClose} size={"40%"} px={10}>
            <PageLoading isLoading={isLoading}>
                <Grid mx={10}>
                    {!isEdit && <Grid.Col span={6}>
                        <SegmentedControl data={["Manual", "Excel"]?.map(a => ({label: a, value: a}))}
                                          value={createType} onChange={setCreateType}/>
                    </Grid.Col>}
                    {createType === "Manual" && <>
                        <Grid.Col span={6}>
                            <FilledTextInput label={"Mobile"} value={mobile} onChange={setMobile} disabled={isEdit} error={isError && mobile?.trim() === "" ? "Mobile is Required": ""} />
                        </Grid.Col>
                        {!isEdit && <Grid.Col span={12}>
                            <Group>
                                <BoldText text={"If Mobile Already Exits: "}/>
                                <Group>
                                    <RadioButtonView text={"Ignore"} isSelected={ifExists === "ignore"}
                                                     onClick={() => setIfExists("ignore")}/>
                                    <RadioButtonView text={"Update"} isSelected={ifExists === "update"}
                                                     onClick={() => setIfExists("update")}/>
                                </Group>
                            </Group>
                        </Grid.Col>}
                        <Grid.Col span={12}>
                            <Paper shadow={"md"} withBorder p={5}>
                                <Group position={"apart"}>
                                    <BoldText text={"Contact Parameters"} size={"md"} />
                                    <Button compact onClick={addParams}>Add Params</Button>
                                </Group>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col span={12} sx={{maxHeight: 400}}>
                            <Stack sx={{gap: 3}}>
                                {params?.map((a,i) =>{
                                    return <Group>
                                        <FilledTextInput size={"sm"} label={"Key"} value={a?.key} onChange={(value) => changeParamsValue(i, "key", value)}  />
                                        <FilledTextInput size={"sm"} label={"Value"} value={a?.value} onChange={(value) => changeParamsValue(i, "value", value)}  />
                                        <IconView iconType={"delete"} onClick={() => deleteParams(i)} />
                                    </Group>
                                })}
                            </Stack>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Paper shadow={"md"} withBorder p={5}>
                                <Group position={"apart"}>
                                    <BoldText text={"Tags"} size={"md"} />
                                    {/*<Button compact onClick={addParams}>Add Params</Button>*/}
                                </Group>
                            </Paper>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <MultiSelect
                                data={tags}
                                value={selectedTags}
                                onChange={setSelectedTags}
                                searchable
                                clearable
                                creatable
                                getCreateLabel={(query) => `+ Create ${query}`}
                                onCreate={(query) => {
                                    const item = { value: query, label: query };
                                    setTags((current) => [...current, item]);
                                    return item;
                                }}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Group position={"center"}>
                                <Button onClick={handleSave} loading={isUpdating}>Save User</Button>
                            </Group>
                        </Grid.Col>
                    </>}
                    {createType === "Excel" && <>
                        <Grid.Col span={6}>
                            <input type={"file"} onChange={handleUpload} />
                        </Grid.Col>
                        {bulkData?.length > 0 && <>
                            <Grid.Col span={12}>
                                <Group position={"apart"}>
                                    <Group>
                                        <BoldText text={"If Mobile Already Exits: "} />
                                        <Group>
                                            <RadioButtonView text={"Ignore"} isSelected={ifExists === "ignore"} onClick={() => setIfExists("ignore")} />
                                            <RadioButtonView text={"Update"} isSelected={ifExists === "update"}  onClick={() => setIfExists("update")} />
                                        </Group>
                                    </Group>
                                    <Pagination size={"xs"} total={bulkData?.length} page={page} onChange={setPage} />
                                </Group>
                            </Grid.Col>
                            {bulkData?.[page - 1] && <UserDetails details={bulkData?.[page - 1]} />}
                        </>}
                        <Grid.Col span={12}>
                            <Group position={"center"}>
                                <Button loading={isUpdating} onClick={handleBulkUpload}>Save Contacts</Button>
                            </Group>
                        </Grid.Col>
                    </>}
                </Grid>
            </PageLoading>

    </Drawer>
}

export default CreateContact;
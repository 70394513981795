import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import { Provider} from 'react-redux';
import history from "./Layouts/history";
import store from "./store/store";
import {persistStore} from "redux-persist";
import {PersistGate} from "redux-persist/integration/react";
import Layout from "./Layouts/layout";
const persistor = persistStore(store);
ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter history={history}>
                <Layout />
              </BrowserRouter>
          </PersistGate>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

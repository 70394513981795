import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {useSelector} from "react-redux"
import {Grid, Modal, SegmentedControl, Select, Textarea, TextInput, Button, Group} from "@mantine/core";
import {ErrorText, SmallText} from "./TextComponents";
import PickFile from "../Schedule/PickFiles";
import {FilledTextArea} from "../Sequence/CustomButton";

const fileFilter = {
    "image": ["image"],
    "audio": ["audio"],
    "video": ["video"],
    "document": ["image","audio","video","application"]
}
const ImageFilesPicker = forwardRef((props, ref)=>{
    const {msgType,details} = props;
    const ref1 = useRef();
    const [caption,setCaption] = useState(details?.caption || "");

    useImperativeHandle(ref, () => ( {
        isValid() {
            const fileDetails = ref1.current.isValid();
            const isValid = fileDetails?.isValid;
            if(isValid){
                const {file, details, isCode} = fileDetails;
                const message = {message: caption,[msgType?.toLowerCase()]: isCode ? {code: file}: {link: file}};
                const msgDetails =  {caption,isCode,mediaCode: isCode ? file: undefined, ...details};
                return {isValid, message,msgDetails}
            }else{
                return {isValid: false, message:{},msgDetails:{}}
            }
        }
    }));
    return  <>
            <PickFile ref={ref1} msgType={msgType} details={details} />
            {msgType !== "Audio" && <Grid.Col span={12}>
                <FilledTextArea value={caption} onChange={setCaption} label={"Caption"} limit={1024} minRows={4}/>
            </Grid.Col>}
    </>
});
export default ImageFilesPicker;

import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ActionShowAlert} from "../../../store/actions/alert";
import {Button, CopyButton, Divider, Grid, Group} from "@mantine/core";
import ResponseView from "./ResponseView";
import {CloudApi, RequestPost} from "../../../Layouts/RequestManager";
import {socket} from "../../Alerts/Socket";
import {IconCheck, IconCopy} from "@tabler/icons";
import {Colors} from "../../../Constants/Colors";
import {FlowWebhookButton} from "../../Components/PageComponents";

const BSMWebhook = ({props,value}) =>{
    const app ='webhook';
    const {sessionId,userId} = useSelector(state => state.active);
    const [waitingWH,setWaitingWh] = useState(false);
    const [sampleResponse,setSampleResponse] = useState(value?.webhookRes || undefined)
    const dispatch = useDispatch();

    const triggerWebhookListener = async () =>{
        setWaitingWh(true);
        await RequestPost(`${CloudApi.bsmFlows}/WebhookListen/${sessionId}`,{step: props?.step, id: props?.id});
        socket.on(`Flow${props?.id}_STEP${props?.step}`, handleWebhook);
    }
    const handleWebhook = (data) =>{
        dispatch(ActionShowAlert({message: "Webhook Captured", color: "green"}));
        setSampleResponse(data);
        setWaitingWh(false);
    }
    const handleSaveFlow = async () =>{
        const flow = {app,id: value?.id};
        const data = await RequestPost(`${CloudApi.bsmFlows}/saveDetails/${sessionId}`,{flow,step: props?.step, id: props?.id});
        dispatch(ActionShowAlert({message: data?.message}));
        triggerWebhookListener();
    }
    return <>
        <Grid.Col span={3}>
            <FlowWebhookButton id={props?.id} />
        </Grid.Col>
        <Grid.Col span={3}>
            <Group position={'apart'} mt={25}>
                {!waitingWH && !sampleResponse && <Button onClick={handleSaveFlow} variant={'gradient'}>Save & Check Response</Button>}
                {waitingWH && <Button loading={true}>Waiting For Webhook</Button>}
            </Group>
        </Grid.Col>
        <Divider />
        {sampleResponse && <ResponseView response={sampleResponse} onRefresh={triggerWebhookListener}/>}
    </>
}

export default BSMWebhook;

import React from "react";
import {
    Group,
    Paper,
    Text,
    ActionIcon,
    Divider,
    Stack,
    Grid,
    Button,
    Badge,
    Modal,
    NumberInput,
    CopyButton
} from "@mantine/core";
import {IconCopy, IconPlus, IconToggleLeft, IconToggleRight} from "@tabler/icons";
import {useState,useEffect} from "react";
import {Colors} from "../../Constants/Colors";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {IconView, PageLoading} from "../Components/PageComponents";
import PreviewScheduleMessage from "../Components/PreviewScheduleMessage";
import MessageOptions from "../Schedule/MessageOptions";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
const formatHours = (time) =>{
    let timeStr = `${time >= 60 ? `${Math.floor(time/60)}Hr`: ''} ${time % 60 > 0 ? `${time % 60}mins`:""}`
    return timeStr;
}
const OptinMessages = ({programId}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [optins,setOptins] = useState({});
    const [delayed,setDelayed] = useState([]);
    const [settings,setSettings] = useState({});
    const [isLoading,setIsLoading] = useState(false);
    const [isAdd,setIsAdd] = useState();
    const dispatch = useDispatch();

    useEffect(()=>{
        getDetails();
    },[]);
    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.programs}/details/${sessionId}`,{type: "optin",programId});
        setIsLoading(false);
        setOptins(data?.optins || {});
        setDelayed(data?.delayed || []);
        setSettings(data?.settings || {});
    }
    const AlertMessages ={
        delete: {  title: "Delete Message",content: "Are you sure want to Delete Message?", successTitle: "Delete"}

    }
    const DeleteMessage = async (type,id) =>{
        dispatch(ActionShowPopup({
            ...AlertMessages.delete,
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const data = await RequestPost(`${CloudApi.programDeleteOptin}/${sessionId}/${type}`,{programId,id});
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup());
                getDetails();
            }
        }))

    }
    const AutoVerify = async (autoVerify) =>{
        const data =  await RequestPost(`${CloudApi.programSettings}/${sessionId}/${programId}`, {type: "autoVerify",autoVerify});
        dispatch(ActionHidePopup());
        dispatch(ActionShowAlert({message: data?.message}));
        getDetails();
    }

    return <PageLoading isLoading={isLoading}>
        {isAdd && <AddOptinMessages onClose={() => setIsAdd()} autoVerify={settings?.autoVerify} onRefresh={getDetails} {...isAdd} programId={programId} />}

        <Grid mb={10} mr={10}>
        <Grid.Col span={6}>
            <Paper shadow={'md'} p={5} sx={{borderColor:settings?.autoVerify ? 'green':'red',borderWidth: 1,borderStyle: "solid" }}>
                <Group mx={5} position={'apart'} >
                    <Text weight={'bold'}>Auto Verify Members While Register</Text>
                    <ActionIcon variant={'subtle'} sx={{backgroundColor: Colors.Secondary}} onClick={() => AutoVerify(!settings?.autoVerify)}>
                        {settings?.autoVerify ? <IconToggleRight color={'green'} /> : <IconToggleLeft color={'red'} />}
                    </ActionIcon>
                </Group>
            </Paper>
        </Grid.Col>
         <MessageHeader type={"OPTIN"} setIsAdd={setIsAdd} disableButton={optins?.messages?.length >= 5} />
        <Grid.Col span={12}>
            {!optins?.messages || optins?.messages?.length === 0  ?  <Group m={10} mb={20} position={'center'}>
                    <Badge color={'red'}>No Optin Messages. Please add to Send Optin</Badge>
                </Group> :
                <Grid>
                    {optins?.messages?.map(optin => <MessageList message={optin} DeleteMessage={DeleteMessage} setIsAdd={setIsAdd} type={"OPTIN"} />)}
                </Grid>}
        </Grid.Col>
        {optins?.messages?.length > 0 && <>
            {/*success Message*/}
            {!settings?.autoVerify && <>
                <Grid.Col span={12}>
                    <Divider variant={'solid'} color={Colors.Primary} label={<Text weight={'bold'} size={'lg'}>{"Verification Message"}</Text>} labelPosition={"center"} />
                </Grid.Col>
                {/*<VerifyCode verifyCode={verifyCode} sessionId={sessionId} programId={this.state?.id} userId={this.props?.user.id} />*/}
                <Grid.Col span={6}>
                    <Paper shadow={'md'} withBorder>
                        <Group mx={10} position={'apart'}>
                            <Text weight={'bold'} color={Colors.Primary}>Immediately After Optin Success</Text>
                            <Group sx={{gap: 3}}>
                                <IconView label={"Edit Message"} iconType={'edit'} onClick={() => setIsAdd({details: {message: optins?.success} ,type: "Success"})} />
                            </Group>
                        </Group>
                        <Divider />
                        {optins?.success ? <PreviewScheduleMessage message={optins?.success} />:<Group m={10} mb={20}>
                            <Badge color={'red'}>No Optin Success Message. Please add to Send Optin</Badge>
                        </Group>}
                    </Paper>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Paper shadow={'md'} withBorder>
                        <Group mx={10} position={'apart'}>
                            <Text weight={'bold'} color={Colors.Primary}>Immediately After Optin Denied</Text>
                            <Group sx={{gap: 3}}>
                                <IconView label={"Edit Message"} iconType={'edit'} onClick={() => setIsAdd({details: {message: optins?.denied} ,type: "Denied"})} />
                            </Group>
                        </Group>
                        <Divider />
                        {optins?.denied ? <PreviewScheduleMessage message={optins?.denied} />:<Group m={10} mb={20}>
                            <Badge color={'red'}>No Optin Success Message. Please add to Send Optin</Badge>
                        </Group>}
                    </Paper>
                </Grid.Col>
            </>}
        </>}
        {/*Delayed Message*/}
        <MessageHeader type={"DELAYED"} setIsAdd={setIsAdd} disableButton={delayed?.length >= 5} />
        {delayed?.sort((a, b) => a.time - b.time)?.map(a => <MessageList message={a} DeleteMessage={DeleteMessage} setIsAdd={setIsAdd} type={"DELAYED"} />)}

        </Grid>
    </PageLoading>
}

export default OptinMessages;

const AddOptinMessages = ({onClose,type,programId,onRefresh,details,autoVerify}) =>{
    const {sessionId} = useSelector(state => state.active);
    const ref = React.createRef();
    const [hr,setHr] = useState(details?.time ? Math.floor(details?.time / 60) :1);
    const [min,setMin] = useState(details?.time ? details?.time % 60 : 0);

    const onSubmit = async () =>{
        const {isValid,message} = ref.current.isValid();
        if(isValid){
            const time = ((hr || 0) * 60) + (min || 0);
            const data = await RequestPost(`${CloudApi.programOptin}/${sessionId}/${type}`,{
                message, programId,subType:type,time,id: details?.id
            });
            onClose();
            onRefresh();
        }
    }
    const msgShow = {
        "OPTIN": ["template"],
        "Success": ["template","session"],
        "Delayed": ["template","session"],
    }
    return <Modal opened={true} size={"80%"} onClose={onClose} title={<b>{type} Mesasge</b>}>
        <Grid>
            {['DELAYED'].includes(type) && <Grid.Col span={6}>
                 <Group>
                    <Text> After </Text>
                    <NumberInput size={'xs'} min={0} max={24} sx={{width: 50}} value={hr} onChange={setHr} />
                    <Text weight={'bold'}>Hrs</Text>
                    <NumberInput size={'xs'} min={0} max={59} sx={{width: 50}} value={min} onChange={setMin} />
                    <Text><b>Mins</b> of Member Verification</Text>
                </Group>
            </Grid.Col>}
            <MessageOptions ref={ref} details={details?.message?.editOptions} show={msgShow?.[type]} />
            {["OPTIN"].includes(type) && !autoVerify && <Grid.Col span={6}>
                <Group sx={{gap: 3}}>
                    <CopyButton value={`Pro_${programId}_Success`}>
                        {({ copied, copy }) => (
                            <Button leftIcon={<IconCopy />} compact color={copied ? 'blue' : 'green'} onClick={copy}>
                                {copied ? 'Copied Verify code' : 'Copy Verify code'}
                            </Button>
                        )}
                    </CopyButton>
                    <CopyButton compact value={`Pro_${programId}_Deny`}>
                        {({ copied, copy }) => (
                            <Button  leftIcon={<IconCopy />} compact  color={copied ? 'blue' : 'red'} onClick={copy}>
                                {copied ? 'Copied Denied code' : 'Copy Denied code'}
                            </Button>
                        )}
                    </CopyButton>
                </Group>
            </Grid.Col>}
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={onSubmit}>Submit</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}
const MessageList = ({message,setIsAdd,DeleteMessage, type}) =>{
    return <Grid.Col span={6}>
        <Paper shadow={'md'} withBorder>
            <Group mx={10} position={'apart'}>
                <Text weight={'bold'} color={Colors.Primary}>{type=== "OPTIN" ?"Immediate" : `After ${formatHours(message?.time)}`}</Text>
                <Group sx={{gap: 3}}>
                    <IconView label={"Edit Message"} iconType={'edit'} onClick={() => setIsAdd({type, details: message})}  />
                    <IconView label={"Delete Message"} iconType={'delete'}  onClick={() => DeleteMessage(type,message?.id)} />
                </Group>
            </Group>
            <Divider />
            <PreviewScheduleMessage message={message?.message} />
        </Paper>
    </Grid.Col>
}
const MessageHeader = ({type,disableButton,setIsAdd}) =>{
    const messages = {
        "DELAYED": {title: "Delayed Messages",subTitle: "All Messages Will Deliver After Verification Done By User.at Specific Interval",},
        "OPTIN": {title: "Immediate Message After Register",subTitle: "Random One From Below Messages Will Send At the Time of Register",},
    }
   return  <Grid.Col span={12}>
        <Grid mx={10} columns={24}>
            <Grid.Col span={21}>
                <Divider
                    variant={'solid'}
                    color={Colors.Primary}
                    label={<Stack sx={{gap: 3}}>
                        <Text weight={'bold'} align={'center'} size={'lg'}>{messages?.[type]?.title}</Text>
                        <Text color={'dimmed'}>{messages?.[type]?.subTitle}</Text>
                    </Stack>}
                    labelPosition={"center"}
                />
            </Grid.Col>
            <Grid.Col span={3}>
                <Button mt={10} variant={'gradient'} leftIcon={<IconPlus />} compact onClick={() => setIsAdd({type})} disabled={disableButton}>Add Message</Button>
            </Grid.Col>
        </Grid>
    </Grid.Col>
}

import {useDispatch, useSelector} from "react-redux";
import {ActionUserLogout} from "../store/actions/user";
import {IconGauge, IconLogout, IconMessage, IconSquareX, IconTimeline, IconUser, IconUsers} from "@tabler/icons";
import {Colors} from "../Constants/Colors";
import {Divider, Group, ScrollArea, Stack, Text} from "@mantine/core";
import React from "react";

const AdminSideBar = ({height,navigation}) =>{
    const dispatch = useDispatch();
    const handleNavigation = (path) => navigation(path);
    const handleLogout = () =>{
        dispatch(ActionUserLogout());
        navigation("/")
    }
    const  lists =[
        {icon: <IconGauge size={20} color={Colors.Primary}  />,label: 'Dashboard',path: `/`},
        {icon: <IconUsers size={20} color={Colors.Primary}  />,label: 'Users',path: `/users`},
        {icon: <IconGauge size={20} color={Colors.Primary}  />,label: 'Sessions',path: `/sessions`},

    ];

    return <ScrollArea pt={10} sx={{position: 'fixed', bottom: 0, left: 0,top: 0,backgroundColor:'rgb(90, 50, 168,0.2)', height, borderRightWidth: 2, borderRightColor: Colors.Primary,borderRightStyle: 'solid',gap: 14}} align={'center'} scrollbarSize={1} type={"auto"} >
        <Stack mb={50} sx={{gap: 10}} >
            <Stack sx={{gap: 3,cursor: "pointer"}} onClick={() => navigation("/")}>
                <Text size={"md"} weight={"bolder"} color={"violet"} my={10}>BSM</Text>
            </Stack>
            {lists.map(a => {
                if(a?.type === 'divider') <Divider sx={{gap: 3}} color={'white'} />
                return <Stack sx={{gap: 3,cursor: "pointer"}} onClick={() => handleNavigation(a?.path)}>
                    <Group position={"center"}>{a?.icon}</Group>
                    <Text size={"xs"} color={"violet"}>{a?.label}</Text>
                </Stack>
            })}
            <Stack sx={{gap: 3,cursor: "pointer"}} onClick={handleLogout}>
                <Group position={"center"}><IconLogout size={20} /></Group>
                <Text size={"xs"} color={"violet"}>Logout</Text>
            </Stack>
        </Stack>
    </ScrollArea>
}
export default AdminSideBar;

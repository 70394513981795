import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {IconBookmark, IconEdit, IconFile, IconMusic, IconTrash, IconVideo} from "@tabler/icons";
import {Badge, Button, Grid, Group, Image, Modal, Paper, SegmentedControl, Select, Stack, Text} from "@mantine/core";
import {CustomButton, CustomHandle, FilledTextArea, FilledTextInput, MenuOptions} from "../CustomButton";
import {Handle, Position} from "reactflow";
import {Colors} from "../../../Constants/Colors";
import {IconView} from "../../Components/PageComponents";

const MessageNode = ({data,id}) =>{
    const {imageFolders =[],files =[]} = useSelector(state => state.active);
    const [list,setList] = useState([{type: "Text", message: ""}]);
    const [render,setRender] = useState(0);
    const [onEdit,setIsEdit] = useState();
    const [isError,setIsError] = useState(false);
    const initialOpen = [{type: "Text", message: ""}];
    useEffect(() => {
        if(onEdit){
            setList(onEdit || [{type: "Text", message: ""}]);
        }
    }, [onEdit])


    const CloseModal = () =>{
        setIsEdit();
        setList([]);
    }
    const changeValues = (i,key,value) =>{
        let l = list;
        l[i] = {...l?.[i],[key]: value};
        setList(l);
        setRender(render+1);
    }
    const onSave = () =>{
        const isValid = list?.filter(a => a?.type === "Text" ?  a?.message && a?.message?.trim() !== "" : a?.file &&a?.file?.trim() !== "")?.length === list?.length;
        if(isValid){
            setIsError(false);
            data?.onChange({message: list, id});
            CloseModal();
        }else{
            setIsError(true);
        }

    }
    const menuOptions = () =>{

        let options = [];
        if(data?.message){
            options.push({label: "Edit",type: "item",icon:  <IconEdit size={12} />, onClick: () => setIsEdit(data?.message)});
            options.push({type: "divider"});
        }
        options.push({label: "Delete", type: "item",icon:  <IconTrash color={"red"} size={12} />, onClick: () => data?.onDelete(id)});
        options.push({type: "divider"});
        if(data?.startNode){
            options.push({label: "Remove Start Node",type: "item",icon:  <IconBookmark size={12} />, onClick: () => data?.onStartNodeChange()});
            options.push({type: "divider"});
        }else{
            options.push({label: "Add Start Node",type: "item",icon:  <IconBookmark size={12} />, onClick: () => data?.onStartNodeChange(id)});
            options.push({type: "divider"});
        }
        return options;
    }
    const onAdd = (type)=>{
        setList([...list,{type,fileType: "file"}]);
        setRender(render+1);
    }
    const onDelete = (index) =>{
        setList(list?.filter((a,i) => i !== index));
        setRender(render+1);
    }

    return  <Paper shadow={"md"} withBorder px={2 } pb={2} sx={{backgroundColor: "lightblue", width: 200}}>
        <Group position={"apart"} m={3}>
            <Text size={"xs"} weight={"bold"}>Message</Text>
            {data?.startNode === true && <Badge>Start</Badge>}
            <MenuOptions options={menuOptions()} />
        </Group>
        <Handle type="target" position={Position.Left}  style={{ background: 'red' }} />
        <CustomHandle id={id} />
        <Modal opened={onEdit !== undefined} size={"50%"} title={<b>Add Messages</b>} onClose={CloseModal}>
            {list?.length < 5 && <Group><Text weight={"bold"}>Click to Add: </Text>{["Text","Image","Video","Audio","Document"]?.map(a => <Button compact onClick={() => onAdd(a)} >{a}</Button>)}</Group>}
            {list?.map((a,i)=>{
                return <Paper my={5} p={5} withBorder>
                    <Grid>
                        <Grid.Col span={12} sx={{backgroundColor: Colors.PrimaryLow}}>
                            <Group position={"apart"}>
                                <Text color={"white"}>{a?.type}</Text>
                                <IconView iconType={"delete"} onClick={() => onDelete(i)} />
                            </Group>
                        </Grid.Col>
                        {a?.type === "Text" ? <Grid.Col span={12}>
                            <FilledTextArea label={"Message"} value={a?.message} onChange={(value) => changeValues(i,"message",value)}  error={isError && (!a?.message || a?.message?.trim() === "")?"Message is Required": ""} />
                        </Grid.Col>: <>
                            <Grid.Col span={6}>
                                <Stack sx={{gap: 3}}>
                                    <Text size={"xs"} weight={"700"}>Select File Method</Text>
                                    <SegmentedControl size={"xs"} data={[{label: "Choose File",value: "file"},{label: "Manual Url",value: "url"}]} value={a?.fileType} onChange={(value) => changeValues(i,"fileType",value)} />
                                </Stack>
                            </Grid.Col>
                            {a?.fileType === "file" ? <>
                                {['Audio','Video']?.includes(a?.type ) && <Grid.Col span={4}>
                                    <Select size={"xs"}   nothingFound={"No Files.. Please Add"} label={"Choose File"} data={files}  value={a?.file}
                                            onChange={(value) => changeValues(i,"file",value)}  /></Grid.Col>}
                                {['Document']?.includes(a?.type ) && <Grid.Col span={4}>
                                    <Select size={"xs"} nothingFound={"No Files.. Please Add"} label={"Choose File"}
                                            data={files}  value={a?.file}  onChange={(value) => changeValues(i,"file",value)} />
                                </Grid.Col>}
                                {['Image']?.includes(a?.type ) && <>
                                    <Grid.Col span={3}>
                                        <Select size={"xs"} nothingFound={"No Images.. Please Add"} label={"Choose Folder"}
                                                data={imageFolders}
                                                value={a?.folder}
                                                onChange={(value) => changeValues(i,"folder",value)}
                                        />
                                    </Grid.Col>
                                    {a?.folder && <Grid.Col span={3}>
                                        <Select size={"xs"} nothingFound={"No Images.. Please Add"}
                                                label={"Choose Image"}
                                                data={imageFolders?.find(b => b.value === a?.folder)?.images?.map(b => ({value: b?.url, label: b?.name})) || []}
                                                value={a?.file}
                                                onChange={(value) => changeValues(i,"file",value)}
                                        />
                                    </Grid.Col>}
                                    {a?.file && <Grid.Col span={3}>
                                        <img src={a?.file} width={100} height={100}/>
                                    </Grid.Col>}
                                </>}
                            </> : <>
                                <Grid.Col span={4}>
                                    <FilledTextInput size={"xs"} label={"File Url"} value={a?.file}  onChange={(value) => changeValues(i,"file",value)}  />
                                </Grid.Col>
                            </>}
                            {isError && !a?.file && <Grid.Col span={12}>
                                <Text size={"xs"} color={"red"}>{a?.type} is Required</Text>
                            </Grid.Col>}
                            <Grid.Col span={12}>
                                <FilledTextArea size={"xs"} label={"Caption"} value={a?.caption}  onChange={(value) => changeValues(i,"caption",value)} />
                            </Grid.Col>
                        </>}
                    </Grid>
                </Paper>

            })}
            <CustomButton title={"Save Message"} onClick={onSave} />
        </Modal>
        {data?.message ? <Stack sx={{gap: 3}}>
            {data?.message?.map(a =>{
                return <Paper shadow={"md"} p={5}>
                    {a?.file && a?.type === "Image" ?  <Image src={a?.file} width={"100%"} height={150} /> : a?.file && <Paper sx={{backgroundColor:"#ccc",alignItems: "center",justifyContent: "center"}}>
                        <Group my={5} align={"center"} position={"center"}>
                            {a?.type === "Audio" && <IconMusic size={"30"} color={"white"}/>}
                            {a?.type === "Video" && <IconVideo size={"30"} color={"white"}/>}
                            {a?.type === "Document" && <IconFile size={"30"} color={"white"}/>}
                        </Group>
                    </Paper>}
                    {a?.message?.split("\n")?.map(b => <Text size={"xs"}>{b}</Text>)}
                    {a?.caption?.split("\n")?.map(b => <Text size={"xs"} color={"dimmed"}>{b}</Text>)}
                </Paper>
            })}
        </Stack> :<Text size={"xs"} sx={{cursor: "pointer"}} onClick={() => setIsEdit(initialOpen)} align={"center"} variant={"gradient"} my={5}>+ Add Message</Text>}
    </Paper>
}
export default MessageNode;

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Grid, Paper, Text, Button, Group, Badge, Stack, Divider, Modal} from "@mantine/core";
import {useNavigate} from "react-router-dom";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {PageLoading, Header, IconView} from "../Components/PageComponents";
import {BoldText, SmallText} from "../Components/TextComponents";
import moment from "moment";
import {ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {ActionUserLogout} from "../../store/actions/user";
import {FilledTextInput} from "../Sequence/CustomButton";
const PageHeading = {
    title: "Devices",
    subTitle: "It will Show All of Your wba Devices",
    buttons: [{type: "refresh", title: "Refresh"},{type: "logout", title: "Logout"}]
}


const DeviceList = () =>{
    const {userId} = useSelector(state => state.user);
    const [isLoading,setIsLoading] = useState(true);
    const [devices,setDevices] = useState([]);
    const dispatch = useDispatch();


    const handleLogout = () =>{
        dispatch(ActionShowPopup({
            title: "Logout",
            content: "Are you sure want to Logout ?",
            successTitle: "Logout",
            cancelTitle: "Cancel",
            onSuccess: () =>{
                dispatch(ActionUserLogout());
                navigation("/")
            }
        }))
    }

    useEffect(() =>{
        getDeviceList();
    },[]);
    const getDeviceList = async () =>{
        setIsLoading(true)
        const list = await RequestGet(`${CloudApi.deviceList}/${userId}`);
        setDevices(list?.devices || []);
        setIsLoading(false);
        setIsLoading(false);

    }
    const navigation = useNavigate();
    const switchDevice = (deviceId) =>{
        navigation(`/device/${deviceId}`);
    }
    const statusArray = [{color: "red", value: "Inactive"},{color: "green", value: "Active"},{color: "red", value: "Expired"}]
    return <>
        <Header {...PageHeading} buttonProps={[{onClick: getDeviceList}, {onClick: handleLogout}]} />
        <PageLoading isLoading={isLoading}>
            <Grid mt={10}>
                {devices?.map(device =>{
                    return <Grid.Col span={12}  md={4} lg={3} xl={3}>
                        <Paper shadow={"md"} withBorder p={5} sx={{height: device?.status !== 2 ? (device?.r365 ? 200: 180): 80}}>
                            <Group position={"apart"}>
                                <DeviceNameEdit name={device?.name} sessionId={device?.sessionId} onRefresh={getDeviceList} />
                                <Group>
                                    <Badge color={statusArray?.[device?.status]?.color}>{statusArray?.[device?.status]?.value}</Badge>
                                </Group>
                            </Group>
                            <Divider/>
                            {device?.r365 && <Stack my={3} sx={{gap: 3}}>
                                <Group position={"apart"}>
                                    <BoldText text={"R365"} />
                                    <Group>
                                        {device?.r365?.productId?.map(a => <Badge>{a}</Badge>)}
                                    </Group>
                                </Group>
                                <Divider />
                            </Stack>}
                            {device?.status !== 2 && <>
                                <Group position={"apart"} my={5}>
                                    <Stack sx={{gap: 3}}>
                                        <BoldText text={"Plans"}/>
                                        <SmallText text={`Expires  ${moment.unix(device?.expiry).fromNow()}`}/>
                                    </Stack>
                                    <Text>{device?.activePlans?.map(b => <Badge>{b}</Badge>)}</Text>
                                </Group>
                                <Divider/>
                                <>
                                    <BoldText text={"Connected Whatsapp"} my={5}/>
                                    {device?.connected ? <Group position={"apart"}>
                                        <Text size={"xs"}>{device?.connected?.waName}</Text>
                                        <Text size={"xs"} color={"dimmed"}>{device?.connected?.waNumber}</Text>
                                    </Group> : <Text size={"xs"}>None</Text>}
                                </>
                                <Group position={"center"} mt={10}>
                                    <Button compact fullWidth variant={"outline"}
                                            onClick={() => switchDevice(device?.alias)}>View</Button>
                                </Group>
                            </>}
                            {device?.status === 2 && <>
                                <Group position={"center"} my={15}>
                                    <Badge color={"red"}>No Active Plans</Badge>
                                </Group>
                            </>}
                        </Paper>
                    </Grid.Col>
                })}
            </Grid>
        </PageLoading>
    </>
}
export default DeviceList;

const DeviceNameEdit = ({sessionId,name, onRefresh}) =>{

    const [isEdit,setIsEdit] = useState(false);
    const [title,setTitle] = useState(name || "");
    const [isLoading,setIsLoading] = useState(false);
    const [isError,setIsError] = useState(false);
    const dispatch = useDispatch();


    const UpdateDeviceName = async () =>{
        if(title?.trim() !== ""){
            setIsError(false)
            setIsLoading(true);
            const data = await RequestPost(`${CloudApi.deviceOperations}/deviceNameUpdate/${sessionId}`, {name: title});
            dispatch(ActionShowAlert({message: data?.message}))
            setIsEdit(false);
            onRefresh();
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }


    return   <>
            <Modal title={<b>{"Update Device name"}</b>} opened={isEdit} onClose={() => setIsEdit(false)}>
                    <FilledTextInput label={"Device Name"} value={title} onChange={setTitle} limit={25} />
                    <Group position={"center"} mt={25}>
                        <Button loading={isLoading} onClick={UpdateDeviceName}>Update</Button>
                    </Group>
            </Modal>
            <Group>
                <Text weight={"bold"} variant={"gradient"} align={"center"}>{name}</Text>
                <IconView iconType={"edit"} onClick={() => setIsEdit(true)} />
            </Group>
        </>
}
import React, {Component, useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {
    Button,
    Grid,
    Text,
    Select,
    Anchor,
    Paper,
    Checkbox,
    Group,
    Stack,
    TextInput,
    Pagination,
    Drawer
} from "@mantine/core";
import * as XLSX from "xlsx";
import {useDispatch, useSelector} from "react-redux";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {IconView, PageLoading} from "../Components/PageComponents";
import {IconSearch, IconSquare, IconSquareCheck, IconTrash} from "@tabler/icons";

const AddBulkMembers = ({sessionId,programId,onClose,onRefresh}) =>{
    const {contactFolders,programs} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [isUploading,setIsUploading] = useState(false);
    const [userType,setUserType] = useState("ContactFolder");
    const [selectedFolder,setSelectedFolder] = useState();

    const [numbersList,setNumbersList] = useState([]);
    const [selected,setSelected] = useState([]);
    const [manual,setManual] = useState([]);
    const [search,setSearch] = useState("");
    const [page,setPage] = useState(1);

    const [excelErrors,setExcelErrors] = useState([]);
    const handleUpload = (e) => {
        console.log('upload calling');
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, {type: 'binary'});
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];
            /* Convert array to json*/
            let [title,...dataparsed] = XLSX.utils.sheet_to_json(ws, {header:1});
            let error = [];
            if(!title.includes('mobile')) error.push('mobile Required');
            if(!title.includes('name')) error.push('name Required');
            if(error.length > 0){
                setExcelErrors(error);
            }else{
                const numberIndex = title.indexOf('mobile');
                const nameIndex = title.indexOf('name');
                const emailIndex = title.indexOf('email');
                let excelNumbers = dataparsed.map((a,i)=> {
                    return {mobile: a?.[numberIndex]?.toString(),name: a?.[nameIndex], email: a?.[emailIndex]}
                });
                const users = excelNumbers.filter(a => ![null,undefined,''].includes(a?.mobile));
                setExcelErrors([]);
                setNumbersList(users);
            }
        };
        reader.readAsBinaryString(f)
    }
    useEffect(() =>{
        setNumbersList([]);
        setSelected([]);
        setManual([]);
        setSearch("");
        setPage(1);
    },[userType]);

    useEffect(() =>{
        setNumbersList([]);
        if(userType && selectedFolder){
            fetchNumbers();
        }
    },[selectedFolder]);

    const fetchNumbers = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: userType, id: selectedFolder});
        setNumbersList(data?.list || [])
        setIsLoading(false);
    }
    const handleSelectAll = () =>{
        setSelected(numbersList?.map(a => a?.mobile))
    }
    const handleDeselectAll = () =>{
        setSelected([])
    }
    const handleStatus = (mobile) =>{
        if(selected?.includes(mobile)){
            setSelected(selected?.filter(a => a !== mobile));
        }else{
            setSelected([...selected,mobile]);
        }
    }
    const handleSearch = () =>{}
    const onSave = async (users) =>{
        setIsUploading(true);
        const data = await RequestPost(`${CloudApi.programMembers}/${sessionId}/${programId}`,{type: "bulkAdd", users});
        setIsUploading(false);
        onClose();
        onRefresh();
    }

    return <Drawer opened={true} size={"60%"} position={"right"} onClose={onClose} title={<b>Add Members</b>}>
        <Grid mx={10}>
                <Grid.Col span={6}>
                    <Select
                        size={"xs"}
                        label="Import From"
                        placeholder="Import From"
                        value={userType}
                        data={[
                            {value: 'ContactFolder',label: 'Contact Folder'},
                            {value: 'Program',label: 'Other Programs'},
                            {value: 'Excel',label: 'Excel'},
                            {value: 'Manual',label: 'Manual'}
                        ]}
                        onChange={setUserType}
                    />
                </Grid.Col>
                {userType === 'ContactFolder' &&  <Grid.Col span={6}>
                    <Select size={"xs"} label={'Choose Contact Folder'} data={contactFolders} value={selectedFolder} onChange={setSelectedFolder} />
                </Grid.Col>}
                {userType === 'Program' &&  <Grid.Col span={6}>
                    <Select size={"xs"} label={'Choose Programs'} data={programs?.filter(a => a?.value !== programId)} onChange={setSelectedFolder} value={selectedFolder} />
                </Grid.Col>}
                {userType === 'Excel' && <Grid.Col span={6}>
                    <Text weight={'bold'}>Choose File</Text>
                    <input type="file" onChange={handleUpload} style={{"padding": "10px"}}/>
                    <a href={`https://coach.businesssalesmachine.com:3002/api/download-sample/members`}>Download Sample Excel</a>
                    <Text color={'yellow'}>Use <b>name</b> for User Name,<b>mobile</b> for Mobile Number as Header in Excel</Text>
                    {excelErrors?.length > 0 && <div> Errors: {excelErrors?.map(a => <div>{a}</div>)}</div>}
                </Grid.Col>}
                {userType === 'Manual' && <ManualAdd onSave={onSave} isUploading={isUploading} />}
                {userType !== "Manual" && <PageLoading isLoading={isLoading}>
                    <Grid mx={5}>
                        {numbersList.length > 0 && <>
                            <Grid.Col span={3}>
                                <TextInput size={"xs"} placeholder={"Search"} variant={'filled'} type={'search'}
                                           onChange={(e) => handleSearch(e.target.value)} value={search}/>
                            </Grid.Col>
                            <Grid.Col span={3}>{numbersList?.length !== selected.length ?
                                <Group sx={{gap: 3}} onClick={handleSelectAll}>
                                    <IconSquare size={16}/>
                                    <Text size={"xs"}>Select All</Text>
                                </Group> : <Group sx={{gap: 3}} onClick={handleDeselectAll}>
                                    <IconSquareCheck size={16}/>
                                    <Text size={"xs"}>Deselect All</Text>
                                </Group>}</Grid.Col>
                            <Grid.Col span={3}><Text size={"xs"}
                                                     weight={'bold'}>Selected: {selected.length} / {numbersList.length}</Text></Grid.Col>
                            <Grid.Col span={3}>
                                <Group position={'right'}>
                                    {numbersList?.length > 20 &&
                                        <Pagination size={"xs"} total={Math.ceil(numbersList?.length / 20)} page={page + 1}
                                                    onChange={setPage}/>}
                                </Group>
                            </Grid.Col>
                        </>}
                        {numbersList?.slice((page - 1) * 20, page * 20).map(a => {
                            return <Grid.Col span={3}>
                                <Paper shadow={'md'} withBorder>
                                    <Group sx={{gap: 3}} ml={3} onClick={() => handleStatus(a?.mobile)}>
                                        {selected?.includes(a?.mobile) ? <IconSquareCheck size={16}/> :
                                            <IconSquare size={16}/>}
                                        <Stack sx={{gap: 1}} mx={5}>
                                            <Text size={"xs"}>{a?.name?.toString()?.slice(0, 20)}</Text>
                                            <Text size="xs">{a?.mobile}</Text>
                                        </Stack>
                                    </Group>
                                </Paper>
                            </Grid.Col>
                        })}
                    </Grid>
                    <Grid.Col span={12}>
                        <Group position={"center"}>
                            <Button loading={isUploading} onClick={() => onSave(numbersList?.filter(a => selected.includes(a?.mobile)))} compact mt={10}>Add Members</Button>
                        </Group>
                    </Grid.Col>
                </PageLoading>}

        </Grid>

    </Drawer>
}

export default AddBulkMembers;

const  ManualAdd = ({onSave,isUploading}) =>{
    const [manual,setManual] = useState([{name: "", mobile: ""}])
    const [render,setRender] = useState(1)

    const handleAddMore =  () => {
        setManual([...manual, {name: "", mobile: ""}]);
        setRender(render+1)
    }
    const DeleteManual = (index) => {
        setManual(manual?.filter((a, i) => i !== index));
        setRender(render+1)
    }
    const onChangeManual = (value,key,index) =>{
        let m = manual;
        m[index] = {...m?.[index], [key]:value};
        setManual(m);
        setRender(render+1);
    }

    return <>
        {manual?.length < 10 && <Grid.Col span={6}>
            <Group position={'left'} mt={25}>
                <Button variant={'outline'} size={"xs"} compact onClick={handleAddMore} disabled={manual.length >= 10}>Add More</Button>
            </Group>
        </Grid.Col>}
            {manual.map((a,i) => {
                return <Grid.Col span={manual?.length === 1 ? 12 :6}>
                    <Group>
                        <TextInput size={"xs"} label={'Name'} value={a?.name} onChange={e => onChangeManual(e.target.value,'name',i)} />
                        <TextInput size={"xs"} label={'Mobile (Format: +919876543210)'} value={a?.mobile} onChange={e => onChangeManual(e.target.value,'mobile',i)} />
                        <IconView iconType={"delete"} onClick={() => DeleteManual(i)} />
                    </Group>
                </Grid.Col>
            })}
        <Grid.Col span={12}>
            <Group position={"center"}>
                <Button onClick={() => onSave(manual)} compact mt={10} loading={isUploading}>Add Members</Button>
            </Group>
        </Grid.Col>
    </>
}

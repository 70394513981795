import {useDispatch, useSelector} from "react-redux";
import React,{useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {ActionShowAlert} from "../../store/actions/alert";
import {Autocomplete, Button, Divider, Group, Modal, SegmentedControl, Text, TextInput} from "@mantine/core";
import {CheckBoxView, RadioButtonView} from "../Components/PageComponents";

const ProgramCreate = ({onClose, onRefresh, isClone,details, isEdit}) =>{
    const {sessionId,userId,ProGroups} = useSelector(state => state.active);
    const [title,setTitle] = useState(details?.title || "");
    const [programType,setProgramType] = useState(details?.programType || "single");
    const [groupName,setGroupName] = useState(details?.groupName || "");
    const [isLoading,setIsLoading] = useState(false);
    const dispatch= useDispatch();
    const [optin,setOptin] = useState(false);
    const [daily,setDaily] = useState(false);
    const [members,setMembers] = useState(false);
    const [settings,setSettings] = useState(true);
    const [resetDays,setResetDays] = useState(false);
    const [isError,setIsError] = useState(false);

    const AddPrograms = async () =>{
        if(title?.trim() !== "" && (programType === "single" || groupName?.trim() !== "")){
            setIsError(false);
            setIsLoading(true);
            if(isClone){
                const data = await RequestPost(`${CloudApi.programs}/clone/${sessionId}`,{
                    userId,programId: details?.id,title,groupName:programType === 'single'? undefined: groupName,programType, options:{optin,daily,members,settings,resetDays}
                });
                dispatch(ActionShowAlert({message: data?.message}));
            }else{
                const data = await RequestPost(`${CloudApi.programs}/create/${sessionId}`,{title,groupName:programType === 'single'? undefined: groupName,programType,userId, id: isEdit ? details?.id : undefined});
                dispatch(ActionShowAlert({message: data?.message}));
            }
            setIsLoading(false);
            onClose();
            onRefresh();
        }else{
            setIsError(true);
        }


    }

    return  <Modal size={"50%"} opened={true} title={<b>Create Program</b>} onClose={onClose}>
        <TextInput size={"xs"} label={"Program Title"} value={title} onChange={(e) => setTitle(e?.target.value)} error={isError && title?.trim() === "" ? "Title is Required": ""} />
        <SegmentedControl
            mt={10}
            size={"xs"}
            data={[{label: "Individual Program",value: 'single'},{label: "Group Program",value: 'group'}]}
            value={programType}
            onChange={setProgramType}
        />
        {programType === "group" && <Autocomplete data={ProGroups || []}  size={"xs"} label={"Group Name"} value={groupName} onChange={setGroupName} error={isError && programType === "group" && groupName?.trim() === "" ? "Group Name is Required": ""}/>}

        {isClone && <>
            <Divider labelPosition={'center'}  label={<Text color={"dimmed"}>Clone this program's</Text>} />
            <CheckBoxView text={"Optin Messages & Delayed Messages"} onClick={setOptin} isSelected={optin} />
            <CheckBoxView text={"Daily Messages"} onClick={setDaily} isSelected={daily} />
            <CheckBoxView text={"Settings"} onClick={setSettings} isSelected={settings} />
            <CheckBoxView text={"Members"} onClick={setMembers} isSelected={members} />

            {members && <Group>
                <RadioButtonView text={"Keep User Days."} isSelected={!resetDays} onClick={() => setResetDays(!resetDays)} />
                <RadioButtonView text={"Reset  Days to 0"} isSelected={resetDays} onClick={() => setResetDays(!resetDays)} />
            </Group>}
            {members && <Text color={"orange"}>It will Not Send Optin Messages to Members .. It will Keep same Status Of users. {resetDays && `It Will Reset All user Days to 0`}</Text>}
        </>}

        <Group position={"center"} mt={25}>
            <Button fullWidth onClick={AddPrograms} loading={isLoading}>Create</Button>
        </Group>
    </Modal>
}
export default ProgramCreate;

import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {Divider, Group, Loader, Paper, Stack} from "@mantine/core";
import {BoldText, SmallText} from "../Components/TextComponents";
import {DatePicker} from "@mantine/dates";
import {IconWithText} from "../Components/PageComponents";
import {IconCheck, IconChecks, IconCircleX, IconInfoCircle, IconUsers} from "@tabler/icons";

const UserMessageStats = () =>{
    const {sessionId} = useSelector(state => state.active);

    const [date,setDate] = useState(new Date());
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();

    useEffect(() =>{
        getDetails();
    },[date])

    const getDetails = async () =>{
        setIsLoading(true);
        const details = await RequestPost(`${CloudApi.msgStats}/${sessionId}`,{date});
        setDetails(details?.result);
        setIsLoading(false);
    }
    console.log("JK",details);

    return <Paper shadow={"md"} withBorder p={5} sx={{width: 500}}>
        <Stack sx={{gap: 3}}>
            <Group position={"apart"} m={5}>
                <BoldText text={"Message Stats"} />
                <DatePicker value={date} onChange={setDate} size={"xs"} />
            </Group>
            <Divider />
            {isLoading ? <Group position={"center"}>
                <Loader />
            </Group>: <>
                <Group>
                    <IconWithText text={details?.notSent || 0} icon={<IconCircleX size={14} color={"red"}/>} label={"NotSent"}  />
                    <IconWithText text={details?.total || 0} icon={<IconUsers size={14} />} label={"Total"} />
                    <IconWithText text={details?.sent || 0} icon={<IconCheck size={14} />} label={"Sent"} />
                    <IconWithText text={details?.delivered || 0} icon={<IconChecks size={14} color={"lightgrey"} />} label={"Delivered"} />
                    <IconWithText text={details?.read || 0} icon={<IconChecks size={14} color={"green"} />} label={"Read"} />
                    <IconWithText text={details?.failed || 0} icon={<IconInfoCircle size={14} color={"red"} />} label={"Failed"} />
                </Group>
                <Divider />
                <Group position={"apart"}>
                    <BoldText text={"Received"} />
                    <SmallText text={details?.received || 0} />
                </Group>
                {details?.reason  && Object.keys(details?.reason)?.length > 0 && <>
                    <Divider label={"Error Reasons"} labelPosition={"center"} labelProps={{color: "dimmed"}} />
                    {Object.keys(details?.reason)?.map(a =>{
                        return <Group position={"apart"}>
                            <SmallText text={a} />
                            <SmallText text={details?.reason?.[a] || 0} />
                        </Group>
                    })}
                </>}
            </>}
        </Stack>

    </Paper>
}

export default UserMessageStats;
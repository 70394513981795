const initialState = {
    userId: "",
    sessionId: "",
    prefrences: {},
    prefUsed: {},
    status: 0,
    activePlans: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "UpdateActiveSession":
            return {...state,...action.data};
        case "UpdatePrefUsed":
            return {...state,prefUsed: {...(state?.prefUsed || {}),...action.data}}
        case 'UserLogout':
        case 'DeleteActiveSession':
            return initialState;
        default:
            return state;
    }
};

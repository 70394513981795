import React,{useEffect, useState} from "react";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {useSelector} from "react-redux";
import moment from "moment";
import {Badge, Group, Text,Button,Pagination} from "@mantine/core";
import {IconView, IconWithText, PageLoading, TableView} from "../Components/PageComponents";
import {convertUnixToFormat} from "../../Constants/Functions";
import {IconCheck, IconChecks, IconCopy, IconEye, IconMessage, IconRefresh, IconX} from "@tabler/icons";
import {Colors} from "../../Constants/Colors";
import {BoldText} from "../Components/TextComponents";

const ProgramSchedules = ({programId}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [schedules,setSchedules] = useState([]);
    const [total,setTotal] = useState(1);
    const [page,setPage] = useState(1);

    useEffect(() =>{
        getSchedules();
    },[page])
    const getSchedules = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.programSchedules}/${sessionId}/${programId}`,{page});
        setSchedules(data?.schedules || []);
        setTotal(data?.total);
        setIsLoading(false);
    }

    return <>
        <Group position={"apart"} my={5}>
            <BoldText text={`Total Results: ${total}`} />
            <Group>
                {total > 10 && <Pagination size={"xs"} page={page} total={Math.ceil(total / 10)} onChange={setPage}/>}
                <Button compact size={"xs"} leftIcon={<IconRefresh />} onClick={getSchedules}>Refresh</Button>
            </Group>

        </Group>
        <PageLoading isLoading={isLoading}>
            <TableView mt={10} headers={["Time","Title","Contacts","Stats"]}>
                {schedules?.map(b =>{
                    return <tr>
                        <td width={"20%"}>{convertUnixToFormat(b?.sendTime,'LT')}</td>
                        {/*<td width={"20%"}>{b?.type || "Schedule"}</td>*/}
                        <td width={"20%"}><Text lineClamp={1}>{b?.title}</Text></td>
                        <td width={"20%"}>{b?.contacts?.count || b?.contacts?.numbers?.length|| b?.contacts?.members?.length} {b?.contacts?.type}</td>
                        <td width={"20%"}>
                            {b?.sendTime <= moment().unix() ? <Group position={"apart"}>
                                <Group sx={{gap: 3}}><IconMessage size={18} color={Colors.Primary} /><Text>{b?.logInfo?.msgLength}</Text></Group>
                                <Group sx={{gap: 3}}><IconCheck  size={18} color={'grey'} /><Text>{b?.logInfo?.sent}</Text></Group>
                                <Group sx={{gap: 3}}><IconChecks  size={18} color={'grey'} /><Text>{b?.logInfo?.delivered}</Text></Group>
                                <Group sx={{gap: 3}}><IconChecks  size={18} color={'green'} /><Text>{b?.logInfo?.read}</Text></Group>
                                <Group sx={{gap: 3}}><IconX size={18} color={'red'} /><Text>{b?.logInfo?.failed}</Text></Group>
                            </Group>:  <Group position={"center"}>
                                <Badge>Upcoming</Badge>
                            </Group>}
                        </td>
                    </tr>
                })}
            </TableView>
        </PageLoading>
    </>
}
export default ProgramSchedules;

import {
    Grid,
    Paper,
    Group,
    Stack,
    Divider,
    Modal,
    Button,
    Text,
    NumberInput,
    SegmentedControl,
    Select
} from "@mantine/core";
import {BoldText, SmallText} from "../../Pages/Components/TextComponents";
import {Header, IconView, PageLoading} from "../../Pages/Components/PageComponents";
import React,{useEffect, useRef, useState} from "react";
import MessageOptions from "../../Pages/Schedule/MessageOptions";
import {R365Api, RequestPost} from "../../Layouts/RequestManager";
import {useSelector} from "react-redux";
import PreviewScheduleMessage from "../../Pages/Components/PreviewScheduleMessage";
import ProductSelect from "./ProductSelect";
import ModalAddMessage from "../Components/ModalAddMessage";

const Messages = () =>{
    const {r365} = useSelector(state => state.active);
    const [productId,setProductId] = useState(r365?.productId?.[0]);
    const [isLoading,setIsLoading] = useState(false);
    const [isAdd,setIsAdd] = useState();
    const [messages,setMessages] = useState({});

    useEffect(() =>{
        getDetails();
    },[productId]);

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${R365Api.UpdateMessages}/list/${productId}`,{});
        setMessages(data?.messages);
        setIsLoading(false);
    }
    const DeleteMessage = async (msgType,deleteIndex = 0) =>{
        const data = await RequestPost(`${R365Api.UpdateMessages}/delete/${productId}`,{msgType,deleteIndex});
        getDetails();
    }

    const list = [
        {title: "Initial",type: "initial",subTitle: "After Register Send an Initial Message"},
        {title: "After Success",type: "success",subTitle: "After User Verified Send Messages"},
        {title: "Plan Upgrade",type: "upgraded",subTitle: "User Upgraded the Plan "},
        {title: "No Next Part",type: "noNext",subTitle: "Today Messages Completed."},
        {title: "Expired",type: "expired",subTitle: "After User Expired"},
        {title: "Expiry Reminder",type: "expiryReminder",subTitle: "Before Expiry Send Reminder Before few Days"},
        {title: "Unread Stop",type: "unreadStop",subTitle: "Stop Message After Stopped"},
        {title: "Unread Stop Reminder",type: "unreadStopReminder",subTitle: "Inform User Your account Is Stoped for few Days"},
        {title: "Unread Resume",type: "unreadResume",subTitle: "After User Clicked Resume"},

        {title: "Pause",type: "pause",subTitle: "User Initiated Pause"},
        {title: "Resume",type: "resume",subTitle: "User Initiated Pause"},
        {title: "PausedSuccess",type: "paused",subTitle: "After Paused message for Resume"},
        {title: "ResumedSuccess",type: "resumed",subTitle: "After Resumed success Message"},
        {title: "PausedAlready",type: "alrPaused",subTitle: "Already Paused Please Resume"},
        {title: "AlreadyActive",type: "alreadyActive",subTitle: "Account Already in Active State"},
        {title: "No Active Plans",type: "noPlans",subTitle: "No Active Account"},

        {title: "Before 30 Mins of Session end",type: "active30",subTitle: "It will send Message Before 30 Mins of Session end"},
        {title: "Reminder to Active Session",type: "activeRemind",subTitle: "It will send Message For Next 2 Days."},
        {title: "Receive Book From Tomorrow",type: "activeTomorrow",subTitle: "If User Click Start Message To Be send"},
        {title: "Receive Book From Today",type: "activeToday",subTitle: "If User Click Before 6 Inform"},
    ]

    return <>
        <Header title={"Messages For R365"} buttons={[{type: "refresh",title: "Refresh"}]} buttonProps={[{onClick: getDetails}]} subTitle={"Here You can Add All Default Messages"} />
          {isAdd && <ModalAddMessage {...isAdd} onRefresh={getDetails} details={isAdd?.details} productId={productId} onClose={() => setIsAdd()} />}
        <ProductSelect productId={productId} setProductId={setProductId} />
        <PageLoading isLoading={isLoading}>
            <Grid>
                {list?.map(a =>{
                    const isSuccess = a?.type === "success" || a?.type === "activeRemind";

                    return <Grid.Col span={isSuccess? 12 : 6}>
                        <Paper shadow={"md"} withBorder p={5}>
                            <Group position={"apart"}>
                                <Stack sx={{gap: 1}}>
                                    <BoldText text={a?.title} />
                                    <SmallText text={a?.subTitle} color={"dimmed"} />
                                </Stack>
                                {!isSuccess ? <Group>
                                    <IconView iconType={"edit"} onClick={() => setIsAdd({...a,details: messages?.[a?.type]?.editOptions})} />
                                    <IconView iconType={"delete"} onClick={() => DeleteMessage(a?.type, 0)} />
                                </Group>:  <IconView iconType={"add"} onClick={() => setIsAdd({...a, details: undefined})}/>}
                            </Group>
                            <Divider />
                            {!isSuccess ? <PreviewScheduleMessage message={messages?.[a?.type]} />: <Stack mt={10} sx={{gap: 1}}>
                                {messages?.[a?.type]?.map((b,i)=> {
                                    return <Stack sx={{gap: 3}}>
                                        <Group position={"apart"}>
                                            <Group>
                                                <BoldText text={`${i+1} .`} />
                                                <PreviewScheduleMessage message={b} />
                                            </Group>
                                            <Group>
                                                {a?.type === "success" && <SmallText text={`After ${b?.mins || 0} mins`}/>}
                                                <IconView iconType={"edit"}  onClick={() => setIsAdd({...a, details: b?.editOptions, editIndex: i})}/>
                                                <IconView iconType={"delete"} onClick={() => DeleteMessage(a?.type, i)} />
                                            </Group>
                                        </Group>
                                        <Divider />
                                    </Stack>
                                })}
                            </Stack>}
                        </Paper>
                    </Grid.Col>
                })}
            </Grid>
        </PageLoading>
    </>
}

export default Messages;


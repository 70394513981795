import React, {useEffect, useState,forwardRef, useImperativeHandle} from 'react';
import {
    Select,
    Grid,
    Text,
    Badge,
    Group,
    Divider,
    Stack,
    Button, Tabs, TextInput,  Paper,
} from "@mantine/core";
import {useSelector} from "react-redux";
import {CloudApi, CloudUrl, RequestGet} from "../../Layouts/RequestManager";
import TemplatePreview from "../Templates/PreviewTemplate";
import {LoadingText} from "../Components/PageComponents";
import {useMediaQuery} from "@mantine/hooks";

const WBAMessageCreate = forwardRef((props, ref) => {
    const {sessionId,details} = props;
    const {templates} = useSelector(state => state.active);
    const [templateId, setTemplateId] = useState(details?.message?.templateId);
    const [tempDetailsLoading, setTempDetailsLoading] = useState(false);
    const [templateDetails, setTemplateDetails] = useState({});
    const [variables, setTemplateVariables] = useState([]);
    const [isError, setIsError] = useState(false);
    const [render, setRender] = useState(0);
    const isMobile = useMediaQuery('(max-width: 700px)');
    const handleVariables = (i,key,value)=>{
        const v = variables;
        v[i]={...v[i],value};
        setTemplateVariables(v);
        setRender(render+1);
    }
    useEffect(() =>{
        if(details) {
            setTemplateId(details?.message?.templateId);
            setTemplateVariables(details?.message?.variables);
        }},[details])
    useEffect(() => {
        if (templateId) getTemplatesDetails();
    }, [templateId]);

    const getTemplatesDetails = async () => {
        setTempDetailsLoading(true);
        const temp = await RequestGet(`${CloudApi.templateDetails}/${sessionId}/${templateId}`);
        console.log("temp",temp);
        console.log("temp1",temp?.details?.variables);

        setTemplateDetails(temp?.details);
        setTemplateVariables(details?.message?.variables || temp?.details?.variables || [])
        setTempDetailsLoading(false);

    }

    useImperativeHandle(ref, () => ( {
        isValid() {
            const message = {
                templateId,
                name: templateDetails?.name,
                language: templateDetails?.language ||"en_US",
                variables,
                buttons: templateDetails?.message?.buttons
            };
            const isValid = !!templateId;
            setIsError(!isValid);
            return {message,isValid};
        },
        reset(){
            setTemplateId("");
            setTemplateVariables([]);
            setTemplateDetails({})
        }
    }));

    return <Grid.Col span={12} p={5}>
        <Group sx={{alignItems : "baseline"}}>
            <Grid sx={{width: isMobile ? "100%" :"40%",alignItems: "baseline"}}>
                {sessionId && <Grid.Col span={12}>
                    <Select required size={"xs"} searchable label={"Choose Template"} placeholder={"Choose Template"}
                            data={templates || []}
                            value={templateId}
                            onChange={setTemplateId}
                            error={isError && !templateId ? "Template is Required": ""}
                            />
                </Grid.Col>}
                {variables && !tempDetailsLoading && <Grid.Col span={12} lg={4} xl={4}>
                    {variables?.map((a, i) => {
                        return <TextInput
                            value={a?.value}
                            label={a?.key}
                            placeholder="Pick one"
                            onChange={(e)=> handleVariables(i,a?.key,e.target.value)}
                        />
                    })}
                </Grid.Col>}
            </Grid>
            <Grid sx={{width: isMobile ? "100%" :"60%",alignItems:"baseline"}}>
                {sessionId && templateId && <Grid.Col span={12}>
                    {tempDetailsLoading ? <LoadingText label={"Fetching Template Details"} /> : <>
                        <Paper shadow={"md"} withBorder>
                            <Stack sx={{gap: 3}} p={5}>
                                <Text weight={"bold"} variant={"gradient"}>Message Preview</Text>
                                <TemplatePreview message={templateDetails?.message} variables={variables} showVar={false} />
                            </Stack>
                        </Paper>
                    </>}
                </Grid.Col>}
            </Grid>
        </Group>
        {isError && <Text color={"red"}> Please Fill All Mandatory Fields</Text>}
    </Grid.Col>
});
export default WBAMessageCreate;

import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {
    Grid,
    Modal,
    MultiSelect,
    Button,
    Group,
    Text,
    Badge,
    FileButton,
    TextInput,
    Select,
    Pagination
} from "@mantine/core";
import {IconBrandGoogleAnalytics, IconCopy, IconDownload, IconEdit, IconSend, IconUpload} from "@tabler/icons";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import FormCreate from "./FormCreate";
import {Header, IconView, IconWithText, PageLoading, PrefCountView, TableView} from "../Components/PageComponents";
import {useNavigate} from "react-router-dom";
import {WBASelects} from "../../Constants/Strings";
import {convertUnixToFormat, showMaxAlert} from "../../Constants/Functions";
import {Colors} from "../../Constants/Colors";
const PageHeading = {
    title: "Whatsapp Forms",
    subTitle: "It will Show The Forms Created in Whatsapp",
     buttons:[
        {type: "add",title: "Create Form"},
        {type: "refresh",title: "Refresh"},
        {type: "refresh",title: "Sync Forms"}]
}
const Forms = () =>{
    const {sessionId,alias, prefrences,prefUsed} = useSelector(state => state.active);
    const [flows,setFlows] = useState([]);
    const [filtered,setFiltered] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [isAdd,setIsAdd] = useState(false);
    const [page,setPage] = useState(1);
    const [isClone,setIsClone] = useState();
    const dispatch = useDispatch();

    useEffect(()=>{ getForms() },[]);
    const getForms = async () =>{
        setIsLoading(true);
        const data = await RequestGet(`${CloudApi.whatsappFlows}/list/${sessionId}`);
        setFlows(data?.flows);
        setIsLoading(false);
    }
    const syncFlows = async () =>{
        await RequestGet(`${CloudApi.whatsappFlows}/sync/${sessionId}`);
        getForms();
    }
    const onCloseAdd =(isRefresh = false) =>{
        setIsAdd(false);
        setIsClone();
        if(isRefresh) getForms();
    }
    const handleCreate = () =>{
        if(prefrences?.forms > prefUsed?.forms){
            setIsAdd(true)
        }else{
            showMaxAlert({dispatch, type: "Forms"})
        }
    }



    return <>
        <Header {...PageHeading} buttonProps={[{onClick: handleCreate},{onClick: getForms},{onClick: syncFlows}]}  />
        <PageLoading isLoading={isLoading}>
            <Grid>
                <Grid.Col span={4}>
                    <PrefCountView  type={"forms"} />
                </Grid.Col>
                <Grid.Col span={4}>
                    <FilterForms flows={flows} setFiltered={setFiltered} setPage={setPage} />
                </Grid.Col>
                {/*<Grid.Col span={4}>*/}
                {/*    <Text mt={10} align={"center"} size={"md"} mx={10}>Showing {filtered?.length} / {flows?.length} Results</Text>*/}
                {/*</Grid.Col>*/}
                <Grid.Col span={4}>
                    <Pagination position={"right"} mt={10} size={"xs"} total={Math.ceil(filtered?.length/ 10)} value={page} onChange={setPage} />
                </Grid.Col>
            </Grid>
            <TableView headers={["Name","Status","last Updated","Options"]}>
                {filtered?.slice((page-1) * 10, page * 10)?.map(flow => <FormList flow={flow} refresh={getForms} onClone={setIsClone} />)}
            </TableView>
            {isAdd && <FormCreate onClose={onCloseAdd} sessionId={sessionId} />}
            {isClone && <FormCreate onClose={onCloseAdd} sessionId={sessionId} isClone={isClone} />}
        </PageLoading>
    </>
}
export default Forms;

const FilterForms = ({flows,setFiltered,setPage})=>{
    const [search,setSearch] = useState("");
    const [status,setStatus] = useState("all");
    const [render,setRender] = useState(0);
    useEffect(() =>{
        let filtered = flows;
        if(search?.trim() !== "") filtered = filtered?.filter(a => a?.name?.includes(search?.toLowerCase()));
        if(status !== "all") filtered = filtered?.filter(a => a?.status === status);
        setFiltered(filtered);
        setRender(render+ 1);
        setPage(1)

    },[search,flows,status, ])
    return <Group my={10}>
        <TextInput variant={"filled"} size={"xs"} placeholder={"Search Name"} value={search} onChange={e => setSearch(e.target.value)} />
         <Select variant={"filled"} size={"xs"} data={WBASelects.flowStatus} value={status} onChange={setStatus} />
    </Group>
}
const FormList = ({flow, refresh,onClone}) =>{
    const {sessionId,alias} = useSelector(state => state.active);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const FormPreview = async () =>{
        const data = await RequestGet(`${CloudApi.whatsappFlows}/preview/${sessionId}/${flow?.id}`);
        window.open(data?.url);
    }
    const DownloadFlowJson = async () =>{
        const data = await RequestGet(`${CloudApi.whatsappFlows}/download/${sessionId}/${flow?.id}`);
        window.open(data?.url);
    }
    const downloadFormLeads = async () =>{
        window.open(`${CloudApi.downloadFlowLeads}/${sessionId}/${flow?.id}`);
    }
    const formOptions = async (type) =>{
        const data = await RequestGet(`${CloudApi.whatsappFlows}/${type}/${sessionId}/${flow?.id}`);
        if(data?.status === 1){
            dispatch(ActionHidePopup());
            dispatch(ActionShowAlert({message: data?.message}));
            refresh();
        }else{
            dispatch(ActionHidePopup());
            dispatch(ActionShowAlert({message: data?.message,color: "red"}));
        }
    }
    const ShowFormOptionAlert = (type) =>{
        const list = {
            "publish":{title: "Publish Flow",content: "Once the Flow Submitted, You can't Edit. Are you sure?",successTitle: "Publish",cancelTitle: "Cancel"},
            "delete":{title: "Delete Flow",content: "Once the Flow deleted, You can't Recover. Are you sure?",successTitle: "Delete",cancelTitle: "Cancel"},
            "deprecate":{title: "Deprecate Flow",content: "Published Flows Can't be deleted. but you can deprecate. Are you sure?",successTitle: "Deprecate",cancelTitle: "Cancel"},
        }
        dispatch(ActionShowPopup({...list?.[type],onSuccess: () => formOptions(type)}));
    }

    const openEdit = ()=>{
        navigation(`/device/${alias}/form-edit`, {state: {flowId: flow?.id, flowName: flow?.name}})
    }
    const color = {DEPRECATED : "red", "PUBLISHED": "green",DRAFT: "orange"}
    return <tr>
        <td width={"15%"}>{flow?.name}</td>
        {/*<td width={"30%"}>{flow?.categories}</td>*/}
        <td width={"15%"}><Badge color={color[flow?.status]}>{flow?.status}</Badge></td>
        <td width={"20%"}>{convertUnixToFormat(flow?.lastUpdated)}</td>
        <td width={"20%"}>
            <Group position={"left"}>
                <IconView iconType={"view"} label={"Preview"} onClick={FormPreview} />
                <IconWithText text={flow?.leadCount} icon={<IconBrandGoogleAnalytics size={16} color={Colors.Primary} />} label={"Download Leads"} onClick={downloadFormLeads} />
                <IconView icon={<IconCopy />} label={"Clone Form"} onClick={() => onClone({id: flow?.id,name: flow?.name})} />
                {flow?.status === "PUBLISHED" && <IconView iconType={"delete"} label={"Deprecate"} onClick={() => ShowFormOptionAlert("deprecate")} />}
                {/*<IconView icon={<IconDownload />} label={"Download Flow Json"} onClick={DownloadFlowJson} />*/}
                {flow?.status === "DRAFT" && <>
                    <IconView iconType={"delete"} label={"Delete"} onClick={() => ShowFormOptionAlert("delete")} />
                    <IconView icon={<IconEdit />} label={"Edit Form"} onClick={openEdit} />
                    <IconView icon={<IconSend />} label={"Publish Form"} onClick={() => ShowFormOptionAlert("publish")} />
                </>}
            </Group>
        </td>
    </tr>
}

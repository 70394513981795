import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {R365Api, RequestPost} from "../../Layouts/RequestManager";
import {Button, Grid, Group, Modal, NumberInput, SegmentedControl, Select, Text} from "@mantine/core";
import {BoldText} from "../../Pages/Components/TextComponents";
import MessageOptions from "../../Pages/Schedule/MessageOptions";
import {TimeArray} from "../../Constants/Strings";

const ModalAddMessage = ({onClose,type,title, details,productId,onRefresh,editIndex,day}) =>{
    const ref1 = useRef();
    const [min,setMin] = useState(details?.mins || 1);
    const [time,setTime] = useState(details?.time || "7:00 AM");

    // const [formId,setFormId] = useState("");
    // const [formType,setFormType] = useState("Form");
    // const {forms} = useSelector(state => state.active);

    const saveMessage = async () =>{
        const msg = ref1.current?.isValid();
        if(msg?.isValid){
            if(type === "AddOn"){
                const data = await RequestPost(`${R365Api.UpdateAddOn}/create/${productId}`,{day, message: {...msg?.message,time, editOptions: {...msg?.message?.editOptions, time}},editIndex});

            }else{
                const data = await RequestPost(`${R365Api.UpdateMessages}/create/${productId}`,{msgType: type, message: {...msg?.message,mins: min,editOptions: {...msg?.message?.editOptions, mins: min}},editIndex});

            }
            onClose();
            onRefresh();
        }
    }
    return <Modal title={<BoldText size={"md"} text={title} />} opened={true} onClose={onClose} size={"90%"}>
        <Grid>
            {type === "success" && <Grid.Col span={4}>
                <Group>
                    <Text> After </Text>
                    <NumberInput size={'xs'} min={0} max={59} sx={{width: 50}} value={min} onChange={setMin}/>
                    <Text><b>Mins</b> of Member Verification</Text>
                </Group>
            </Grid.Col>}
            {/*{type === "initial" && <>*/}
            {/*    <Grid.Col span={6}>*/}
            {/*        <Group>*/}
            {/*            <SegmentedControl size={"xs"} data={["Form","Template"]?.map(a =>({label: a, value: a}))} value={formType} onChange={setFormType} />*/}
            {/*            {formType === "Form" && <Grid.Col span={4}>*/}
            {/*                <Select size={"xs"} label={"Select Form"} data={forms} value={formId} onChange={setFormId}/>*/}
            {/*            </Grid.Col>}*/}
            {/*        </Group>*/}
            {/*    </Grid.Col>*/}
            {/*</>}*/}
            {type === "AddOn" && <Grid.Col span={4}>
                <Select label={"Time"} size={"xs"} value={time} onChange={setTime} data={TimeArray?.map(a => ({label: a, value: a}))} />
            </Grid.Col>}
            <MessageOptions show={["template","session","sequence"]} ref={ref1} details={details} />
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={saveMessage}>Save Message</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}

export default ModalAddMessage;
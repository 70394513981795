import React, {useState} from 'react';
import {Button, Grid, Group, Select} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {ActionShowAlert} from "../../../store/actions/alert";
import {CloudApi, RequestPost} from "../../../Layouts/RequestManager";
import VariablesView from "./VariablesView";

const TriggerSequence = ({props,value}) =>{
    const app= "triggerSequence";
    const {sessionId,userId,sequence = []} = useSelector(state => state.active);
    const [variables,setVariables] = useState(value?.variables || [{label: "mobile", type: "map", value: "",required: true}]);
    const [seqId,setSeqId] = useState(value?.seqId || "");
    const dispatch = useDispatch();
    const [render,setRender] = useState(0);

    const handleSaveFlow = async () =>{
        const flow = {app,sessionId,seqId, id: value?.id,variables}
        const data = await RequestPost(`${CloudApi.bsmFlows}/saveDetails/${sessionId}`,{flow,step: props?.step, id: props?.id});
        dispatch(ActionShowAlert({message: data?.message}));
    }
    const updateVar = (variables) =>{
        setVariables(variables);
        setRender(render+1);
    }
    return  <>
        <Grid.Col span={3}>
            <Select label={"Choose Sequence"} data={sequence} value={seqId} onChange={setSeqId}/>
        </Grid.Col>
        <VariablesView variables={variables} props={props} updateVariables={updateVar} addVariable={false} />

        <Grid.Col span={3}>
            <Group position={'apart'} mt={25}>
                <Button onClick={handleSaveFlow} variant={'gradient'}>Save</Button>
            </Group>
        </Grid.Col>
    </>
}

export default TriggerSequence;

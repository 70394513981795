import React from "react";
import {Group, Stack} from "@mantine/core";
import {BoldText} from "../Components/TextComponents";
import {IconView} from "../Components/PageComponents";
import {IconDownload} from "@tabler/icons";

const FileLinkPreview = ({link, type}) =>{
    return <>
        <Stack p={5} sx={{border: "1px solid #ccc", borderRadius: 5}}>
            <Group position={"apart"}>
                <BoldText text={type}/>
                <IconView icon={<IconDownload />} onClick={() => window.open(link)} />
            </Group>
        </Stack>
    </>
}

export default FileLinkPreview;
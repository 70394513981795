import React, {useEffect, useState} from "react";
import {Colors} from "../Constants/Colors";
import {Alert, Header, MantineProvider, Navbar, ScrollArea, Stack, Group, Text, AppShell, Grid} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery, useViewportSize} from "@mantine/hooks";
import {useLocation, useNavigate} from "react-router-dom";
import {CloudApi, RequestGet} from "./RequestManager";
import {DeleteActiveSession, UpdateActiveSession} from "../store/actions/user";
import SideBar from "./Sidebar";
import Navigator from "./Navigation";
import {IconWithText, PageLoading} from "../Pages/Components/PageComponents";
import AlertPopup from "../Pages/Alerts/AlertPopup";
import {ActionHideAlert} from "../store/actions/alert";
import {SocketSetup} from "../Pages/Alerts/Socket";
import AdminNavigation from "./AdminNavigation";
import AdminSideBar from "./AdminSidebar";
import R365SideBar from "./R365Sidebar";
import {IconDeviceMobile, IconUser} from "@tabler/icons";
import {SmallText} from "../Pages/Components/TextComponents";
import ProfilePage from "../Pages/Device/ProfilePage";

const theme  = {
    colorScheme: 'light',
    loader: 'bars',
    primaryColor: 'violet',
    defaultGradient:{
        from: '#ff7f50',
        to: Colors.Primary,
        deg: 45
    },
    colors:{
        default: ['red',Colors.Primary]
    },
    components:{
        Header:{
            styles: {
                root: {
                    height: 40,
                    backgroundColor: Colors.Primary,
                    color: '#ffffff',
                },
            },
        },
    }
}
const Layout = () =>{
    const {isLogged,userId,email,...user} = useSelector(state => state.user);
    const {sessionId,name} = useSelector(state => state.active);
    const { height, width } = useViewportSize();
    const location = useLocation();
    const navigation = useNavigate();
    const dispatch = useDispatch();
    let [empty,device,token] = location?.pathname?.split("/");
    const [isLoading,setIsLoading] = useState(false);
    const {alert,popup} = useSelector(state => state.alert);
    const isMobile = useMediaQuery(`(max-width:  750px)`);
    const isR365 = location?.pathname?.includes("/r365");
    const isHome = location?.pathname === "/devices" || location?.pathname === "/" || location?.pathname === "/login" ;
    const fixedWidth = isHome? 2: isMobile&& isR365 ? 60: isMobile ? 30 : isR365 ? 140 : 70;
    const [isProfileOpen,setIsProfileOpen] = useState(false);

    useEffect(() =>{
        dispatch(ActionHideAlert())
        if(device === "device" && token) FetchDeviceInfo();
        if(device === "device" && token === "undefined"){
            navigation("/")
        }
    },[token]);

    const FetchDeviceInfo = async () =>{
        setIsLoading(true);
        dispatch(DeleteActiveSession());
        const device = await RequestGet(`${CloudApi.deviceDetails}/${userId}/${token}`);
        console.log("device",device);
        if(device?.device?.status !== 1){
            navigation("/")
        }else{
            let info = {...device?.device, connected: {...device?.device?.connected, token: undefined}}
            dispatch(UpdateActiveSession(info));
        }
        setIsLoading(false);
    }

    return <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
        <AppShell
            padding="md"
            navbar={isLoading || (location?.pathname === "/" && email !== "admin@magaduka.in")? <></> :
                <SidebarView width={fixedWidth} isR365={location?.pathname?.includes("/r365")} isAdmin={email === "admin@magaduka.in"} />}
            header={<Header height={40} sx={{backgroundColor:'rgb(90, 50, 168)'}}>
                <Group position={"apart"}>
                    <Text sx={{cursor: "pointer"}} onClick={() => navigation("/")} size={25} color={"white"} weight={"bold"} mx={10}>BusinessSalesMachine</Text>
                    {!isMobile && <Group mx={30}>
                        {name && <Group sx={{
                            gap: 3,
                            border: "1px solid",
                            borderRadius: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                            backgroundColor: "white"
                        }}>
                            <IconDeviceMobile size={16} color={Colors.Primary}/>
                            <SmallText size={16} text={name} color={Colors.Primary}/>
                        </Group>}
                        {isLogged && <Group onClick={() => setIsProfileOpen(true)} sx={{
                            gap: 3,
                            border: "1px solid",
                            borderRadius: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                            backgroundColor: "white",
                            cursor: "pointer"
                        }}>
                            <IconUser size={16} color={Colors.Primary}/>
                            <SmallText size={16} text={user?.name} color={Colors.Primary}/>
                        </Group>}
                    </Group>}
                </Group>
            </Header>}
            styles={(theme) => ({main: {
                paddingTop: "50px",
                paddingLeft: isHome ? "2px" : isR365 ? "140px": isMobile ? "30px" :(![ "/","/login"].includes(location?.pathname) || email === "admin@magaduka.in")? "70px": "2px",
                paddingRight: "2px"
            }})}
        >
            {isProfileOpen && <ProfilePage onClose={() => setIsProfileOpen(false)} />}
            <PageLoading isLoading={isLoading}>
                <Stack sx={{gap: 3}} mx={10}>
                    <AlertPopup opened={popup != undefined} {...popup} />
                    {alert?.message && <Alert
                        title={alert?.title || ''}
                        color={alert?.color || "green"}
                        withCloseButton
                        onClose={() => alert?.onClose() || dispatch(ActionHideAlert())}
                    >
                        {alert?.message}
                    </Alert>}
                    {email === "admin@magaduka.in" ? <AdminNavigation />:<Navigator/>}
                </Stack>
            </PageLoading>
            <SocketSetup />
        </AppShell>
    </MantineProvider>
}


const SidebarView = ({isR365,isAdmin, width}) =>{
    const {isLogged,userId,email,...user} = useSelector(state => state.user);
    const location = useLocation();
    const navigation = useNavigate();
    const { height } = useViewportSize();
    // const isMobile = useMediaQuery(`(max-width:  750px)`);


    return <>
        {!isLogged ? <></>:<Navbar width={{base: width}} height={"100%"} mt={5}>
                {location?.pathname !== "/" && !isAdmin && <div style={{ width: "auto", height: height-10}}>
                    <Grid>
                        <Grid.Col sx={{padding:1}} span={isR365 ? 6: 12}>
                            <SideBar height={height} navigation={navigation} />
                        </Grid.Col>
                        {isR365 && <Grid.Col sx={{padding: 1}} span={6}>
                              <R365SideBar height={height} navigation={navigation} />
                        </Grid.Col>}
                    </Grid>
                </div>}
                {isAdmin && <div style={{ width: 70, height: height-10}}>
                    <AdminSideBar height={height} navigation={navigation} />
                </div>}
        </Navbar>}
     </>
}
export default  Layout;




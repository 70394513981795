import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import queryString from "query-string";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {ActionShowAlert} from "../../store/actions/alert";
import {PageLoading} from "../Components/PageComponents";

const AddWBA = () =>{
    const loc = useLocation();
    const {code,state} = queryString.parse(loc?.search) || {};
    const {sessionId,userId} = useSelector(state => state.active);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    useEffect(() =>{
        if(code) getDetails();
    },[code])

    const getDetails = async () =>{
        const details = await RequestPost(CloudApi.connectDevice,{code,sessionId,userId});
        dispatch(ActionShowAlert({message: details?.message, color: details?.status === 1? "green":"red"}));
        navigation(`/device/${state}`)
    }


    return <PageLoading isLoading={true} />
}

export default AddWBA;

import React, {useState} from 'react';
import {Button, Group, Modal, Stack, Text} from "@mantine/core";
import {useDispatch} from "react-redux";
import {ActionHidePopup} from "../../store/actions/alert";

const AlertPopup = ({opened,onClose,title,content,successTitle,cancelTitle,onSuccess,hideCancel, hideLoading}) =>{
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false);
    const handleClose = () =>{
        if(onClose) {
            onClose()
        }else{
            dispatch(ActionHidePopup());
        }
    }
    const handleSuccess = () =>{
        if(!hideLoading) setLoading(true);
        onSuccess();
    }
    if(loading && opened === false) setLoading(false);

    return <Modal opened={opened} onClose={handleClose} title={<Text weight={'bold'}>{title}</Text>}>
        <Stack sx={{gap: 3}}>
            {content?.split('\n')?.map(a => <Text size={'sm'} color={'dimmed'}>{a}</Text>)}
        </Stack>
        <Group my={5} position={'right'}>
            {!hideCancel && <Button onClick={handleClose} color={'gray'} size={'xs'}>{cancelTitle}</Button>}
            <Button color={'green'}  size={'xs'} onClick={handleSuccess} loading={loading}>{successTitle}</Button>
        </Group>
    </Modal>
}

export default AlertPopup;

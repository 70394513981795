import React, { useState} from "react";
import {Button, Container, Paper, PasswordInput, TextInput,Text,Group} from "@mantine/core";
import {ErrorText} from "../Components/TextComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useNavigate} from "react-router-dom";
import CryptoJS from "crypto-js";
import {useDispatch} from "react-redux";
import {ActionUserLogin} from "../../store/actions/user";
import {ActionShowAlert} from "../../store/actions/alert";

const Login = () =>{
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    const [isError,setIsError] = useState(false);
    const [error,setError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const handleLogin = async () =>{
        if(email?.trim() !== '' && password?.trim() !== ''){
            setIsLoading(true);
            const response = await RequestPost(CloudApi.login,{email,password});
            if(response?.status === 1){
                var bytes  = CryptoJS.AES.decrypt(response?.userDetails, 'coachesMagaduka');
                var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                dispatch(ActionUserLogin(decryptedData));
                dispatch(ActionShowAlert({message: 'Logged in SuccessFully',color: 'green'}));
                navigation('/');
            }else{
                setError(response?.message);
                setIsLoading(false);
            }

        }else{
            setIsLoading(false);
            setIsError(true);
        }
    }
    return  <Group position={"center"} my={100}>
            <Paper withBorder shadow="md" p={30} mt={30} radius="md" sx={{width: 500}}>
                <Text size={"xl"} align={"center"} weight={"bold"} variant={"gradient"} my={10}>BusinessSalesMachine</Text>
                <TextInput
                    label="Email"
                    placeholder="you@gmail.com"
                    name={'email'}
                    required
                    onChange={(e) =>setEmail(e.target.value)}
                    value={email}
                    error={isError && email.trim() === '' ? 'Email is Required': ''}
                />
                <PasswordInput
                    label="Password"
                    placeholder="Your password"
                    required
                    mt="md"
                    name={'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    error={isError && password.trim() === '' ? 'Password is Required': ''}
                />
                {error && <ErrorText text={error} />}
                <Button variant={'gradient'} fullWidth mt="md" onClick={handleLogin} loading={isLoading} disabled={email?.trim() === '' || password?.trim() === ''}>
                    Login
                </Button>
            </Paper>
        </Group>
}
export default Login;

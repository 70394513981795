import {Header, IconView, PageLoading} from "../../Pages/Components/PageComponents";
import {
    Button,
    Divider,
    Grid,
    Group,
    Badge, MultiSelect,
    NumberInput,
    Paper,
    SegmentedControl,
    Select,
    Stack,
    Text
} from "@mantine/core";
import {useSelector} from "react-redux";
import React,{useState,useEffect} from "react";
import {BoldText, SmallText} from "../../Pages/Components/TextComponents";
import ProductSelect from "./ProductSelect";
import {R365Api, RequestPost} from "../../Layouts/RequestManager";
import {IconCheck, IconX} from "@tabler/icons";
import {TimeArray, WBASelects, WeekDays} from "../../Constants/Strings";
const selectData = {
    daysPerWeek: WeekDays?.map((a,i) => ({label: a, value: i})),
    lang: WBASelects?.BookLang,
    allLang: WBASelects?.BookLang,
    timings: TimeArray.map(a =>({label:a,value: a}))
}

const R365Settings = () =>{
 const {r365} = useSelector(state => state.active);
 const [productId,setProductId] = useState(r365?.productId?.[0]);
 const [isLoading,setIsLoading] = useState(false);
 const [books,setBooks] = useState([]);
 const [settings,setSettings] = useState({});
 const [isAdd,setIsAdd] = useState();

 useEffect(() =>{
  getDetails();
 },[productId]);

 const getDetails = async () =>{
  setIsLoading(true);
  const data = await RequestPost(`${R365Api.UpdateSettings}/list/${productId}`,{});
  setSettings(data?.settings);
  setBooks(data?.books);
  setIsLoading(false);
 }

 const list =[
   {title: "Parts Per Day", type: "partsPerDay", options: ["number"]},
   {title: "Days Per Week", type: "daysPerWeek", options: ["daysPerWeek"]},
   {title: "Default Language", type: "lang", options: ["lang"]},
   {title: "Default Timings", type: "timings", options: ["timings"]},
   {title: "All Languages", type: "allLang", options: ["allLang"]},
   {title: "Stop Messages After Not Read Count", type: "unreadStop", options: ["number"]},
   {title: "Expiry Reminder From", type: "reminderExpiry", options: ["number"]},
   {title: "Book Parts", type: "bookIds", options: ["bookIds","updateDefaults"], size: 12},
 ]
 return <>
  <Header title={"Settings For R365"} buttons={[{type: "refresh",title: "Refresh"}]} buttonProps={[{onClick: getDetails}]} subTitle={"Here You can Add All Default Messages"} />

  <ProductSelect productId={productId} setProductId={setProductId} />
   <PageLoading isLoading={isLoading}>
       <Grid mt={20}>
        {list?.map(a =>{
             return <Grid.Col span={a?.size || 4}>
                <Paper shadow={"md"} withBorder p={5}>
                   <Group position={"apart"}>
                         <Stack sx={{gap: 1}}>
                            <BoldText text={a?.title} />
                            <SmallText text={a?.subTitle} color={"dimmed"} />
                         </Stack>
                         <Group>
                           {typeof settings?.[a?.type] !== "object" && <SmallText text={settings?.[a?.type]}/>}
                           {a?.options?.includes("updateDefaults") && <a href={`${R365Api.UpdateBooktoUser}/${productId}`} target="_blank" >
                               <Button compact>Update to Users</Button>
                           </a>}
                           <IconView iconType={"edit"} onClick={() => setIsAdd(isAdd ? undefined : a)} />
                         </Group>
                    </Group>
                    {typeof settings?.[a?.type] === "object" && <>
                        <Divider mb={5} />
                        <Group sx={{gap: 5}}>
                            {a?.type !== "bookIds" && settings?.[a?.type]?.map(b => <Badge size={"xs"}>{selectData?.[a?.type]?.find(c => c?.value === b)?.label}</Badge>)}
                            {a?.type === "bookIds" && settings?.[a?.type]?.map((b,i) => <Badge size={"xs"}>{i+1}. {books?.find(c => c?.value === b?.toString())?.label}</Badge>)}
                        </Group>
                    </>}

                     {isAdd?.type === a?.type && <AddSettings books={books} {...isAdd} details={settings?.[a?.type]} productId={productId} onClose={() => setIsAdd()} onRefresh={getDetails} />}
                </Paper>
             </Grid.Col>
            })}
     </Grid>
   </PageLoading>
  </>
}

export default R365Settings;



const AddSettings = ({onClose,type,title,options=[],books,details,productId,onRefresh,editIndex}) =>{
    const [value,setValue] = useState(details);
    const saveMessage = async () =>{
        const data = await RequestPost(`${R365Api.UpdateSettings}/create/${productId}`,{
            type,
            [type]: value
        });
        onClose();
        onRefresh();
    }

    return <Group position={"apart"}>
        {options?.map(option =>{
           return <>
                {["number","unreadStop","reminderExpiry"].includes(option) && <NumberInput size={"xs"} label={title} value={value} onChange={setValue} />}
                {["daysPerWeek","allLang","timings"].includes(option) && <MultiSelect data={selectData?.[option]} size={"xs"} label={title} value={value} onChange={setValue} />}
                {["bookIds"].includes(option) && <MultiSelect data={books} size={"xs"} label={title} value={value} onChange={setValue} />}
                {["lang"].includes(option)  && <Select data={selectData?.[option]} size={"xs"} label={title} value={value} onChange={setValue} />}
            </>
        })}
        <Group mt={20}>
            <IconView icon={<IconCheck color={"green"} />} variant={"outline"} onClick={saveMessage} />
            <IconView icon={<IconX color={"red"} />}  variant={"outline"} onClick={onClose}/>
        </Group>
    </Group>
}
import {useEffect, useRef, useState} from "react";
import {
    Grid,
    Group,
    Modal,
    NumberInput,
    SegmentedControl,
    TextInput,
    Button,
    Text,
    Select,
    Divider
} from "@mantine/core";
import {FilledTextInput} from "../../Pages/Sequence/CustomButton";
import {AdminApi, RequestPost} from "../../Layouts/RequestManager";
import {FlowErrorMsg, FlowSuccessMsg, StatusMessage} from "../../Pages/Components/PageComponents";
import {PaymentDefaults, PaymentPlanList, PaymentStatus} from "../../Constants/Strings";
import moment from "moment";
import {DatePicker, TimeInput} from "@mantine/dates";
import {forwardRef, useImperativeHandle} from "react";
import {Colors} from "../../Constants/Colors";
const prefList = [ "programs", "chatbots", "sequence", "forms", "contactFolders", "contacts", "attachments", "files", "messageList"];


export const CreateUser = ({onClose,onRefresh}) =>{
    const [name,setName] = useState("");
    const [email,setEmail] = useState("");
    const [mobile,setMobile] = useState("");
    const [isError,setError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);

    const [statusMsg,setStatusMsg] = useState();

    const CreateUser = async () =>{
        if(name?.trim() !== '' && email?.trim() !== ''&& mobile?.trim() !== ''){
            setError(false);
            setIsLoading(true);
            const data = await RequestPost(`${AdminApi.AdminUsers}/create`,{name:name?.trim(),email: email?.trim()?.toLowerCase(),mobile: mobile?.trim()});
            setStatusMsg({status: data?.status === 1 ? "success": "error", message: data?.message});
            if(data?.status === 1){
                onClose();
                onRefresh();
            }
            setIsLoading(false);
        }else{
            setError(true);
        }
    }

    return <Modal title={<Text weight={"bold"}>Create User</Text>} onClose={onClose} size={"90%"} opened={true}>
        <Grid>
            <Grid.Col span={4}>
                <FilledTextInput label={"Name"} value={name} onChange={setName} error={isError && name?.trim() === "" ? "Name is Required": ""} />
            </Grid.Col>
            <Grid.Col span={4}>
                <FilledTextInput label={"Email"} value={email} onChange={setEmail} error={isError && email?.trim() === "" ? "Email is Required": ""} />
            </Grid.Col>
            <Grid.Col span={4}>
                <FilledTextInput label={"Mobile"} value={mobile} onChange={setMobile} error={isError && mobile?.trim() === "" ? "Mobile is Required": ""} />
            </Grid.Col>
            <Grid.Col span={12}>
                    <StatusMessage {...statusMsg} onClose={setStatusMsg} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={CreateUser} loading={isLoading}> Create User</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}
export const CreateDevice = ({onClose,onRefresh,userId}) => {
    const ref1 = useRef();
    const [name,setName] = useState("");
    const [isError,setIsError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [statusMsg,setStatusMsg] = useState();
    const handleDeviceCreate = async ()=>{
        const pref = ref1?.current?.isValid();
        if(pref?.isValid && name?.trim() !== ""){
            setIsError(false);
            setIsLoading(true);
            const data = await RequestPost(`${AdminApi.AdminUsers}/createDevice`,{userId,name:name?.trim(), ...pref?.data});
            setStatusMsg({status: data?.status === 1 ? "success": "error", message: data?.message});
            onClose();
            onRefresh();
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }

    return  <Modal title={<Text weight={"bold"}>Create Device</Text>} onClose={onClose} size={"90%"} opened={true}>
        <Grid>
            <Grid.Col span={4}>
                <FilledTextInput label={"Device Name"} value={name} onChange={setName} error={isError && name?.trim() === "" ? "Name is Required": ""} />
            </Grid.Col>
            <Grid.Col span={12}>
                    <Divider size={"xs"} label={"Plan Details"} labelPosition={'center'} labelProps={{weight: "bold"}} />
            </Grid.Col>
            <PayEdit ref={ref1} />
            <Grid.Col span={12}>
                <StatusMessage {...statusMsg} onClose={setStatusMsg} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={handleDeviceCreate} loading={isLoading}>Create Device</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}

export const CreatePayment = ({onClose,onRefresh,details}) => {
    const ref1 = useRef();
    const [isError,setIsError] = useState(false);
    const [isLoading,setIsLoading] = useState(false);
    const [statusMsg,setStatusMsg] = useState();
    const handlePayments = async ()=>{
        const pref = ref1?.current?.isValid();
        if(pref?.isValid ){
            setIsError(false);
            setIsLoading(true);
            const data = await RequestPost(`${AdminApi.AdminUsers}/createPay`,{userId: details?.userId,sessionId: details?.sessionId, ...pref?.data,isEdit: details?.isEdit,editId: details?.editId});
            setStatusMsg({status: data?.status === 1 ? "success": "error", message: data?.message});
            onClose();
            onRefresh();
            setIsLoading(false);
        }else{
            setIsError(true);
        }
    }

    return <Modal title={<Text weight={"bold"}>Create/Edit Payment</Text>} onClose={onClose} size={"90%"} opened={true}>
        <Grid>
            <PayEdit ref={ref1} details={details} />
            <Grid.Col span={12}>
                <StatusMessage {...statusMsg} onClose={setStatusMsg} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Group position={"center"}>
                    <Button onClick={handlePayments} loading={isLoading}>Create Pay</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}
const PayEdit = forwardRef(({details}, ref) => {
    console.log("details",details);
    const [plan,setPlan] = useState("");
    const [status,setStatus] = useState("1");
    const [paymentId,setPaymentId] = useState("");
    const [amount,setAmount] = useState(0);
    const [totalDays,setTotalDays] = useState(30);
    const [prefrences,setPrefrences] = useState({});

    const [created,setCreated] = useState(new Date());
    const [createdTime,setCreatedTime] = useState(new Date());
    const [expiry,setExpiry] = useState(undefined);

    const [isError,setError] = useState(false);
    const [isLoading,setloading] = useState(false);
    const [isEdit,setIsEdit] = useState(false);

    const [renderCount,setRenderCount] = useState(0);

    useEffect(()=>{
        if(details?.isEdit){
            setPlan(details?.plan);
            setStatus(details?.status?.toString());
            setPaymentId(details?.paymentId);
            setAmount(details?.amount || 0);
            setTotalDays(details?.totalDays || 0);
            setPrefrences(details?.prefrences || {});
            setCreated(new Date(moment.unix(details?.created)));
            setCreatedTime(new Date(moment.unix(details?.created)));
            setExpiry(new Date(moment.unix(details?.expiry)));
        }
        setIsEdit(details?.isEdit)
    },[details]);

    const handlePrefrences = (type,value) =>{
        let pref = prefrences;
        pref[type] = value;
        setPrefrences(pref);
        setRenderCount(renderCount+1);
    }
    const handlePlan =(plan) =>{
        const pref = PaymentDefaults?.[plan];
        if(pref) {
            setPrefrences(pref);
            if(pref?.totalDays) setTotalDays(pref?.totalDays);
        }
        setPlan(plan)
    }
    useImperativeHandle(ref, () => ({
        isValid() {
            if(plan !== "" && expiry !== '' && created !== '' &&createdTime !== '' && totalDays > 0 && paymentId?.trim() !== ""){
                setError(false);
                setloading(true);
                const createdAt = moment(created).format('ll')+" "+moment(createdTime).format('LT');
                return {isValid: true,data: {plan,status,expiry,created:createdAt,totalDays,prefrences,paymentId,amount}}
            }else{
                setError(true);
                return {isValid: false}
            }
        }
    }));
    return  <>
            <Grid.Col span={3}>
                <Select size={"xs"} label={"Plan"} data={PaymentPlanList} value={plan} onChange={handlePlan} error={isError && plan?.trim() === "" ? "Plan is Required": ""} />
            </Grid.Col>
            <Grid.Col span={3}>
                <Select size={"xs"} label={"Status"} data={PaymentStatus} value={status} onChange={setStatus} />
            </Grid.Col>
            {/*<Grid.Col span={3}>*/}
            {/*    <Select label={"SessionId"} data={state?.sessionDetails?.map(a =>({label: a?.name,value:a?.sessionId})) || []} value={sessionId} onChange={setSessionId} />*/}
            {/*</Grid.Col>*/}
            <Grid.Col span={3}>
                <TextInput size={"xs"} label={"PaymentId"} value={paymentId} onChange={(e) => setPaymentId(e.target.value)} error={isError && paymentId?.trim() === "" ? "PaymentId is Required": ""}  />
            </Grid.Col>
            <Grid.Col span={3}>
                <NumberInput size={"xs"} label={"amount"} value={amount} onChange={setAmount} />
            </Grid.Col>
            <Grid.Col span={3}>
                <NumberInput size={"xs"} label={"TotalDays"} value={totalDays} onChange={setTotalDays} error={isError && totalDays <= 0 ? "Total Days is Required": ""} />
            </Grid.Col>
            <Grid.Col span={6}>
                <Group>
                    <DatePicker size={"xs"} label={"Created Date"} value={created} onChange={setCreated} clearable  />
                    <TimeInput size={"xs"} label={"Time"} format={"12"} value={createdTime} onChange={setCreatedTime} clearable  />
                </Group>
            </Grid.Col>
            <Grid.Col span={3}>
                <DatePicker size={"xs"} label={"Expiry"} value={expiry} onChange={setExpiry} clearable />
            </Grid.Col>
            <Grid.Col span={12}>
                <Divider size={"xs"} label={"Prefrences"} labelPosition={'center'} labelProps={{weight: "bold"}} />
            </Grid.Col>
            {prefList?.map(a =>{
                return  <Grid.Col span={2}>
                    <NumberInput min={0} size={"xs"} label={a} value={prefrences?.[a] || 0} onChange={(value) => handlePrefrences(a,value)} />
                </Grid.Col>
            })}
        </>
});

export const ViewPref = ({userId,onClose,details}) =>{
    return  <Modal opened={true} size={"90%"} title={<Text weight={'bold'}>View Prefrences</Text>} onClose={onClose}>
        <Grid sx={{borderWidth: 1, borderColor: Colors.Primary, borderStyle: "solid"}}>
            <Grid.Col span={3} sx={{backgroundColor: "#ccc",borderWidth: 1, borderColor: Colors.Primary, borderStyle: "solid"}}><Text weight={'bold'}>Expiry: </Text></Grid.Col>
            <Grid.Col span={3} sx={{borderWidth: 1, borderColor: Colors.Primary, borderStyle: "solid"}}><Text>{moment.unix(details?.expiry)?.format('ll')}</Text></Grid.Col>
            {Object.keys(details?.prefrences || {})?.filter(a => !['totalDays','Device'].includes(a))?.map(a =>{
                return  <>
                    <Grid.Col span={3} sx={{backgroundColor: "#ccc",borderWidth: 1, borderColor: Colors.Primary, borderStyle: "solid"}}><Text weight={'bold'}>{a}</Text></Grid.Col>
                    <Grid.Col span={3} sx={{borderWidth: 1, borderColor: Colors.Primary, borderStyle: "solid"}}><Text>{details?.prefrences?.[a]?.toString()}</Text></Grid.Col>
                </>
            })}
        </Grid>
    </Modal>
}



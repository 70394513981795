import React, {Component, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {FlowActionApps, FlowAppDetails, FlowTriggerApps} from "../../Constants/Strings";
import {Header, IconView, PageLoading} from "../Components/PageComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {Accordion, Group, Stack, Text, Paper, Grid, Drawer, Select} from "@mantine/core";
import {IconArrowDown, IconPlayerPlay, IconPlus} from "@tabler/icons";
import {Colors} from "../../Constants/Colors";
import FlowStep from "./FlowStep";
const PageHeading = {
    link: {name: "BSM Flows", link: `bsm-flow`},
    title: "Edit",
    subTitle: "You can Edit BSm Flow Steps Here",
    buttons: [
        {type: "refresh", title: "Refresh"},
    ]
}
const BSMFlowEdit = () =>{
    const {id} = useParams();
    const [isLoading,setIsLoading] = useState();
    const [details,setDetails] = useState();
    const [isEdit,setIsEdit] = useState();
    const {sessionId,userId} = useSelector(s => s.active);
    const dispatch = useDispatch();

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.bsmFlows}/details/${sessionId}`,{id});
        setDetails(data?.details);
        setIsLoading(false);
    }
    useEffect(()=>{getDetails()},[]);


    const createNewStep = async (step) =>{
        const data = await RequestPost(`${CloudApi.bsmFlows}/AddStep/${sessionId}`,{id,step});
        dispatch(ActionShowAlert({message: data?.message}));
        getDetails();
    }
    const DeleteStep = (stepId) =>{
        dispatch(ActionShowPopup({
            title: "Delete Step",
            content: "It will stop Processing this Step. Are you sure want to Delete?",
            onSuccess: async () => {
                const data = await RequestPost(`${CloudApi.bsmFlows}/DeleteStep/${sessionId}`,{id,stepId});
                dispatch(ActionShowAlert({message: data?.message}));
                dispatch(ActionHidePopup())
                getDetails();
            },
            successTitle: "Delete",
            cancelTitle: "Cancel"
        }))

    }
    const prepareWebhookList = () =>{
        const result = [];
        details?.steps?.map((a,i)=> {
            if(a?.webhookRes){
                Object.keys(a?.webhookRes)?.map(b => {
                    result.push({group: `${i+1}. ${FlowAppDetails?.[a?.app]?.label}`, label: `${i+1}. ${FlowAppDetails?.[a?.app]?.label} --> ${b}` ,value: `${a?.id}__${b}`,description: a?.webhookRes?.[b] })
                })
            }
        })
        return result;
    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: getDetails}]} />
        <PageLoading isLoading={isLoading}>
            <Accordion>
                {details?.steps?.map((a,step) =>{
                    return <>
                        <FlowStep
                            id={id}
                            details={details?.steps?.[step]}
                            step={step}
                            deleteStep={DeleteStep}
                            apps = {step === 0 ? FlowTriggerApps : FlowActionApps}
                            webhookList={prepareWebhookList()}
                            triggerApp={details?.steps?.[0]?.app}
                        />
                        <Group position={'center'} >
                            <Stack position={"center"} sx={{gap: 3}}>
                                <Text size={"xl"} align={"center"} weight={"bold"}>|</Text>
                                <IconView icon={<IconPlus color={Colors.Primary} />} label={"Add New Step"} onClick={() => createNewStep(step+1)} />
                                <Text size={"xl"} align={"center"}><IconArrowDown /></Text>
                            </Stack>
                        </Group>
                    </>
                })}
            </Accordion>

        </PageLoading>
    </>
}

export default BSMFlowEdit;




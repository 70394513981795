import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {R365Api, R365Url, RequestPost} from "../../Layouts/RequestManager";
import {PageLoading, TableView} from "../../Pages/Components/PageComponents";
import {
    Group,
    Tabs,
    Paper,
    Text,
    Accordion,
    Badge,
    Modal,
    Grid,
    Select,
    MultiSelect,
    NumberInput,
    Button,
    Stack, Textarea, TextInput
} from "@mantine/core";
import {IconEdit, IconTemplate} from "@tabler/icons";
import moment from "moment";
import {PlanStatus, TimeArray} from "../../Constants/Strings";
import {Colors} from "../../Constants/Colors";
import {ActionShowAlert} from "../../store/actions/alert";
import {useDispatch} from "react-redux";
const langArray= {'en': "English","hin": "Hindi","ta":"Tamil"};
const statusArray =[
    {color: 'red',name: "Inactive",code: 0},
    {color: 'green',name: "Active",code: 1},
    {color: 'red',name: "Duplicate",code: 2},
    {color: 'red',name: "Paused",code: 3},
    {color: 'orange',name: "Completed",code: 4},
    {color: 'red',name: "Unread",code: 5},
];

const CustomerDetails = () =>{
    const {mobile} = useParams();
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    const [plans,setPlans] = useState([]);
    const [isEdit,setIsEdit] = useState();

    useEffect(()=>{
        getDetails();
    },[])

    const getDetails = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${R365Api.CustomerDetails}/details/${mobile}`,{});
        setDetails(data?.details);
        setPlans(data?.plans || []);
        setIsLoading(false);
    }


    return <PageLoading isLoading={isLoading}>
        <Paper shadow={'md'} withBorder p={5} m={5}>
            <Group position={'apart'}>
                <Text>{details?.name}</Text>
                <Text>{details?.mobile}</Text>
                <Text>{details?.email}</Text>
            </Group>
        </Paper>
        <Tabs defaultValue="plans">
            <Tabs.List>
                <Tabs.Tab value="plans" icon={<IconTemplate size="0.8rem" />}>Customer plans</Tabs.Tab>
                <Tabs.Tab value="payments" icon={<IconTemplate size="0.8rem" />}>Payments</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value={"payments"} pt={"xs"}>
                <TableView  headers={["Type","Action","PaymentId","Amount","Day","Products","Purpose","Created"]}>
                    {details?.payments.map((a,i) => <tr>
                        <td>{a?.payType}</td>
                        <td>{a?.action}</td>
                        <td>{a?.paymentId}</td>
                        <td>{a?.amount}</td>
                        <td>{a?.day || a?.days}</td>
                        <td>{a?.products?.map(a => `${a}, `)}</td>
                        <td>{a?.purpose}</td>
                        <td>{moment.unix(a?.paidAt).format('lll')}</td>
                    </tr>)}
                </TableView>
            </Tabs.Panel>
            <Tabs.Panel value="plans" pt="xs">
                {isEdit !== undefined && <EditPrefrences onRefresh={getDetails} details={isEdit}  mobile={mobile} onHide={() => setIsEdit()} />}
                <Accordion variant={'separated'} mx={10} styles={{label:{padding: 2}}}>
                    {plans.map(a => <Accordion.Item value={a?.productId} style={{padding: 2}} p={2}>
                        <Accordion.Control>
                            <Group position={'apart'}>
                                <Text>{a?.productId}</Text>
                                <Badge>Day {a?.day}/{a?.totalDays}</Badge>
                                <Badge color={statusArray[a?.status]?.color}>{statusArray[a?.status]?.name}</Badge>
                                <IconEdit onClick={() => setIsEdit(a)} />
                            </Group>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <TableView headers={[]}>
                                <tr>
                                    <td style={{backgroundColor: "#ccc"}}><Text>Last Message</Text></td>
                                    <td><Text>{a?.lastMessage}</Text></td>
                                    <td style={{backgroundColor: "#ccc"}}>Days</td>
                                    <td>
                                        <DaysPerWeek days={a?.daysPerWeek} />
                                    </td>
                                </tr><tr>
                                    <td style={{backgroundColor: "#ccc"}}><Text>Parts</Text></td>
                                    <td><Text>{a?.partsPerDay}</Text></td>
                                    <td style={{backgroundColor: "#ccc"}}>Language</td>
                                    <td>{langArray[a?.lang]}</td>
                                </tr><tr>
                                    <td style={{backgroundColor: "#ccc"}}><Text>Timezone</Text></td>
                                    <td><Text>{a?.tz}</Text></td>
                                    <td style={{backgroundColor: "#ccc"}}><Text>Completed Books</Text></td>
                                    <td><Text>{a?.completed?.length}</Text></td>
                                </tr><tr>
                                <td style={{backgroundColor: "#ccc"}}>Ongoing Book</td>
                                <td>{a?.ongoing?.book} ({a?.ongoing?.start} - {a?.ongoing?.end})</td>
                                    <td style={{backgroundColor: "#ccc"}}>Upcoming Books</td>
                                    <td>{a?.upcoming?.length}</td>
                                </tr><tr>
                                <td style={{backgroundColor: "#ccc"}}>Created</td>
                                <td>{moment.unix(a?.created)?.format('lll')}</td>
                                    <td style={{backgroundColor: "#ccc"}}>Verified</td>
                                    <td>{moment.unix(a?.verified)?.format('lll')}</td>
                                </tr>
                            </TableView>
                        </Accordion.Panel>
                    </Accordion.Item>)}
                </Accordion>
            </Tabs.Panel>
        </Tabs>
    </PageLoading>
}
export default  CustomerDetails;

const DaysPerWeek = ({days}) =>{
    return <Text my={5}>{['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((a, index) =>{
        const check = days?.includes(index);
        return <Badge
            color={check? 'green':'red'}
            p={5}
            size={'xs'} mx={5}>{a}</Badge>
    })}</Text>
}
const EditPrefrences = ({details,timings,mobile,onHide,onRefresh}) =>{

    const [partsPerDay,setPartsPerDay] = useState(details?.partsPerDay || 15);
    const [time,setTime] = useState(details?.time || []);
    const [lang,setLang] = useState(details?.lang || 'en');
    const [status,setStatus] = useState(details?.status?.toString() || "");
    const [totalDays,setTotalDays] = useState(details?.totalDays);
    const [daysPerWeek,setDaysPerWeek] = useState(details?.daysPerWeek || []);
    const [completed,setCompleted] = useState(details?.completed?.join("\n") || "");
    const [upcoming,setUpcoming] = useState(details?.upcoming?.join("\n") || "");
    const [lastMessage,setLastMessage] = useState(details?.lastMessage || "");

    const [onGoingBook,setOnGoingBook] = useState(details?.ongoing?.book || "");
    const [onGoingStart,setOnGoingStart] = useState(details?.ongoing?.start || "");
    const [onGoingEnd,setOnGoingEnd] = useState(details?.ongoing?.end|| "");
    const [onGoingDate,setOnGoingDate] = useState(details?.ongoing?.date || "");
    useEffect(()=>{
        handleTime(details?.time || [])
    },[])


    const dispatch = useDispatch();
    const [isError,setError] = useState(false);
    const [isLoading,setLoading] = useState(false);

    const changeParts = (count) =>{
        setPartsPerDay(parseInt(count,10));
        setTime(timings[count]);
    }
    const handleTime = (t) =>{
        let timeSelected = t.map(a =>{
            return {time: a, displayTime: moment(a ,'LT').unix()}
        }).sort((a,b) => {
            if (a.displayTime < b.displayTime) return -1;
            if (a.displayTime > b.displayTime) return 1;
            return 0;
        }).map(a => a.time);
        console.log("ts",timeSelected)
        setTime(timeSelected);
    }

    const onChange = (index) =>{
        if(daysPerWeek.includes(index)) {
            setDaysPerWeek(daysPerWeek.filter(a => a != index));
        }else{
            let d = [...daysPerWeek,index].sort();
            setDaysPerWeek(d);
        }
    }
    const handleSubmit = async () =>{
        if(daysPerWeek.length > 3 && time.length === partsPerDay && status != undefined){
            setError(false);
            setLoading(true);
            let update = {
                productId: details?.productId,
                lang,
                time,
                partsPerDay,
                daysPerWeek,
                mobile,
                status: parseInt(status,10),
                totalDays,
                lastMessage,
                upcoming: upcoming?.split("\n"),
                completed: completed?.split("\n"),
                ongoing: {date: onGoingDate, start: parseInt(onGoingStart, 10), end: parseInt(onGoingEnd, 10), book: onGoingBook}
            };
            const data = await RequestPost(`${R365Api.CustomerDetails}/update/${mobile}`,update);
            dispatch(ActionShowAlert({message: data?.message}));
            onHide();
            onRefresh();
            console.log(data);
        }else{
            setError(true);
        }
    }
    return <Modal opened={true}  title={<Text weight={'bold'}>Edit Prefrences - {details?.name}</Text>} size={'90%'} onClose={onHide}>
        <Grid>
            <Grid.Col span={6}>
                <Stack position={'apart'} mt={5} sx={{gap: 3}}>
                    <Text weight={'bold'} size={'sm'} color={Colors.Primary}>Days Per Week
                        <Text color={'dimmed'} size={'xs'}>{"Select Days You want to Receive Messages (Atleast 3)"}</Text>
                    </Text>
                    <Text my={5}>{['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((a, index) =>{
                        const check  =  daysPerWeek?.includes(index);
                        return <Badge
                            onClick={() => onChange(index)}
                            color={check? 'green':'red'}
                            p={5}
                            size={'md'} mx={5}>{a}</Badge>
                    })}</Text>
                    {isError && daysPerWeek.length < 3 && <Text color={'red'}>Atleast 3 Days Need to Select</Text>}
                </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
                <Select
                    label={<Text weight={'bold'}>Change Language</Text>}
                    data={[{label: 'English',value:'en'},{label: 'Tamil',value:'ta'},{label: 'Hindi',value:'hin'}]}
                    value={lang}
                    onChange={setLang}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <NumberInput
                    label={<Text weight={'bold'}>No. of parts to receive</Text>}
                    min={3}
                    max={15}
                    value={partsPerDay}
                    onChange={changeParts}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <MultiSelect
                    label={<Text weight={'bold'}>{`Choose Timings (${partsPerDay})`}</Text>}
                    data={TimeArray.map(a => ({label: a,value:a}))}
                    value={time}
                    onChange={handleTime}
                    maxSelectedValues={partsPerDay}
                    error={isError && time.length < partsPerDay ? `Select ${partsPerDay - time.length} More Timings`: ""}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <Select
                    label={<Text weight={'bold'}>Status</Text>}
                    data={PlanStatus?.map((a,i) => ({label: a,value: i?.toString()}))}
                    value={status}
                    onChange={setStatus} />
            </Grid.Col>
            <Grid.Col span={6}>
                <NumberInput label={"TotalDays"} value={totalDays} onChange={setTotalDays} />
            </Grid.Col>
            <Grid.Col span={6}>
                <Textarea label={"Completed"} value={completed} onChange={setCompleted} />
            </Grid.Col>
            <Grid.Col span={6}>
                <Textarea label={"Upcoming"} value={upcoming} onChange={setUpcoming} />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput label={"OnGoing Book Id"} value={onGoingBook} onChange={setOnGoingBook} />
            </Grid.Col>
            <Grid.Col span={6}>
                <NumberInput label={"OnGoing Start Part"} value={onGoingStart} onChange={setOnGoingStart} />
            </Grid.Col>
            <Grid.Col span={6}>
                <NumberInput label={"On Going End Part"} value={onGoingEnd} onChange={setOnGoingEnd} />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput label={"On Going Book Date"} value={onGoingDate} onChange={setOnGoingDate} />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput label={"Last Message"} value={lastMessage} onChange={setLastMessage} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Group position={'center'}>
                    <Button color={'gray'} onClick={onHide}>Cancel</Button>
                    <Button onClick={handleSubmit} loading={isLoading} disabled={daysPerWeek.length < 3 || time.length < partsPerDay}>Submit</Button>
                </Group>
            </Grid.Col>
        </Grid>
    </Modal>
}
import {Colors} from "../../Constants/Colors";
import {
    Divider,
    Group,
    Image,
    Paper,
    Stack,
    Text,
    Badge,
    Grid,
    Loader,
    Drawer,
    Textarea,
    Button,
    TextInput, FileButton
} from "@mantine/core";
import {
    IconBrandWhatsapp,
    IconBuilding, IconBuildingStore,
    IconEdit,
    IconGlobe,
    IconInfoCircle,
    IconLink, IconLocation,
    IconMail,
    IconRefresh
} from "@tabler/icons";
import React, {useEffect,useState} from "react";
import {useSelector} from "react-redux";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {IconView, IconWithText, ProfileCardItem} from "../Components/PageComponents";
import {BoldText, ErrorText, SmallText} from "../Components/TextComponents";

const DeviceConnection = ({connection}) =>{
    return <Paper shadow={"md"} withBorder p={5} sx={{width: 300}}>
        <Text size={"md"} color={"white"}>{connection?.waName}</Text>
        <Group sx={{gap: 5, backgroundColor: "white"}}>
            <IconBrandWhatsapp size={"20"} color={"green"}/>
            <Text size={"md"} weight={"bold"}>{connection?.waNumber}</Text>
        </Group>
    </Paper>
}
const DeviceProfile = ({details}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [profile,setProfile] = useState(details)
    const [isLoading,setIsLoading] = useState(false);
    const [isEdit,setIsEdit] = useState(false);

    const getDetails = async () =>{
        setIsLoading(true);
        const details = await RequestGet(`${CloudApi.businessProfile}/${sessionId}`);
        setProfile(details?.data);
        setIsLoading(false);
    }

    return <Paper shadow={"md"} withBorder p={5} sx={{width: 400}}>
        {isEdit && <EditBusinessProfile profile={profile} sessionId={sessionId} onClose={() => setIsEdit(false)} onRefresh={getDetails} />}
        <Group position={"apart"}>
           <Group>
               <Image src={profile?.profile?.profile_picture_url} width={50} height={50} />
               <Stack sx={{gap: 3}}>
                   <Group position={"apart"} sx={{width: "100%"}}>
                       <Text weight={"bold"}>{profile?.device?.verified_name}</Text>
                   </Group>
                   <Text size={"xs"} color={"dimmed"}>{profile?.device?.display_phone_number}</Text>
               </Stack>
           </Group>
            <Group>
                {isLoading ? <Loader size={"xs"} />: <IconView iconType={"refresh"} onClick={getDetails}/>}
                <IconView iconType={"edit"} onClick={() => setIsEdit(!isEdit)} />
            </Group>
        </Group>
        <Divider />
        {profile?.profile?.email && <ProfileCardItem icon={<IconMail size={18}/>} text={profile?.profile?.email}/>}
        {profile?.profile?.address && <ProfileCardItem icon={<IconLocation size={18}/>} text={profile?.profile?.address}/>}
        {profile?.profile?.websites?.map(a =>  <ProfileCardItem icon={<IconLink size={18} />} text={a} />)}
        {profile?.profile?.description && <ProfileCardItem icon={<IconBuildingStore size={18}/>} text={profile?.profile?.description}/>}
        {profile?.profile?.about && <ProfileCardItem icon={<IconInfoCircle size={18}/>} text={profile?.profile?.about}/>}
        {profile?.profile?.vertical && <ProfileCardItem icon={<IconBuilding size={18} />} text={profile?.profile?.vertical} />}
        {profile?.device?.quality_rating && <ProfileCardItem icon={<SmallText text={"Quality"} />} text={profile?.device?.quality_rating} />}
        {profile?.device?.throughput && <ProfileCardItem icon={<SmallText text={"Throughput"} />} text={profile?.device?.throughput?.level} />}
        {profile?.nameStatus && <ProfileCardItem icon={<SmallText text={"Name"} />} text={<Badge size={"xs"}>{profile?.nameStatus?.name_status}</Badge>} />}
    </Paper>
}



const EditBusinessProfile = ({profile,sessionId,onClose,onRefresh}) =>{
    const [file,setFile] = useState();
    const [email,setEmail] = useState(profile?.profile?.email || "");
    const [website1,setWebsite1] = useState(profile?.profile?.websites?.[0] || "");
    const [website2,setWebsite2] = useState(profile?.profile?.websites?.[1] || "");
    const [about,setAbout] = useState(profile?.profile?.about || "");
    const [address,setAddress] = useState(profile?.profile?.address || "");
    const [description,setDescription] = useState(profile?.profile?.description || "");


    const [isLoading,setIsLoading] = useState(false);
    const [error,setError] = useState(false);
    const updateProfile = async () =>{
        setIsLoading(true);
        const details = await RequestPost(`${CloudApi.businessProfile}/${sessionId}`, {
            body: { "messaging_product":"whatsapp",
                    about,
                    address,
                    description,
                    email,
                    websites: [website1?.trim(),website2?.trim()]?.filter(a => !!a)
                },
            image: file
        });
        if(details?.status == 1){
            onClose();
            onRefresh();
        }else{
            setError(details?.data?.error?.message || "Something went Wrong");
        }
        setIsLoading(false);
    }
    const handleFile = (file) =>{
        const reader = new FileReader();
        reader.onload = function (event) {
            const base64Data = event.target.result;
            setFile({name: file?.name, size: file?.size, file: base64Data, type: file?.type});
        };
        reader.readAsDataURL(file);
    };

    return <Drawer opened={true} position={"right"} size={"50%"} onClose={onClose} title={<BoldText mx={10} text={"Business Profile"} size={"md"} />}>
        <Grid mx={10} sx={{gap:1}}>
           <Grid.Col span={12}>
               <Group>
                   {!file && profile?.profile?.profile_picture_url && <Image src={profile?.profile?.profile_picture_url} width={50} height={50} />}
                   {file && <Group>
                       <Text size={"sm"} variant={"gradient"} weight={"bold"}>{file?.name}</Text>
                       <IconView iconType={"delete"} onClick={()=> setFile()}/>
                   </Group>}
                   <FileButton onChange={handleFile} accept={"image/*"}>
                       {(props) => <Button compact variant={"outline"} sx={{cursor: "pointer",color: Colors.Primary}} {...props}>
                           Change Image
                       </Button>}
                   </FileButton>
               </Group>
           </Grid.Col>
            <Grid.Col span={12}>
                <TextInput label={"Email"} value={email} onChange={(e) => setEmail(e.target.value)} maxLength={128} />
            </Grid.Col>
            <Grid.Col span={12}>
                <TextInput label={"Website1"} value={website1} onChange={(e) => setWebsite1(e.target.value)} maxLength={256} />
            </Grid.Col>
            <Grid.Col span={12}>
                <TextInput label={"Website2"} value={website2} onChange={(e) => setWebsite2(e.target.value)} maxLength={256} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Textarea label={"About"} value={about} onChange={(e) => setAbout(e.target.value)} maxLength={139} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Textarea label={"Address"} value={address} onChange={(e) => setAddress(e.target.value)} maxLength={256} />
            </Grid.Col>
            <Grid.Col span={12}>
                <Textarea label={"Description"} value={description} onChange={(e) => setDescription(e.target.value)} maxLength={512} />
            </Grid.Col>
            {error && <Grid.Col span={12}>
                <ErrorText text={error}/>
            </Grid.Col>}
            <Grid.Col span={12}>
                <Button fullWidth onClick={updateProfile} loading={isLoading}>Update Profile</Button>
            </Grid.Col>
        </Grid>
    </Drawer>


}

export default DeviceProfile;



import React, {useState} from 'react';
import {Button, Grid, Group, Select} from "@mantine/core";
import {useDispatch, useSelector} from "react-redux";
import ResponseView from "./ResponseView";
import {ActionShowAlert} from "../../../store/actions/alert";
import {socket} from "../../Alerts/Socket";
import {CloudApi, RequestPost} from "../../../Layouts/RequestManager";

const FormLeads = ({props,value}) =>{
    const app= "formLeads";
    const {sessionId,userId,forms = []} = useSelector(state => state.active);

    const [formId,setFormId] = useState(value?.formId || "");
    const [waitingWH,setWaitingWh] = useState(false);
    const dispatch = useDispatch();
    const [sampleResponse,setSampleResponse] = useState(value?.webhookRes || undefined)

    const handleSaveFlow = async () =>{
        const flow = {app,sessionId,formId, id: value?.id}
        setWaitingWh(false);
        const data = await RequestPost(`${CloudApi.bsmFlows}/saveDetails/${sessionId}`,{flow,step: props?.step, id: props?.id,isWebhookListen: true});
        dispatch(ActionShowAlert({message: data?.message}));
        triggerWebhookListener();
    }
    const triggerWebhookListener = async () =>{
        setWaitingWh(true);
        await RequestPost(`${CloudApi.bsmFlows}/WebhookListen/${sessionId}`,{step: props?.step, id: props?.id});
        socket.on(`Flow${props?.id}_STEP${props?.step}`, handleWebhook);
    }
    const handleWebhook = (data) =>{
        dispatch(ActionShowAlert({message: "Webhook Captured", color: "green"}));
        setSampleResponse(data);
        setWaitingWh(false);
    }
    return  <>
        <Grid.Col span={3}>
            <Select label={"Choose Form"} data={forms} value={formId} onChange={setFormId}/>
        </Grid.Col>
        <Grid.Col span={3}>
            <Group position={'apart'} mt={25}>
                {!waitingWH && <Button onClick={handleSaveFlow} variant={'gradient'}>Save & Check Response</Button>}
                {waitingWH && <Button loading={true}>Waiting For Webhook</Button>}
            </Group>
        </Grid.Col>
        {sampleResponse && <ResponseView response={sampleResponse} onRefresh={triggerWebhookListener}/>}
    </>
}

export default FormLeads;

import {useSelector} from "react-redux";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import React from "react";
import Login from "../Pages/Dashboard/Login";
import AdminDashboard from "../Admin/Dashboard";
import AdminSessions from "../Admin/Sessions/Sessions";
import {NotFound} from "../Pages/Alerts/404";
import Users from "../Admin/Sessions/Users";
const paths =[
    {path: `/`,component: <AdminDashboard />},
    {path: `/sessions`,component: <AdminSessions />},
    {path: `/users`,component: <Users />},
]
const Navigator = () =>{
    const {sessionId,alias} = useSelector(state => state.active);
    return  <Routes>
        <Route exact path="/login" element={<PublicOutlet />}>
            <Route path="" element={<Login />}/>
        </Route>
        {paths.map(path =><Route exact path={path?.path} element={<PrivateOutlet />}>
            <Route path="" element={path?.component} />
        </Route>)}
        <Route path="*" element={<NotFound />}/>
    </Routes>
}
function PrivateOutlet() {
    const isLogged = useSelector(state => state.user?.isLogged && state?.user?.userId);
    return isLogged ? <Outlet /> : <Navigate to="/login" />;
}
function PublicOutlet() {
    const isLogged = useSelector(state => state.user?.isLogged && state?.user?.userId);
    return isLogged ? <Navigate to={'/'} /> : <Outlet />;
}

export default Navigator;

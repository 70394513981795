import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Divider, Grid, Group,Select} from "@mantine/core";
import VariablesView from "./VariablesView";
import {CloudApi, RequestPost} from "../../../Layouts/RequestManager";
import {ActionShowAlert} from "../../../store/actions/alert";
import {FlowSuccessMsg} from "../../Components/PageComponents";

const BSMProgram = ({props,value}) =>{
    const app ='pro';
    const [programId,setProgramId] = useState(value?.programId || undefined);
    const [render,setRender] = useState(0);
    const [isLoading,setIsLoading] = useState(false);
    const [variables,setVariables] = useState(value?.variables || [{label: "name", type: "map", value: "",required: true},{label: "email", type: "map", value: "",required: true},{label: "mobile", type: "map", value: "",required: true},{label: "link", type: "map", value: "",required: true}]);
    const {sessionId,programs} = useSelector(state => state.active);

    const [successMsg,setSuccessMsg] = useState("");
    const dispatch = useDispatch();

    const updateVar = (variables) =>{
        setVariables(variables);
        setRender(render+1);
    }
    const handleSaveFlow = async () =>{
        const flow = {app,sessionId,programId,variables, id: value?.id,extraValues: variables?.filter(a => !a?.required)?.map(a => a?.label)};
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.bsmFlows}/saveDetails/${sessionId}`,{flow,step: props?.step, id: props?.id,isWebhookListen: true});
        setSuccessMsg(data?.message);
        setTimeout(() => setSuccessMsg(), 3000)
        setIsLoading(false);
    }

    return <>
       <Grid.Col span={6}>
            <Select required label={"Choose Program"} data={programs} value={programId} onChange={setProgramId}/>
        </Grid.Col>
        {programId && <>
            <Grid.Col span={12}>
                <Divider />
            </Grid.Col>
            <VariablesView variables={variables} props={props} updateVariables={updateVar} addVariable={true} />
        </>}
        <Grid.Col span={12}>
            <Group mt={25}>
                 <Button onClick={handleSaveFlow} loading={isLoading} variant={'gradient'}>Save Step</Button>
                {successMsg && <FlowSuccessMsg message={successMsg} />}
            </Group>
        </Grid.Col>
    </>
}

export default BSMProgram;

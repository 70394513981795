import React, {useEffect, useState} from "react";
import {Drawer, Group, Text, Paper, Pagination} from "@mantine/core";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import moment from "moment";
import {DatePicker} from "@mantine/dates";
import {Header, IconView, PageLoading, TableView} from "../Components/PageComponents";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
const PageHeading = {
    link: {name: "Sequence", link: `sequence`},
    title: "Leads", subTitle: "Leads Will Show Here", buttons:[{type:"refresh", title: "Refresh"}]}

const SequenceLeads = () =>{
    const [isLoading,setIsLoading] = useState(false);
    const {sessionId} = useSelector(state => state.active);
    const {id} = useParams();
    const [date,setDate] = useState(new Date());
    const [total,setTotal] = useState(1);
    const [page,setPage] = useState(1);

    const [leads,setLeads] = useState([]);
    const [leadOpen,setLeadOpen] = useState();
    useEffect(()=>{
        setPage(1);
        getLeads({page: 1});
    },[date])
    useEffect(()=>{
        getLeads();
    },[page])

    const getLeads = async (params = {}) =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.sequence}/leads/${sessionId}`, {date,page,chatbotId: id});
        setLeads(data?.leads || []);
        setTotal(data?.total);
        setIsLoading(false);
    }
    const openLeads = (id) => setLeadOpen(id);
    return <>
        <Header {...PageHeading}  buttonProps={[{onClick: getLeads}]} />
        <PageLoading isLoading={isLoading}>
            <Group position={"apart"}>
                <DatePicker label={"Choose Date"} value={new Date(date)} onChange={setDate} />
                <Text>Total Results: {total}</Text>
                {total > 10 ? <Pagination size={"xs"} total={Math.ceil(total / 10)} page={page} onChange={setPage}/>: <Text></Text>}
            </Group>
            <TableView headers={["Mobile","Triggered From","Started At","Options"]}>
                {leads?.map(a => <tr>
                    <td>{a?.mobile}</td>
                    <td>{a?.trigger}</td>
                    <td>{moment.unix(a?.created).format("lll")}</td>
                    <td>
                        <IconView iconType={"view"} onClick={() => openLeads(a?._id)} />
                    </td>
                </tr>)}
            </TableView>
            {leadOpen && <LeadDetails leadId={leadOpen} chatbotId={id} sessionId={sessionId} onClose={() => setLeadOpen()} />}
        </PageLoading>
     </>
}
export default SequenceLeads;


const LeadDetails = ({sessionId,chatbotId,leadId,onClose}) =>{
    const [isLoading,setIsLoading] = useState(false);
    const [details,setDetails] = useState();
    useEffect(() =>{
        getLeads();
    },[])

    const getLeads = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.sequence}/leadDetails/${sessionId}`, {leadId,chatbotId});
        setDetails(data?.details);
        setIsLoading(false);
    }

    return <Drawer  onClose={onClose} title={<Text weight={"bold"} mx={5}>Lead Details</Text>} opened={true} position={"right"} size={"50%"} p={5}>
        <PageLoading isLoading={isLoading}>
            <Paper shadow={"md"} withBorder mb={5}>
                <Group position={"apart"} mx={10}>
                    <Text weight={"bold"}>{details?.mobile}</Text>
                    <Text>{moment.unix(details?.created).format("lll")}</Text>
                </Group>
            </Paper>
            <TableView headers={["Action","Time","Options"]}>
                {details?.actions?.map(a => <tr>
                    <td>{a?.message}</td>
                    <td>{moment.unix(a?.time).format("lll")}</td>
                    <td></td>
                </tr>)}
            </TableView>
        </PageLoading>
    </Drawer>
}

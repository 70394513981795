import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import {Pagination, Paper, Group, Text, Badge, Select, TextInput, Divider,Button} from "@mantine/core";
import {DatePicker} from "@mantine/dates";
import {useNavigate} from "react-router-dom";
import {R365Api, RequestPost} from "../../Layouts/RequestManager";
import {CheckBoxView, IconView, PageLoading, TableView} from "../../Pages/Components/PageComponents";
import {PlanStatus, WBASelects} from "../../Constants/Strings";
import ProductSelect from "../Misc/ProductSelect";

const Customers = () =>{
    const {userId, r365,alias} = useSelector(state => state.active);
    const [productId,setProductId] = useState(r365?.productId?.[0]);
    const navigation = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const [users,setUsers] = useState([]);
    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(1);
    const [filter,setFilter] = useState({});
    const [sessionActive,setSessionActive] = useState(0);
    useEffect(() =>{
        getDetails();
    },[page,filter,productId]);

    const getDetails = async () =>{
        setIsLoading(true);
        const users = await RequestPost(`${R365Api.Customers}/${productId}`, {page,...filter});
        setUsers(users?.users || []);
        setTotal(users?.total || 0)
        setSessionActive(users?.sessionActive || 0)
        setIsLoading(false);
    }
    const renderStatus = (status) =>{
        const statusArray = [{color: "red",label: "Inactive",status: 0},{color: "green",label: "Active",status: 1},{color: "orange",label: "Duplicate",status: 2},{color: "yellow",label: "Paused",status: 3},{color: "blue",label: "Completed",status: 4},{color: "red",label: "Not Read Stopped",status: 5}]
        const {color,label} = statusArray?.[status] || {color: "blue",label: status};
        return <Badge color={color}>{label}</Badge>
    }
    const openDetails = (mobile) =>{
        navigation(`/device/${alias}/r365/customer-details/${mobile}`);
    }
    return <>
        <ProductSelect productId={productId} setProductId={setProductId} />
        <Paper shadow={"md"} withBorder p={5} mt={10}>
            <Filter onRefresh={setFilter} setPage={setPage} />
            <Divider />
            <Group position={"apart"} mt={2}>
                <Text><b>Total Result:</b> {total}</Text>
                <Text><b>Session Active:</b> {sessionActive}</Text>
                <Pagination size={"xs"} total={Math.ceil(total / 10)} value={page} onChange={setPage} />
            </Group>
        </Paper>
        <PageLoading isLoading={isLoading}>
            <TableView headers={["Name","Mobile","Email","lang","TZ","Day","Status","Created","isActive","Last Message","Today Msg","Options"]}>
                {users?.map(a =>{
                    return <tr>
                        <td>{a?.name}</td>
                        <td>{a?.mobile}</td>
                        <td>{a?.email}</td>
                        <td>{a?.lang}</td>
                        <td>{a?.tz}</td>
                        <td>{a?.day} / {a?.totalDays}</td>
                        <td>{renderStatus(a?.status)}</td>
                        <td>{moment.unix(a?.created)?.format("lll")}</td>
                        <td>{a?.isActive ? "Active": "No"}</td>
                        <td>{a?.lastMessage}</td>
                        <td>{a?.msgCount}</td>
                        <td>
                            <IconView iconType={"view"} onClick={() => openDetails(a?.mobile)}  />
                        </td>
                    </tr>
                })}
            </TableView>
        </PageLoading>
    </>
}
export default Customers;

const Filter = ({onRefresh,setPage}) =>{
    const[lang,setLang] = useState("");
    const[status,setStatus] = useState("");
    const[created,setCreated] = useState();
    const[mobile,setMobile] = useState("");
    const[isActive,setIsActive] = useState(false);
    const handleUpdate = () =>{
        onRefresh({lang,status,created,mobile,isActive});
        setPage(1);
    }
    return <Group mb={2}>
        <Select size={"xs"} label={"Language"} clearable placeholder={"Choose Language"} value={lang} onChange={setLang} data={WBASelects?.BookLang} />
        <Select size={"xs"} label={"Status"} clearable data={PlanStatus?.map((a,i) =>({label: a,value: i}))} placeholder={"Choose Status"} value={status} onChange={setStatus} />
        <DatePicker size={"xs"} label={"Created"} clearable placeholder={"Choose Created Date"} value={created} onChange={setCreated} />
        <TextInput size={"xs"} label={"Mobile"} value={mobile} onChange={(e) => setMobile(e.target.value)} />
        <CheckBoxView text={"Session Active"} onClick={() => setIsActive(!isActive)} isSelected={isActive} />
        <Button mt={15} onClick={handleUpdate}  size={"xs"}>Filter</Button>
    </Group>
}

import {Button, Menu, Paper, Stack, Textarea, TextInput, Text, Group} from "@mantine/core";
import React from "react";
import {IconDotsVertical, IconEdit, IconTrash} from "@tabler/icons";
import {Handle, Position, useNodeId, useStore} from "reactflow";
import {IconView} from "../Components/PageComponents";

export const CustomButton = ({title, onClick, ...props})=>{
    return <Button my={20} compact fullWidth variant={"gradient"} onClick={onClick}>{title}</Button>
}
export const MultiLineText = ({text, ...props})=>{
    return text ? <Stack sx={{gap: 3}}>
        {text?.split("\n")?.map(a => <Text sx={{overflow: "clip"}} size={"xs"} {...props}>{a}</Text>)}
    </Stack>: <></>
}

export const FilledTextInput = ({value, onChange,label,limit, ...props})=>{
    return <Stack sx={{gap: 1}}>
        {label && <Label label={label} value={value} limit={limit} />}
        <TextInput size={"xs"} variant={"filled"} value={value} onChange={e => onChange(e.target.value)} {...props} />
    </Stack>
}
export const FilledTextArea = ({value, onChange,label,limit, ...props})=>{
    return <Stack sx={{gap: 1}}>
        {label && <Label label={label} value={value} limit={limit} />}
        <Textarea size={"xs"} variant={"filled"}  value={value} onChange={e => onChange(e.target.value)} {...props} />
    </Stack>
}
export  const MenuOptions= ({options}) =>{
    return <Menu shadow="md" width={150} position={"bottom-end"}>
        <Menu.Target>
            <IconView icon={<IconDotsVertical size={"12"}  />} />
        </Menu.Target>
        <Menu.Dropdown mb={90} ml={40}>
            {options?.map(a =>{
                switch (a?.type){
                    case "item":
                        return <Menu.Item p={1} icon={a?.icon} onClick={a?.onClick}>{a?.label}</Menu.Item>;
                    case "divider":
                        return  <Menu.Divider />
                }
            })}
        </Menu.Dropdown>
    </Menu>

}
export const ButtonWithHandle = ({label,id,buttonProps,handlerProps}) =>{
    const selector = (s) => ({nodeInternals: s.nodeInternals,edges: s.edges,});
    const { edges } = useStore(selector);
    const nodeId = useNodeId();
    const connected = edges?.find(a => a?.source === nodeId && a?.sourceHandle === id);
    return  <Button size={"xs"} fullWidth compact variant={"light"} sx={{height: 25,borderColor: "lightgray"}} {...buttonProps}>
            {label}
            <Handle type="source" position={Position.Right} isConnectable={connected === undefined} id={id} style={{background: 'green', right: 5, opacity: connected === undefined ? "": 0}} title={label}   {...handlerProps} />
        </Button>
}
export const CustomHandle = ({label,id,handlerProps}) =>{
    const selector = (s) => ({nodeInternals: s.nodeInternals,edges: s.edges,});
    const { edges } = useStore(selector);
    const nodeId = useNodeId();
    const connected = edges?.find(a => a?.source === nodeId && a?.sourceHandle === id);
    return <Handle type="source" position={Position.Right} isConnectable={connected === undefined} id={id} style={{background: 'green',  opacity: connected === undefined ? "": 0}} title={label}   {...handlerProps} />
}

export const Label = ({label,value,limit}) =>{
    return <Group position={"apart"}>
        <Text size={"xs"} weight={"700"}>{label}</Text>
        {limit && <Text size={"xs"} color={"dimmed"}>{value?.length} / {limit}</Text>}
    </Group>
}

import moment from "moment";

const initialState = {
    isLogged: false,
    userId: '',
    name: "",
    email: '',
    status: 0,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "UserLogin":
            return {...state,...action.user,isLogged: true};
        case 'UserLogout':
            return initialState;
        case "UserInfo":
            const {prefUsed,...otherInfo} = action.data;
            const pref = {...(state?.prefUsed || {}),...(prefUsed || {})}
            return {...state,...otherInfo,prefUsed: pref};
        default:
            return state;
    }
};

import {CheckBoxView, Header, IconView, IconWithText, PageLoading, RadioButtonView} from "../Components/PageComponents";
import {useEffect, useState} from "react";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {
    Grid,
    Group,
    Paper,
    Text,
    Badge,
    Modal,
    TextInput,
    SegmentedControl,
    Button,
    Pagination,
    Divider
} from "@mantine/core";
import {IconCircle, IconCircleCheck, IconCopy, IconMessage} from "@tabler/icons";
import {Colors} from "../../Constants/Colors";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {ProgramStatus} from "../../Constants/Strings";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {BoldText} from "../Components/TextComponents";
import ProgramCreate from "./ProgramCreateEdit";

const Programs = () =>{
    const {sessionId,userId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [programs,setPrograms] = useState([]);
    const [results,setResults] = useState([]);
    const [isAdd,setIsAdd] = useState(false);
    const [isClone,setIsClone] = useState();
    useEffect(()=>{
       getPrograms();
    },[]);

    const getPrograms = async () =>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.programs}/list/${sessionId}`,{});
        setIsLoading(false);
        setPrograms(data?.programs || []);
    }
    return <>
        <Header title={"Programs"} subTitle={"Actual program in which members of specific course & the day wise content of that course is stored."} buttons={[{type:"add",title: "Add Program"},{type:"refresh",title: "Refresh"}]} buttonProps={[{onClick: () =>setIsAdd(true)},{onClick: getPrograms}]}  />
        <PageLoading isLoading={isLoading}>
            <Filter programs={programs} setResults={setResults} />
            <ProgramCard programs={results} sessionId={sessionId} onRefresh={getPrograms} onClone={setIsClone} />
           {isAdd && <ProgramCreate onClose={() => setIsAdd(false)} onRefresh={getPrograms} />}
           {isClone && <ProgramCreate isClone={true} details={programs?.find(a => a?.id === isClone)} onClose={() => setIsClone(false)} onRefresh={getPrograms} />}
        </PageLoading>
    </>
}
export default Programs;

const Filter = ({programs, setResults}) =>{
    const [search,setSearch] = useState("");
    const [programType,setProgramType] = useState("single");
    const [filtered,setFiltered] = useState([]);
    const [page,setPage] = useState(1);

    useEffect(()=>{
        filterPrograms();
    },[search,programType,programs]);
    useEffect(()=>{
        onPageChange();
    },[page]);
    const onPageChange = () =>{
        setResults(filtered?.slice((page-1) * 20, page * 20))
    }
    const filterPrograms = () =>{
        let filter = programs?.filter(a => a?.programType === programType);
        if(search != "") filter = filter?.filter(a => a?.title?.toLowerCase()?.includes(search?.toLowerCase()?.trim()));
        setFiltered(filter);
        setPage(1);
        setResults(filter?.slice(0, 20));
    }
    return <Paper  p={5} mb={10}>
        <Grid>
            <Grid.Col span={6}>
                <Group>
                    <TextInput placeholder={"Search"} size={"xs"} variant={"filled"} value={search} onChange={(e) => setSearch(e.target.value)} />
                    <SegmentedControl
                        size={"xs"}
                        value={programType}
                        onChange={setProgramType}
                        data={[{label: "Individual Program",value: 'single'},{label: "Group Program",value: 'group'}]}
                    />
                </Group>
            </Grid.Col>
            <Grid.Col span={3}>
                <BoldText text={`Total Results: ${filtered?.length} / ${programs?.length}`} mt={7} />
            </Grid.Col>
            <Grid.Col span={3}>
                {filtered?.length > 20 && <Pagination position={"right"} size={"xs"} page={page} onChange={setPage} total={Math.ceil(filtered?.length / 20)}/>}
            </Grid.Col>
        </Grid>
    </Paper>
}
const ProgramCard = ({programs,onClone,sessionId,onRefresh}) =>{
    const {alias} = useSelector(state => state.active)
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const handleDelete = async (programId) =>{
        const data = await RequestPost(`${CloudApi.programs}/delete/${sessionId}`,{programId});
        dispatch(ActionHidePopup());
        dispatch(ActionShowAlert({message: data?.message}));
        onRefresh();
    }
    const openView = (data) => {
        navigation(`/device/${alias}/programs/view`, {state: data})
    }
    const confirmDelete = (id,event) =>{
        event.stopPropagation();
        dispatch(ActionShowPopup({
            title: "Delete Program",
            content: "Are you sure want to Delete Program ? It will Delete All Members & Messages.",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: () => handleDelete(id)
        }))
    }
    const cloneProgram = (id,event) =>{
        event.stopPropagation();
        onClone(id);
    }
    return  <Grid>
        {programs?.map(data => {
            return <Grid.Col span={3}>
                <Paper shadow={'md'} withBorder sx={{cursor: "pointer", borderRadius: 10, overflow: 'hidden'}} onClick={() => openView(data)}>
                    <Group position={'apart'} sx={{backgroundColor: Colors.Primary,color: Colors.Secondary}}>
                        <Text mx={10}>{data?.title}</Text>
                    </Group>
                    {!!data?.settings?.programComplete && data?.status !== 2 && <Badge color={"red"} size={"sm"}>
                        Completes {moment.unix(data?.settings?.programComplete).fromNow()}
                    </Badge>}
                    <Group position={'apart'} ml={5}>
                        <Group position={'left'} sx={{gap: 2}}>
                            <Badge size={'xs'} color={ProgramStatus?.[data?.status]?.color}>{ProgramStatus?.[data?.status]?.label}</Badge>
                            <Group position={'left'} sx={{gap: 2}}>
                                <IconWithText text={data?.parts || "0"} label={"Program Days"} icon={<IconMessage />} />
                            </Group>
                            {data?.groupName && <Badge size={'xs'} mx={5}>{data?.groupName}</Badge>}
                        </Group>
                        <Group>
                            <IconView icon={<IconCopy color={Colors.Primary} />} onClick={(event) => cloneProgram(data?.id,event)} label={"Clone Program"}  />
                            <IconView iconType={'delete'} onClick={(event) => confirmDelete(data?.id,event)} label={"Delete Program"} />
                        </Group>
                    </Group>
                </Paper>
            </Grid.Col>
        })}
    </Grid>
}


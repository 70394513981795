import React, {Component, useEffect, useState} from "react";
import {SegmentedControl, Paper, Text, Grid, Modal, TextInput, Group, Button, Pagination} from '@mantine/core';
import {IconPlus, IconRefresh, IconTrash} from "@tabler/icons";
import {PageLoading} from "../../Pages/Components/PageComponents";
import {R365Api, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {ActionHidePopup, ActionShowAlert, ActionShowPopup} from "../../store/actions/alert";
import {BoldText} from "../../Pages/Components/TextComponents";


const BlockList = () =>{
    const {sessionId} = useSelector(state => state.active);
    const [isLoading,setIsLoading] = useState(false);
    const [isAdd,setIsAdd] = useState(false);
    const [userType,setUserType] = useState("blocked");
    const [numbers,setNumbers] = useState([]);
    const [filtered,setFiltered] = useState([]);

    const [search,setSearch] = useState("");
    const [page,setPage] = useState(1);



    const dispatch = useDispatch();

    useEffect(()=>{
        getDetails();
    },[userType])
    const getDetails = async () =>{
        setIsLoading(true);
        const list = await RequestPost(`${R365Api.blockList}/${sessionId}/list`,{userType});
        setNumbers(list?.numbers);
        setFiltered(list?.numbers);
        setSearch("");
        setPage(1);
        setIsLoading(false);
    }
    const handleDelete = async (number) =>{
        dispatch(ActionShowPopup({
            title: "Delete Number",
            content: "Are you sure want to Delete Number ?",
            successTitle: "Delete",
            cancelTitle: "Cancel",
            onSuccess: async () =>{
                const list = await RequestPost(`${R365Api.blockList}/${sessionId}/delete`,{userType,number});
                dispatch(ActionHidePopup());
                dispatch(ActionShowAlert({message: list?.message}));
            }
        }))

    }

    const handleSearch = (value) => {
        let n = numbers;
        if(value?.trim() !== ""){
            n = n?.filter(a => a?.name?.toLowerCase()?.includes(value?.toLowerCase()) || a?.mobile?.includes(value))
        }
        setSearch(value);
        setFiltered(n);
        setPage(1);
    }



    return <PageLoading isLoading={isLoading}>
        <Group position={"apart"}>
            <SegmentedControl
                my={20}
                size={"xs"}
                data={[{label: "Users",value: "blocked"},{label: "Not Read",value: "notRead"},{label: "Promotional Block",value: "promotionBlocked"}]}
                value={userType}
                onChange={setUserType}
            />
            <Group>
                <Button onClick={() => setIsAdd(true)} compact leftIcon={<IconPlus />}> Add users</Button>
                <Button onClick={getDetails} compact leftIcon={<IconRefresh />}> Refresh</Button>
            </Group>
        </Group>
        {isAdd && <AddNumbers userType={userType} onClose={() => setIsAdd(false)} onRefresh={getDetails} />}
        <Group position={"apart"} mb={10}>
            <TextInput size={"xs"} placeholder={"search"} value={search} onChange={(e) => handleSearch(e.target.value)} />
            <BoldText text={`Filtered Results: ${filtered?.length} / ${numbers?.length}`} />
            <Pagination size={"xs"} total={Math.ceil(numbers?.length / 20)} page={page} onChange={setPage} />
        </Group>
        <Grid>
            {filtered?.slice((page -1) * 20, page * 20)?.map(a =>{
                return <Grid.Col span={3}>
                    <Paper shadow={'md'} withBorder px={5}>
                        <Text>{a?.name}</Text>
                        <Group position={'apart'}>
                            <Text>{a?.mobile}</Text>
                            <IconTrash color={'red'} onClick={()=> handleDelete(a?.mobile)} />
                        </Group>
                    </Paper>
                </Grid.Col>
            })}
        </Grid>
    </PageLoading>
}

export default BlockList;

const AddNumbers = ({userType,onClose,onRefresh}) =>{
    const {sessionId} = useSelector(state => state.active);
    const [numbers,setNumbers] = useState([{name: "",mobile: ""}]);
    const [loading,setLoading] = useState(false);
    const [render,setRender] = useState(0)
    const dispatch = useDispatch();

    const handleAddMore = () =>{
        if(numbers.length < 10) setNumbers([...numbers,{name: "",mobile: ""}]);
    }
    const onChangeManual = (value,type,index) =>{
        let num = numbers;
        num[index][type] = value;
        setNumbers(num);
        setRender(render+1);
    }
    const onDeleteManual = (index) =>{
        let num = numbers;
        num.splice(index, 1);
        setNumbers(num);
        setRender(render+1);
    }
    const handleAdd = async () =>{
        setLoading(true);
        const list = await RequestPost(`${R365Api.blockList}/${sessionId}/add`,{userType,list: numbers});
        dispatch(ActionShowAlert({message: list?.message}));
        onClose();
        onRefresh();
        setLoading(false)
    }

    return <Modal opened={true} size={"90%"} onClose={onClose} title={<Text weight={'bold'}>Add Numbers</Text>}>
        <Group position={'right'} mr={10} mt={10}>
            <Button variant={'outline'} onClick={handleAddMore} disabled={numbers.length >= 10}>Add More</Button>
        </Group>
        <Grid align={'center'} mx={10}>
            {numbers.map((a,i) => {
                return <Grid.Col span={6}><Group>
                    <TextInput label={'Name'} value={a?.name} onChange={e => onChangeManual(e.target.value,'name',i)} />
                    <TextInput label={'Mobile (Format: 919876543210)'} value={a?.mobile} onChange={e => onChangeManual(e.target.value,'mobile',i)} />
                    <IconTrash color={'red'} onClick={() => onDeleteManual(i)} />
                </Group></Grid.Col>
            })}
            {numbers?.length > 0 && <Grid.Col span={6}>
                <Button loading={loading} onClick={handleAdd}>Update</Button>
            </Grid.Col>}
        </Grid>

    </Modal>
}

import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react"
import {
    Button,
    Divider, FileButton,
    Grid,
    Group, Loader,
    SegmentedControl,
    Select,
    Stack,
    Text,
    Textarea,
    TextInput
} from "@mantine/core";
import {useSelector} from "react-redux";
import ImageFilesPicker from "../Components/ImageFilePicker";
import TemplateMessage from "./TemplateMessage";
import {IconView, IconWithText} from "../Components/PageComponents";
import {CloudApi, RequestGet} from "../../Layouts/RequestManager";
import {BoldText, ErrorText} from "../Components/TextComponents";
import {isArrayUnique} from "../../Constants/Functions";
import {IconCircle, IconCircleCheck} from "@tabler/icons";
import {Colors} from "../../Constants/Colors";
import PickFile from "./PickFiles";
import {FilledTextArea, FilledTextInput} from "../Sequence/CustomButton";
import {useMediaQuery} from "@mantine/hooks";

const list = [{label: "Template Message",value: "template"},{label: "Session Message",value: "session"},{label: "Sequence Message",value: "sequence"}];
const MessageOptions = forwardRef(({details,show}, ref) => {
    const {sessionId,chatFlows,sequence} = useSelector(state => state.active);
    const [options,setOptions] = useState(show?.length > 0 ? list?.filter(a => show?.includes(a?.value)): list)

    const [type,setType] = useState(details?.type || "template");
    const [msgType,setMsgType] = useState(details?.msgType ||"Text");
    const [message,setMessage] = useState(details?.message || "");
    const [seqId,setSeqId] = useState(details?.seqId || "");
    const [isError,setIsError] = useState(false);
    const isMobile = useMediaQuery('(max-width: 700px)');
    const ref1 = useRef();

    useImperativeHandle(ref, () => ({
        isValid() {
            if (type === "template") {
                const details = ref1.current.isValid();
                if(details?.isValid){
                    const {variables, name, language} = details?.message;
                    const components = [];
                    const headerImg = variables?.filter(a => a?.key === 'image')?.map(a => ({
                        type: "image",
                        image: {link: a?.value}
                    }));
                    const headerVideo = variables?.filter(a => a?.key === 'video')?.map(a => ({
                        type: "video",
                        video: {link: a?.value}
                    }));
                    const headerDocument = variables?.filter(a => a?.key === 'document')?.map(a => ({
                        type: "document",
                        document: {link: a?.value}
                    }));
                    const header = variables?.filter(a => a?.key?.includes("h_"))?.map(a => ({
                        type: "text",
                        text: a?.value
                    }))
                    const body = variables?.filter(a => a?.key?.includes("b_"))?.map(a => ({type: "text", text: a?.value}));
                    if (body?.length > 0) components.push({type: "body", parameters: body});
                    if (header?.length > 0) components.push({type: "header", parameters: header});
    
                    if (headerImg?.length > 0) components.push({type: "header", parameters: headerImg});
                    if (headerVideo?.length > 0) components.push({type: "header", parameters: headerVideo});
                    if (headerDocument?.length > 0) components.push({type: "header", parameters: headerDocument});
    
                    console.log("components1",message?.buttons,details?.message?.buttons);
                    if (details?.message?.buttons?.length > 0) {
                        details?.message?.buttons?.map((a, i) => {
                            console.log("components2",a);
                            switch (a?.type) {
                                case "PHONE_NUMBER":
                                    break;
                                case "URL":
                                    const value = variables?.find(b => b?.key === `buttons_${i}`)?.value;
                                    if (value) {
                                        components.push({
                                            type: "button",
                                            "sub_type": a?.type,
                                            index: i,
                                            ...(value ? {
                                                parameters: [{
                                                    type: "text",
                                                    text: variables?.find(b => b?.key === `buttons_${i}`)?.value
                                                }]
                                            } : {})
                                        })
                                    }
                                    break;
                                case "COPY_CODE":
                                    components.push({
                                        type: "button",
                                        "sub_type": a?.type,
                                        index: i,
                                        parameters: [{
                                            type: "coupon_code",
                                            coupon_code: variables?.find(b => b?.key === `buttons_${i}`)?.value
                                        }]
                                    });
                                    break;
                                case "QUICK_REPLY":
                                    components.push({
                                        type: "button",
                                        "sub_type": "quick_reply",
                                        index: i,
                                        parameters: [{
                                            type: "payload",
                                            payload: variables?.find(b => b?.key === `buttons_${i}`)?.value
                                        }]
                                    });
                                    break;
                                case "FLOW":
                                    components.push({
                                        type: "button",
                                        "sub_type": a?.type,
                                        index: i,
                                        parameters: [
                                            {type: "action", action: {
                                                    "flow_token": "token123",
                                                    "flow_action_data": {
                                                        name: "Jagan"
                                                    }
                                                }}
                                        ]
                                    })
                                    break;
                            }
                        })
                    }
                    console.log("components3",components);
                    const template = {
                        "name": name,
                        "language": {
                            "code": language || "en_US"
                        },
                        components,
                    };
                    return {isValid: true, message: {template,editOptions: {msgType, type, message: details?.message}}}
                }else{
                    return {isValid: false}
                }
       
            }
            else if(type === "sequence"){
                const isValid = !!seqId
                setIsError(!isValid);
                const name = sequence?.find(a => a?.value === seqId)?.label;
                return {isValid, message: {type, seqId, name,editOptions: {type,seqId,name}}}
            }
            else if (msgType === "Text") {
                const isValid = message?.trim() !== ""
                setIsError(!isValid);
                return {isValid, message: {message,editOptions: {msgType, type, message}}}
            }
            else {
                const {isValid, message, msgDetails} = ref1.current.isValid();
                setIsError(!isValid);
                return {isValid,message: {...message,editOptions: {msgType, type, ...msgDetails}}}
            }
        }
      }));

    return  <>
        {options?.length > 1 && <Grid.Col span={12} lg={6} xl={6}>
            {!isMobile ? <SegmentedControl
                mt={15}
                data={options}
                value={type}
                onChange={setType}
                size={"xs"}
            />: <Select mt={15}
                        data={options}
                        value={type}
                        onChange={setType}
                        size={"xs"}  />}
            {type === "session" &&
                <Text size={"xs"} color={"orange"}>Session Messages Will Deliver to People Who Messaged You in last 24
                    Hours</Text>}
        </Grid.Col>}
        {type === "template" && <TemplateMessage sessionId={sessionId} ref={ref1} details={details} />}
        {type === "session" && <>
            <Grid.Col span={12} lg={6} xl={6}>
                {isMobile ? <>
                    <Select size={"sm"} label={"Message Type"} data={["Text","Image","Audio","Video","Document","Form","List","Url","Buttons"]?.map(a =>({label: a,value:a}))} value={msgType} onChange={setMsgType} />
                </> : <>
                    <BoldText text={"Message Type"} />
                    <SegmentedControl size={"sm"} label={"Message Type"} data={["Text","Image","Audio","Video","Document","Form","List","Url","Buttons"]?.map(a =>({label: a,value:a}))} value={msgType} onChange={setMsgType} />
                </>}
                 </Grid.Col>
            {msgType === "Text" && <Grid.Col span={12}>
                <FilledTextArea label={"Message"} value={message} onChange={setMessage} limit={4096} minRows={6} />
                {/*<Textarea label={"Message"} value={message} onChange={(e) => setMessage(e.target.value)} error={isError && message?.trim() === ""? "Message is Required": ""} />*/}
            </Grid.Col>}
            {["Image",'Audio','Video','Document'].includes(msgType) && <ImageFilesPicker msgType={msgType} ref={ref1}  details={details} />}
            {["Buttons"].includes(msgType) && <ButtonsType ref={ref1} details={details} />}
            {["Url","List"].includes(msgType) && <InteractiveMessage msgType={msgType} ref={ref1} details={details} />}

            {["Form"].includes(msgType) && <FormMessage msgType={msgType} ref={ref1} details={details} />}
        </>}
        {type === "sequence" && <Grid.Col span={12} lg={6} xl={6}>
            <Select size={"xs"} label={"Choose Sequence"} data={sequence} value={seqId} onChange={setSeqId} error={ isError && !seqId ? "Sequence is Required": ""} />
        </Grid.Col>}
    </>
});


const ButtonsType =  forwardRef((props, ref)=>{
    const {details} = props;
    const ref1 = useRef();
    const [headerType,setHeaderType] = useState(details?.header?.type || details?.headerType || "NONE");
    const [headerText,setHeaderText] = useState(details?.header?.text ||details?.headerText || "");
    const [bodyText,setBodyText] = useState(details?.body || details?.bodyText || "");
    const [footerText,setFooterText] = useState(details?.footer || details?.footerText || "");
    const [buttons,setButtons] = useState(details?.buttons || [{title: "Button1", id: "id1"}]);

    const [render,setRender] = useState(0);
    const [isError,setIsError] = useState(false);
    const [errors,setErrors] = useState([]);

    const checkIsValid = () =>{
        let errorCount = 0;
        let errors = []
        if(bodyText?.trim() === "") errorCount++;
        if(buttons?.length === 0) {
            errorCount++;
            errors.push("Please Add Buttons")
        }
        buttons?.map(a => a?.id?.trim() === "" || a?.title?.trim() === "" ? errorCount++: "");

        
        const idUnique = isArrayUnique(buttons?.map(a => a?.id) || []);
        const titleUnique = isArrayUnique(buttons?.map(a => a?.title) || []);
        if(!idUnique) {
            errorCount++;
            errors.push("Ids Must be Unique")
        }
        if(!titleUnique) {
            errorCount++;
            errors.push("Title Must be Unique")
        }

        if(headerType === "TEXT" && headerText?.trim() === "") {
            errorCount++;
            errors.push("Header Text is Mandatory")
        }

        if(!["TEXT","NONE"].includes(headerType)) {
            const data = ref1?.current?.isValid();
            if(!data?.isValid){
                errorCount++;
                errors.push("Media File is Mandatory")
            }
        }

        let isValid = errorCount === 0;
        setIsError(!isValid);
        setErrors(errors);
        return isValid;
    }

    useImperativeHandle(ref, () => ( {
        isValid() {
            const isValid = checkIsValid();
            let header = {type: headerType};
            const {file, isCode} = ref1.current?.isValid() || {};
            if(headerType === "NONE") {
                header = undefined;
            }else if(headerType === "TEXT") {
                header = {...header, text: headerText};
            }else{
                header = {...header, [headerType?.toLowerCase()]: isCode? {code: file}: {link: file}}
            }
            const message = {header,bodyText,footerText,buttons};
            const msgDetails = {headerText,bodyText,footerText,buttons,headerType,file};
            return {isValid, message, msgDetails}
        }
    }));
    const handleValues = (key,value,index) =>{
        const b = buttons?.map((a,i) => {
            if(i === index) return {...a,[key]: value};
            return a;
        });
        setButtons(b);
        setRender(render+1)
    }

    return <>
        <Grid.Col span={12}>
            <Group>
                <Text weight={"bold"} size={"md"}>Header</Text>
                <Group my={3}>{["NONE","TEXT","IMAGE","VIDEO","DOCUMENT"]?.map(a => <IconWithText onClick={() => setHeaderType(a)} icon={headerType === a ? <IconCircleCheck size={14} color={Colors.Primary} /> : <IconCircle color={Colors.Primary} size={14} />} text={a} textProps={{size: "sm"}} />)}</Group>
            </Group>
            {headerType === "TEXT" && <FilledTextInput label={"Header Message"} value={headerText} onChange={setHeaderText} limit={60}  error={isError && headerText?.trim() === "" ? "Message is Required For Text Type": ""} />}
        </Grid.Col>
        {!["NONE","TEXT"]?.includes(headerType) && <>
            <PickFile msgType={headerType} ref={ref1}  details={details} />
        </>}
        <Grid.Col span={12} lg={6} xl={6}>
            <FilledTextArea label={"Body Message"} value={bodyText} onChange={setBodyText} limit={1024} minRows={4} error={isError && bodyText?.trim() === "" ? "Body text is Required": ""} />
        </Grid.Col>
        <Grid.Col span={12} lg={6} xl={6}>
            <FilledTextInput label={"Footer Message"} value={footerText} onChange={setFooterText} limit={60} />
        </Grid.Col>
        <Grid.Col span={12}>
            <Group>
                <Text weight={"bold"} variant={"gradient"} size={"md"}>Buttons</Text>
                <Button compact onClick={() => buttons?.length < 3 ? setButtons([...buttons,{id: "",title: ""}]): {}}>Add Button</Button>
            </Group>
        </Grid.Col>
        <Grid.Col span={12}>
            <Stack sx={{gap: 3}}>
                {errors?.map((a,i) => <ErrorText text={`${i+1}. ${a}`} align="left" size="xs" />)}
            </Stack>
        </Grid.Col>
        {buttons?.map((a,i) =>{
            return <Grid.Col span={12} lg={6} xl={6}>
                <Group>
                    <FilledTextInput label={"Button Title"} value={a?.title} onChange={(value) => handleValues("title",value,i)} limit={20} />
                    <FilledTextInput label={"Button Id"} value={a?.id} onChange={(value) => handleValues("id",value,i)} limit={256} />
                    {buttons?.length > 1 && <IconView iconType={"delete"} onClick={() => setButtons(buttons?.filter((a,index) => i !== index))} />}
                </Group>
            </Grid.Col>
        })}
       
    </>
});
const InteractiveMessage = forwardRef((props, ref)=>{
    const {msgType,details} = props;
    const ref1 = useRef();
    const [headerType,setHeaderType] = useState(details?.headerType || "NONE");
    const [header,setHeader] = useState(details?.header || "");

    const [body,setBody] = useState(details?.body || "");
    const [footer,setFooter] = useState(details?.footer || "");
    const [btnText,setBtnText] = useState(details?.btnText || "");
    const [btnUrl,setBtnUrl] = useState(details?.btnUrl || "");
    const [sections,setSections]= useState(details?.sections ||[{title: "Section 1",list: [{id: "id1", title: "Title 1",description: ""}]}]);
    const [render,setRender] = useState(0);
    const [isError,setIsError] = useState(false);
    const [errors,setErrors] = useState([]);

    const checkIsValid = () =>{
        let errorCount = 0;
        let errors = []
        if(body?.trim() === "") errorCount++;
        if(sections?.length === 0) {
            errorCount++;
            errors.push("Please Add Sections")
        }
        const ids = [];
        const titles = [];
        sections?.map(a => {
            if(a?.title?.trim() === "" || a?.list?.length === 0) errorCount++;
            ids.push(...(a?.list?.map(a => a?.id) || []))
            titles.push(...(a?.list?.map(a => a?.title) || []))
        });
        const idUnique = isArrayUnique(ids?.filter(a => a?.trim() !== ""));
        const titleUnique = isArrayUnique(titles?.filter(a => a?.trim() !== ""));

        if(!idUnique) {
            errorCount++;
            errors.push("Ids Must be Unique")
        }
        if(!titleUnique) {
            errorCount++;
            errors.push("Title Must be Unique")
        }
        if(headerType === "TEXT" && header?.trim() === "") {
            errorCount++;
            errors.push("Header Text is Mandatory")
        }

        if(!["TEXT","NONE"].includes(headerType)) {
            const data = ref1?.current?.isValid();
            if(!data?.isValid){
                errorCount++;
                errors.push("Media File is Mandatory")
            }
        }
        console.log("error", errorCount);
        let isValid = errorCount === 0;
        setIsError(!isValid);
        setErrors(errors);
        return isValid;
    }


    useImperativeHandle(ref, () => ( {
        isValid() {
            let isValid = false;
            setErrors([]);
            const {file, isCode} = ref1.current?.isValid() || {};

            let message = {header,body,footer,file};
            let msgDetails = {headerType,header,body,footer, btnText,btnUrl,sections,file};
            if(msgType === "Url"){
                let header1 = {type: headerType};
                if(headerType === "NONE") {
                    header1 = undefined;
                }else if(headerType === "TEXT") {
                    header1 = {...header1, text: header};
                }else{
                    header1 = {...header1, [headerType?.toLowerCase()]: isCode ? {code: file} : {link: file}}
                }
                isValid =  body?.trim() !== "" && btnText?.trim() !== "" && btnUrl?.trim() !== "";
                message = {...message,header: header1,urlButton: {text: btnText,link: btnUrl}}
            }else{
                isValid =  checkIsValid();
                message = {...message,header,btnText,list: sections}
            }

            setIsError(!isValid);
            return {message,msgDetails, isValid};
        }
    }));



    const handleSections =()=>{
        setSections([...sections, {title: "",list: [{id: "",title: ""}]}]);
        setRender(render+1);
    }
    const handleListItems =(index)=>{
        let count = 0;
        sections?.map(a => count += (a?.list?.length|| 0));
        if(count < 10){
            const s = sections?.map((a,i) => i === index ? {...a,list: [...(a?.list||[]),{id: "",title: "",description:""}]}: a)
            setSections(s);
            setRender(render+1);
        }
    }
    const handleRemoveList = (sectionId,listId) =>{
        const s = sections?.map((a,i) => i === sectionId ? {...a,list: a?.list?.filter((b,j) => j !== listId)}: a)
        setSections(s);
        setRender(render+1);
    }
    const DeleteSection = (i) =>{
        setSections(sections?.filter((a,index) => index !== i))
        setRender(render+1)
    }
    const handleSectionValues = (key,value,sectionId,) =>{
        const s = sections?.map((a,i) => {
            if(i !== sectionId) return a;
            return {...a,[key]: value}
        });
        setSections(s);
        setRender(render+1);
    }
    const handleListValues = (key,value,sectionId,listId) =>{
        const s = sections?.map((a,i) => {
            if(i !== sectionId) return a;
            return {...a, list: a?.list?.map((b, j) => j === listId ? ({...b, [key]: value}): b)}
        });
        setSections(s);
        setRender(render+1);
    }

    return  <>
        {["Url"].includes(msgType) && <>
            <Grid.Col span={12}>
                <Group>
                    <Text weight={"bold"} size={"md"}>Header</Text>
                    <Group my={3}>{["NONE","TEXT","IMAGE","VIDEO","DOCUMENT"]?.map(a => <IconWithText onClick={() => setHeaderType(a)} icon={headerType === a ? <IconCircleCheck size={14} color={Colors.Primary} /> : <IconCircle color={Colors.Primary} size={14} />} text={a} textProps={{size: "sm"}} />)}</Group>
                </Group>
                {headerType === "TEXT" && <FilledTextInput label={"Header Message"} value={header} onChange={setHeader} limit={60}  error={isError && header?.trim() === "" ? "Message is Required For Text Type": ""}  />}
            </Grid.Col>
            {!["NONE","TEXT"]?.includes(headerType) && <PickFile ref={ref1} msgType={headerType} details={details?.header?.[headerType?.toLowerCase()]?.code} isLink={true} />}
        </>}
        {["List"].includes(msgType) && <Grid.Col span={12} lg={6} xl={6}>
           <FilledTextInput label={"Header Message"} value={header} onChange={setHeader} limit={60}  error={isError && header?.trim() === "" ? "Message is Required": ""}  />
         </Grid.Col>}
        <Grid.Col span={12} lg={6} xl={6}>
            <FilledTextArea label={"Body Message"} value={body} onChange={setBody} limit={4096} error={isError && body?.trim() === "" ? "Body text is Required": ""}  />
        </Grid.Col>
        <Grid.Col span={12} lg={6} xl={6}>
            <FilledTextInput label={"Footer Message"} value={footer} onChange={setFooter} limit={60} error={isError && body?.trim() === "" ? "Body text is Required": ""}  />
        </Grid.Col>
        <Grid.Col span={12} lg={6} xl={6}>
            <FilledTextInput required label={"Button Text"} value={btnText} onChange={setBtnText} limit={20} error={isError && btnText?.trim() === "" ? "Button text is Required": ""} />
        </Grid.Col>
        {["Url"].includes(msgType) && <>
            <Grid.Col span={12} lg={6} xl={6}>
                <FilledTextInput required label={"Button Url"} value={btnUrl} onChange={setBtnUrl} error={isError && btnUrl?.trim() === "" ? "Button Url is Required": ""} />
            </Grid.Col>
        </>}
        {["List"].includes(msgType) && <>
            <Grid.Col span={12}>
                <Stack sx={{gap: 3}}>
                    {errors?.map((a,i) => <ErrorText text={`${i+1}. ${a}`} align="left" size="xs" />)}
                </Stack>
         </Grid.Col>
            <Grid.Col span={12}>
                <Group>
                    <Stack sx={{gap: 3}}>
                        <Text weight={"bold"} size={"md"} variant={"gradient"}>Sections</Text>
                        <Text color={"dimmed"} size={"xs"}>Max. 10 List Items</Text>
                    </Stack>
                    <Button compact mt={15} onClick={handleSections}>Add Section</Button>
                </Group>
            </Grid.Col>
            {sections?.map((a,i) =>{
                return <>
                    <Grid.Col span={12}>
                        <Group>
                            <FilledTextInput limit={24} required size={"xs"} label={"Section  Title"} value={a?.title} onChange={(value) => handleSectionValues("title",value,i)} error={isError && a?.title?.trim() === "" ? "Title is Required": ""}  />
                            <Button compact mt={15} onClick={() => handleListItems(i)}>Add List Items</Button>
                           {sections?.length > 1 && <Button compact color={"red"} mt={15} onClick={() => DeleteSection(i)}>Remove Section</Button>}
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={12}>
                        {a?.list?.map((b,j) =>{
                            return <Group>
                                <FilledTextInput limit={200} required label={"Row  id"} value={b?.id} onChange={(value) => handleListValues("id",value, i, j)} error={isError && b?.id?.trim() === "" ? "Id is Required": ""}  />
                                <FilledTextInput limit={24} required label={"Row  Title"} value={b?.title} onChange={(value) => handleListValues("title",value,i, j)} error={isError && b?.title?.trim() === "" ? "Title is Required": ""}  />
                                <FilledTextInput limit={72} label={"Row  Description"} value={b?.description} onChange={(value) => handleListValues("description",value,i, j)} />
                                {a?.list?.length > 1 && <IconView iconType={"delete"} onClick={() => handleRemoveList(i,j)} />}
                            </Group>
                        })}
                    </Grid.Col>
                    <Grid.Col span={12}><Divider /></Grid.Col>
                </>
            })}
        </>}
    </>
});
const FormMessage = forwardRef((props, ref)=>{
    const {msgType,details} = props;
    const {forms,sessionId} = useSelector(state => state.active);
    const ref1 = useRef();

    const [headerText,setHeaderText] = useState(details?.headerText || "");
    const [body,setBody] = useState(details?.body || "");
    const [footer,setFooter] = useState(details?.footer || "");
    const [formId,setFormId] = useState(details?.formId || "");
    const [screenId,setScreenId] = useState(details?.screenId || "");
    const [btnText,setBtnText] = useState(details?.btnText || "");

    const [headerType,setHeaderType] = useState(details?.headerType || "NONE");
    const [payload,setPayload] = useState(details?.payload || []);


    const [selected,setSelected] = useState({});
    const [isScreenLoad,setIsScreenLoad] = useState(false);
    const [isError,setIsError] = useState(false);

    const [screens,setScreens] = useState([]);
    const [render,setRender] = useState(0);

    useEffect(() =>{
        setSelected(forms?.find(a => a?.value === formId));
        if(formId) getFlowScreens(formId);
    },[formId])

    const getFlowScreens = async (flowId) =>{
        setIsScreenLoad(true)
        const data = await RequestGet(`${CloudApi.whatsappFlows}/screens/${sessionId}/${flowId}`);
        setScreens(data?.screens || []);
        setRender(render+1);
        setIsScreenLoad(false)
    }

    useImperativeHandle(ref, () => ( {
        isValid() {
            let header = {type: headerType};
            const {file, isCode} = ref1.current?.isValid() || {};
            if(headerType === "NONE") {
                header = {type: "NONE"};
            }
            else if(headerType === "TEXT") {
                header = {...header, text: headerText};
            }else{
                header = {...header, [headerType?.toLowerCase()]: isCode? {code: file}: {link: file}}
            }

            let payloadFormat = {};
            payload.map(a => payloadFormat[a?.key] = a?.value);
            console.log(payloadFormat);

            if(formId && screenId && selected?.status !== "PUBLISHED"){
                let message = {draftForm: {formId,screenId,name: selected?.label, payload: payloadFormat}};
                let msgDetails = {formId,screenId,name: selected?.label, payload};
                setIsError(false);
                return {message,msgDetails, isValid: true };
            }else if(formId && screenId && selected?.status === "PUBLISHED"){
                let message = {form: {formId,screenId,name: selected?.label},header,body,footer,btnText,file,payload: payloadFormat};
                let msgDetails = {formId,screenId,headerText,headerType,body,footer,btnText,name: selected?.label,file,payload};
                const isValid =  body?.trim() !== "" && btnText?.trim() !== "";
                setIsError(!isValid);
                return {message,msgDetails, isValid};
            }else{
                setIsError(true);
                let message = {formId,screenId,header,body,footer,btnText,file,payload: payloadFormat};
                let msgDetails = {formId,screenId,headerText,headerType,body,footer,btnText,file,payload};
                return {message,msgDetails, isValid: false };
            }
        }
    }));
    const handleValues = (key,value,index) =>{
        const b = payload?.map((a,i) => {
            if(i === index) return {...a,[key]: value};
            return a;
        });
        setPayload(b);
        setRender(render+1)
    }

    return  <>
        <Grid.Col span={12} lg={6} xl={6}>
            <Select required size={"xs"} label={"Choose Form"} data={forms} value={formId} onChange={setFormId} error={isError && !formId ? "Please Select Form": ""}  />
        </Grid.Col>
        {formId && <Grid.Col span={12} lg={6} xl={6}>
            {isScreenLoad ? <Loader size={"xs"} mt={20}/> :
                <Select required size={"xs"} label={"Choose Screen"} data={screens} value={screenId} onChange={setScreenId} error={isError && !screenId ? "Please Select Screen": ""} />}
        </Grid.Col>}
        {formId && selected?.status === "PUBLISHED" && <>
            <Grid.Col span={12} lg={6} xl={6}>
                <Group>
                    <Text weight={"bold"} size={"md"}>Header</Text>
                    <Group my={3}>{["NONE","TEXT","IMAGE","VIDEO","DOCUMENT"]?.map(a => <IconWithText onClick={() => setHeaderType(a)} icon={headerType === a ? <IconCircleCheck size={14} color={Colors.Primary} /> : <IconCircle color={Colors.Primary} size={14} />} text={a} textProps={{size: "sm"}} />)}</Group>
                </Group>
            </Grid.Col>
            <Grid.Col span={12} lg={6} xl={6}>
                {headerType === "TEXT" && <FilledTextInput label={"Header Message"} value={headerText} onChange={setHeaderText} limit={60}/>}
            </Grid.Col>
            {!["NONE","TEXT"]?.includes(headerType) && <PickFile msgType={headerType} ref={ref1} details={details} isLink={true} />}
            <Grid.Col span={12} lg={6} xl={6}>
                <FilledTextArea required label={"Body Message"} value={body} onChange={setBody} error={isError && body?.trim() === "" ? "Body Message is Required": ""} />
            </Grid.Col>
            <Grid.Col span={12} lg={6} xl={6}>
                <FilledTextInput  label={"Footer Message"} value={footer} onChange={setFooter} />
            </Grid.Col>
            <Grid.Col span={12} lg={6} xl={6}>
                <FilledTextInput required  label={"Button Text"} value={btnText} onChange={setBtnText} error={isError && btnText?.trim() === "" ? "Button text is Required": ""} />
            </Grid.Col>
        </>}
        <Grid.Col span={12}>
            <Group>
                <Text weight={"bold"} variant={"gradient"} size={"md"}>Payload</Text>
                <Button compact onClick={() => payload?.length < 10 ? setPayload([...payload,{key: "",value: ""}]): {}}>Add Payload</Button>
            </Group>
        </Grid.Col>
        {payload?.map((a,i) =>{
            return <Grid.Col span={12} lg={6} xl={6}>
                <Group>
                    <FilledTextInput label={"Payload Key"} value={a?.key} onChange={(value) => handleValues("key",value,i)} limit={20} />
                    <FilledTextInput label={"Payload Value"} value={a?.value} onChange={(value) => handleValues("value",value,i)} limit={256} />
                    {payload?.length > 1 && <IconView iconType={"delete"} onClick={() => setPayload(payload?.filter((a,index) => i !== index))} />}
                </Group>
            </Grid.Col>
        })}


    </>
});


export default MessageOptions;

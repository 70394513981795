import {Badge, Grid, Group, Paper, Select, Text, Button, TextInput, Pagination, Modal} from "@mantine/core";
import {ChatbotKeyTypes} from "../../Constants/Strings";
import React, {useEffect, useState} from "react";
import {convertUnixToFormat} from "../../Constants/Functions";
import {CloudApi, RequestPost} from "../../Layouts/RequestManager";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {IconDownload, IconSearch} from "@tabler/icons";
import {Header, IconView, PageLoading} from "../Components/PageComponents";
import {BoldText} from "../Components/TextComponents";
import PreviewScheduleMessage from "../Components/PreviewScheduleMessage";
const PageHeading = {
    link: {name: "Chatbot", link: `chatbots`},
    title: "Chatbot Leads",
    subTitle: "Leads of Chatbots will Show Here",
    buttons: [{type: "refresh",title: "Refresh"}]
}

const ChatbotLeads = () =>{
    const {sessionId,alias,chatbots,...params} = useSelector(state => state.active);
    const {state} = useLocation();
    const [isLoading,setIsLoading] = useState(false);
    const [leads,setLeads] = useState([]);
    const [chatbotId,setChatbotId] = useState(state?.id);
    const [total,setTotal] = useState(1);
    const [page,setPage] = useState(1);
    const [search,setSearch] = useState("");
    const [viewMessage,setViewMessage] = useState();

    useEffect(() =>{
        getLeads();
    },[chatbotId,page,search])
    const getLeads= async ()=>{
        setIsLoading(true);
        const data = await RequestPost(`${CloudApi.chatbot}/leads/${sessionId}`, {id:chatbotId,page,search});
        setLeads(data?.leads || []);
        setTotal(data?.total);
        setIsLoading(false);
    }

    return <>
        <Header {...PageHeading} />
        <PageLoading isLoading={isLoading}>
            <Options search={search} total={total} page={page} setPage={setPage} setSearch={setSearch} chatbotId={chatbotId} setChatbotId={setChatbotId} />
            <Grid m={5}>
                {leads?.map(a => <Grid.Col span={3}>
                    <Paper shadow={'md'} withBorder>
                        <Group position={'apart'}>
                            <Text mx={5} size={'sm'}>{a?.mobile}</Text>
                            <Badge  size={'xs'} ><Text>{ChatbotKeyTypes[a?.key?.type]} {a?.key?.key}</Text></Badge>
                        </Group>
                        <Group position={'apart'}>
                            <Text mx={5} size={'xs'} color={'dimmed'}>{convertUnixToFormat(a?.created)}</Text>
                            {/*<IconView label={"View user Message"} iconType={"view"} onClick={() => setViewMessage(a)} />*/}
                        </Group>
                    </Paper>
                </Grid.Col>)}
            </Grid>
        </PageLoading>
    </>
}
export  default ChatbotLeads;

const Options = ({total,page,setPage,setSearch,chatbotId,setChatbotId,search}) =>{
    const [searchTerm,setSearchTerm] = useState(search || "");
    const {chatbots,sessionId} = useSelector(state => state.active);

    return  <Grid mx={10}>
        <Grid.Col span={3}>
            <Select size={'xs'} label={"Select Chatbot"} data={chatbots || []} value={chatbotId} onChange={setChatbotId} />
        </Grid.Col>
        <Grid.Col span={3}>
            <Group position={"left"} mt={25}>
                <TextInput size={"xs"} placeholder={"Search"} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                <IconView icon={<IconSearch />} variant={"filled"} onClick={() => {
                    setSearch(searchTerm);
                    setPage(1);
                }} />
            </Group>
        </Grid.Col>
        <Grid.Col span={3}>
            <Group position={"left"} mt={25}>
                <BoldText text={`Total Results: ${total}`} size={"md"} />
            </Group>
        </Grid.Col>
        <Grid.Col span={3}>
            <Group position={"right"}  mt={25}>
                {total > 40 && <Pagination size={"xs"} total={Math.ceil(total / 40)} page={page} onChange={setPage}/>}
                <a href={`${CloudApi.downloadChatbot}/${sessionId}/${chatbotId}`}>
                    <Button size={'xs'} variant={'gradient'} leftIcon={<IconDownload />}>Export as Excel</Button>
                </a>
            </Group>
        </Grid.Col>
    </Grid>
}

import React from "react";
import {Tabs} from "@mantine/core";
import R365Settings from "./R365Settings";
import Messages from "./Messages";
import MiscOperations from "./MiscOperations";
import BlockList from "./BlockList";

const Actions = () =>{
    return <>
        <Tabs  keepMounted={false} defaultValue="settings">
            <Tabs.List grow>
                <Tabs.Tab value="settings">Settings</Tabs.Tab>
                <Tabs.Tab value="messages">Messages</Tabs.Tab>
                <Tabs.Tab value="misc">Misc</Tabs.Tab>
                <Tabs.Tab value="block">BlockList</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="settings" pt="xs">
                <R365Settings />
            </Tabs.Panel>
            <Tabs.Panel value="messages" pt="xs">
                <Messages />
            </Tabs.Panel>
            <Tabs.Panel value="misc" pt="xs">
                <MiscOperations />
            </Tabs.Panel>
            <Tabs.Panel value="block" pt="xs">
                <BlockList />
            </Tabs.Panel>

        </Tabs>
    </>
}

export default Actions;
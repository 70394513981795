import React, {useEffect, useState} from "react";
import {CloudApi, RequestGet, RequestPost} from "../../Layouts/RequestManager";
import {useSelector} from "react-redux";
import {Text, Paper, Group, Stack, Divider, Button} from "@mantine/core";
import WhatsappEmbedded from "../Device/WhatsappEmbedded";
import {Header, IconWithText, PageLoading} from "../Components/PageComponents";
import DeviceConnection from "./DeviceConnection";
import DeviceHealth from "./DeviceHealth";
import UserMessageStats from "./UserMessageStats";
import DeviceProfile from "./DeviceConnection";
const PageHeading = {
    title: "Dashboard",
    subTitle: "Device Connection Status & Analytics will show here",
    buttons: [
        {type: "whatsapp", title: "Whatsapp Manager"},
        {type: "payments", title: "Payments"},
        {type: "refresh", title: "Refresh"},
    ]
}
const Dashboard = () =>{
    const {sessionId,userId,alias,...active} = useSelector(state => state.active);
    const [connection,setConnection] = useState();
    const [health,setHealth] = useState();
    const [profile,setProfile] = useState();
    const [isLoading,setIsLoading] = useState(false);

    useEffect(() =>{
        getDetails();
    },[])

    const getDetails = async () =>{
        setIsLoading(true);
        const details = await RequestGet(`${CloudApi.deviceDashboard}/${userId}/${sessionId}`);
        setConnection(details?.connection);
        setProfile(details?.profile);
        setHealth(details?.health);
        setIsLoading(false);
    }
    const openPayments = () =>{
        window.open(`https://business.facebook.com/billing_hub/accounts/details?asset_id=${connection?.businessId}&business_id=4525347817604088&placement=standalone&global_scope_id=4525347817604088&account_type=whatsapp-business-account`)
    }
    const openWhatsapp = () =>{
        window.open(`https://business.facebook.com/wa/manage/phone-numbers/?business_id=4525347817604088&waba_id=${connection?.businessId}`)
    }

    return <>
        <Header {...PageHeading} buttonProps={[{onClick: openWhatsapp},{onClick: openPayments},{onClick: getDetails}]} />
        <PageLoading isLoading={isLoading}>
            {connection && connection?.whatsappId ? <Group align={"flex-start"}>
                    {/*<DeviceConnection connection={connection}  />*/}
                    <DeviceProfile details={profile} />
                    <DeviceHealth health={health} />
                    <UserMessageStats />
            </Group>:
                <Paper shadow={"md"} withBorder p={5} sx={{width: 400}}>
                    <Group position={"apart"}>
                        <Text weight={"bold"}>Device Not Connected</Text>
                        <WhatsappEmbedded sessionId={sessionId} alias={alias} />
                    </Group>
                </Paper>}
        </PageLoading>
    </>
}
export default Dashboard;
